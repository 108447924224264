import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';

export default class StudentMarkDetails extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      EXAM_ID: '',
      SESSION_ID: '',
      EXAM_NAME: '',
      START_DATE: '',
      EXAM_STATUS: '',

    }

  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/StudentMarkDetails/'} exact render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Student Mark Details</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{ '--mMargin': '80px' }}>
                      <div className="row bg_card_blue_outline" style={{ margin: '5px 15px 10px 15px', height: '90px' }}>
                        <div className="col-sm-3  bg_card_blue gravity_center" style={{ padding: '1px', height: '85px' }}>
                          <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                            <div className="circular_image">
                              <img src='#' alt=""></img>
                              <p className="student_id gravity_center"> <small> {'122'} </small></p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6" style={{ padding: '5px 10px', height: '85px' }}>
                          <h5> {'xyz'} </h5>
                          <p className="mb-0"> Father: <span>{'xyz'}</span> </p>
                          <p className="mb-0" style={{ fontSize: '10px' }}><span>{'xyz'}</span> </p>
                        </div>
                        <div className="col-sm-3 padding_0 gravity_center" style={{ height: '85px' }}>
                          <div className="text-center bg_card_blue_outline width_fill_parent" style={{ margin: '2px' }}>
                            <p className="mb-0"> Roll No:{'xyz'} </p>
                            <p> <div className="st-list-roll-n" style={{
                              background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                              margin: '2px auto', fontSize: '20px'
                            }}>
                              <input type="checkbox" id="issue_notice_c" class="ch-in" value="" /></div>
                            </p>
                            <h6><span> {'xyz'} </span>  </h6>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className="col-sm-6 col-md-6">
                          <Link to={'/AdminMain/StudentMarkDetails'}>
                            <button className="btn btn-success" style={{ width: '100%', margin: '', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Pre Student</button>
                          </Link>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <button className="btn btn-success" style={{ cursor: 'pointer', width: '100%', margin: '', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Next Student</button>
                        </div>
                      </div>
                      <div className='row padding_0'>
                        <div className='col-md-12 padding_0'>
                          <table className='table_border'>
                            <tbody className=''>
                              <tr>
                                <th style={{ 'border': '2px solid black','width':'10%','backgroundColor': 'var(--primary_color_shade2)' }}>
                                  <div className='admission_data'>S.N</div>
                                </th>
                                <th style={{ 'border': '2px solid black','width':'30%','backgroundColor': 'var(--primary_color_shade2)' }}>
                                  <div className='admission_data'>Subject</div>
                                </th>
                                <th style={{ 'border': '2px solid black','width':'30%','backgroundColor': 'var(--primary_color_shade2)' }}>
                                  <div className='admission_data'>Subject Paper</div>
                                </th>
                                <th style={{ 'border': '2px solid black','width':'13%','backgroundColor': 'var(--primary_color_shade2)' }}>
                                  <div className='admission_data'>Obtained Mark</div>
                                </th>

                              </tr>
                              <tr>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>01</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>English</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>English</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>20/50</div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>02</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>Marathi</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>Marathi</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>40/50</div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>03</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>Math</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>Math</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>36/50</div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>04</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>हिंदी</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>हिंदी</div>
                                </td>
                                <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                  <div className='table_data'>36/50</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                )
              }
            } />

          </switch>
        </Router>
      )
    }
  }
}