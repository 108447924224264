import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import SelectClass from '../ChildComponent/SelectClass';

export default class ClassChange extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: true,
      Loading: false,


    }
  }
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else if (this.state.Loading) {
      return <MyLoader />
    }
    else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/ClassChange'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Admission Link</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>


                      <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>
                      <div className='row'>
                        <div className="col-sm-6 col-md-6 p-0">
                          <div className="row bg_card_blue_outline" style={{ margin: '5px 15px 10px 15px', height: '90px' }}>
                            <div className="col-sm-3  bg_card_blue gravity_center" style={{ padding: '1px', height: '85px' }}>
                              <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                                <div className="circular_image">
                                  <img src="" alt=""></img>
                                  <p className="student_id gravity_center"> <small> {"Student id"} </small></p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6" style={{ padding: '5px 10px', height: '85px' }}>
                              <h5> {"varun"} </h5>
                              <p className="mb-0"> Father: <span>{"chandra vijay"}</span> </p>
                              <p className="mb-0" style={{ fontSize: '10px' }}><span>{"manikadih"}</span> </p>

                            </div>
                            <div className="col-sm-3 padding_0 gravity_center" style={{ height: '85px' }}>
                              <div className="text-center bg_card_blue_outline width_fill_parent" style={{ margin: '2px' }}>
                                <p className="mb-0"> Roll No:{"1123"} </p>


                                <p> <div className="st-list-roll-n" style={{
                                  background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                                  margin: '2px auto', fontSize: '20px'
                                }}>
                                  <input type="checkbox" id="issue_notice_c" class="ch-in" value="" /></div>
                                </p>

                                <h6><span> {"LKG"} </span>  </h6>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                )
              }
            } />

          </switch>
        </Router>
      )
    }
  }
}
