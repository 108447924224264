import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';

import axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import MdlSelectSingleStudent from '../dialog/MdlSelectSingleStudent';
import myToast from '../Utils/MyToast';
import { Button, Card } from 'react-bootstrap';
import MdlEditStudentFee from '../dialog/MdlEditStudentFee';

export default class SpecialCaseCreation extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: true,
      Loading: false,
      showMsg:false,
      SingleStudentSelector:false,
      showEditFee:false,
    
      mStudentFee:[],
      mSelectedStudent:'',
      ENROLLMENT_NO:'',
      SESSION_ID:'',
      SESSION_NAME:'',
      CLASS_ID:'',
      SECTION_ID:'',
      FEE_NAME:'',
      FEE_ID:'',
      FEE_VALUE:'',
      NEW_FEE_VALUE:''
  
    }
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleClose = this.HandleClose.bind(this);

   
  }
  componentDidMount()
  {
    
      window.scrollTo(0, 0);
      
  }
  EditStudentFee = (selected_fee) => {
    this.state.FEE_ID=selected_fee.fee_id;
    this.state.FEE_NAME=selected_fee.fee_name;
    this.state.FEE_VALUE=selected_fee.student_fee;

    this.showEditStudentFee();   
  }
  showEditStudentFee = () => {

    this.setState({
      showEditFee: !this.state.showEditFee
    });   
  }
  handleFeeEditDone = (new_fee_value) => {
   
    this.state.NEW_FEE_VALUE=new_fee_value;
   
    this.setState({
      showEditFee: !this.state.showEditFee,
     
    }); 
  
    this.updateStudentFee(); 
  }

  HandleShow = () => {

    this.setState({
      SingleStudentSelector: !this.state.SingleStudentSelector
    });   
  }
  
  HandleClose = () => {

    this.setState({
      SingleStudentSelector:false
    });  
     
  }
  

  HandleSelectedStudent = (student_id,selected_item,session_id,session_name) => {
    this.state.ENROLLMENT_NO=student_id;
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.state.CLASS_ID=selected_item.class_id;
    this.state.SECTION_ID=selected_item.section_id;
    this.setState({
      
      SingleStudentSelector:false,
      mSelectedStudent:selected_item,
    
   
    });
    this.state.mSiblingsDetails=[];
    this.checkIfStudentFeeCollected();
    
  }

  
  SelectStudent=()=>{
   
    this.setState({
    
      MultipleStudentSelector:true,
    
    })
   
  }

  checkIfStudentFeeCollected(){
  
    this.setState({
      Loading:true,
      showMsg:false
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
   
    const FUNCTION_NAME='checkIfStudentFeeCollected.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{
       
        student_id:this.state.ENROLLMENT_NO,
        class_id:this.state.CLASS_ID,
        session_id:this.state.SESSION_ID,
        school_code:SCHOOL_CODE,
   
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        //alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
            showMsg:true
           
            })
        }
        else{
          //let api_remark=response.api_remark;
          //myToast.notify_warning(api_remark);
          this.getStudentFee();
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

    
  getStudentFee(){
  
    this.setState({
      Loading:true,
  
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
   
    const FUNCTION_NAME='getStudentSpecialFee.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{
       
        enrollment_no:this.state.ENROLLMENT_NO,
        class_id:this.state.CLASS_ID,
        session_id:this.state.SESSION_ID,
        school_code:SCHOOL_CODE,
   
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
         // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
               mStudentFee:response.data
           
            })
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
  updateStudentFee(){
  
      this.setState({
        Loading:true,
      
      })

      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;

      const SCHOOL_CODE=SessionManager.getSchoolCode();
     
      const FUNCTION_NAME='updateSpecialFee.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      // var params = new URLSearchParams();
        //  params.append('school_code', SCHOOL_CODE);
      
      axios.get(URL,{
        params:{
          staff_id:STAFF_ID,
          student_id:this.state.ENROLLMENT_NO,
          session_id:this.state.SESSION_ID,
          class_id:this.state.CLASS_ID,
          section_id:this.state.SECTION_ID,
          fee_id:this.state.FEE_ID,
          fee_value_old:this.state.FEE_VALUE,
          fee_value:this.state.NEW_FEE_VALUE,
          school_code:SCHOOL_CODE,
     
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
          
          if(api_status=="OK")
          {
               let msg="Updated Successfully";
               myToast.notify_warning(msg);
               this.getStudentFee();
             
          }
          else{
            let api_remark=response.api_remark;
             myToast.notify_warning(api_remark);
            this.getStudentFee();
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
  
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else if (this.state.Loading) {
      return <MyLoader />
    }
    else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/SpecialCaseCreation'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">{"Special Case"}</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '100px' }}>

                    <div className="row" style={{margin:'5px 20px'}}>
                        <div className="col-sm-12 col-lg-12 text-center" onClick={this.HandleShow} style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                            <h4 className="arrow"> Select Student</h4>
                      </div>
                    </div>


                  {this.state.mSelectedStudent.length===0?<></>:<>
                    <div className='bg_card_blue_outline' style={{margin:'5px 20px'}}>
                   
                    <div className="row" style={{margin:'5px 5px 5px 5px',height:'100px'}}>

                    <div className="col-sm-2  bg_card_blue gravity_center" style={{padding:'0px',height:'95px'}}>
                    <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                      <div className="circular_image">
                          <img  src={this.state.mSelectedStudent.student_img_path}  alt=""></img>
                          <p className="student_id gravity_center"> <small> {this.state.mSelectedStudent.student_enrollment_no} </small></p>
                      </div> 
                      </div>                
                    </div>
                    <div className="col-sm-8" style={{padding:'2px',height:'95px'}}>
                      <h5 className="mb-0 black"> {this.state.mSelectedStudent.stu_name} </h5>
                      <p className="mb-0 black7"style={{fontSize:'15px'}}> Father: <span className='nowrap'>{this.state.mSelectedStudent.father_name}</span> </p>
                      <p className="mb-0"><span>{"Village/Town:"+this.state.mSelectedStudent.village_town}</span> </p>
                       <p className="mb-0" style={{fontSize:'10px'}}> SRN: <span>{this.state.mSelectedStudent.sr_admission_no}</span> </p>
                  
                 
                    
                    </div>
                    <div className="col-sm-2 padding_0 gravity_center">
                      <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px',height:'95px'}}>
                        <p className="mb-0 black7"> Roll No. </p>
                        <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'40px', height:'40px',border:'2px',
                        margin:'3px auto',fontSize:'20px',color:COLORS.white}}>{this.state.mSelectedStudent.student_roll_no}</div> </p>
                        <h6 className="black7"><span> {this.state.mSelectedStudent.section_id==='No'?this.state.mSelectedStudent.class_name:this.state.mSelectedStudent.class_name+":"+this.state.mSelectedStudent.section_name} </span>  </h6>
                      </div>
                    </div>
                    
           
                      </div>

               </div>  


               <div className='row '>
                  <div className="col-sm-4 col-md-4"style={{padding:0}}>
                  </div>
                  <div className="col-sm-4 col-md-4 gravity_center"style={{padding:0}}>
                      <h3>{"Student  Fee"}</h3>
                  </div>
                  <div className="col-sm-4 col-md-4"style={{padding:0}}>
                  </div>               
                </div>

              
                {this.state.showMsg===true?<>
                  <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'2px 20px 5px 20px'}}>
  
                  <div className="row gravity_center" style={{margin:'10px'}}>
                  <div className="col-sm-1 col-md-1"style={{padding:0}}>
                  </div>
                  <div className="col-sm-10 col-md-10 gravity_center"style={{padding:0}}>
                  <h4 style={{color:COLORS.red5}}>Some collection of student fee is found.You Cannot create special case student after fee collection.For Support Contact EduOK Team</h4>
                  </div>
                  <div className="col-sm-1 col-md-1"style={{padding:0}}>
                  </div>
                      
                  </div>
                  </div> 
                </>:""
                   
                   
                  }
                  



                   {this.state.mStudentFee && this.state.mStudentFee.map((item2, index2) => { 
                                                                  
                          return(
                        
                              <Card border={item2.special_case=='Yes'?'danger':'primary'} bg="light" style={{margin:'20px'}}>
                                  <Card.Body> 
                                    <div className="row">
                                     <div className="col-sm-4 col-md-4" style={{padding:'1px'}}>
                                      <div className={item2.special_case=='Yes'?'bg_card_red_outline':'bg_card_blue_outline'} style={{height:'60px'}}>
                                        <div  className='gravity_center'>
                                            <h4>Fee Name</h4>
                                        </div>
                                        <div className='gravity_center'>
                                             <h5 style={{color:COLORS.primary_color_shade7}}>{item2.fee_name}</h5> 
                                        </div>
                                        
                                          
                                      </div>
                                           
                                     </div>
                                       <div className="col-sm-2 col-md-2"style={{padding:'1px'}}>

                                       <div className={item2.special_case=='Yes'?'bg_card_red_outline':'bg_card_blue_outline'} style={{height:'60px'}}>
                                        <div  className='gravity_center'>
                                            <h4>Fee Value</h4>
                                        </div>
                                        <div className='gravity_center'>
                                             <h5 style={{color:COLORS.primary_color_shade7}}>{'Rs:'+item2.fee_value}</h5> 
                                        </div>
                                        
                                          
                                      </div>
                                     </div>
                                     <div className="col-sm-2 col-md-2"style={{padding:'1px'}}>

                                          <div className={item2.special_case=='Yes'?'bg_card_red_outline':'bg_card_blue_outline'} style={{height:'60px'}}>
                                          <div  className='gravity_center'>
                                              <h4>Discount</h4>
                                          </div>
                                          <div className='gravity_center'>
                                          <h5 style={{color:COLORS.primary_color_shade7}}>{'Rs:'+(item2.fee_value-item2.student_fee)}</h5> 
                                          </div>
                                          
                                            
                                          </div>
                                      </div>
                                      <div className="col-sm-2 col-md-2"style={{padding:'1px'}}>

                                          <div className={item2.special_case=='Yes'?'bg_card_red_outline':'bg_card_blue_outline'} style={{height:'60px'}}>
                                          <div  className='gravity_center'>
                                              <h4>Student Fee</h4>
                                          </div>
                                          <div className='gravity_center'>
                                          <h5 style={{color:COLORS.primary_color_shade7}}>{'Rs:'+item2.student_fee}</h5> 
                                          </div>
                                          
                                            
                                          </div>
                                      </div>
                                      <div className="col-sm-2 col-md-2"style={{padding:'1px'}}>

                                          <div className={item2.special_case=='Yes'?'bg_card_red_outline':'bg_card_blue_outline'} style={{height:'60px'}}>
                                          <div  className='gravity_center match_parent' >
                                              <Button className='margin_0' onClick={()=>this.EditStudentFee(item2)}>Edit Fee</Button>
                                          </div>
                                         
                                            
                                          </div>
                                      </div>
                              
                                        
                                  </div>
                                  </Card.Body>
                             </Card>
                          
                               
                
                                )
                            })}

                   
                  </>
              }

                       
                       {this.state.SingleStudentSelector===true? <MdlSelectSingleStudent action={this.HandleClose} action2={this.HandleSelectedStudent}></MdlSelectSingleStudent>:''}
                       {this.state.showEditFee===true? <MdlEditStudentFee  fee_name={this.state.FEE_NAME} fee_value={this.state.FEE_VALUE} action_close={this.showEditStudentFee} action_done={this.handleFeeEditDone}></MdlEditStudentFee>:''}
          

                    </div>
                  </div>
                )
              }
            } />

          
          </switch>
        </Router>
      )
    }
  }
}
