import React, { Component,createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';

import MdlSelectSession from './MdlSelectSession';
import SelectClass from '../ChildComponent/SelectClass';

export default class MdlSelectSingleStudent extends Component {
    constructor(props){
        super(props)       
     
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            isOpenSessionDialog:false,
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            show:true,
          
            CLASS_ID:'',
            SECTION_ID:'',
            SESSION_ID:'',
            SESSION_NAME:'',
            SELECTED_CLASS_POSITION:0,
            StudentList:[],
            SelectedClass:''
        }
       
        this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
      
}
CallBackClassSelect = (class_id,section_id,session_id,selected_position,class_name,section_name,session_name) => {
  
  this.state.CLASS_ID=class_id;
  this.state.SECTION_ID=section_id;
  this.state.SESSION_ID=session_id;
  this.state.SELECTED_CLASS_POSITION=selected_position;
  //alert("Back"+class_id+"/"+section_id+"/"+session_id);
  this.getStudentList();
 
}

componentDidMount(){

  var mSession=SessionManager.getCurrentSession();
  this.state.SESSION_ID=mSession[0].session_id;
  this.state.SESSION_NAME=mSession[0].session_name;
  
}

HandleClose = () => {

  this.setState({
    show: !this.state.show
  });
this.props.action();
  
}
HandleSelectedStudent = (student_id,selected_item) => {
  //alert("okk"+student_id);

  this.props.action2(student_id,selected_item,this.state.SESSION_ID,this.state.SESSION_NAME);
  
}

 
getStudentList(){
  
  this.setState({
  LoadingStudent:true,
  StudentList:[]
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getCurrentStudent.php?'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    class_id:this.state.CLASS_ID,
    section_id:this.state.SECTION_ID,
    session_id:this.state.SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    LoadingStudent:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
     
       this.setState({
        
        StudentList:response.data

        })
       // alert(JSON.stringify(this.state.StudentList));
           
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
    render() {
      
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
            return (

              <div>
                
      <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton style={{background:COLORS.primary_color_shade7,color:COLORS.white}}>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Student
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.state.Loading ? <MyLoader/> : 
         <div>
           

            <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>

            
            <div className='row'>
              {this.state.LoadingStudent?<div><MyLoader/></div>:
              
              this.state.StudentList.map((item, index) => ( 
                                           
                       
                <div className="col-sm-6 col-md-6 p-0">
                <Link to='#' onClick={()=>this.HandleSelectedStudent(item.student_enrollment_no,item)}>
                <div className="row bg_card_blue_outline" style={{margin:'5px 5px 10px 5px',height:'90px'}}>
                 <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'0px',height:'85px'}}>
                   <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                   <div className="circular_image">
                      <img  src={item.student_img_path}  alt=""></img>
                      <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                   </div>  
                   </div>               
                 </div>
                 <div className="col-sm-6" style={{padding:'0px 2px 0px 2px',height:'85px'}}>
                  <h6 className="mb-0"> {item.stu_name} </h6>
                  <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                  <p className="mb-0"><span>{"Village/Town:"+item.village_town}</span> </p>
                  <p className="mb-0" style={{fontSize:'10px'}}> SRN: <span>{item.sr_admission_no}</span> </p>
                  
                 
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center">
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'1px',height:'83px'}}>
                    <p className="mb-0"> Roll No. </p>
                    <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'35px', height:'35px',border:'2px',
                     margin:'2px auto',fontSize:'20px',color:COLORS.white}}>{item.student_roll_no}</div> </p>
                    <h6 className="mb-0"><span> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </span>  </h6>
                    
                  </div>
                 </div>
              
                </div>
                </Link>
                </div>                   
                
                ))}

                   </div>
                   </div>
               }
                       
      </Modal.Body>
    </Modal>
            </div>
      
            )
        }
    }
}

