import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';

export default class AdmissionQueryDetails extends Component {
    constructor(props) {
        super(props)   
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }     
        this.state={  
        
            loggedIn:true,
            Loading:false,
            mAdmissionQuery:[]
            
        }
      }
    
      componentDidMount() {
        window.scrollTo(0, 0)
        var item=JSON.parse(localStorage.getItem("AdmissionQuery"));
        //alert("heloo"+JSON.stringify(mSTDetails));
        if(item==null){
          this.state.mAdmissionQuery=[];
         } 
       this.state.mAdmissionQuery.push(item);
  }
    
    render() {
      
        if(this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
        else if(this.state.Loading)
        {
                return <MyLoader/>
        }
       else{       
         
            return (
              
            <Router>
            <switch>
              <Route path={'/AdminMain/AdmissionQueryDetails'}  exact render={
                ()=>{
              return(
    
                <div className="content" style={{background:COLORS.white}}>
                        <div className="content-header">
                          <div className="leftside-content-header">
                            <ul className="breadcrumbs">
                              <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Admission query Details</a></li>
                            </ul>
                          </div>
                        </div>
                        <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'150px'}}>
                      
                              <div className="row" style={{padding:'0px 20px 0px 20px'}}>
                                <h4>{"Admission Query Details"}</h4>
                              </div>
                              
                              <div className="row">
                              {this.state.mAdmissionQuery && this.state.mAdmissionQuery.map((item,index)=>{
                                          return(                                            
                                            <div className="col-sm-12 col-md-12 " >
                                            
                                            <div className="box_shadow bg_card_grey_outline " style={{padding:2,margin:10}}>
                                               
                                               <h4 className="gravity_center">{item.student_name+"("+item.father_name+")"}</h4>
                                              
                                               <h5 className="gravity_center"> {"Address:"+item.address} </h5>
                                               <h5 className="gravity_center"> {"Seeking Admission in Class:"+item.query_class} </h5>
                                               <h5 className="gravity_center"> {"Previous Class:"+item.previous_class} </h5>
                                  
                                               <h5 className="gravity_center"> {"Previous School:"+item.previous_school_name} </h5>
                                               <h5 className="gravity_center"> {"Father Name:"+item.father_name} </h5>
                                               <h5 className="gravity_center"> {"Gender:"+item.gender} </h5>
                                               <h5 className="gravity_center"> {"Community:"+item.community} </h5>
                                               <h5 className="gravity_center"> {"Religion:"+item.religion} </h5>
                                               <h5 className="gravity_center"> {"Nationality:"+item.nationality} </h5>
                                               <h5 className="gravity_center"> {"Remark:"+item.remark} </h5>
                                               <h5 className="gravity_center"> {"Date Added:"+item.date_added} </h5>
                                               <h5 className="gravity_center"> {"Status:"+item.status} </h5>
                                               <h5 className="gravity_center"> {"Contact_number:"+item.contact_number} </h5>


                                              <Link>
                                               <div className="row">
                                                 
                                                <div className="col-sm-12 col-md-12 gravity_center " >
                                                
                                                    <button className="btn btn-success" style={{fontSize:'16px', margin:'3px 2px 3px 2px',width:'100%',background:COLORS.primary_color_shade5}}>View Details </button>
                                                         
                                                </div>  
                                               
                                              
                                                </div>
                                                </Link>
                                            </div>
                                            
              
                                        </div>
                                         
                                            )
                                        })}
                                  
                              </div>
                            
                         
                         
                            </div>
                            </div>
               )
              }
            } />
                         
            </switch>       
           </Router>  
            )
        }
        }
}
