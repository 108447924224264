import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
export default class StudentInformation extends Component {
  constructor(props) {
    super(props)        

    this.state={  
        isOpen: false, 
        current_due:''
       
    }  
  }
  componentDidMount(){
    //alert("hello"+this.props.showStudentInfo);
    this.setState({
      current_due:this.props.current_due
    })
    //alert("2"+this.props.current_due);
  }
  render() {
    if(!this.props.showStudentInfo)
    {
      return null;
    }
    else{
    return (         
        <div className="animated fadeInUp" style={{margin:' 30px 0px'}}>


          <div className='bg_card_blue_outline' style={{margin:'0px 20px'}}>
            <div className="row">

                  <div className="col-sm-12 col-md-12"style={{padding:0}}>
                    
                    <div className="row" style={{margin:'5px 5px 10px 5px',height:'100px'}}>

                    <div className="col-sm-2  bg_card_blue gravity_center" style={{padding:'0px',height:'95px'}}>
                    <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                      <div className="circular_image">
                          <img  src={this.props.mStudentDetails[0].student_img_path}  alt=""></img>
                          <p className="student_id gravity_center"> <small> {this.props.mStudentDetails[0].student_enrollment_no} </small></p>
                      </div> 
                      </div>                
                    </div>
                    <div className="col-sm-8" style={{padding:'2px',height:'95px'}}>
                      <h5 className="mb-0 black"> {this.props.mStudentDetails[0].stu_name} </h5>
                      <p className="mb-0 black7"style={{fontSize:'15px'}}> Father: <span className='nowrap'>{this.props.mStudentDetails[0].father_name}</span> </p>
                      <p className="mb-0 black7" style={{fontSize:'14px'}}> Address: <span>{this.props.mStudentDetails[0].current_address}</span> </p>
                      
                    
                    </div>
                    <div className="col-sm-2 padding_0 gravity_center"style={{height:'95px'}} >
                      <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                        <p className="mb-0 black7"> Roll No. </p>
                        <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'30px', height:'30px',border:'2px',
                        margin:'3px auto',fontSize:'20px',color:COLORS.white}}>{this.props.mStudentDetails[0].class[0].student_roll_no}</div> </p>
                        <h6 className="black7"><span> {this.props.mStudentDetails[0].class[0].class_name} </span>  </h6>
                      </div>
                    </div>
                                    
                    </div>
                                      
              </div> 

           
            </div>

            <div className="row">
              <div className="col-sm-4">
                <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={{pathname:'/AdminMain/FeeCard'}} className="text-white">Fee Card</Link>
                </div>
               </div>
               <div className="col-sm-4">
               <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={'/AdminMain/PaymentHistory/'+this.props.mStudentDetails[0].student_enrollment_no+"/"+this.props.mStudentDetails[0].class[0].session_id+"/"+this.props.mStudentDetails[0].class[0].session_name} className="text-white">Payment History</Link>
                </div>
               </div>
               <div className="col-sm-4">
               <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={'/AdminMain/AdvancedPayment/'+this.props.mStudentDetails[0].student_enrollment_no+"/"+this.props.mStudentDetails[0].class[0].session_id+"/"+this.state.current_due} className="text-white">Advanced Payment</Link>
                </div>
               </div>
              
              
               </div> 
           </div>  
        </div>
      )
    }
      
  }
 
}

