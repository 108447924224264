import React, { Component, useRef, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import StudentList from '../cms/StudentList';
import myToast from '../Utils/MyToast';
import Button from 'react-bootstrap/Button';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from '../dialog/MdlSelectSession';
import MdlEditSubjectMasterList from '../dialog/MdlEditSubjectMasterList';
import MdlAddSubjectMasterList from '../dialog/MdlAddSubjectMasterList';
import COLORS from '../resources/colors/COLORS';
export default class SubjectList extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      isOpenSubjectDialog: false,
      isOpenAddSubjectDialog: false,
      isOpenSessionDialog: false,
      loggedIn: true,
      Loading: false,
      SESSION_ID: '',
      SESSION_NAME: '',
      SESSION_STATUS: '',
      CLASS_ID: '',
      SECTION_ID: '',
      SUBJECT_NAME: '',
      SUBJECT_ID:'',
      mSubjectList: [],
    }
    this.HandleCloseMdlEdit = this.HandleCloseMdlEdit.bind(this);
  }

  HandleEditSubjectName = (item) => {
    this.state.SUBJECT_NAME = item.subject_name;
    this.state.SUBJECT_ID = item.subject_id;

    // alert(this.state.SUBJECT_ID)
    this.HandleCloseMdlEdit();
  }
  HandleCloseMdlEdit = () => {

    this.setState({
      isOpenSubjectDialog: !this.state.isOpenSubjectDialog
    });

    this.getSubjectMasterList();

  }
  HandleShow1 = () => {

    this.setState({
      isOpenAddSubjectDialog: !this.state.isOpenAddSubjectDialog
    });
  }
  HandleCloseMdlAdd = () => {

    this.setState({
      isOpenAddSubjectDialog: !this.state.isOpenAddSubjectDialog
    });

  }
  componentDidMount() {
    this.getSubjectMasterList();

  }
  getSubjectMasterList() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = 'getSubjectMasterList.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME;

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          this.setState({
            mSubjectList: response.data,

          })

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //alert(JSON.stringify(this.state.mAdmissionQuery));

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (
        <Router>
          <switch>
            <Route path='/AdminMain/SubjectList' exact render={
              () => {

                return (

                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Subject List List</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '80px' }}>
                      <div className='row'>
                        {this.state.mSubjectList && this.state.mSubjectList.map((item, index) => {
                          return (
                            <div className='col-md-4 '>
                              <div className='row mar_top1 bg_card_red'>
                                <div className='col-md-10 padding_0'>
                                  <div className='subject_list mar_top1'>{item.subject_name}</div>
                                </div>
                                <div className='col-md-2 padding_0'>
                                  <div className='mar_top1'>
                                    <Button variant="primary" onClick={() => this.HandleEditSubjectName(item)}>Edit</Button>{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        <div className="col-md-12">
                          <center>
                            <button className="btn btn-success" onClick={this.HandleShow1} style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Add New Subject</button>

                          </center>
                        </div>
                        {this.state.isOpenSubjectDialog === true ? <MdlEditSubjectMasterList actionClose={this.HandleCloseMdlEdit} subject_name={this.state.SUBJECT_NAME} subject_id={this.state.SUBJECT_ID}></MdlEditSubjectMasterList> : ''}
                        {this.state.isOpenAddSubjectDialog === true ? <MdlAddSubjectMasterList actionClose={this.HandleCloseMdlAdd}></MdlAddSubjectMasterList> : ''}

                      </div>


                    </div>
                  </div>

                )
              }
            } />



          </switch>

        </Router>
      )
    }
  }

}

