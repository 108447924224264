import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";
import { Alert } from "react-bootstrap";

const RollNumberManagement = () => {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") !== null);
  const [Loading, setLoading] = useState(false);
  const [LoadClass, setLoadClass] = useState(false);
  const [SESSION_ID, setSESSION_ID] = useState("");
  const [CLASS_ID, setCLASS_ID] = useState("");
  const [SECTION_ID, setSECTION_ID] = useState("");
  const [SELECTED_CLASS_POSITION, setSELECTED_CLASS_POSITION] = useState(0);
  const [CLASS_SECTION_NAME, setCLASS_SECTION_NAME] = useState("");
  const [mStudentList, setMStudentList] = useState([]);
  const [Inputs, setInputs] = useState([]);

  useEffect(() => {
    setLoadClass(true);
  }, []);

  useEffect(() => {
    StudentList();
  }, [CLASS_ID,SECTION_ID]);
  

  const toggleDisableRollNo = (index, state) => {
    setInputs((prev) =>
      prev.map((input, i) =>  i === index ? { ...input, disable_roll_no: !input.disable_roll_no }: input)
    );
  
    if (!state) {
      const ROLL_NUMBER_OLD = mStudentList[index].student_roll_no;
      const STUDENT_ID = mStudentList[index].student_enrollment_no;
      
      // Using the updated state (prev) instead of Inputs directly
      setInputs((prev) => {
        const ROLL_NUMBER_NEW = prev[index].student_roll_no;
        // Uncomment this to call the update function
        updateStudentRollNumber(STUDENT_ID, ROLL_NUMBER_OLD, ROLL_NUMBER_NEW);
        return prev;
      });
    }
  };
  
  const toggleDisableAdmNo = (index,state) => {
    setInputs((prev) =>
      prev.map((input, i) =>  i === index ? { ...input, disable_adm_no: !input.disable_adm_no }: input)
    );
  
    if(!state)
      {
         const ADM_NO_OLD=mStudentList[index].sr_admission_no;
         const STUDENT_ID=mStudentList[index].student_enrollment_no;
         const ADM_NO_NEW=Inputs[index].sr_admission_no;
         updateStudentAdmissionNumber(STUDENT_ID,ADM_NO_OLD,ADM_NO_NEW);
      }
  };
  const handleInputChange = (id,my_key, event) => {
    //alert(JSON.stringify(id));
    const newInputs = Inputs.map(input => {
      if (input.id === id) {
        return { ...input, [my_key]: event.target.value };
      }
      return input;
    });
    setInputs(newInputs);
  };
  
  const CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name) => {
    const classSectionName = section_id === "No" ? class_name : `${class_name}:${section_name}`;
    
    setSECTION_ID(section_id);
    setSESSION_ID(session_id);
    setCLASS_SECTION_NAME(classSectionName);
    setSELECTED_CLASS_POSITION(selected_position);
    setCLASS_ID(class_id);  
    
  };

  const StudentList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getCurrentStudent.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          setMStudentList(response.data);
          const formattedData = response.data.map(item => ({
            id: item.id,
            student_roll_no: item.student_roll_no,
            sr_admission_no: item.sr_admission_no,
            disable_roll_no: true,
            disable_adm_no: true,

          }));
          setInputs(formattedData);
         
        } else {
         // myToast.notify_warning(response.api_remark);
          setMStudentList([]);
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  
  const updateStudentRollNumber = (STUDENT_ID,ROLL_NUMBER_OLD,ROLL_NUMBER_NEW) => {
    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStudentRollNumber.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          staff_id: STAFF_ID,
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
          student_id: STUDENT_ID,
          student_roll_no: ROLL_NUMBER_NEW,
          student_roll_no_old: ROLL_NUMBER_OLD,
          

        },
      })
      .then((res) => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
         alert("Roll Number updated successfully");
         
        } else {
           myToast.notify_warning(response.api_remark);
         
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStudentAdmissionNumber = (STUDENT_ID,ADM_NUMBER_OLD,ADM_NUMBER_NEW) => {
    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStudentAdmissionNo.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          staff_id: STAFF_ID,
          school_code: SCHOOL_CODE,
          session_id: SESSION_ID,
          enrollment_no: STUDENT_ID,
          admission_no: ADM_NUMBER_NEW,
          admission_no_old: ADM_NUMBER_OLD,
          

        },
      })
      .then((res) => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
         alert("Admission Number updated successfully");
         
        } else {
           myToast.notify_warning(response.api_remark);
         
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path="/AdminMain/RollNumberManagement"
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.whitesmoke }}>
            <div className="content-header" style={{ background: COLORS.primary_color_shade6 }}>
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Roll Number Management </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="animated fadeInUp bg_page_grey_rounded box_shadow" style={{ "--mMargin": "50px", marginTop: "20px", padding: '20px' }}>
              {LoadClass && (
                <SelectClass
                  value={LoadClass}
                  action={CallBackClassSelect}
                  selected_position={SELECTED_CLASS_POSITION}
                />
              )}

              {Loading ? (
                <MyLoader />
              ) : Inputs.length > 0 ? (
                mStudentList.map((item, index) => (
                  <div key={index} className="bg_card_blue_outline btn_set">
                    <div className="row btn_set">
                      <div className="col-md-6 mp1_0 gravity_center">
                        <div className="row" style={{width:'100%'}}>
                          <div className="col-md-3 gravity_center mp1_0">
                            <div className="bg_card_blue" style={{ height: "70px", width: "70px" }}>
                              <div className="circular_image">
                                <img src={item.student_img_path} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Student Name:</span> {item.stu_name}
                              </h6>
                            </div>
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Father Name:</span> {item.father_name}
                              </h6>
                            </div>
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Class:</span> {item.section_id === 'No' ? item.class_name : `${item.class_name}:${item.section_name}`}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row btn_set">
                          <div className="col-md-4 padding_0">
                            <label className="text_color btn_set">Roll Number</label>
                          </div>
                          <div className="col-md-6 padding_0">
                            <div className="input-group">
                              <input placeholder="Edit Roll Number" type="text" className="form-control" disabled={Inputs[index].disable_roll_no} value={Inputs[index].student_roll_no} onChange={(e) => handleInputChange(item.id,'student_roll_no', e)}/>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <center>
                              <button className="btn btn-success" onClick={() => toggleDisableRollNo(index,Inputs[index].disable_roll_no)}>{Inputs[index].disable_roll_no ? 'Edit' : 'Save'} </button>
                            </center>
                          </div>
                        </div>
                        <div className="row btn_set">
                          <div className="col-md-4 padding_0">
                            <label className="text_color">Admission Number</label>
                          </div>
                          <div className="col-md-6 padding_0">
                            <div className="input-group">
                              <input placeholder="Edit Admission Number" type="text" className="form-control" disabled={Inputs[index].disable_adm_no}  value={Inputs[index].sr_admission_no} onChange={(e) => handleInputChange(item.id,'sr_admission_no',e)}/>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <center>
                              <button className="btn btn-success" onClick={() => toggleDisableAdmNo(index,Inputs[index].disable_adm_no)}>{Inputs[index].disable_adm_no ? 'Edit' : 'Save'}</button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No students found</div>
              )}

              <div className="row mar_top">
                <div className="col-md-6" style={{ padding: "3px" }}>
                  <center>
                    <button className="btn btn-success" style={{ margin: "3% 0%", fontSize: "21px", width: "100%" }}>
                      Roll Out From 1
                    </button>
                  </center>
                </div>
                <div className="col-md-6" style={{ padding: "3px" }}>
                  <center>
                    <button className="btn btn-success" style={{ margin: "3% 0%", fontSize: "21px", width: "100%" }}>
                      Roll Out Alphabetically
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        )}
      />
      
    </Router>
  );
};

export default RollNumberManagement;
