import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import COLORS from '../resources/colors/COLORS';
import icon_edit from '../resources/icons/icons_edit_pencile_100_white.png'
import uploadIcon from '../resources/images/upload-icon.png'

import ExamStudentReportCard from './ExamStudentReportCard';
import ExamMarkingSystemAnnual from './ExamMarkingSystemAnnual';
import MdlSelectSession from '../dialog/MdlSelectSession';
import { Table } from 'react-bootstrap';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
export default class ExamReportCardList extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.slt_pattern=createRef();
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mExamReportCardList:[],
        mReportCardPattern:[],
        mPattern:[],
        mExamList:[],
        SESSION_ID:'',
        SESSION_NAME:'',
        report_card_caption:'Progress Report',
        report_card_pattern_id:'',
        EXAM_ID:''
       

    }
    this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
    this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);
  
  }
  componentDidMount()
  {
    var mSession=SessionManager.getCurrentSession();
    this.state.SESSION_ID=mSession[0].session_id;
    this.state.SESSION_NAME=mSession[0].session_name;
    this.state.report_card_caption= this.state.report_card_caption+" "+this.state.SESSION_NAME;

      window.scrollTo(0, 0);
      this.getExamReportCardList();
     
  }
  OnPatternChange = (event) => { 
    
    var position=this.slt_pattern.current.selectedOptions[0].getAttribute('position_no'); 
    this.state.report_card_pattern_id=this.state.mReportCardPattern[position].reportcard_pattern_id;
    this.getPatternDetails(position);
    
   };
   getPatternDetails = (position) => {
 
    this.state.mPattern=this.state.mReportCardPattern[position].details;
    this.setState({

    })
  }

  ChangeSession = () => {
 
    this.HandleShowSessionDialog();
  }
  
  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog
    });   
  }
  
  HandleCloseSessionDialog = () => {
  
    this.setState({
      isOpenSessionDialog:false
    });  
     
  }
  HandleSelectedSession = (session_id,session_name) => {
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.state.report_card_caption="Progress Report";
    this.state.report_card_caption= this.state.report_card_caption+" "+this.state.SESSION_NAME;
    this.setState({
      
      isOpenSessionDialog:false,
      mExamReportCardList:[]
    });
   
    this.getExamReportCardList();
  }
  getExamReportCardList(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getSchoolRepordCardList.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
             mExamReportCardList:response.data
          })

          
      }
      else{
        let api_remark=response.api_remark;
        //myToast.notify_warning(api_remark);
      }
      this.getExamReportCardPattern();
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }

  getExamReportCardPattern(){
  
    this.setState({
      Loading:true
    })
    const FUNCTION_NAME='getReportCardPattern.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
     
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            mReportCardPattern:response.data
            })
            this.state.report_card_pattern_id=this.state.mReportCardPattern[0].reportcard_pattern_id;
            this.getPatternDetails(0);
            this.getExamList();
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

     
  getExamList(){
  
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getExamList.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{school_code:SCHOOL_CODE,session_id:this.state.SESSION_ID}
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            mExamList:response.data
            })
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

    selectExams(position,event){
     // alert(JSON.stringify(this.state.mPattern));
      this.state.mPattern[position]['concerned_exam_id'] = event.target.value;
     // alert(JSON.stringify(this.state.mPattern));
      
    
    } 
    CreateReportCard= () =>{
        this.SubmitReportCard();
    }
    
    SubmitReportCard(){
  
      this.setState({
        Loading:true
      })
     
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='createExamReportCard.php';
      var str_exam_list=JSON.stringify(this.state.mPattern);
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
      console.log("URL>>>>>"+URL);
       var params = new URLSearchParams();
        params.append('school_code', SCHOOL_CODE);
        params.append('session_id', this.state.SESSION_ID);
        params.append('report_card_name', this.state.report_card_caption);
        params.append('str_exam_list', str_exam_list);
        params.append('report_card_pattern_id', this.state.report_card_pattern_id);
      
      axios.get(URL,{
        params
        
      }).then(res => res.data)
      .then((response) => {
       
       
        this.setState({ 
          Loading:false
         })
          let api_status=response.api_status;
          // alert(JSON.stringify(response));
          
          if(api_status=="OK")
          {
              myToast.notify_warning("Created Successfully");
              this.setState({
             
              })
              this.getExamReportCardList();
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
    getConcernedExam(item){
     // alert(JSON.stringify(item));

      var mConcerned_exams=[];
      mConcerned_exams=item.exams_list;
      //alert(JSON.stringify(mConcerned_exams));

      var strExams='';
      {mConcerned_exams && mConcerned_exams.map((item2,index)=>{

            if(index==0)
            {
              strExams=item2.exam_name;
            }
            else{
              strExams=strExams+','+item2.exam_name;
            }
      })}
      return strExams;

    }   

    getLastExam(item){
    
           var arr=[];
            arr=item.exams_list;
          if(arr.length !==0)
          {
            
            return  (arr.length-1);
          }
          else{
            return  0;
          }
          
    }
 
  render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamReportCardList'}  exact render={
            ()=>{
              
         return (
       
          <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Reportcard List</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
      
          <div className="row">

            <div className="col-sm-12 col-md-12">
            <Link to='#' onClick={()=>this.ChangeSession()}>
            <p className="bg_card_blue_outline" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center'}}> Session:
              {this.state.SESSION_NAME}
            <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
            </Link>
            </div>  

            </div>

            <div className="row">
          {this.state.mExamReportCardList && this.state.mExamReportCardList.map((item, index) => { 
                                           
            return( 

             <div className="col-sm-6 col-md-6">
              <div style={{margin:'10px'}}>
              
                <div className="bg_card_blue_outline">
                  
                
                  <div className="row">
                  
                    <div className="col-sm-10 col-md-10 gravity_center">
                      <p style={{fontSize:'18px'}}>{item.report_card_name}</p>
                    </div>
                    <div className="col-sm-2 col-md-2 gravity_center">
                       <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'25px',width:'25px'}}>
                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade3,'--myBorderColor':COLORS.primary_color_shade5,'--myBorder':'1px'}}>
                              <img  src={icon_edit}  alt="" style={{width:'100%'}}></img>
                             
                          </div> 
                        </div>  
                       
                    </div>

                  </div>
                  
                
                  <div className='row viewShow'>
                          <div className="col-sm-12 col-md-12 gravity_center">
                                       {'Concerned Exam:'+this.getConcernedExam(item)} 
                          </div>    
                   </div>
                   <hr></hr>
                   <div className='row viewShow'>
                          <div className="col-sm-12 col-md-12 ">
                            <Link to={'/AdminMain/ExamStudentReportCard/'+item.report_card_id+"/"+item.exams_list[this.getLastExam(item)].concerned_exams+"/"+item.session_id+"/"+item.student_id}>
                                 <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Generate Report Card For Student </button>
                            </Link>                
                          </div>    
                   </div>
                   

                </div>
              </div>

            </div>
              )
            })}
          </div>  


            <div style={{padding:'0px 15px 0px 15px'}}><h5>Create New Report Card</h5></div>

              <div className="bg_card_primary_outline" style={{padding:'10px',margin:'0px 15px 0px 15px'}}>
              <div className="row">
                <div className="col-sm-6 col-md-6 bg_grid" style={{padding:'0px 2px 2px 2px'}}>
                <h5>Report Card Caption:</h5>
                    <input type="text" id="input_exam_date" value={this.state.report_card_caption} onChange={this.onEdtChange}style={{ width:'100%',height:'40px',fontSize:'18px'}}></input>
              </div>

            

                  <div className="col-sm-6 col-md-6 bg_grid" style={{padding:'0px 2px 0px 2px'}}>
                  <h5>Report Card Pattern:</h5>
                   <select  onChange={this.OnPatternChange} ref ={this.slt_pattern} style={{ width:'100%',height:'40px',fontSize:'18px'}}>
                                      {this.state.mReportCardPattern && this.state.mReportCardPattern.map((item, index) => ( 
                                      <option reportcard_pattern_id={item.reportcard_pattern_id} position_no={index}> {item.reportcard_pattern} </option>
                  
                                      ))}
                  </select>
                  </div>
                  
                  </div>
                  <div className="row bg_grid gravity_center" style={{padding:'10px 0px 5px 0px'}}>
                  <h5>Map Exams</h5>
                      <div className="col-sm-12 col-md-12" >                                          
                            <Table striped bordered hover style={{width:'100%',border:'1'}}>
                            <thead>
                              <tr>
                                <th style={{ textAlign: "center" }}>Sr.</th>
                                <th style={{ textAlign: "center" }}>Exam Pattern Sample</th>
                                <th style={{ textAlign: "center" }}>School Exams </th>
                               
                                
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.mPattern && this.state.mPattern.map((item,index)=>{
                            return(   
                              <tr>
                                <td style={{ textAlign: "center" }}>{index+1}</td>
                                <td style={{ textAlign: "center" }}>{item.reportcard_pattern_exam}</td>
                                <td style={{ textAlign: "center" }}>

                                  <div>
                                  <RadioGroup aria-label="position" name="" id="" value={this.state.gender} onChange={(e) => this.selectExams(index, e)} row>
                                  {this.state.mExamList && this.state.mExamList.map((item2, index2) => ( 
                                          <FormControlLabel
                                            value={item2.exam_id}
                                            control={<Radio color="primary" />}
                                            label={item2.exam_name}
                                            labelPlacement="end"
                                          />
                                          ))}      
                                         
                                    </RadioGroup>
                                  </div>
                                </td>
                               
                                
                              </tr>
                            )
                          })}
  
                            </tbody>
                          </Table>
                          
                        
                    </div>
                                   
                    </div>

                    <div className='row'>
                          <div className="col-sm-12 col-md-12 ">
                          <button className="btn btn-success gravity_center bg_card_blue" onClick={this.CreateReportCard} style={{width:'100%',height:'35px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Create Report Card</button>               
                          </div>    
                   </div>
              </div>

          

          {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           
                           
                                
      </div>


     </div>
           
           )
          }
          } />

            <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
            
              
    </switch>       
   </Router>  
    )
  }
 }
 }