import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import oBook from '../resources/images/o-book.png';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class ExamCreation extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      getClass: [],
      classBoard: [],
      classMedium: [],
      ClassSection: []
    }
    //  this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }
  componentDidMount() {
    this.getClass();
  }
  getClass() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();

    const FUNCTION_NAME = 'getClass.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: { school_code: SCHOOL_CODE }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            getClass: response.response.class,
            classBoard: response.response.board,
            classMedium: response.response.medium,
            ClassSection: response.response.class[0].section
          })
          console.log("response>>>>>" + this.state.getClass);
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  handleDropdownChange(e) {
    alert("hhh");
    // console.log("ggggggggggggggg>>>>>>>>>>");
  }

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <div className="content">
          <div className="content-header" style={{ background: COLORS.primary_color_shade5 }}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{ color: COLORS.white }} /><a href="#"> Exam Creation </a></li>
              </ul>
            </div>
          </div>



          <div className="bg_page_white_rounded" style={{ '--mMargin': '50px' }}>

            <div className='row bg_card_orange'>

              <div className="col-md-6 mar_top1 ">
                <label className="col-md-4  col-form-label"> <h4 className='subject_3'> Select Boards:</h4>  </label>
                <div className="col-md-8 ">
                  <select className="select-field" onChange={this.handleDropdownChange}>
                    {this.state.classBoard.map((item, index) => (
                      <option>{item.board_name}</option>

                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6 mar_top1 ">
                <label className="col-md-4  col-form-label padding_0"> <h4 className='subject_3'> Select Medium:</h4>  </label>
                <div className="col-md-8 padding_0">
                  <select className="select-field">
                    {this.state.classMedium.map((item, index) => (
                      <option>{item.medium_name}</option>
                    ))}

                  </select>
                </div>
              </div>

              <div className="col-md-6 mar_top1 ">
                <label className="col-md-4  col-form-label"> <h4 className='subject_3'> Exam name</h4>  </label>
                <div className="col-md-8 ">
                  <div className="input-group">
                    <input id="ExamName" type="text" value="Enter Exam name" className="select-field" />
                  </div>
                </div>
              </div>

              <div className="col-md-6 mar_top1">
                <label className="col-md-4  col-form-label padding_0"> <h4 className='subject_3'> Select Start Date </h4> </label>
                <div className="col-md-8 padding_0">
                  <div className="input-group ">
                    <input id="date" type="date" className="select-field" />
                  </div>
                </div>
              </div>

            </div>

            <div className="mar_top1">
              <h4 className='subject_2'> Select Participating Class And Subject </h4>
            </div>


            <section className="exam_section mar_top1">
              <div className="row class-li">

                <div className="col-sm-4 col-md-4">
                  <div className="chek">
                    <input type="checkbox" className="chek-in" />
                  </div>
                </div>
                <div className="col-sm-8 col-md-8">
                  <h4 className='subject_1'> Nursery: B1 </h4>
                </div>
              </div>

              <div className="row exam_section1 class-li">
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> English</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> Hindi</h4>
                
                  </div>
                  
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> Maths</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>                
                    <h4 className='text_bott1'> Science</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>                    
                    <h4 className='text_bott1'> Art</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>                   
                    <h4 className='text_bott1'> History</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> Games</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'>G.K.</h4>
                  </div>
                </div>

              </div>
            </section>
            <section className="exam_section mar_top1">
              <div className="row class-li">

                <div className="col-sm-4 col-md-4">
                  <div className="chek">
                    <input type="checkbox" className="chek-in" />
                  </div>
                </div>
                <div className="col-sm-8 col-md-8">
                  <h4> Nursery: B1 </h4>
                </div>
              </div>

              <div className="row exam_section1 class-li">
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> English</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> Hindi</h4>
                    
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> Maths</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>                
                    <h4 className='text_bott1'> Science</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>                    
                    <h4 className='text_bott1'> Art</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>                   
                    <h4 className='text_bott1'> History</h4>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'> Games</h4>
                  
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 padding_0">
                  <div className="bg_white1">
                    <p className="text-center"><img src={oBook} alt="o-book" /> &nbsp; <span><input type="checkbox" className="rr" /></span> </p>
                    <h4 className='text_bott1'>G.K.</h4>
                  </div>
                </div>

              </div>
            </section>


            <div className='row'>
              <div className="col-sm-12 col-md-12 padding_0">
                <button className="btn btn-success subject_2" style={{ width: '100%', fontSize: '24px', margin: '30px 0px 15px 0px', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}> Submit Exam Creation </button>
              </div>
            </div>
          </div>
        </div>

      )
    }
  }
}
