import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
export default class ExamMarkingSystemAnnual extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        LoadingRankList:false,
    
        mExamMarkingSystem:[],
        mExamParticipatingClass:[],
        mMarkingSystem:[],
        mMarkingSystemValue:[],
        mStudentList:[],
        EXAM_ID:'',
        STUDENT_ID:'',
        REPORT_CARD_ID:'',
    
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        CLASS_SELECTED_POSITION:0,
        SELECTED_STUDENT:''
        
       

    }
   
  
  }
  
  componentDidMount()
  {
  
    this.state.REPORT_CARD_ID=this.props.match.params.report_card_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
  
    window.scrollTo(0, 0); 
    this.getMarkingSystemData();  
  }

  getMarkingSystemData(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamMarkingSystem.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
      exam_id:"",
      report_card_type:'ANNUAL',
      report_card_id:this.state.REPORT_CARD_ID,
    
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
       //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
            mExamMarkingSystem:response.data,
                   
          })
          if(this.state.mExamMarkingSystem.length!==0)
          {
            this.state.EXAM_ID=this.state.mExamMarkingSystem[0]['concerned_exams'];

          }
         

          this.getParticipatingClass();
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  getParticipatingClass()
  {
      
    this.setState({         
      mExamParticipatingClass:this.state.mExamMarkingSystem[0]['participating_class'],
    })
    this.setClassList(0);
  }
  selectClass=(position)=>{  
    this.setClassList(position);
    
  }
  setClassList(selected_position){      
    if(this.state.mExamParticipatingClass.length!==0)
    {
        this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
        this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;

        if(this.state.SECTION_ID=="null")
        {
            this.state.SECTION_ID="No";
        }
        this.state.CLASS_SELECTED_POSITION=selected_position;

    }
 
    this.state.mMarkingSystem=this.state.mExamParticipatingClass[selected_position].marking_system;
    this.state.mMarkingSystemValue=this.state.mMarkingSystem[0].MarkingSystemValue;

  this.setState({

  })
   
  }

  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamMarkingSystemAnnual/:report_card_id/:session_id'}  exact render={
            ()=>{
              
         return (
       
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Marking System And Settings</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
        
          <div className="row gravity_center" style={{margin:'0px 15px 0px 15px'}}>
                <div className="col-sm-4 col-md-4 grid_primery_outline_primery">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Academic Session:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['session_name']:''}  </p> 
                </div>
                <div className="col-sm-4 col-md-4 grid_primery_outline_primery">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['exam_name']:''} </p> 
                </div>
                <div className="col-sm-4 col-md-4 grid_primery_outline_primery">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam Date:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['start_date']:''}  </p> 
                </div>
                               
          </div>
          <div className="row gravity_center" style={{margin:'2px 15px 2px 15px'}}>
               
               <div className="col-sm-6 col-md-6 grid_primery_outline_primery" style={{height:'40px'}}>
                 <p  style={{padding:'2px',fontSize:'18px'}}><strong> ReportCard Caption:</strong> {this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['report_card_name']:''} </p> 
               </div>
               <div className="col-sm-3 col-md-3 bg_grid_primary_2" style={{height:'40px'}}>
                  <div className='gravity_center'>              
                    <Link to={"/AdminMain/ExamStudentReportCard/"}>
                        
                     </Link>
                    </div>

               </div>
               <div className="col-sm-3 col-md-3 bg_grid_primary_2 " style={{height:'40px'}}>
                 <div className='gravity_center'>              
                    <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.REPORT_CARD_ID+"/"+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+"null"}>
                    <button className="bg_card_primary btn btn-success">Get Report Card</button>
                     </Link>
                    </div>

               </div>
                
         </div>
          <div style={{padding:'0px 15px 0px 15px'}}><h5>Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>
       

        
              <div style={{padding:'0px 15px 0px 15px'}}><h5>Marking System:</h5></div>
                 <div className="row">
                            
                  <div className="col-sm-12 col-md-12" >
                  <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'5px 2px 10px 3px'}}>
                    <div className="row">
                     <div className="col-sm-8 col-md-8" >
                                                
                    <div className="gravity_center">
                        <p><strong> Marking System: </strong> </p> 
                    </div> 
                      </div>
                    </div>
                  
                    
                    <hr/>
                    <div className="row" style={{padding:'0px 15px 5px 15px'}}>
                      
                    <div className="col-sm-12 col-md-12" >                                          
                          <Table striped bordered hover variant="dark" style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Sr.</th>
                              <th style={{ textAlign: "center" }}>From</th>
                              <th style={{ textAlign: "center" }}>To</th>
                              <th style={{ textAlign: "center" }}>Marking</th>
                              <th style={{ textAlign: "center" }}>Performance Descriptor</th>
                              <th style={{ textAlign: "center" }}>Result</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mMarkingSystemValue && this.state.mMarkingSystemValue.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td style={{ textAlign: "center" }}>{index2+1}</td>
                              <td style={{ textAlign: "center" }}>{item2.from_value}</td>
                              <td style={{ textAlign: "center" }}>{item2.to_value}</td>
                              <td style={{ textAlign: "center" }}>{item2.marking}</td>
                              <td style={{ textAlign: "center" }}>{item2.performance_descriptors}</td>
                              <td style={{ textAlign: "center" }}>{item2.result}</td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                        
                      
                  </div>
                                 
                  </div>
                  <hr/>             
                  
                  </div>
                </div> 
                                                                           
                </div>  
              
           
                          
               

               
            </div>
           
         
         
     </div>


           
           )
          }
          } />
             <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }