import React, { Component } from 'react';
import {BrowserRouter as Router, Link,Redirect,Route } from 'react-router-dom';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import '../css/Admin_css/admin-customize.css';
import PaymentDetails from './PaymentDetails';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import HTMLReactParser from 'html-react-parser';
import STRINGS from '../resources/strings/STRINGS';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from '../dialog/MdlSelectSession';
export default class PaymentHistory extends Component {
    constructor(props){
        super(props)
    
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
    
        this.state={
    
            loggedIn:true,
            Loading:false, 
            PaymentHistory:[],
            StudentDetails:[], 
            SESSION_ID:'',
            SESSION_NAME:'',
            SESSION_STATUS:'',   
        }
        this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
        this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);
      
    }
    ChangeSession = () => {
 
      this.HandleShowSessionDialog();
    }
    
    HandleShowSessionDialog = () => {
      this.setState({
        isOpenSessionDialog: !this.state.isOpenSessionDialog
      });   
    }
    
    HandleCloseSessionDialog = () => {
    
      this.setState({
        isOpenSessionDialog:false
      });  
       
    }
    HandleSelectedSession = (session_id,session_name,session_status) => {
      this.state.SESSION_ID=session_id;
      this.state.SESSION_NAME=session_name;
      this.state.SESSION_STATUS=session_status;
      this.setState({
        
        isOpenSessionDialog:false
      });
     
      this.getPaymentHistory();
    }
    componentDidMount()
    {
          this.state.SESSION_ID=this.props.match.params.session_id;
          this.state.SESSION_NAME=this.props.match.params.session_name;
          this.state.SESSION_STATUS=this.props.match.params.session_status;
          // alert(this.state.SESSION_STATUS);

          this.getPaymentHistory();
    }
getPaymentHistory(){
  
  this.setState({
    Loading:true
  })
 
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Student_id=this.props.match.params.student_id;
  const FUNCTION_NAME='getPaymentHistory.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
  console.log("URL>>>>>"+URL);
axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID,
    student_id:Student_id
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
      //console.log("Response2>>>>>"+response);
      //alert("res"+JSON.stringify(response));
      let api_status=response.api_status;
      if(api_status=="OK")
      {
        
         this.setState({
          
            PaymentHistory:response.data[0].payment_history,
            StudentDetails:response.data[0].student_details
          })
         // console.log("response>>>>>"+this.state.PaymentHistory);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }

    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
}
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{

    return ( 

      
      <Router>
        <switch>
          <Route path='/AdminMain/PaymentHistory/:student_id/:session_id/:session_name'  exact render={
            ()=>{
          return(
              <div className="content">
      <div className="content-header">
     <div className="leftside-content-header">
       <ul className="breadcrumbs">
         <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Payment History</a></li>
       </ul>
     </div>
     
   </div>            
     <div className="animated fadeInUp bg_page_white_rounded" style={{'--mMargin':'50px'}}>

     <div className="row" style={{padding:'10px 20px 2px 20px'}}>

          <div className="col-sm-12 col-md-12">
          <Link to='#' onClick={()=>this.ChangeSession()}>
          <p className="bg_card_blue" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center',color:COLORS.white,fontWeight:'500'}}> Session:
            {this.state.SESSION_NAME}
          <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
          </Link>
          </div>  
      </div>


        <div className="row" style={{padding:'10px 20px 2px 20px'}}>
            {this.state.StudentDetails.map((item, index) => (

            <div className="col-sm-12 col-md-12">
            <div className="row" style={{background:COLORS.primary_color_shade5,padding:'15px 5px 15px 5px',borderRadius:'5px'}}>
           
            <div className="col-sm-9" style={{height:'140px'}}>
              <div className="row bg_card_primary_outline  height_match_parent">
               
                <div className="col-sm-5" style={{padding:'5px 5px 5px 5px'}}>
                      <div className="text-justify">  
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> <strong> Student ID : </strong> </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> <strong> Student Name : </strong> </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> <strong> Father Name  : </strong> </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> <strong> Class : </strong> </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> <strong> Roll No : </strong> </p>
                      </div>
                  </div>
                  <div className="col-sm-7" style={{padding:'5px 5px 5px 5px'}}>
                        <div className="text-justify">
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> {item.student_enrollment_no} </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> {item.stu_name} </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> {item.father_name} </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> {item.class[0].section_name==null?item.class[0].class_name:item.class[0].class_name+":"+item.class[0].section_name} </p>
                        <p style={{fontSize:'15px',fontWeight:'500',color:COLORS.black7}}> {item.class[0].student_roll_no} </p>
                        </div>
                  </div>
                 
           
              </div>
            </div>
            
            <div className="col-sm-3 gravity_center">
            <div className="bg_card_primary_outline  gravity_center height_match_parent" style={{height:'140px',width:'140px'}}>
              <div className=""><img src={item.student_img_path} style={{width: '130px',height:'130px'}} alt="image"/></div>
            </div>
            </div>

            </div>
          </div>
         ))}
     </div>
      <div className="row" style={{padding:'0px 20px'}}>

        {this.state.PaymentHistory && this.state.PaymentHistory.map((item,index) => {

            if(item.transaction_for=='FEE')
            {
              return( 

                <div className="col-sm-6 col-md-6">
                   <div  className="row bg_card_green_outline" style={{margin:'10px 0px 10px 0px'}}>
                     <div className="col-sm-4 col-md-4 gravity_center_vertical">
                           <div className="gravity_center" style={{marginTop:'3px'}}>
                              <div className="bg_circle_green_check" style={{height:'40px',width:'40px',fontSize:'24px'}}> &#10003; </div>   
                          </div>
                          <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.card_green}}>{HTMLReactParser(STRINGS.rupees_sign)+item.paid_amount}</p>
                     </div>
                     <div className="col-sm-8 col-md-8">
                        <div >
                        <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+item.student_id+"/"+item.transaction_for} style={{textDecoration:'none'}}>
                        
                        <div className=" gravity_center_vertical">
                        
                         
    
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Receipt No: "+item.reciept_no}</p>
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction ID: "+item.transaction_id}</p>
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction Date: "+item.transaction_date}</p>
                          <p style={{fontSize:'16px',fontWeight:'700',color:COLORS.card_green}}>{"Transaction For: "+item.transaction_for}</p>
        
                        </div>
                        </Link>
                      </div>
                  </div>
                 
                 </div>
   
               </div>
                 )
            }
            else if(item.transaction_for=='OLD_DUE')
            {
              return( 

                <div className="col-sm-6 col-md-6">
                 <div  className="row bg_card_red_outline" style={{margin:'10px 0px 10px 0px'}}>
                     <div className="col-sm-4 col-md-4 gravity_center_vertical">
                           <div className="gravity_center" style={{marginTop:'3px'}}>
                              <div className="bg_circle_red_check" style={{height:'40px',width:'40px',fontSize:'24px'}}> &#10003; </div>   
                          </div>
                          <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.card_red}}>{HTMLReactParser(STRINGS.rupees_sign)+item.paid_amount}</p>
                     </div>
                     <div className="col-sm-8 col-md-8">
                        <div >
                        <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+item.student_id+"/"+item.transaction_for} style={{textDecoration:'none'}}>
                        
                        <div className=" gravity_center_vertical">
                        
                         
    
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Receipt No: "+item.reciept_no}</p>
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction ID: "+item.transaction_id}</p>
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction Date: "+item.transaction_date}</p>
                          <p style={{fontSize:'16px',fontWeight:'700',color:COLORS.card_red}}>{"Transaction For: "+item.transaction_for}</p>
        
                        </div>
                        </Link>
                      </div>
                  </div>
                 
                 </div>
   
               </div>
                 )
            }
            else{
              return( 

                <div className="col-sm-6 col-md-6">
                 <div  className="row bg_card_primary_outline" style={{margin:'10px 0px 10px 0px'}}>
                     <div className="col-sm-4 col-md-4 gravity_center_vertical">
                           <div className="gravity_center" style={{marginTop:'3px'}}>
                              <div className="bg_circle_primery_check" style={{height:'40px',width:'40px',fontSize:'24px'}}> &#10003; </div>   
                          </div>
                          <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.primary_color_shade5}}>{HTMLReactParser(STRINGS.rupees_sign)+item.paid_amount}</p>
                     </div>
                     <div className="col-sm-8 col-md-8">
                        <div >
                        <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+item.student_id+"/"+item.transaction_for} style={{textDecoration:'none'}}>
                        
                        <div className=" gravity_center_vertical">
                        
                         
    
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Receipt No: "+item.reciept_no}</p>
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction ID: "+item.transaction_id}</p>
                          <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction Date: "+item.transaction_date}</p>
                          <p style={{fontSize:'16px',fontWeight:'700',color:COLORS.primary_color_shade5}}>{"Transaction For: "+item.transaction_for}</p>
        
                        </div>
                        </Link>
                      </div>
                  </div>
                 
                 </div>
   
               </div>
                 )
            }
          })}
    </div>
</div>
{this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           

  </div>  
             )
        }
       } />

        <Route path='/AdminMain/PaymentDetails/:transaction_id/:session_id/:student_id/:transaction_for'  component={PaymentDetails} />       
        
    </switch>   
   </Router>

      )
      
    }    
  }
 
}

