import React, { Component, useRef, createRef } from 'react'
import '../css/Admin_css/admin-customize.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import axios from 'axios'
import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';
import Form from 'react-bootstrap/Form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import StRegSuccessfull from './StRegSuccessfull';
import myToast from '../Utils/MyToast';


export default class EditStudentDetails extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();
    this.slt_Class = createRef();
    this.slt_Section = createRef();
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn: false,
      Loading: false,
      mClassBoard: [],
      mClassMedium: [],
      mClassData: [],
      AddmisType: '',
      AddmissionNumber: '1',
      FirstName: '',
      LastName: '',
      dob: '',
      date: '',
      gender: '',
      caste: '',
      community: '',
      stRegion: '',
      AdharNo: '',
      nationality: '',
      board_id: '',
      medium_id: '',

      curntVillage: '',
      crntPost: '',
      crntDistrict: '',
      crntState: '',
      crntPincode: '',
      perVillage: '',
      perPost: '',
      perDistrict: '',
      perState: '',
      perPincode: '',
      FatherName: '',
      fatherOccupation: 'Farmer',
      father_qualification: '',
      father_aadhar: '',
      motherName: "",
      motherOccupation: 'House Wife',
      mother_qualification: "",
      mother_aadhar: "",
      contact1: '',
      contactName_1: '',
      relation_1: '',
      contact2: '',
      contactName_2: '',
      relation_2: '',
      email: '',
      mFilteredClassSectionList: [],
      mFilteredSectionList: [],
      CLASS_ID: '',
      SECTION_ID: ''

    }
    this.regSubmit = this.regSubmit.bind(this);
    this.regChange = this.regChange.bind(this);
  }

  handleChangeAdmissionType = event => {
    console.log("adm>>>>>>>>>>>>", event.target.value);
    this.setState({

      AddmisType: event.target.value,
    })

    /* alert(this.state.AddmisType);
   */
  };
  handleChangeGender = event => {
    this.setState({

      gender: event.target.value,
    })
  };
  handleChangeCommunity = event => {
    this.setState({

      community: event.target.value,
    })
  };


  onBoardChange = (event) => {
    this.getClassList();
  };

  onMediumChange = (event) => {
    this.getClassList();
  }

  OnClassChange = (event) => {
    this.getClassSectionList();
  }
  OnSectionChange = (event) => {
    this.state.CLASS_ID = this.slt_Class.current.selectedOptions[0].getAttribute('class_id');
    this.state.SECTION_ID = this.slt_Section.current.selectedOptions[0].getAttribute('section_id');

  }
  getClassSectionList() {
    let class_id = this.slt_Class.current.selectedOptions[0].getAttribute('class_id');
    //alert(JSON.stringify(class_id));
    // alert(JSON.stringify(this.state.mFilteredClassSectionList));

    this.state.mFilteredSectionList = [];
    let section = null;
    for (var i = 0; i < this.state.mFilteredClassSectionList.length; i++) {
      let item = this.state.mFilteredClassSectionList[i];
      if (item.class_id === class_id) {
        section = item.section;

      }
    }
    // alert(JSON.stringify(section));

    if (section !== 'No') {
      this.setState({
        mFilteredSectionList: section
      });
      this.state.CLASS_ID = class_id;
      this.state.SECTION_ID = section[0].section_id;


    }
    else {
      this.state.CLASS_ID = class_id;
      this.state.SECTION_ID = "No";
      this.setState({

      })

    }

  }

  getClassList() {
    let board_id = this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id = this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    //filtering class of selected board and medium

    this.state.mFilteredClassSectionList = [];
    for (var i = 0; i < this.state.mClassData.length; i++) {
      let item = this.state.mClassData[i];
      if (item.board_id === board_id && item.medium_id === medium_id) {

        this.state.mFilteredClassSectionList.push(item);

      }
    }
    // alert(JSON.stringify(this.state.mFilteredClassSectionList));

    this.setState({

    })
    this.getClassSectionList();


  }
  componentDidMount() {
    this.getClass();
    this.getAdmissionNo();
  }


  getClass() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;


    const FUNCTION_NAME = 'getClass.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mClassData: response.data.class,
            mClassBoard: response.data.board,
            mClassMedium: response.data.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
        }
        else {
          let api_remark = response.api_remark;
          //myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }


  getAdmissionNo() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getLastAdmissionNo.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,

      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })

        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          if (response.data.length !== 0) {

            var sr_admission_no = response.data[0].sr_admission_no;

            var temp = sr_admission_no.match(/[a-z]+|[^a-z]+/gi);

            if (temp.length === 1) {
              this.state.AddmissionNumber = parseInt(temp[0]) + 1;
            }
            else {
              if (isFinite(temp[0])) {
                this.state.AddmissionNumber = (parseInt(temp[0]) + 1) + temp[1];
              }
              else {
                this.state.AddmissionNumber = temp[0] + (parseInt(temp[1]) + 1);
              }

            }
          }
          else {
            this.state.AddmissionNumber = 1;
          }
          //alert(this.state.AddmissionNumber);
          this.setState({

          })

        }
        else {
          let api_remark = response.api_remark;
          //myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }

  regChange(e) {

    this.setState({
      [e.target.id]: e.target.value
    })
  }
  copytopermAddr = e => {
    let val = e.target.checked;
    if (val) {
      this.setState({
        perVillage: this.state.curntVillage,
        perPost: this.state.crntPost,
        perDistrict: this.state.crntDistrict,
        perState: this.state.crntState,
        perPincode: this.state.crntPincode
      });
    } else {
      this.setState({
        perVillage: this.state.perVillage,
        perPost: this.state.perPost,
        perDistrict: this.state.perDistrict,
        perState: this.state.perState,
        perPincode: this.state.perPincode
      });
    }
  };
  regSubmit() {
    //alert(this.state.AddmisType);
    if (this.isValidInput()) {


      this.state.board_id = this.slt_board.current.selectedOptions[0].getAttribute('board_id');
      this.state.medium_id = this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');

      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mSession = SessionManager.getCurrentSession();
      const SESSION_ID = mSession[0].session_id;
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;

      const ACADEMIC_MODE = "ACDM01";
      const FUNCTION_NAME = 'registerStudent.php?'
      URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME;
      console.log("URL>>>>>" + URL);

      var params = new URLSearchParams();
      params.append('school_code', SCHOOL_CODE);
      params.append('session_id', SESSION_ID);
      params.append('student_name', this.state.FirstName + " " + this.state.LastName);
      params.append('dob', this.state.dob);
      params.append('admDate', this.state.date);
      params.append('admission_type', this.state.AddmisType);
      params.append('admission_no', this.state.AddmissionNumber);
      params.append('academic_mode', ACADEMIC_MODE);
      params.append('religion', this.state.stRegion);
      params.append('gender', this.state.gender);
      params.append('community', this.state.community);
      params.append('caste', this.state.caste);
      params.append('aadhar_no', this.state.AdharNo);
      params.append('nationality', this.state.nationality);
      params.append('board_id', this.state.board_id);
      params.append('medium_id', this.state.medium_id);
      params.append('class_id', this.state.CLASS_ID);
      params.append('section_id', this.state.SECTION_ID);
      params.append('curAddress', "Village : " + this.state.curntVillage + " Post : " + this.state.crntPost + " District : " + this.state.crntDistrict + " State : " + this.state.crntState + " Pincode : " + this.state.crntPincode);
      params.append('perAddress', "Village : " + this.state.perVillage + " Post : " + this.state.perPost + " District : " + this.state.perDistrict + " State : " + this.state.perState + " Pincode : " + this.state.perPincode);

      params.append('father_name', this.state.FatherName);
      params.append('father_occupation', this.state.fatherOccupation);
      params.append('father_qualification', this.state.father_qualification);
      params.append('father_aadhar', this.state.father_aadhar);

      params.append('mother_name', this.state.motherName);
      params.append('mother_occupation', this.state.motherOccupation);
      params.append('mother_qualification', this.state.mother_qualification);
      params.append('mother_aadhar', this.state.mother_aadhar);

      params.append('contact1', this.state.contact1);
      params.append('contact_person_name1', this.state.contactName_1);
      params.append('contact_person1', this.state.relation_1);

      params.append('contact2', this.state.contact2);
      params.append('contact_person_name2', this.state.contactName_2);
      params.append('contact_person2', this.state.relation_2);
      params.append('email', this.state.email);
      params.append('caste', this.state.caste);
      params.append('staff_id', STAFF_ID);



      axios.post(URL, params).then(res => res.data)
        .then((response) => {

          this.setState({
            Loading: false
          })
          let api_status = response.api_status;
          //console.log("responsel>>>>>"+response);
          if (api_status == "OK") {
            var enrollment_no = response.enrollment_no;
            alert("successfully Registered !");
            window.location.reload();
            window.location = "/AdminMain/StudentRegSuccess/" + enrollment_no + "/" + SESSION_ID;

          }
          else {
            alert("Problem in Registration !");
          }

        })
    }
  }

  isValidInput() {
    var option = document.getElementsByName('radio_css_inlinead');

    if (!(option[0].checked || option[1].checked)) {
      alert("Please Select  Addmission Type !");
      return false;
    }
    else
      if (this.state.FirstName === "") {
        alert('First Name is Required!');
        return false;
      }

      else if (this.state.dob === "") {
        alert("DOB  accepts Only Numeric!");
        return false;
      }
      else
        var option = document.getElementsByName('radio_css_inline');

    if (this.state.gender === "") {
      alert("Please Select Your Gender");
      return false;
    }
    else
      var option = document.getElementsByName('radio_css_inline1');

    if (this.state.community === "") {
      alert("Please Select Your Community");
      return false;
    }

    else if (this.state.nationality === "") {
      alert("Nationality accepts Only Alphabate!");
      return false;
    }
    else if (this.state.stRegion === "") {
      alert("Religion  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.board === "") {
      alert("Board  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.medium === "") {
      alert("Medium  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.class === "") {
      alert("Enter your Class Name");
      return false;
    }
    else if (this.state.curntVillage === "") {
      alert("Current village  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.crntPost === "") {
      alert("Current Post  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.crntDistrict === "") {
      alert("Current District  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.crntState === "") {
      alert("Current state  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.crntPincode === "") {
      alert("Current Pin code  accepts Only Numeric!");
      return false;
    }
    else if (this.state.perVillage === "") {
      alert("Permanent village  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.perPost === "") {
      alert("Permanent Post  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.perDistrict === "") {
      alert("Permanent District  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.perState === "") {
      alert("Permanent state  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.perPincode === "") {
      alert("Permanent Pin code  accepts Only Numeric!");
      return false;
    }
    else if (this.state.FatherName === "") {
      alert("Father Name  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.motherName === "") {
      alert("Mother Name  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.motherOccupation === "") {
      alert("Mother Occupation  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  accepts Only Alphabate!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  accepts Only Alphabate!");
      return false;
    }
    else {
      return true;
    }

  }
  render() {

    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/EditStudentDetails/:student_id'} exact render={
              () => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Student Registration</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="row animated fadeInUp">
                      <div className="col-sm-12 col-lg-12">
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="panel">
                              <div className="panel-content text-center" style={{ minHeight: '400px' }}>

                                {/* content body */}
                                <div className="panel-body">
                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="form-horizontal form-bordered ">
                                      <div className="form-group row">

                                        <div className="col-md-9 bg_card_blue_outline1 s mar_bottom bg_admission">
                                          <div className="row mar_top gravity_center">

                                            <label className=" new_admission font_size">  ADMISSION TYPE: </label>
                                            <div className="new_admission font_size">
                                              <RadioGroup className='new_admission font_size' aria-label="position" name="radio_css_inlinead" value={this.state.AddmisType} onChange={this.handleChangeAdmissionType} row>
                                                <FormControlLabel
                                                  value="New"
                                                  control={<Radio color="primary" />}
                                                  label="New Admission"
                                                  labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                  value="Re"
                                                  control={<Radio color="primary" />}
                                                  label="Re Admission(Promoted)"
                                                  labelPlacement="end"
                                                />
                                              </RadioGroup>
                                            </div>

                                            <div className='row'>
                                              <div className="col-md-8 mar_top padding_0">
                                                <label className=" new_admission font_size">  Admission Number(Optional): </label>
                                              </div>
                                              <div className="col-md-4 mar_top">
                                                <div className="input-group font_size padding_0">
                                                  <input type="text" id="AddmissionNumber" name="Addmission Number" value={this.state.AddmissionNumber} onChange={this.regChange} className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div className="col-sm-3 bg-white mar_bottom">
                                          <div class="circular_image1">
                                            <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img_staff1" alt="student list image" />
                                          </div>

                                          <div className="photo-row-bottom posi-rel mar_top">
                                            <span><input type="file" name="upload photo" /></span>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="pnlSubHedingBorder">
                                    <div className="pnlSubHeding heading-with-bg-w">
                                      Basic Information
                                    </div>
                                  </div>

                                  <div className="row mar_top">

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">First Name</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" required id="FirstName" variant="outlined" placeholder="First Name" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Last Name</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" required id="LastName" variant="outlined" placeholder="Last Name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className="">Admission Date</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group date admission_form2" id="stAdmissionDate">
                                            <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className="">Date of Birth</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group date" id="stDateofBirth">
                                            <input type="date" id="dob" name="dob" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className=""> Gender </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className='d-flex admission_form'>
                                            <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.gender} onChange={this.handleChangeGender} row>
                                              <FormControlLabel
                                                value="Male"
                                                control={<Radio color="primary" />}
                                                label="Male"
                                                labelPlacement="end"
                                              />
                                              <FormControlLabel
                                                value="Female"
                                                control={<Radio color="primary" />}
                                                label="Female"
                                                labelPlacement="end"
                                              />
                                            </RadioGroup>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 padding_0">
                                      <div className='row padding_0'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className="admission_radio1">Community</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.community} onChange={this.handleChangeCommunity} row>
                                            <FormControlLabel
                                              value="SC"
                                              control={<Radio color="primary" />}
                                              label="SC"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="ST"
                                              control={<Radio color="primary" />}
                                              label="ST"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="OBC"
                                              control={<Radio color="primary" />}
                                              label="OBC"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="General"
                                              control={<Radio color="primary" />}
                                              label="General"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="Others"
                                              control={<Radio color="primary" />}
                                              label="Others"
                                              labelPlacement="end"
                                            />
                                          </RadioGroup>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Religion</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" className="form-control" placeholder='Religion' value={this.state.stRegion} onChange={this.regChange} id="stRegion" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Caste(Optional)</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="caste" type="text" placeholder='Caste' value={this.state.caste} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Adhar Number</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="AdharNo" type="text" placeholder='Adhar Number' value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Nationality</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" id="nationality" placeholder='Nationality' name="nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>




                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Class Information
                                      </div>
                                    </div>

                                    <div className="row mar_top">
                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className="mar_top"> Board </label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <select className="form-ii" onChange={this.onBoardChange} ref={this.slt_board}>
                                              {this.state.mClassBoard.map((item, index) => (
                                                <option board_id={item.board_id}>{item.board_name}</option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className="mar_top"> Medium</label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <select className="form-ii" onChange={this.onMediumChange} ref={this.slt_medium}>
                                              {this.state.mClassMedium.map((item, index) => (
                                                <option medium_id={item.medium_id}>{item.medium_name}</option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className="mar_top"> Class </label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <select className="form-ii" onChange={this.OnClassChange} ref={this.slt_Class}>
                                              {this.state.mFilteredClassSectionList.map((item, index) => (
                                                <option class_id={item.class_id} position_no={index}> {item.class_name} </option>

                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>

                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Current Address
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Village/Town </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="curntVillage" placeholder='Village/Town/Building Number' value={this.state.curntVillage} onChange={this.regChange} type="text" className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Post/Locality </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntPost" type="text" placeholder='Post/Locality' value={this.state.crntPost} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Police Station </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntDistrict" type="text" placeholder='Police Station' value={this.state.crntDistrict} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> District </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntDistrict" type="text" placeholder='District' value={this.state.crntDistrict} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> State </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntState" type="text" placeholder='State' value={this.state.crntState} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Pin Code </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntPincode" placeholder='Pin Code' type="text" value={this.state.crntPincode} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                  <div className="pnlSubHedingBorder headMarginTop">
                                    <div className="pnlSubHeding heading-with-bg-w">
                                      Permanent Address
                                    </div>
                                  </div>

                                  <div className="row mar_top">
                                    <div className="col-md-8 padding_0">
                                      <div className=" padding_0 admission_prvform1 d-flex">
                                        <label className="admission_radio1"> Same As Current Address </label>
                                        <div className="switcher admission_radio1">
                                          <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" onChange={this.regChange} defaultChecked={this.state.checked} onClick={this.copytopermAddr} />
                                          <label htmlFor="switcher_checkbox_1" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Village/Town </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perVillage" placeholder='Village/Town/Building Number' type="text" value={this.state.perVillage} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Post/Locality </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perPost" placeholder='Post/Locality' type="text" value={this.state.perPost} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Police Station </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="crntDistrict" type="text" placeholder='Police Station' value={this.state.crntDistrict} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> District </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perDistrict" placeholder='District' type="text" value={this.state.perDistrict} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> State </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perState" placeholder='State' type="text" value={this.state.perState} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Pin Code </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perPincode" placeholder='Pin Code' type="text" value={this.state.perPincode} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Family Information
                                      </div>
                                    </div>
                                    <div className="row mar_top">

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className=""> Father Name </label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="FatherName" placeholder='Father Name' type="text" value={this.state.FatherName} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-6 padding_0 admission_prvform1">
                                            <label className="">Father Occupation</label>
                                          </div>
                                          <div className="col-md-6 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="fatherOccupation" placeholder='Father Occupation' type="text" value={this.state.fatherOccupation} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className=""> Mother Name </label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="motherName" placeholder='Mother Name' type="text" value={this.state.motherName} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-6 padding_0 admission_prvform1">
                                            <label className="">Mother Occupation</label>
                                          </div>
                                          <div className="col-md-6 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="motherOccupation" placeholder='Mother Occupation' type="text" value={this.state.motherOccupation} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>

                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Contact Details
                                      </div>
                                    </div>

                                    <div className='row mar_top'>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Contact Number 1</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contact1" placeholder='Enter Mobile Number' type="text" value={this.state.contact1} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Name</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contactName_1" type="text" placeholder='Name' value={this.state.contactName_1} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className="">Relation</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="relation_1" type="text" placeholder='Relation' value={this.state.relation_1} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                    </div>

                                    <div className='row'>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Contact Number 2</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contact2" type="text" placeholder='Contact Number ' value={this.state.contact2} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Name</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contactName_2" type="text" placeholder='Name' value={this.state.contactName_2} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className="">Relation</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="relation_2" type="text" placeholder='Relation' value={this.state.ralation_2} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                      <div className="col-md-2 padding_0 admission_prvform1">
                                        <label className="email_mar_left">Email Address </label>
                                      </div>
                                      <div className="col-md-10 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="email" type="text" value={this.state.email} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <center>
                                    <button className="btn btn-success" onClick={this.regSubmit} style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Submit</button>

                                  </center>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                )
              }
            } />
             
          

          </switch>

        </Router >
      )
    }
  }
}
