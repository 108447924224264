import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class UpdateSchoolDetails extends Component {
    constructor(props){
      super(props)
  
    
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
  
      this.state={
          loggedIn:false,
          Loading:false,
          schName:'',
          SchTagLine:'',
          Schemail:'',
          SchofficeCon1:'',
          SchofficeCon2:'',
          schstbyear:'',
          schReg:'',
          schAddress:''

  
      }
      this.regSubmit= this.regSubmit.bind(this);
      this.regChange= this.regChange.bind(this);
  }
  regChange(e){
  
    this.setState({
      [e.target.id]:e.target.value
  })
  }
  regSubmit(){
    // var hh= this.state.schName;
    // alert(hh);
    // return;
    if(this.isValidInput())
    {
    const web="www.tets.com";
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='updateSchoolDetails.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    axios.get(URL, {
      params: {
        school_code:SCHOOL_CODE,
        school_name:this.state.schName,
        tagline:this.state.SchTagLine,
        contact1:this.state.SchofficeCon1,
        contact2:this.state.SchofficeCon2,
        address: this.state.schAddress,
        registration_no: this.state.schReg,
        established_year: this.state.schstbyear,
        email: this.state.Schemail,
        website:web
  
      }
  
    }).then(res => res.data)
    .then((response) => {
      this.setState({ 
         Loading:false
        })
        let api_status=response.api_status;
        //console.log("responsel>>>>>"+response);
       // Add notification for update 
        alert("successfully Updated !" );
        window.location.reload();

      console.log("response>>>>>"+api_status)
     })
  }
  }  
  
  isValidInput()
  {
    if (this.state.schName==="")
      {
      alert("Please Enter School Name!");
      return false;
      }
    else if(this.state.schAddress==="")
    {
      alert("Please Enter School Adrress!");
      return false;
    }
    else if(this.state.SchTagLine==="")
    {
      alert("Please Enter School Tag Line!");
      return false;
    }
    else if(this.state.schReg==="")
    {
      alert("Please Enter School Registration!");
      return false;
    }
    else if(this.state.schstbyear==="")
    {
      alert("Please Enter School Stablished Year!");
      return false;
    }
    else if(this.state.SchofficeCon1.length !="10")
    {
      alert("Please Enter Contact-1 10 Digit Numeric value!");
      return false;
    }
    else if(this.state.SchofficeCon2.length !="10")
    {
      alert("Please Enter Contact-2 10 Digit Numeric value!");
      return false;
    }
    else if(this.state.Schemail==="")
    {
      alert("Please Enter School officeial Email Id!");
      return false;
    }
    else{
      return true;
    }
  }
          render() {
  
   if(this.state.Loading)
  {
     return <MyLoader/>
  }
  else{
              return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Update School Details </a></li>
              </ul>
            </div>
          </div> 
          <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> School Name: </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="schName" name="schNamer" value={this.state.schName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">School Address: </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="schAddress" name="schAddress" value={this.state.schAddress} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> School Tag Line: </label>
                                    <div className="col-md-10">
                                      <div className="input-group">
                                        <input type="text" id="SchTagLine" name="SchTagLine" value={this.state.SchTagLine} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    </div>
                                    <div className="row school-update">
                                    <label className="col-md-2  col-form-label"> Registration No: </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="schReg" name="schReg" value={this.state.schReg} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Established Year: </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="schstbyear" name="schstbyear" value={this.state.schstbyear} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Official Contact 1: </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="SchofficeCon1" name="SchofficeCon1" value={this.state.SchofficeCon1} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Official Contact 2: </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="SchofficeCon2" name="SchofficeCon2" value={this.state.SchofficeCon2} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Official Email: </label>
                                    <div className="col-md-10">
                                      <div className="input-group">
                                        <input type="email" id="Schemail" name="Schemail" value={this.state.Schemail} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    </div>
              <div className="row">
              <div className="col-sm-12 col-md-12">
              <button className="btn btn-info" onClick={this.regSubmit} style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Submit  </button>
             </div> 
             </div>
            </div>
  
  )
} 
}
  }
