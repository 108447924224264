import React, { Component } from 'react'
import {Modal, Table} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import Axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import MyLoader from '../Spinner/MyLoader';


export default class MdlEditStudentRemark extends Component {
    constructor(props){
        super(props)       
        this.state={   
            show:true,
            Loading:false,
            SELECTED_STUDENT:'',
            REMARK:'',
            REMARK_OLD:'',
            REPORT_CARD_ID:'',
            total_working_days:'',
            total_present:''
            
        }
      
}
componentDidMount(){
 
  this.state.SELECTED_STUDENT=this.props.selected_student;
 // alert(JSON.stringify(this.state.SELECTED_STUDENT));
  this.state.REMARK=this.state.SELECTED_STUDENT.rank_details[0].remark;
  //alert(this.state.REMARK);
  this.state.REMARK_OLD=this.state.SELECTED_STUDENT.rank_details[0].remark;
  if(this.state.REMARK===null)
  {
    
    this.state.REMARK="";
    this.state.REMARK_OLD="";
    
  }

  this.state.REPORT_CARD_ID=this.props.report_card_id;
  
  this.setState({

  })
  
}
handleChangeRemark(event){
  this.state.REMARK= event.target.value
 
  this.setState({

  })

}


HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action();
    
  }
 
  SubmitRemark = () => {
    
    this.setState({
      Loading:true,
    
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mStaffData=SessionManager.getStaffDetails();
  var STAFF_ID=mStaffData.staff_id;
  var student_id=this.state.SELECTED_STUDENT.student_enrollment_no;
  var session_id=this.state.SELECTED_STUDENT.class[0].session_id;
  var class_id=this.state.SELECTED_STUDENT.class[0].class_id;
  var section_id=this.state.SELECTED_STUDENT.class[0].section_id;
  
  const FUNCTION_NAME='updateStudentReportCardRemark.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
  

  
  Axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      student_id:student_id,
      staff_id:STAFF_ID,
      session_id:session_id,
      class_id:class_id,
      section_id:section_id,
      remark:this.state.REMARK,
      remark_old:this.state.REMARK_OLD,
      report_card_id:this.state.REPORT_CARD_ID,
      
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      //console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
   
      if(api_status=="OK")
      {
       
        myToast.notify_warning("Updated Successfully");     
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }

    
render() {
        return (
            <div>
              <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='gravity_center'>
                       Student Remark
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='gravity_center'>
                   <input type="text"  value={this.state.REMARK} onChange={(e) => this.handleChangeRemark(e)} style={{width:'80%',height:'40px',fontSize:'16px',padding:'2px'}}></input>
                </div>
                {this.state.Loading?<div><MyLoader/></div>:''}
                        
                </Modal.Body>
                <Modal.Footer>
                <div className='gravity_center' style={{width:'100%'}}>
                <Button variant="primary" size="lg" block onClick={this.SubmitRemark} style={{width:'80%',height:'40px',fontSize:'16px',padding:'2px'}}>
                        Submit
                </Button>
                </div>
                    
               </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
