import React, { Component ,useRef, createRef} from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import uploadIcon from '../resources/images/upload-icon.png'
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import SelectClass from '../ChildComponent/SelectClass';
import MdlBatchShift from '../dialog/MdlBatchShift';
import myToast from '../Utils/MyToast';
export default class BatchShifting extends Component {
    constructor(props){
        super(props)
        this.slt_board=createRef();
        this.slt_medium=createRef();
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false,
          LoadingStudent:false,
          LoadClass:false,
          mPreviousSession:[], 
          SESSION_ID:"",
          CLASS_ID:"",
          SECTION_ID:"",
          SELECTED_CLASS_POSITION:'0',
          StudentList:[],
          mSelectedStudent:[],
          SelectedStudentCount:''
        }
      this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
      this.HandleSelectedClass = this.HandleSelectedClass.bind(this);
      this.HandleClose = this.HandleClose.bind(this);
      
    }
    
    
  HandleShow = () => {
    if(this.state.mSelectedStudent.length==0)
    {
        myToast.notify_error("Select Student !!!");
    }
    else{
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
   
  }
  
  HandleClose = () => {

    this.setState({
      isOpen:false
    });  
     
  }
  

  HandleSelectedClass = (class_id,section_id,session_id,selected_position,board_id,medium_id) => {
    this.setState({
      
      isOpen:false
    });
    this.BatchShift(class_id,section_id,session_id,selected_position,board_id,medium_id);
    
  }
   
    componentDidMount()
    {
       
       window.scrollTo(0, 0)
       this.getPreviousSession();
      //var mSession=SessionManager.getCurrentSession();
      //this.state.SESSION_ID=mSession[0].session_id;

      this.setState({
        showClass:true
      })
       
    }
    CallBackClassSelect = (class_id,section_id,session_id,selected_position,class_name,section_name,session_name) => {
      
      this.state.CLASS_ID=class_id;
      this.state.SECTION_ID=section_id;
      this.state.SESSION_ID=session_id;
      this.state.SELECTED_CLASS_POSITION=selected_position;
      //alert("Back"+class_id+"/"+section_id+"/"+session_id+"/"+selected_position+"/"+board_id+"/"+medium_id);
      this.state.mSelectedStudent=[];
      this.state.SelectedStudentCount=0;

    
      this.getStudentList();
      
    }
    getPreviousSession(){
    
      this.setState({
        Loading:true
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      
      const FUNCTION_NAME='getPreviousSession.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      // var params = new URLSearchParams();
        //  params.append('school_code', SCHOOL_CODE);
      
      axios.get(URL,{
        params:{school_code:SCHOOL_CODE}
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
          let api_status=response.api_status;
         // alert(api_status);        
          if(api_status=="OK")
          {
          
             this.setState({           
                 mPreviousSession:response.data,
                 LoadClass:true
                
              })
             
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
    }


      getStudentList(){
    
        this.setState({
        LoadingStudent:true,
        StudentList:[]
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const SESSION_ID=this.state.mPreviousSession[0].session_id;
      
      const FUNCTION_NAME='getBatchShiftStudent.php?'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      
      axios.get(URL,{
        params:{school_code:SCHOOL_CODE,
          class_id:this.state.CLASS_ID,
          section_id:this.state.SECTION_ID,
          session_id:SESSION_ID
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingStudent:false
         })
          console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
         // alert(api_status);
          
          if(api_status=="OK")
          {
           
             this.setState({
              
              StudentList:response.student
      
              })
             // alert(JSON.stringify(this.state.StudentList));
                 
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
      getSelectedStudent = (e,postion) => {
    
        let isChecked = e.target.checked;
        let student_id=this.state.StudentList[postion].student_enrollment_no;  
        //alert("okk"+student_id+"..."+isChecked);
        if(isChecked)
        {
            let item=this.state.StudentList[postion];
            this.state.mSelectedStudent.push(item);
                  
        }
        else{
          
          let item_index=this.getItemPostion(student_id);
          this.state.mSelectedStudent.splice(item_index,1);
         
        
        }
        this.setState({
          SelectedStudentCount:this.state.mSelectedStudent.length,
        })
        
         
      }
      getItemPostion(student_id){
        
        for (let index = 0; index < this.state.mSelectedStudent.length; index++) {
          const element = this.state.mSelectedStudent[index];
          if(student_id===element.student_enrollment_no)
          {
            return index;
          }
          
        }
        return -1;
      }
      BatchShift(class_id,section_id,session_id,selected_position,board_id,medium_id){
        
        this.setState({
          Loading:true,
      
        })
        const SCHOOL_CODE=SessionManager.getSchoolCode();
        var student_ids=this.getStudentIds();
        const FUNCTION_NAME='BatchShift.php?'
        URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
        
        axios.get(URL,{
          params:{
            student_ids:student_ids,           
            class_id:class_id,
            section_id:section_id,
            board_id:board_id,
            medium_id:medium_id,
            old_session_id:this.state.SESSION_ID,
            new_session_id:session_id,
            school_code:SCHOOL_CODE,
            
          }
        }).then(res => res.data)
        .then((response) => {
         
          this.setState({ 
            Loading:false
           })
            let api_status=response.api_status;
            //alert(api_status);
            
            if(api_status=="OK")
            {
                myToast.notify_success("Shifted Successfully!!!");
                this.setState({         
        
                })
             
            }
            else{
              let api_remark=response.api_remark;
              myToast.notify_warning(api_remark);
            }
         }).catch((error)=>{
          this.setState({ 
            Loading:false
           })
          console.log("error>>>>>"+error);
          });
      }
     getStudentIds() {
        var mStudentIds=[];
        var str;
        
        for (var i=0;i<this.state.mSelectedStudent.length;i++)
        {
          let item={};
         
          item["student_enrollment_no"]=this.state.mSelectedStudent[i].student_enrollment_no;
          item["student_roll_no"]=this.state.mSelectedStudent[i].student_roll_no;
          mStudentIds.push(item);
           
        }
        str=JSON.stringify(mStudentIds);
       
        return str;
    }
   

      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

    return (
      
      <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Batch Shifting </a></li>
              </ul>
            </div>
          </div>
          <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>

                      
          <div className="row">
                  
                <div className="col-sm-12 col-md-12">
                 {this.state.LoadClass===true?
                  <SelectClass action={this.CallBackClassSelect} selected_position={this.state.SELECTED_CLASS_POSITION} session_id={this.state.mPreviousSession[0].session_id}></SelectClass>:''
                  }
                </div>
           
                       
                <div className="col-sm-12 col-md-12 ">
                            <div className="bg_card_primary gravity_center">
                               <h2 className="en-adhar text-white"> Selected Students: {this.state.SelectedStudentCount}  &nbsp;&nbsp;&nbsp;&nbsp; &#8649; </h2>
                            </div>
                </div>
            </div>
            

            <div className="row">
               {this.state.LoadingStudent?<div><MyLoader/></div>:
               
               this.state.StudentList && this.state.StudentList.map((item, index) => ( 
                                            
                <div className="col-sm-6 col-md-6 p-0">
                <div className="row bg_card_blue_outline" style={{margin:'5px 15px 10px 15px',height:'90px'}}>
                 <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'1px',height:'85px'}}>
                   <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                   <div className="circular_image">
                      <img  src={item.student_img_path}  alt=""></img>
                      <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                   </div>  
                   </div>               
                 </div>
                 <div className="col-sm-6" style={{padding:'5px 10px',height:'85px'}}>
                  <h5> {item.stu_name} </h5>
                  <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                  <p className="mb-0"> Board: <span>{item.class[0].board_name}</span> </p>
                  <p className="mb-0"> Medium: <span>{item.class[0].medium_name}</span> </p>
                  
                 
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center" style={{height:'85px'}}>
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                    <p className="mb-0"> Roll No:{item.class[0].student_roll_no} </p>
                    

                    <p> <div className="st-list-roll-n" style={{
                              background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                              margin: '2px auto', fontSize: '20px'
                             }}> 
                             <input type="checkbox" id="issue_notice_c" class="ch-in" value="" onChange={e=>this.getSelectedStudent(e,index)}/></div>
                    </p>

                    <h6><span> {item.class[0].class_name} </span>  </h6>
                  </div>
                 </div>
              
                </div>
              
                </div>   
                                   
                 ))} 
                 </div>      
         
             
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                     <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px',background:COLORS.primary_color_shade5,border:COLORS.primary_color_shade5}} data-toggle="modal" data-target="#exampleModal" onClick={this.HandleShow}> Shift </button>
                                    
                 </div>    
                </div>
                {this.state.isOpen===true? <MdlBatchShift action={this.HandleClose} action2={this.HandleSelectedClass}></MdlBatchShift>:''}
           
  
          </div>
        </div>
  )
} 
}
}
