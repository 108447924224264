import React, { Component } from 'react'
import '../css/Admin_css/admin-customize.css';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';
import myToast from '../Utils/MyToast';
import Form from 'react-bootstrap/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
export default class StaffRegistration extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: false,
      Loading: false,
      staffName: '',
      department_id: '',
      dob: '',
      doj: '',
      inlineCssRadio1: '',
      inlineCssRadio2: '',
      phone: '',
      stRegion: '',
      AdharNo: '',
      nationality: '',
      Village: '',
      Post: '',
      District: '',
      State: '',
      Pincode: '',
      email: '',
      loginType: '',
      staffRole: '',
      Degree: '',
      Religion:'',
      University: '',
      year: '',
      Percentage: '',
      mDepartment: [],
      mstaffRole: ['Teacher', 'Clerk', 'Class Teacher', 'Clerk And Techer', 'Director And Principal', 'Director And Voice Principal', 'Faculty', 'LDA', 'UDA', 'Tutor And LDA', 'Academic Incharge', 'Child Caretaker', 'Mother Teacher', 'Computer Operator', 'Head Master', 'Physical Teacher', 'Office Bearers', 'Driver', 'Helper']
    }
    this.regSubmit = this.regSubmit.bind(this);
    this.regChange = this.regChange.bind(this);
  }
  handleChangeDepartment = (e) => {
    this.state.department_id=(e.target.selectedOptions[0].getAttribute('department_id'));
    alert(this.state.department_id)
  };
  handleChangeStaffRole = (e) => {
    this.state.staffRole=(e.target.value);
    alert(this.state.staffRole)
  };

  handleChangeloginType = (e) => {
    this.state.loginType=(e.target.value);
    alert(this.state.loginType)
  };

  handleChangeReligion = (e) => {
    this.state.Religion=(e.target.value);
    alert(this.state.Religion)
  };

  componentDidMount() {
    this.getDepartment();
  }
  

  getDepartment() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();

    const FUNCTION_NAME = 'getDepartment.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,

      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        // console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mDepartment: response.department,

          })

        }
        else {
          let api_remark = response.api_remark;
          //myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }







  regChange(e) {

    this.setState({
      [e.target.id]: e.target.value
    })
  }

  regSubmit() {
    if (this.isValidInput()) {

      // var bb=this.state.Department + this.state.staffRole + this.state.email + this.state.loginType + this.state.phone + this.state.AdharNo + this.state.nationality;
      // alert(bb);
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = 'registerStaff.php?'
      URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
      console.log("URL>>>>>" + URL)
      axios.get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          StaffName: this.state.staffName,
          department_id: this.state.department_id,
          dob: this.state.dob,
          doj: this.state.doj,
          religion: this.state.stRegion,
          gender: this.state.inlineCssRadio1 + this.state.inlineCssRadio2,
          aadhar_no: this.state.AdharNo,
          nationality: this.state.nationality,
          role: this.state.staffRole,
          login_type: this.state.loginType,
          address: this.state.Village + this.state.Post + this.state.District + this.state.State + this.state.Pincode,
          phone: this.state.phone,
          email: this.state.email
        }

      }).then(res => res.data)
        .then((response) => {
          this.setState({
            Loading: false
          })
          let api_status = response.api_status;
          //console.log("responsel>>>>>"+response);
          alert("successfully Updated !");
          if (typeof window !== 'undefined') {
            window.location.reload(false);
            window.location.href = "../AdminMain/StaffList";
          }

          console.log("response>>>>>" + api_status)
        })
    }
  }
  isValidInput() {

    if (this.state.staffName === "") {
      alert('Your Staff name have accepted Only Alphabate!');
      return false;
    }
    else if (this.state.Department === "") {
      alert("Enter Department have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.dob === "") {
      alert("DOB have accepted Only Numeric!");
      return false;
    }
    else if (this.state.doj === "") {
      alert("DOJ have accepted Only Numeric!");
      return false;
    }
    else
      var option = document.getElementsByName('radio_css_inline');

    if (!(option[0].checked || option[1].checked)) {
      alert("Please Select Your Gender");
      return false;
    }
    else if (this.state.AdharNo === "") {
      alert("Enter Adhar No. have accepted Only Numerice!");
      return false;
    }
    else if (this.state.nationality === "") {
      alert("Enter your nationality have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.stRegion === "") {
      alert("Enter your religion have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.Village === "") {
      alert("Enter your current village have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.Post === "") {
      alert("Enter your current Post have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.District === "") {
      alert("Enter your current District have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.State === "") {
      alert("Enter your current state have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.Pincode === "") {
      alert("Enter your current Pin code have accepted Only Numeric!");
      return false;
    }
    else if (this.state.phone === "") {
      alert("Enter your Phone no, have accepted Only Numeric!");
      return false;
    }
    else if (this.state.staffRole === "") {
      alert("Enter your Staff Role !");
      return false;
    }
    else if (this.state.loginType === "") {
      alert("Enter your Login Type !");
      return false;
    }
    else {
      return true;
    }

  }

  render() {

    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Staff Registration</a></li>
              </ul>
            </div>
          </div>
          <div className="row animated fadeInUp">
            <div className="col-sm-12 col-lg-12">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="panel">
                    <div className="panel-content text-center" style={{ minHeight: '400px' }}>

                      <div className="panel-body">

                        <div className="pnlSubHedingBorder">
                          <div className="pnlSubHeding heading-with-bg-w">
                            Personal Details
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 bg-white mar_bottom mar_top1 ">
                            <div class="circular_image1">
                              <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img_staff1" alt="student list image" />
                            </div>

                            <div className="photo-row-bottom posi-rel mar_top1">
                              <input type="file" name="upload photo" style={{ width: '168px' }} />
                            </div>

                          </div>
                          <div className="col-md-9">
                            <div className='row'>
                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Staff Name</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type="text" required id="staffName" name='staff name' variant="outlined" placeholder="Staff Name" value={this.state.staffName} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Department </label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="admission_form2">
                                      <Form.Select className="staff_role staff_mar" onChange={this.handleChangeDepartment}>
                                        <option>Select Department</option>
                                        {this.state.mDepartment.map((item, index) => (

                                          <option value={item.department_name} department_id={item.department_id}>{item.department_name}</option>

                                        ))}
                                      </Form.Select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Staff Role  </label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="admission_form2">
                                      <Form.Select className="staff_role staff_mar"onChange={this.handleChangeStaffRole}>
                                        <option>Select Role</option>
                                        {this.state.mstaffRole.map((item, index) => (
                                          <option value={item}>{item}</option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Login Type </label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="admission_form2">
                                      <Form.Select className="staff_role staff_mar"onChange={this.handleChangeloginType}>
                                        <option>login Type</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Teacher">Teacher</option>
                                        <option value="Clerk">Clerk</option>
                                        <option value="None">None</option>
                                      </Form.Select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mar_top1">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Phone Number</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type='tel' required id="phone" name='' placeholder="Phone Number" value={this.state.phone} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mar_top1">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Email Address</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type="email" required id="email" name='' placeholder="Email id" value={this.state.email} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Date of Joining</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type="date" required id="doj" name='doj' placeholder="Date of Joining" value={this.state.doj} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Date of Birth</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type="date" required id="dob" name='dob' placeholder="Date of Birth" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0 mar_top1">
                                    <label className=""> Gender </label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className='d-flex'>
                                      <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.gender} onChange={this.handleChangeGender} row>
                                        <FormControlLabel
                                          value="Male"
                                          control={<Radio color="primary" />}
                                          label="Male"
                                          labelPlacement="end"
                                        />
                                        <FormControlLabel
                                          value="Female"
                                          control={<Radio color="primary" />}
                                          label="Female"
                                          labelPlacement="end"
                                        />
                                      </RadioGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Religion</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="admission_form2">
                                      <Form.Select className="staff_role staff_mar"onChange={this.handleChangeReligion}>
                                        <option>Select Religion</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Sikh">Sikh</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Jain">Jain</option>
                                        <option value="Other">Other</option>
                                      </Form.Select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Adhar Number</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type="text" required id="AdharNo" name='' placeholder="Adhar Number" value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Nationality</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <input type="text" required id="nationality" name='nationality' placeholder="Nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>




                        <div style={{ float: 'left', width: '100%' }}>
                          <div className="pnlSubHedingBorder headMarginTop">
                            <div className="pnlSubHeding heading-with-bg-w">
                              Address
                            </div>
                          </div>
                          <div className="form-horizontal form-bordered">
                            <div className="form-group row">
                              <label className="col-md-2  col-form-label">  Village/Town </label>
                              <div className="col-md-10 ">
                                <div className="input-group">
                                  <input id="Village" value={this.state.Village} onChange={this.regChange} type="text" className="form-control" />
                                </div>
                              </div>
                              <label className="col-md-2  col-form-label">  Post </label>
                              <div className="col-md-4 ">
                                <div className="input-group">
                                  <input id="Post" type="text" value={this.state.Post} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                              <label className="col-md-2  col-form-label"> District </label>
                              <div className="col-md-4 ">
                                <div className="input-group">
                                  <input id="District" type="text" value={this.state.District} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                              <label className="col-md-2  col-form-label"> State </label>
                              <div className="col-md-4 ">
                                <div className="input-group">
                                  <input id="State" type="text" value={this.state.State} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                              <label className="col-md-2  col-form-label"> Pin Code </label>
                              <div className="col-md-4 ">
                                <div className="input-group">
                                  <input id="Pincode" type="text" value={this.state.Pincode} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div style={{ float: 'left', width: '100%' }}>
                          <div className="pnlSubHedingBorder headMarginTop">
                            <div className="pnlSubHeding heading-with-bg-w">
                              Education Details
                            </div>
                          </div>
                          <div className='bg_card_blue_outline'>
                            <div className='row mar_top1'>
                              <div className="col-md-4">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Degree/Diploma</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="admission_form2">
                                      <h4 className='staff1'> B.tech</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">University/Institute</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <h4> prasad polytechnic jaunpur</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Year</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="admission_form2">
                                      <h4 className='staff2'>2020</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='row'>
                                  <div className="col-md-4 padding_0">
                                    <label className="">Percentage</label>
                                  </div>
                                  <div className="col-md-8 padding_0">
                                    <div className="input-group admission_form2">
                                      <h4>98%</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mar_top1'>
                            <button style={{ width: '20%', borderRadius: '10px', fontSize: '14px', padding: '4px', background: '#28a745', float: 'right' }} data-toggle="modal" data-target="#exampleModal"> Add Next Qualification</button>
                          </div>
                        </div>

                        {/* add modal degree and university section */}

                        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog" role="document">
                            <div className="modal-content" style={{ width: '810px', height: '350px', padding: '40px' }}>
                              <div className="modal-header" style={{ background: '#516d8b', color: '#fff' }}>
                                <h5 className="modal-title" id="exampleModalLabel">Add Qualification section</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true" style={{ color: '#fff' }}>&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <label className="col-md-2  col-form-label"> Degree/Diploma </label>
                                  <div className="col-md-4 ">
                                    <div className="input-group">
                                      <input id="Degree" type="text" value={this.state.Degree} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                  <label className="col-md-2  col-form-label">University/Institute </label>
                                  <div className="col-md-4 ">
                                    <div className="input-group">
                                      <input id="University" type="text" value={this.state.University} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                  <label className="col-md-2  col-form-label">Year </label>
                                  <div className="col-md-4 ">
                                    <div className="input-group">
                                      <input id="year" type="text" value={this.state.year} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                  <label className="col-md-2  col-form-label">Percentage </label>
                                  <div className="col-md-4 ">
                                    <div className="input-group">
                                      <input id="percentage" type="text" value={this.state.percentage} onChange={this.regChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer s-pad-30">
                                <button type="button" onClick={this.saveChanges} class="btn btn-primary s-change"> Add Qualification </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{ float: 'left', width: '100%' }}>
                          <div className=" row">
                            <div className="col-md-12 padding_0">

                              <button className="btn btn-success" onClick={this.regSubmit} style={{ margin: '2% 0px', fontSize: '20px', width: '100%' }}>Submit</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      );
    }
  }
}
