import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
import MdlViewStudentAttendance from '../dialog/MdlViewStudentAttendance';
import MdlEditStudentAttendance from '../dialog/MdlEditStudentAttendance';
import MdlEditStudentRemark from '../dialog/MdlEditStudentRemark';
export default class ExamReportCardAttendanceReport extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        LoadingRankList:false,
        isOpenViewStudentAttendance:false,
        isOpenEditStudentAttendance:false,
        isOpenEditStudentRemark:false,
        mExamMarkingSystem:[],
        mExamParticipatingClass:[],
        mMarkingSystem:[],
        mMarkingSystemValue:[],
        mStudentList:[],
        mAttendance:[],
        mWorkingDays:[],
    
        EXAM_ID:'',
        STUDENT_ID:'',
        REPORT_CARD_ID:'',
        REPORT_CARD_TYPE:'ANNUAL',
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        CLASS_SELECTED_POSITION:0,
        SELECTED_STUDENT:'',
        MANUAL_ATTENDANCE:'',
        total_working_days:0
        
       

    }
    this.HandleCloseViewStudentAttendance = this.HandleCloseViewStudentAttendance.bind(this);
    this.HandleCloseEditStudentAttendance = this.HandleCloseEditStudentAttendance.bind(this);
   
    
  
  }
  ViewStudentAttendance=(item)=>{
   // alert(JSON.stringify(item2));
         
    this.setState({
    
      isOpenViewStudentAttendance:true,
      mSelectedStudent:item
    
    })
    for (let index = 0; index < this.state.mAttendance.length; index++) {
      var element = this.state.mAttendance[index];
      
      if(this.state.MANUAL_ATTENDANCE===0)
      {
        alert(JSON.stringify(element.student_enrollment_no));
        if(item.student_enrollment_no===element.student_enrollment_no)
        {
          this.state.mSelectedStudent[0]['attendance_report']=element;
          
         
        }
  
      }
      else{
        if(item.student_enrollment_no===element.student_enrollment_no)
        {
          this.state.mSelectedStudent[0]['attendance_report']=element;
          //alert(JSON.stringify(element));
        
        }
      }           
    }
   
  }
  HandleCloseViewStudentAttendance = () => {

    this.setState({
      isOpenViewStudentAttendance:false,

    });  
     
  }
  EditStudentAttendance=(item2)=>{
    //alert(JSON.stringify(item2));
         
    this.setState({
    
      isOpenEditStudentAttendance:true,
      SELECTED_STUDENT:item2
    
    })
   
  }
  SyncStudentAttendance=(item2)=>{
    //alert(JSON.stringify(item2));
    this.state.STUDENT_ID=item2.student_enrollment_no;
    this.state.CLASS_ID=item2.class[0].class_id;
    this.state.SECTION_ID=item2.class[0].section_id;
    this.state.SESSION_ID=item2.class[0].session_id;

    //alert(this.state.STUDENT_ID+ this.state.CLASS_ID+this.state.SESSION_ID);
    this.syncStudentAttendanceReport();
    
   
  }
  SyncClassAttendance=()=>{
    //alert(JSON.stringify(item2));
  

    //alert(this.state.STUDENT_ID+ this.state.CLASS_ID+this.state.SESSION_ID);
    this.syncClassAttendanceReport();
    
   
  }
  HandleCloseEditStudentAttendance = () => {

    this.setState({
      isOpenEditStudentAttendance:false,

    }); 
    this.getClassStudentAttendanceReport();

     
  }
 
  HandleCloseEditStudentRemark = () => {

    this.setState({
      isOpenEditStudentRemark:false,

    });  
    this.getClassStudentRankList();
     
  }
  componentDidMount()
  {
  
    this.state.REPORT_CARD_ID=this.props.match.params.report_card_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
    this.state.MANUAL_ATTENDANCE=this.props.match.params.manual_attendance;   
  
    window.scrollTo(0, 0); 
    this.getMarkingSystemData();  
  }

  getMarkingSystemData(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamMarkingSystem.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
      exam_id:"",
      report_card_type:'ANNUAL',
      report_card_id:this.state.REPORT_CARD_ID,
    
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
       //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
            mExamMarkingSystem:response.data,
                   
          })
          if(this.state.mExamMarkingSystem.length!==0)
          {
            this.state.EXAM_ID=this.state.mExamMarkingSystem[0]['concerned_exams'];

          }
         

          this.getParticipatingClass();
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  getParticipatingClass()
  {
      
    this.setState({         
      mExamParticipatingClass:this.state.mExamMarkingSystem[0]['participating_class'],
    })
    this.setClassList(0);
  }
  selectClass=(position)=>{  
    this.setClassList(position);
    
  }
  setClassList(selected_position){      
    if(this.state.mExamParticipatingClass.length!==0)
    {
        this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
        this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;

        if(this.state.SECTION_ID=="null")
        {
            this.state.SECTION_ID="No";
        }
        this.state.CLASS_SELECTED_POSITION=selected_position;

    }
 
    this.state.mMarkingSystem=this.state.mExamParticipatingClass[selected_position].marking_system;
    this.state.mMarkingSystemValue=this.state.mMarkingSystem[0].MarkingSystemValue;
    //alert(this.state.MANUAL_ATTENDANCE);
    if(this.state.MANUAL_ATTENDANCE==="1")
    {
      this.getClassStudentAttendanceReport();
    }
    else{
      this.syncClassAttendanceReport();
    } 
   }

    getClassStudentAttendanceReport(){
  
    this.setState({
      LoadingStudent:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getClassStudentAttendanceReportMannual.php';
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
    console.log("URL>>>>>"+URL);
  
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        session_id:this.state.SESSION_ID,
        class_id:this.state.CLASS_ID,
        section_id:this.state.SECTION_ID,
        report_card_type:'ANNUAL',
        report_card_id:this.state.REPORT_CARD_ID,
      
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        LoadingStudent:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({         
            mStudentList:response.data.student_list,       
            mAttendance:response.data.present_attendance,
            mWorkingDays:response.data.working_days,
                     
            })
          
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

    syncStudentAttendanceReport(){
  
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='syncStudentAttendanceReport.php';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
      console.log("URL>>>>>"+URL);
    
      axios.get(URL,{
        params:{
          school_code:SCHOOL_CODE,
          session_id:this.state.SESSION_ID,
          class_id:this.state.CLASS_ID,
          section_id:this.state.SECTION_ID,
          student_id:this.state.STUDENT_ID,
        
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingStudent:false
         })
          let api_status=response.api_status;
         // alert(JSON.stringify(response));
          
          if(api_status=="OK")
          {
          
             this.setState({         
                
                       
              })
              myToast.notify_success("Synced Successfully");
              this.getClassStudentAttendanceReport();
            
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }

      syncClassAttendanceReport(){
        this.setState({
          LoadingStudent:true
        })
  
        const SCHOOL_CODE=SessionManager.getSchoolCode();
        const FUNCTION_NAME='syncClassAttendanceReport.php';
        URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
        console.log("URL>>>>>"+URL);
      
        axios.get(URL,{
          params:{
            school_code:SCHOOL_CODE,
            session_id:this.state.SESSION_ID,
            class_id:this.state.CLASS_ID,
            section_id:this.state.SECTION_ID,
           
          
          }
        }).then(res => res.data)
        .then((response) => {
         
          this.setState({ 
            LoadingStudent:false,
            mStudentList:[],
            mAttendance:[]
           })
            let api_status=response.api_status;
         // alert(JSON.stringify(response));
            console.log("response>>>>>"+JSON.stringify(response))
            
            if(api_status=="OK")
            {
            
                this.setState({  
                  mStudentList:response.data.student_list,       
                  mAttendance:response.data.present_attendance,
                  mWorkingDays:response.data.working_days,
                         
                })
                //alert(JSON.stringify(this.state.mAttendance));
             this.getTotalWorkingDays();
              
            }
            else{
              let api_remark=response.api_remark;
              myToast.notify_warning(api_remark);
            }
        
          //console.log("response>>>>>"+api_status)
         }).catch((error)=>{
          this.setState({ 
            Loading:false
           })
          console.log("error>>>>>"+error);
          });
        }
    getTotalAttendane(item)
    {
      var total_present=0;


      if(this.state.mAttendance!==null && this.state.mAttendance.length!==0)
        {
          for (let index = 0; index < this.state.mAttendance.length; index++) {
            const element = this.state.mAttendance[index];
            if(this.state.MANUAL_ATTENDANCE===0)
            {
              if(item.student_enrollment_no===element.student_enrollment_no)
              {
                total_present=Number(total_present)+1;
              }
        
            }
            else{
              if(item.student_enrollment_no===element.student_enrollment_no)
              {
                var present=element.present;
                total_present=Number(total_present)+Number(present);
              }
            }           
          }
        }
        else{
          total_present=0;
        }  
      return total_present;
    }
    getTotalWorkingDays()
    { 
      this.state.total_working_days=0;
      var count=0;
      if(this.state.mWorkingDays.length!==0)
      {
            for (let index = 0; index < this.state.mWorkingDays.length; index++) {
             const element = this.state.mWorkingDays[index];
             count=Number(count)+Number(element.working_days);
        
          }
          this.setState({
            total_working_days:count
          })
      }
      else{
        this.setState({
          total_working_days:count
        })
      }

    }

  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return  <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamReportCardAttendanceReport/:report_card_id/:session_id/:manual_attendance/'}  exact render={
            ()=>{
              
         return (
       
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Attendance Report</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
        
          <div className="row gravity_center" style={{margin:'0px 15px 0px 15px'}}>
                <div className="col-sm-4 col-md-4 grid_primery_outline_primery">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Academic Session:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['session_name']:''}  </p> 
                </div>
                <div className="col-sm-4 col-md-4 grid_primery_outline_primery">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['exam_name']:''} </p> 
                </div>
                <div className="col-sm-4 col-md-4 grid_primery_outline_primery">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam Date:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['start_date']:''}  </p> 
                </div>
                               
          </div>
          <div className="row gravity_center" style={{margin:'2px 15px 2px 15px'}}>
               
               <div className="col-sm-6 col-md-6 grid_primery_outline_primery" style={{height:'40px'}}>
                 <p  style={{padding:'2px',fontSize:'18px'}}><strong> ReportCard Caption:</strong> {this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['report_card_name']:''} </p> 
               </div>
               <div className="col-sm-3 col-md-3 bg_grid_primary_2" style={{height:'40px'}}>
                  <div className='gravity_center'>              
                    <Link to={"/AdminMain/ExamStudentReportCard/"}>
                        
                     </Link>
                    </div>

               </div>
               <div className="col-sm-3 col-md-3 bg_grid_primary_2 " style={{height:'40px'}}>
                 <div className='gravity_center'>              
                    <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.REPORT_CARD_ID+"/"+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+"null"}>
                    <button className="bg_card_primary btn btn-success">Get Report Card</button>
                     </Link>
                    </div>

               </div>
                
         </div>
          <div style={{padding:'0px 15px 0px 15px'}}><h5>Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>
       

        
    
                <div className='row'>
                  <div className="col-sm-6col-md-6">
                      <div style={{padding:'0px 15px 0px 15px'}}><h5>Student Attendance Report:</h5></div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                  <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.SyncClassAttendance()}>Sync Class Attendance</button>

                  </div>
                  </div> 
               

                {this.state.LoadingStudent?<div><MyLoader/></div>:
                <div>
                <Table striped bordered hover  style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Sr.</th>
                              <th style={{ textAlign: "center" }}>Student Name</th>
                              <th style={{ textAlign: "center" }}>Father name</th>
                              <th style={{ textAlign: "center" }}>Roll No</th>
                              <th style={{ textAlign: "center" }}>
                                  <p>Attendance</p>
                                  <p>(Attendance/Working Days)</p>
                              </th>
                              <th style={{ textAlign: "center" }}>Attendance(%)</th>
                              <th style={{ textAlign: "center" }}>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mStudentList && this.state.mStudentList.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td style={{ textAlign: "center" }}>{index2+1}</td>
                              <td style={{ textAlign: "center" }}>{item2.stu_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.father_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.student_roll_no}</td>
                              <td style={{ textAlign: "center" }}>{this.getTotalAttendane(item2)+"/"+ this.state.total_working_days}</td>
                              <td style={{ textAlign: "center" }}>{((this.getTotalAttendane(item2)/ this.state.total_working_days)*100).toFixed(2)}</td>
                              <td className='gravity_center_row'> 
                                   <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.ViewStudentAttendance(item2)}>View</button>
                                   <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.EditStudentAttendance(item2)}>Edit</button>
                                   <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.SyncStudentAttendance(item2)}>Sync</button>
                                   <div className='gravity_center'>              
                                     <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.REPORT_CARD_ID+"/"+this.state.REPORT_CARD_TYPE+"/"+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+item2.student_enrollment_no}>
                                           <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}}>Report Card</button>
                                       </Link>
                                  </div>
                              </td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                        </div>

                }       
       
               

               
            </div>
           
            
            {this.state.isOpenViewStudentAttendance===true? <MdlViewStudentAttendance selected_student={this.state.SELECTED_STUDENT} action={this.HandleCloseViewStudentAttendance}></MdlViewStudentAttendance>:''}

            {this.state.isOpenEditStudentAttendance===true? <MdlEditStudentAttendance selected_student={this.state.SELECTED_STUDENT} action={this.HandleCloseEditStudentAttendance}></MdlEditStudentAttendance>:''}
          
         
     </div>


           
           )
          }
          } />
             <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }