import React, { Component } from 'react'
import { BrowserRouter as Router, Link,Route ,Redirect} from 'react-router-dom';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import '../css/Admin_css/admin-customize.css';
import FeeReceipt from './FeeReceipt';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import HTMLReactParser from 'html-react-parser';
import STRINGS from '../resources/strings/STRINGS';
export default class PaymentDetails extends Component {
  constructor(props){
    super(props)

    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={

        loggedIn:true,
        Loading:false, 
        PaymentDetails:[] ,
        mStudentDetails:[] ,
        netPaidAmount:0,
           
    }
  
}
componentDidMount()
{
  this.getPaymentDetails();
}
getPaymentDetails(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Transaction_id=this.props.match.params.transaction_id;
  const Session_id=this.props.match.params.session_id;
  const transaction_for=this.props.match.params.transaction_for;
  var FUNCTION_NAME='';
  if(transaction_for=='OLD_DUE')
  {
    FUNCTION_NAME='getOldDuePaymentDetails.php'
  }
  else{
     FUNCTION_NAME='getPaymentDetails.php'
  }
 
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:Session_id,
      transaction_id:Transaction_id
    }
  }).then(res => res.data)
  .then((response) => {
      this.setState({ 
      Loading:false
     })
     // console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            mStudentDetails:response.data[0].student_details,
            PaymentDetails:response.data[0].payment_details,
          })
        //alert(JSON.stringify(this.state.PaymentDetails.length));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }

    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
}
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{
    return (  
      
      
      <Router>
        <switch>
          <Route path='/AdminMain/PaymentDetails/:transaction_id/:session_id/:student_id/:transaction_for'  exact render={
            ()=>{
          return(
            <div className="content">
            <div className="content-header">
           <div className="leftside-content-header">
             <ul className="breadcrumbs">
               <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Payment Details</a></li>
             </ul>
           </div>
           
         </div> 
                     
      <div className="animated fadeInUp bg_page_white_rounded" style={{'--mMargin':'50px'}}>
         {/* <div className="row">

          <div className="col-sm-12 col-md-12">
              <div className="row class-list-box">
              <div className="col-sm-3 card-class-w stu-info-main-bg">
                <div className="stu-info-pic"><img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" style={{width: '100%'}} alt="student list image"/></div>
              </div> 
              <div className="col-sm-9 stu-info-detail text-justify">
                <div className="p-b-mar payment-hist-student-details">
                <p> <strong> Student ID : </strong> S000311 </p>     
                <p> <strong> Student Name : </strong> Deepika Prajapati </p>
                <p> <strong> Father Name : </strong> <span> Ram Singh Prajapati</span> </p>
                <p> <strong> Class : </strong> <span>LKG</span> </p>
                <p> <strong> Roll No. : </strong> <span>25</span> </p>
                </div>
              </div>
              </div>
          </div>
        </div>   */}
      
      
        <div className="row" style={{padding:'0px 20px'}}>
        {this.state.PaymentDetails.map((item, index)  => { 
          if(this.props.match.params.transaction_for=='OLD_DUE')
          {
            this.state.netPaidAmount=this.state.netPaidAmount+Number(item.amount_paid);
              return(
               
               <div className="col-sm-6 padding_0"> 
                <div  className="row bg_card_red_outline" style={{background:COLORS.white,margin:'5px'}}>  
                  <div className="col-sm-3 padding_0 gravity_center_vertical" style={{}}>
                      <div className="gravity_center">
                        <div className="bg_circle_red_check"> &#10003; </div>   
                      </div>
                      <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.card_red}}>{HTMLReactParser(STRINGS.rupees_sign)+item.amount_paid}</p>
                  </div>

                  <div className="col-sm-4" style={{padding:'5px 5px 5px 5px'}}>
                  <div className="text-justify">  
                    <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Session Name : </strong> </p>
                    <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Fee Name : </strong> </p>
                    <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Transaction Id : </strong> </p>
                    <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Transaction Date : </strong> </p>
                    <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Remark : </strong> </p>
                  </div>
                  </div>
                  <div className="col-sm-5" style={{padding:'5px 5px 5px 5px'}}>
                  <div className="text-justify">
                  <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.session_name} </p>
                  <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {"Old Due"} </p>
                  <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.transaction_id} </p>
                  <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.transaction_date} </p>
                  <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.remark} </p>
                  </div>
                  </div>
              </div> 
              </div>
              )}
              else if(this.props.match.params.transaction_for=='FEE')
              {
                this.state.netPaidAmount=this.state.netPaidAmount+Number(item.fee_paid);
              return(
             
              <div className="col-sm-6 padding_0"> 
                <div  className="row bg_card_green_outline" style={{background:COLORS.white,margin:'5px'}}>  
                  <div className="col-sm-3 padding_0 gravity_center_vertical" style={{}}>
                      <div className="gravity_center">
                        <div className="bg_circle_green_check"> &#10003; </div>   
                      </div>
                      <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.card_green}}>{HTMLReactParser(STRINGS.rupees_sign)+item.fee_paid}</p>
                  </div>
                 <div className="col-sm-4" style={{padding:'5px 5px 5px 5px'}}>
                 <div className="text-justify">  
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Payment Month : </strong> </p>
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Fee Name : </strong> </p>
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Transaction Id : </strong> </p>
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Transaction Date : </strong> </p>
                 </div>
                 </div>
                 <div className="col-sm-5" style={{padding:'5px 5px 5px 5px'}}>
                 <div className="text-justify">
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.year_month} </p>
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.fee_name} </p>
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.transaction_id} </p>
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.transaction_date} </p>
                 </div>
                 </div>
             </div> 
             </div>
             )
              }    
            else{
            this.state.netPaidAmount=this.state.netPaidAmount+Number(item.fee_paid);
            return(
             
              <div className="col-sm-6 padding_0"> 
                <div  className="row bg_card_green_outline" style={{background:COLORS.white,margin:'5px'}}>  
                  <div className="col-sm-3 padding_0 gravity_center_vertical" style={{}}>
                      <div className="gravity_center">
                        <div className="bg_circle_primery_check"> &#10003; </div>   
                      </div>
                      <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.primary_color_shade5}}>{HTMLReactParser(STRINGS.rupees_sign)+item.fee_paid}</p>
                  </div>
                 <div className="col-sm-4" style={{padding:'5px 5px 5px 5px'}}>
                 <div className="text-justify">  
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Payment Month : </strong> </p>
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Fee Name : </strong> </p>
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Transaction Id : </strong> </p>
                   <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> <strong> Transaction Date : </strong> </p>
                 </div>
                 </div>
                 <div className="col-sm-5" style={{padding:'5px 5px 5px 5px'}}>
                 <div className="text-justify">
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.year_month} </p>
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.fee_name} </p>
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.transaction_id} </p>
                 <p style={{fontSize:'14px',fontWeight:'500',color:COLORS.black7}}> {item.transaction_date} </p>
                 </div>
                 </div>
             </div> 
             </div>
             )
          }    
            })}

        
              
              
              </div>
            
              <div className="row">
                <div className="col-sm-12 col-md-12 gravity_center width_match_parent" >
                           
                  <Link to={'/AdminMain/FeeReceipt/'+this.props.match.params.transaction_id+"/"+this.props.match.params.student_id+"/"+this.props.match.params.session_id} style={{width:'100%'}}>     
                          <button className="btn btn-success bg_card_primary width_match_parent" style={{width:'100%',fontSize:'18px', margin:'30px 0px 15px 0px'}}> {"Get FeeReceipt("+HTMLReactParser(STRINGS.rupees_sign)+this.state.netPaidAmount+")" }</button>                                                  
                  </Link>   
                  </div>  
                       
              </div>
          </div>
  
      </div>
  )
}
} />

<Route path='/AdminMain/FeeReceipt/:transaction_id/:student_id/:session_id'  component={FeeReceipt} />       

</switch>   
</Router>
  )
}    
}
 
}

