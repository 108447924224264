import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import AddClassFee from './AddClassFee';
import Attendance from './Attendance';

export default class AttendanceDashboard extends Component {
  constructor(props) {
    super(props)        
    this.slt_board=createRef();
    this.slt_medium=createRef();
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpen: false, 
        loggedIn:true,
        Loading:false,
        SESSION_ID:"",
        CLASS_ID:"",
        SECTION_ID:"",
        mClassAttendenceStatusData:[],
        mFilteredClassSectionList:[],
        mClassBoard:[],
        mClassMedium:[],
        ClassSection:[],
        SELECTED_CLASS_POSITION:''
        
    }
  
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.getClassAttendanceStatus();
  }
  
  onBoardChange = () => {
    this.state.CLASS_SELECTED_POSITION=0;
    this.getClassWithAtdStatus();
    
  }
  onMediumChange = () => {
    this.state.CLASS_SELECTED_POSITION=0;
    this.getClassWithAtdStatus();
    
  }
  
  getClassAttendanceStatus(){

  this.state.mClassAttendenceStatusData=[];
  this.setState({
    Loading:true
  })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      var mSession=SessionManager.getCurrentSession();
      const SESSION_ID=mSession[0].session_id;
      var DAYS="1";
      //alert(SESSION_ID);

      const FUNCTION_NAME='getClassAttendenceStatus.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      // var params = new URLSearchParams();
        //  params.append('school_code', SCHOOL_CODE);

      axios.get(URL,{
        params:{school_code:SCHOOL_CODE,
          session_id:SESSION_ID,
          days:DAYS
        }
      }).then(res => res.data)
      .then((response) => {
      
        this.setState({ 
          Loading:false
        })
          //console.log("Response>>>"+JSON.stringify(response));
          let api_status=response.api_status;
           //alert(api_status);
          
          if(api_status=="OK")
          {
          
            this.setState({       
             
                mClassBoard:response.data.board,
                mClassMedium:response.data.medium,
                mClassAttendenceStatusData:response.data.ClassAttendenceStatus
              })
             this.getClassWithAtdStatus();
              //alert(JSON.stringify(this.state.mClassBoard));
              
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          } 

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
getClassWithAtdStatus()
{

  let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
  let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
  //alert(board_id);
 // alert(medium_id);
  //filtering class of selected board and medium
  var mFilteredClassList=[];
  this.state.mFilteredClassSectionList=[];
  for (var i=0; i < this.state.mClassAttendenceStatusData.length; i++) {
    let item=this.state.mClassAttendenceStatusData[i];
    if(item.board_id===board_id && item.medium_id===medium_id)
    {
    
      mFilteredClassList.push(item);

    }   
  }
  //console.log("ClassList>>>>>>",JSON.stringify(mFilteredClassList)); 
  //getting section of classes
  for (var i=0; i <mFilteredClassList.length; i++) {
    let item=mFilteredClassList[i];
    let SECTION=item.section;
            if(SECTION==="No")
            {
            
                  item["section_id"]="No";
                  item["section_name"]="";
                  this.state.mFilteredClassSectionList.push(item);
            }
            else {
  
              var tempSection=[];
              tempSection=item.section;
              for (var j=0;j<tempSection.length;j++)
              {
                  let item2=tempSection[j];
                  item2["board_id"]=board_id;
                  item2["board_name"]=item.board_name;
                  item2["medium_id"]=medium_id;
                  item2["medium_name"]=item.medium_name;
                  //console.log("item2>>>>>>",item2);                
                  this.state.mFilteredClassSectionList.push(item2);
                  
              }
            }
    
  }

  //console.log("ClassList>>>>>>2",JSON.stringify(this.state.mFilteredClassSectionList)); 

  this.setState({

  })
  
}
   
   
  render() {
    if(this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else{       
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/AttendanceDashboard/:module_id'}  exact render={
              ()=>{
            return(

              <div className="content" style={{background:COLORS.white}}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Attendance Dashboard </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>

                    <div className="bg_card_blue" style={{padding:'5px 5px 10px 5px'}}>          
                <div className="row m-0">
                  <div className="col-sm-6 col-md-6" >
                  <div className="">
                  <h5>Select Boards:</h5>
                  <select className="select-field" onChange={this.onBoardChange} ref = {this.slt_board}>
                  {this.state.mClassBoard && this.state.mClassBoard.map((item, index) => ( 
                  <option board_id={item.board_id}>{item.board_name}</option>
                     
                     ))}
                  </select>
                  </div>
                  </div>
                   <div className="col-sm-6 col-md-6" >
                    <div className="">
                     <h5>Select Medium</h5>
                     <select className="select-field" onChange={this.onMediumChange} ref = {this.slt_medium}>
                     {this.state.mClassMedium && this.state.mClassMedium.map((item, index) => ( 
                     <option medium_id={item.medium_id}>{item.medium_name}</option>
                     ))}
                   
                     </select>
                    </div>
                  </div>
  
                 </div> 
                                  
               </div>
               <section >
                      <h5>Today:Attendance Status(Quick View)</h5>
                      <div className="bg_card_blue_outline" style={{padding:'5px'}}>
                      <div className="row " style={{padding:'0px 15px 0px 15px'}}>
                            {this.state.mFilteredClassSectionList && this.state.mFilteredClassSectionList.map((item, index) => {
                              
                             // alert(mAttendance);
                              if(item.attendance!=null)
                              {
                               
                                var mAttendance=item.attendance;
                                var attendance_status=mAttendance[0].status;
                               // var attendance_status="Taken";
                                return(
                                <div className="col-sm-2 col-md-2" style={{padding:'0px 2px 5px 2px'}}>
                                <Link to={"/AdminMain/Attendance/"+item.class_id+"/"+item.section_id+"/"+item.class_name+"/"+item.section_name}>
                                  <div className={attendance_status==="Taken"?"bg_green_outline_green gravity_center":"bg_white_outline_grey gravity_center"}  style={{cursor:'pointer'}}> 
                                    <p style={{fontSize:14}}> {item.section==='No'?'Class: '+item.class_name:item.class_name+':'+item.section_name} </p>
                                  </div>
                                  </Link>
                                </div>
                                )
                              }
                              else{
                                
                                var attendance_status="Not Taken";
                                return(
                                <div className="col-sm-2 col-md-2" style={{padding:'0px 2px 5px 2px'}}>
                                <Link to={item.section==='No'? "/AdminMain/Attendance/"+item.class_id+"/"+'No':"/AdminMain/Attendance/"+item.class_id+"/"+item.section_id+"/"+item.class_name+"/"+item.section_name}>
                                  <div className={attendance_status==="Taken"?"bg_green_outline_green gravity_center":"bg_white_outline_grey gravity_center"}  style={{cursor:'pointer'}}> 
                                    <p style={{fontSize:14}}> {item.section==='No'?'Class: '+item.class_name:item.class_name+':'+item.section_name} </p>
                                  </div>
                                  </Link>
                                </div>
                                )
                              }
                              
                      })}
                      </div>
                      </div>
              </section>

              <section>
                          <hr/>
                  <h5>Today:Attendance Status</h5>
                  <div className="row">
                    {this.state.mFilteredClassSectionList && this.state.mFilteredClassSectionList.map((item,index)=>{
                       
                       if(item.attendence!=null)
                       {
                                var mAttendence=[];
                                mAttendence=item.attendence;
                                var attendance_status=mAttendence[0].status;
                                var present;
                                var absent;
                                var leave;
                                var btn_lable="Take Attendance"
                                if(attendance_status=='Taken')
                                {
                                      btn_lable="View Attendance"
                                      present=mAttendence[0].P;
                                      absent=mAttendence[0].A;
                                      leave=mAttendence[0].L;
                                      if(present==null)
                                      present="0";
                                      if(absent==null)
                                      absent="0";
                                      if(leave==null)
                                      leave="0";
                                }
                                else{
                                  btn_lable="Take Attendance"
                                  present="N/A";
                                  absent="N/A";
                                  leave="N/A";
                                  }
                       }
                       else{
                        btn_lable="Take Attendance"
                        present="N/A";
                        absent="N/A";
                        leave="N/A";
                       }
                       

                      return(
                        <div className="col-sm-4 col-md-4" >
                        <div className={attendance_status==="Taken"?'bg_card_green_outline  box_shadow_primary':'bg_card_blue_outline  box_shadow_primary'} style={{margin:'5px 2px 10px 3px'}}>
                          <div className="gravity_center">                      
                            <p style={{fontSize:'18px'}}><strong>{item.section==='No'?'Class: '+item.class_name:item.class_name+':'+item.section_name} </strong></p> 
                          </div>                       
                          <div className="gravity_center">
                              <p><strong> Present: </strong>{present} </p> 
                          </div> 
                          <div className="gravity_center">
                              <p><strong> Absent: </strong>{absent} </p> 
                          </div> 
                          <div className="gravity_center">
                              <p><strong> Leave: </strong>{leave} </p> 
                          </div> 
                         
                          <hr/>
                         
                        <div className="row" style={{padding:'0px 5px 0px 5px'}}>
                            <div className="col-sm-12 col-md-12 text-center">
                                  <button className="btn btn-info" style={{width:'100%',height:'30px' ,fontSize:'16px', margin:'5px 5px 5px 0px',padding:'0px'}}> <i class="fa fa-edit"></i> &nbsp;   {btn_lable} </button>
                        
                            </div>
                        </div>
                        
                        </div>
                      </div> 
                  
                      )
                    })}
                    
                  </div>  
               </section>

                         
                        
                        </div>
                        </div>
                
            )
          }
        } />   
         <Route path='/AdminMain/Attendance/:class_id/:section_id/:class_name/:section_name'  component={Attendance} />                             
        </switch>
        
       </Router>
        )
       } 
     }
    }

