import React, { Component } from 'react'
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import FeeCollection from './FeeCollection';
import '../css/Admin_css/admin-customize.css';
import CurrentDue from './CurrentDue';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import axios from 'axios';
import TotalRecieved from './TotalRecieved';
import myToast from '../Utils/MyToast';
import TotalDue from './TotalDue';
import TotalFee from './TotalFee';
import OldDueList from '../Admin/OldDueList';
import COLORS from '../resources/colors/COLORS';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from '../dialog/MdlSelectSession';
import AppConfig from '../AppConfig';
export default class FeeDashBoard extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
    this.state={   
      isOpenSessionDialog:false,
      SESSION_ID:'',
      SESSION_NAME:'', 
        loggedIn:true,
        Loading:false, 
        showCurrentDue:false,
        showTotalDue:false,
        showTotalFee:false,
        showTotalRecieved:false,
        showTotalSettled:false,
        showFeeDash:false,
        showOldDue:false,
        mFeeDashData:[], 
        todays_collection:0,
        mDate:'',
        mFeeDataByDate:[],
    }
    this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
    this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);
  
  }

  ChangeSession = () => {
 
    this.HandleShowSessionDialog();
  }
  
  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog
    });   
  }
  
  HandleCloseSessionDialog = () => {
  
    this.setState({
      isOpenSessionDialog:false
    });  
     
  }
  HandleSelectedSession = (session_id,session_name) => {
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.setState({
      
      isOpenSessionDialog:false
    });
   
    this.getFeeDashBoard();
  }
  
componentDidMount()
{
 
  var mSession=SessionManager.getCurrentSession();
  this.state.SESSION_ID=mSession[0].session_id;
  this.state.SESSION_NAME=mSession[0].session_name;


  var mStaffData=SessionManager.getStaffDetails();
  var LOGIN_TYPE=mStaffData.login_type;
  if(LOGIN_TYPE==AppConfig.LOGIN_TYPE_ADMIN)
  {
    
    this.state.showCurrentDue=true;
    this.state.showTotalDue=true;
    this.state.showTotalFee=true;
    this.state.showTotalRecieved=true;
    this.state.showTotalSettled=true;
    this.state.showFeeDash=true;
    this.state.showOldDue=true;
  }
  else{
      //get advanced privilege 
  }

   this.getFeeDashBoard();
   var today = new Date();
   let day = today.getDate();
   let month = today.getMonth() + 1;
   let year = today.getFullYear();
  
   var mMonth=month>9?month:"0"+month;
   var mDay=day>9?day:"0"+day;
   var mDate=year+"-"+mMonth+"-"+mDay;
   this.state.mDate=mDate;
   
   //alert(mDate);

  this.getCollectionByDate();
}
getFeeDashBoard(){

  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  //alert(SESSION_ID);
  
  const FUNCTION_NAME='getFeeDashboard.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
      params:{school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      //console.log("Response2>>>>>"+JSON.stringify(response));
      let api_status=response.api_status;
      //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({       
             mFeeDashData:response.data,            
         })
       // console.log("mFeeDashData>>>>>"+this.state.mFeeDashData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
       console.log("error>>>>>"+error);
    });
  }
  getCollectionByDate(){


  const SCHOOL_CODE=SessionManager.getSchoolCode();
  
  const FUNCTION_NAME='getFeeCollectionByDate.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        date:this.state.mDate
    }
  }).then(res => res.data)
  .then((response) => {
   
    
      //alert("Response2>>>>>"+JSON.stringify(response));
      let api_status=response.api_status;
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mFeeDataByDate:response.data, 
                  
         })
        
         this.getCollectionAmount();
      }
      else{
        let api_remark=response.api_remark;
        //myToast.notify_warning(api_remark);
      } 
  
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
       console.log("error>>>>>"+error);
    });
  }
  getCollectionAmount(){
        var total=0;
        for (var i=0;i<this.state.mFeeDataByDate.length;i++)
        {
                total=total+Number(this.state.mFeeDataByDate[i].paid_amount);
                
        }
        this.setState({
          todays_collection:total
        })
        
  }

  render() {
    if( this.state.loggedIn==false)
    {
       return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{
    return (
      <Router>
        <switch>
          <Route path={'/AdminMain/FeeDashBoard/:module_id'}  exact render={
           ()=>{
            return(
            <div className="content" style={{background:COLORS.white}}>
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Fee Management</a></li>
                </ul>
              </div> 
            </div>
            <div className="animated fadeInUp bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
  
  
            <div className="row" style={{margin:'5px 0px 5px 0px'}}>
                    <div className="col-sm-12 col-md-12 bg_card_blue_outline">
                    <Link to='#' onClick={()=>this.ChangeSession()}>
                    <p  style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center'}}> Session:
                      {this.state.SESSION_NAME}
                    <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
                    </Link>
                    </div>  
  
                 </div>
              <div className="row">
                   <div className="col-sm-12 col-md-12  bg_card_primary_outline">
  
                    <div  className="gravity_center">
                        <h3>  Today Collection  </h3>
                    </div>
                   
  
                    <div className="row gravity_center_vertical">
                    <div  className=" bg_circle_primery gravity_center">
                        <p style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs:{this.state.todays_collection}</p>
                    </div>
  
                    <div className="row" style={{margin:'10px'}}>
                        <div className="col-xl-12 col-md-12 m-0 p-0">
                            <h3> <Link to="./" class="btn bg-primary btn-block border-bottom text-white" style={{fontSize:'20px',textDecoration:'none'}}> <span className="cld-e"> Collection Details </span> </Link> </h3>
                        
                        </div>
                    </div>
                  </div>
                  </div>              
                </div>
  
              
  
                 <div className={this.state.showFeeDash?'row bg_card_red_outline':'row bg_card_red_outline hidden'} style={{margin:'5px 0px 5px 0px',padding:'10px 0px 10px 0px'}}>
                   <div className="col-sm-1 col-md-1 gravity_center_vertical">
                  </div>
                    <div className={this.state.showTotalDue?'col-sm-2 col-md-2 gravity_center_vertical':'hidden'}>
                        <h4 style={{color:COLORS.blue}}>Total Due</h4>
                        <Link to={{pathname:'/AdminMain/TotalDue'}} style={{textDecoration:'none'}}>
                        <div  className="bg_circle_amber gravity_center">
                            <p className="text-center" style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs<br/>{this.state.mFeeDashData.length===0?'':  this.state.mFeeDashData[0].total_due}</p>
                        </div>
                        </Link>
                    </div> 
                    <div className={this.state.showCurrentDue?'col-sm-2 col-md-2 gravity_center_vertical':'hidden'}>
                    <h4 style={{color:COLORS.blue}}>Current Due</h4>
                    <Link to={{pathname:'/AdminMain/CurrentDue'}} style={{textDecoration:'none'}}>
                        <div  className="bg_circle_card_red gravity_center">
                            <p className="text-center" style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs<br/>{this.state.mFeeDashData.length===0?'':this.state.mFeeDashData[0].current_due}</p>
                        </div>
                     </Link>   
                  </div> 
                 
                  <div className={this.state.showTotalFee?'col-sm-2 col-md-2 gravity_center_vertical':'hidden'}>
                     <h4 style={{color:COLORS.blue}}>Total Fee</h4>
                     <Link to={{pathname:'/AdminMain/TotalFee'}} style={{textDecoration:'none'}}> 
                        <div  className="bg_card_orange gravity_center" style={{height:'120px',width:'120px'}}>
                            <p className="text-center" style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs<br/>{this.state.mFeeDashData.length===0?'': this.state.mFeeDashData[0].total_fee}</p>
                        </div>
                     </Link>   
                  </div>
                  <div className={this.state.showTotalRecieved?'col-sm-2 col-md-2 gravity_center_vertical':'hidden'}>
                  <  h4 style={{color:COLORS.blue}}>Recieved</h4>
                  <Link to={{pathname:'/AdminMain/TotalRecieved'}} style={{textDecoration:'none'}}>
                        <div  className="bg_card_green gravity_center" style={{height:'120px',width:'120px'}}>
                            <p className='text-center' style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs<br/>{this.state.mFeeDashData.length===0?'': this.state.mFeeDashData[0].total_recieved}</p>
                        </div>
                     </Link> 
                  </div>
                  <div className={this.state.showTotalSettled?'col-sm-2 col-md-2 gravity_center_vertical':'hidden'}>
                  <h4 style={{color:COLORS.blue}}>Settled</h4>
                  <Link to={{pathname:'/AdminMain/TotalFee'}} style={{textDecoration:'none'}}>
                        <div  className="bg_card_blue gravity_center" style={{height:'120px',width:'120px'}}>
                            <p className="text-center" style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs<br/>{this.state.mFeeDashData.length===0?'': this.state.mFeeDashData[0].due_settlement}</p>
                        </div>
                     </Link> 
                  </div>  
                  <div className="col-sm-1 col-md-1 gravity_center_vertical">
                  </div>
  
                 </div>
  
                  <div className={this.state.showOldDue? "bg_card_deep_purple_outline gravity_center_vertical":"hidden"}>
                    <h4>Old Due (Before Application)</h4>
                  <div className="row " style={{margin:'5px 0px 5px 0px',padding:'10px 0px 10px 0px'}}>
                  
                  <div className="col-sm-4 col-md-4 gravity_center_vertical">
                     <h4 style={{color:COLORS.blue}}>Total Old Due</h4>
                     <Link to={{pathname:'/AdminMain/OldDueList/'+1}} style={{textDecoration:'none'}}>
                        <div  className="bg_card_deep_purple gravity_center" style={{height:'120px',width:'240px'}}>
                            <p style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs:{this.state.mFeeDashData.length===0?'': this.state.mFeeDashData[0].total_old_due_amount}</p>
                        </div>
                     </Link>   
                  </div>
                  <div className="col-sm-4 col-md-4 gravity_center_vertical">
                  <  h4 style={{color:COLORS.blue}}>Total Recieved</h4>
                  <Link to={{pathname:'/AdminMain/OldDueList/'+2}} style={{textDecoration:'none'}}>
                        <div  className="bg_card_deep_purple gravity_center" style={{height:'120px',width:'240px'}}>
                            <p style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs:{this.state.mFeeDashData.length===0?'': this.state.mFeeDashData[0].total_old_due_paid}</p>
                        </div>
                     </Link> 
                  </div>
                  <div className="col-sm-4 col-md-4 gravity_center_vertical">
                  <h4 style={{color:COLORS.blue}}>Remaining Old Due</h4>
                  <Link to={{pathname:'/AdminMain/OldDueList/'+3}} style={{textDecoration:'none'}}>
                        <div  className="bg_card_deep_purple gravity_center" style={{height:'120px',width:'240px'}}>
                            <p style={{fontSize:'24px',textDecoration:'none',color:COLORS.white}}>Rs:{(this.state.mFeeDashData.length===0?'': (this.state.mFeeDashData[0].total_old_due_amount-this.state.mFeeDashData[0].total_old_due_paid))}</p>
                        </div>
                     </Link> 
                  </div>  
                 
  
                 </div>
                 </div>
                 <div style={{margin:'5px 0px'}}>
                   <h3> <Link to={{pathname:'/AdminMain/FeeCollection'}} class="btn bg-primary  btn-block text-white" style={{fontSize:'18px',textDecoration:'none',padding:'10px'}}>  Collect Fee  </Link>  </h3>
                  </div>
                  </div>
                  {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
             
                 </div>
              )
            }
        } />
    
             <Route path='/AdminMain/FeeCollection'  component={FeeCollection} />
              <Route path='/AdminMain/CurrentDue'  component={CurrentDue} />
              <Route path='/AdminMain/TotalRecieved'  component={TotalRecieved} />
              <Route path='/AdminMain/TotalDue'  component={TotalDue} />
              <Route path='/AdminMain/TotalFee'  component={TotalFee} />
              <Route path='/AdminMain/OldDueList/:tag'  component={OldDueList} />
                     
        </switch>
        
       </Router>
      )
    }
  } 
 
}

