import React, { Component } from 'react'
import '../css/Admin_css/admin-customize.css';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios'

import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';
import myToast from '../Utils/MyToast';
export default class EditStaff extends Component {
  constructor(props){
    super(props)
    this.STAFF_data = props.location.data.Staff_data;
   // console.log(this.STAFF_data);
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={
        loggedIn:false,
        Loading:false,
        stfName:this.STAFF_data[0].staff_name,
        stfdob:this.STAFF_data[0].staff_dob,
        inlineCssRadio1:'',
        inlineCssRadio2:'',
        stfphone:this.STAFF_data[0].staff_phone,
        stfRegion:this.STAFF_data[0].staff_religion,
        stfAdharNo:this.STAFF_data[0].aadhar_no,
        stfnationality:this.STAFF_data[0].nationality,
        stfemail:this.STAFF_data[0].staff_email,
        stfloginType:this.STAFF_data[0].login_type,
        stfRole:this.STAFF_data[0].staff_role,
        stfaddress:this.STAFF_data[0].staff_address,

    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
}
regChange(e){

  this.setState({
    [e.target.id]:e.target.value
})
}

regSubmit(){
  if(this.isValidInput())
  {

//   var bb=this.state.Department + this.state.staffRole + this.state.email + this.state.loginType + this.state.phone + this.state.AdharNo + this.state.nationality;
//   alert(bb);
  const staffId=this.STAFF_data[0].staff_id;
  const FUNCTION_NAME='updateStaffPersonalDetails.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  var params = new URLSearchParams();
  params.append('staff_id', staffId);
  params.append('StaffName', this.state.stfName);
  params.append('phone', this.state.stfphone);
  params.append('dob', this.state.stfdob);
  params.append('religion', this.state.stfRegion);
  params.append('gender', this.state.inlineCssRadio1 + this.state.inlineCssRadio2);
  params.append('aadhar_no', this.state.stfAdharNo);
  params.append('nationality', this.state.stfnationality);
  params.append('role', this.state.stfRole);
  params.append('login_type', this.state.stfloginType);
  params.append('address', this.state.stfaddress);
  params.append('email', this.state.stfemail);
  axios.post(URL,params).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      //console.log("responsel>>>>>"+response);

      alert("successfully Updated !" );
      if (typeof window !== 'undefined') {
           window.location.reload(false);
          // window.location.href = "../SuperAdminDash/Comment";
      }
    console.log("response>>>>>"+api_status)
   })
} 
}
isValidInput()
{
  if (this.state.stfName==="")
    {
    alert('Your Staff name have accepted Only Alphabate!');
    return false;
    }
    else if(this.state.stfRole==="")
    {
      alert("Enter your Staff Role !");
      return false;
    }
    else if(this.state.stfloginType==="")
    {
      alert("Enter your Login Type !");
      return false;
    }
    else if(this.state.stfphone==="")
    {
      alert("Enter your Phone no, have accepted Only Numeric!");
      return false;
    }
    else if(this.state.stfemail==="")
    {
      alert("Enter your Email Id!");
      return false;
    }
  else if(this.state.stfdob==="")
  {
    alert("DOB have accepted Only Numeric!");
    return false;
  }
  else
    var option=document.getElementsByName('radio_css_inline');

if (!(option[0].checked || option[1].checked)) {
    alert("Please Select Your Gender");
    return false;
}
else if(this.state.stfRegion==="")
{
  alert("Enter your religion have accepted Only Alphabate!");
  return false;
}
  else if(this.state.stfAdharNo==="")
  {
    alert("Enter Adhar No. have accepted Only Numerice!");
    return false;
  }
  else if(this.state.stfnationality==="")
  {
    alert("Enter your nationality have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.stfaddress==="")
  {
    alert("Enter your Address !");
    return false;
  }
  else{
    return true;
  }

}

        render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
            return (
            <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Edit Staff </a></li>
                    </ul>
                  </div>
                </div>
                <div className="row animated fadeInUp">
                  <div className="col-sm-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="panel">
                          <div className="panel-content text-center" style={{ minHeight: '400px' }}>
        
                            <div className="panel-body">
                         
                              <div className="pnlSubHedingBorder">
                                <div className="pnlSubHeding heading-with-bg-w">
                                Edit Staff Profile Details
                                </div>
                              </div>
                              <div className="row">
                              <div className="col-sm-3" style={{padding:'10px',borderRight:'1px solid #d7d0d0'}}>
                   <div>
                    <p className="text-center posi-rel"> <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img-phto" alt="student list image"/>
                    </p>
                    <div className="photo-row-bottom posi-rel">
                      <span><input type="file" name="upload photo"/></span>
                      <span className="up-img">Upload Photo</span>
                    </div>
                    </div>
                 </div>
                              <div className="col-md-9">
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Name </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="stfName" name="staff name" value={this.state.stfName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Role </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="stfRole" name="Department" defaultValue={this.state.stfRole} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Login Type </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="stfloginType" name="Department" value={this.state.stfloginType} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Phone Number </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="stfphone" type="text" value={this.state.stfphone} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Email Address </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input id="stfemail" type="text" value={this.state.stfemail} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Date of Birth</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                        <input type="date" id="stfdob" name="stfdob" value={this.state.stfdob} onChange={this.regChange} className="form-control" />
                                        <div className="input-group-addon">
                                          <i className="fa fa-calendar" />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Gender</label>
                                    <div className="col-md-4">
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline" id="inlineCssRadio1" value="Male" checked={this.state.inlineCssRadio1 === "Male"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio1">Male</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline" id="inlineCssRadio2" value="Female" checked={this.state.inlineCssRadio2 === "Female"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio2">Female</label>
                                      </div>
                                    </div>

                                    <label className="col-md-2 col-form-label">Religion</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" className="form-control" value={this.state.stfRegion} onChange={this.regChange} id="stfRegion"  />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Adhar Number</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input id="stfAdharNo" type="text" value={this.state.stfAdharNo} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Nationality</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="stfnationality" name="nationality" value={this.state.stfnationality} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Address</label>
                                    <div className="col-md-10">
                                      <div className="input-group">
                                        <input type="text" id="stfaddress" name="stfaddress" value={this.state.stfaddress} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                                  <div className=" row">
                                    <div className="col-md-12">
                                      <center>
                                        <button className="btn btn-success" onClick={this.regSubmit} style={{margin:'2% 0px',fontSize:'24px',width:'100%'}}>Submit</button>
                                       
                                      </center>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    );
    }
  }
}
