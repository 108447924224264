import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import sett from '../resources/images/sett.png'
import COLORS from '../resources/colors/COLORS';

import icon_class from '../resources/icons/icons_class_setting_100_white.png'
import icon_subject from '../resources/icons/icons_subject_100_white.png'
import icons_settings from '../resources/icons/icons_settings_100_white.png'
import icons_department from '../resources/icons/icons_department_100.png'
import icon_fee from '../resources/icons/icons_fee_80_white.png'
import DataCenter from './DataCenter';
import DeleteStudent from './DeleteStudent';
import DeleteStaff from './DeleteStaff';
import DeleteExam from './DeleteExam';

export default class ManageRecords extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Router>
        <switch>
          <Route path='/AdminMain/ManageRecords' render={
            () => {
              return (
                <div className="content">
                  <div className="content-header">
                    <div className="leftside-content-header">
                      <ul className="breadcrumbs">
                        <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Settings </a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row  bg_page_white_rounded" style={{ '--mMargin': '50px' }}>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={'/AdminMain/DeleteStudent'}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_class} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5> Delete Student </h5>
                          </div>
                        </div>
                      </Link>

                    </div>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/DeleteStaff' }}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_fee} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5>Delete Staff</h5>
                          </div>
                        </div>
                      </Link>

                    </div>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/SubjectSettings' }}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_subject} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5>Student Attendance</h5>
                          </div>
                        </div>
                      </Link>

                    </div>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/FeeReceiptSettings' }}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_subject} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5>Manage Transaction </h5>
                          </div>
                        </div>
                      </Link>

                    </div>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/DeleteExam'}}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icons_department} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5> Delete Exam</h5>
                          </div>
                        </div>
                      </Link>

                    </div>
                  </div>
                </div>
              )
            }
          } />

          <Route path='/AdminMain/DeleteStudent' component={DeleteStudent} />
          <Route path='/AdminMain/DeleteStaff' component={DeleteStaff} />
          <Route path='/AdminMain/DeleteExam' component={DeleteExam} />
        </switch>

      </Router>

    )
  }
}

