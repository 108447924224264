import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import myToast from '../Utils/MyToast';

export default class  extends Component {
    constructor(props){
        super(props)
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
        
        this.state={
    
          loggedIn:true,
          Loading:false, 
          PaymentHistory:[]
              
      }
      
    }
componentDidMount()
{
  this.getPaymentHistory();
}
getPaymentHistory(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Student_id="S000311";
  const Session_id="SSN0001";

  const FUNCTION_NAME='getPaymentHistory.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  fetch(URL, {
    mode:'cors',
    method: 'POST',
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
   },
  body: JSON.stringify({
     school_code:SCHOOL_CODE,
     student_id:Student_id,
     session_id:Session_id
  }),
 
  }).then(res => res.json())
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      
      let api_status=response.api_status;
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            PaymentHistory:response.data
          })
          console.log("response>>>>>"+this.state.PaymentHistory);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }

    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
}


    render() {
        // if( this.state.loggedIn==false)
        // {
        //  return (<Redirect to='/AdminDasboard'/>);
        // }

        return (
            <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Dashboard</a></li>
                </ul>
              </div>
            </div>
            <div className="row animated fadeInUp">
              <div className="col-sm-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div className="panel">
                      <div className="panel-content text-center" style={{minHeight: '400px'}}>
                      <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th>SR. No.</th>
                  <th>Title</th>
                  <th>Posted On</th>
                  <th>Posted By</th>
                  <th>Image</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Google News</td>
                  <td>27-04-2017</td>
                  <td>Ravi Shah</td>
                  <td>photo.png</td>
                  <td>Active</td>
                  <td className="text-center">
                    <button className="btn btn-sm btn-success" type="button">
                      <i className="fa fa-pencil" />
                      Edit
                    </button>
                    <button className="btn btn-sm btn-danger" type="button">
                      <i className="fa fa-trash" />
                      Delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1.</td>
                  <td>Google News</td>
                  <td>27-04-2017</td>
                  <td>Ravi Shah</td>
                  <td>photo.png</td>
                  <td>Active</td>
                  <td className="text-center">
                    <button className="btn btn-sm btn-success" type="button">
                      <i className="fa fa-pencil" />
                      Edit
                    </button>
                    <button className="btn btn-sm btn-danger" type="button">
                      <i className="fa fa-trash" />
                      Delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1.</td>
                  <td>Google News</td>
                  <td>27-04-2017</td>
                  <td>Ravi Shah</td>
                  <td>photo.png</td>
                  <td>Active</td>
                  <td className="text-center">
                    <button className="btn btn-sm btn-success" type="button">
                      <i className="fa fa-pencil" />
                      Edit
                    </button>
                    <button className="btn btn-sm btn-danger" type="button">
                      <i className="fa fa-trash" />
                      Delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1.</td>
                  <td>Google News</td>
                  <td>27-04-2017</td>
                  <td>Ravi Shah</td>
                  <td>photo.png</td>
                  <td>Active</td>
                  <td className="text-center">
                    <button className="btn btn-sm btn-success" type="button">
                      <i className="fa fa-pencil" />
                      Edit
                    </button>
                    <button className="btn btn-sm btn-danger" type="button">
                      <i className="fa fa-trash" />
                      Delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1.</td>
                  <td>Google News</td>
                  <td>27-04-2017</td>
                  <td>Ravi Shah</td>
                  <td>photo.png</td>
                  <td>Active</td>
                  <td className="text-center">
                    <button className="btn btn-sm btn-success" type="button">
                      <i className="fa fa-pencil" />
                      Edit
                    </button>
                    <button className="btn btn-sm btn-danger" type="button">
                      <i className="fa fa-trash" />
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
