import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import '../css/Admin_css/vp_style.css';
import myToast from '../Utils/MyToast';
export default class TotalDue extends Component {
    constructor(props){
        super(props)
        this.slt_board=createRef();
        this.slt_medium=createRef();
       
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }  
      this.state={    
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            mClassData:[],
            mClassBoard:[],
            mClassMedium:[], 
            mFilteredClassList:[],
            mFilteredClassSectionList:[], 
            mStudentPayList:[],
            mDueDetails:[],
            CLASS_SELECTED_POSITION:0,  
            total_amount:0 
      }
    }
    selectClass=(position)=>{
      
      this.state.CLASS_SELECTED_POSITION=position;

      this.getClassStudentPayList();
    }
    componentDidMount()
    {
    this.getClass();
    }
    getClass(){
    
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    var mSession=SessionManager.getCurrentSession();
    const SESSION_ID=mSession[0].session_id;
    const FUNCTION_NAME='getClass.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        session_id:SESSION_ID
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
       // alert(api_status);
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            mClassData:response.response.class,
            mClassBoard:response.response.board,
            mClassMedium:response.response.medium
            })
            this.getClassList();
           
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
    onBoardChange = () => {
      //let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
      //alert(board_id);
      this.getClassList();
      
    }
    onMediumChange = () => {
      this.getClassList();
    }
    getClassList()
    {
      let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
      let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
      //alert(board_id);
      //alert(medium_id);
      
      this.state.mFilteredClassSectionList=[];
      var mFilteredClassList=[];
      for (var i=0; i < this.state.mClassData.length; i++) {
        let item=this.state.mClassData[i];
        if(item.board_id===board_id && item.medium_id===medium_id)
        {
      
          mFilteredClassList.push(item);
    
        }   
      }
      for (var i=0; i <mFilteredClassList.length; i++) {
        let item=mFilteredClassList[i];
        let SECTION=item.section;
                if(SECTION==="No")
                {
                  item["section_id"]="No";
                  this.state.mFilteredClassSectionList.push(item);
                }
                else {
      
                  var tempSection=[];
                  tempSection=item.section;
                  for (var j=0;j<tempSection.length;j++)
                  {
                      let item2=tempSection[j];
                      item2["board_id"]=board_id;
                      item2["board_name"]=item.board_name;
                      item2["medium_id"]=medium_id;
                      item2["medium_name"]=item.medium_name;               
                      this.state.mFilteredClassSectionList.push(item2);
                      
                  }
                }
        
      }
      
    this.getClassStudentPayList();  
      
    }  
    getClassStudentPayList(){
      this.setState({
        LoadingStudent:true
      })
      this.state.mStudentPayList=[];
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      var CLASS_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION].class_id;
      var SECTION_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION].section_id;
      var SESSION_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION].session_id;
      const FUNCTION_NAME='getClassStudentPaylist.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL,{
        params:{
          school_code:SCHOOL_CODE,
          class_id:CLASS_ID,
          section_id:SECTION_ID,
          session_id:SESSION_ID
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingStudent:false
         })
          let api_status=response.api_status;
          if(api_status=="OK")
          {
          
              this.setState({              
                mStudentPayList:response.data,
              })
              this.getDueList();
                         
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
            this.getDueList();
              
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });

    }
getDueList()
{
  this.state.mDueDetails=[];
  this.state.total_amount=0;
  for (var i=0;i<this.state.mStudentPayList.length;i++)
  {

      var mPayDetails=[];
      mPayDetails=this.state.mStudentPayList[i].pay_details;
      
      if(mPayDetails[0].total_due!=="0")
      {
          this.state.mDueDetails.push(this.state.mStudentPayList[i]);
          this.state.total_amount=this.state.total_amount+Number(mPayDetails[0].total_due);

      }
  }
  this.setState({

  })
}    
      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

    return (
      <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Total Due </a></li>
              </ul>
            </div>
          </div>   
          <div className="row">
                <div className="col-sm-6 col-md-6">
                <div className="">
                <h4 className="selectMultiplst-h4" style={{background:COLORS.primary_color_shade3}}>Select Boards:</h4>
                <select className="select-field" onChange={this.onBoardChange} ref = {this.slt_board}>
                {this.state.mClassBoard.map((item, index) => ( 
                   <option board_id={item.board_id}>{item.board_name}</option>
                   
                   ))}
                   </select>
                </div>
                </div>
                 <div className="col-sm-6 col-md-6">
                  <div className="">
                   <h4 className="selectMultiplst-h4" style={{background:COLORS.primary_color_shade3}}>Select Medium:</h4>
                   <select className="select-field" onChange={this.onMediumChange} ref = {this.slt_medium}>
                   {this.state.mClassMedium.map((item, index) => ( 
                   <option medium_id={item.medium_id}>{item.medium_name}</option>
                   ))}
                   </select>
                  </div>
                </div>

               </div>
               <section className="exam-section-1" style={{background:COLORS.primary_color_shade3}}>
              <div className="row class-li">
              
              {this.state.mFilteredClassSectionList.map((item, index) => (
                <div className="col-sm-1 col-md-1 " >
                  <Link>
                  <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect col-centered"} onClick={e=>this.selectClass(index)}> <p> {item.section==='No'?'Class:'+item.class_name:item.class_name+':'+item.section_name} </p></div>
                  </Link>
                </div>
               ))}
                
                </div>
                </section> 
                <section className="bg_grey">
                <div className="row" style={{marginRight:"8px",marginLeft:"8px",marginTop:"5px"}}>
                {this.state.LoadingStudent?<div><MyLoader/></div>:
              
                  this.state.mDueDetails.map((item, index) => ( 
          
                    <div className="col-sm-6 col-md-6" style={{marginBottom:"5px",marginTop:"5px"}}>
                  
                     <div className="row" style={{height:100}}> 

                     <div className="col-sm-2 bg_card_red gravity_center">
                       <div className="profil-im-img" style={{background:'rgb(219 238 246)'}}>
                       <img src="https://www.galaxyskills.org/wp-content/uploads/2019/02/dummy-350x350.png" alt="student due" style={{width:'100%'}}/>
                       
                       </div>
                      
                     </div>
                     
                     <div className="col-sm-9 bg_card_red_outline">
                      <h5> {item.stu_name} </h5>
                      <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                      <p className="mb-0"> Class: <span>{item.class[0].section==='null'?'Class:'+item.class[0].class_name+"("+item.class[0].student_roll_no+")":item.class[0].class_name+':'+item.class[0].section_name+"("+item.class[0].student_roll_no+")"}</span> </p>
                      <p className="mb-0" style={{color:COLORS.card_red,fontSize:"20px"}}><strong> Due: Rs <span>{item.pay_details[0].current_due}</span> </strong></p>
                     
                     </div>
                     
                    </div>
                  
                    </div>                   
                    
                 ))}
 
                </div>
                </section>           
                <div className="row">
                <div className="col-sm-12 col-md-12">
               <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px',background:COLORS.card_red,border:COLORS.primary_color_shade5}}> Total Amount: INR {this.state.total_amount} </button>
                                    
                </div>    
                </div>
               </div>
  
  )
} 
}

}
