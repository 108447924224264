import React, { Component } from 'react'
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import OldDueCollection from './OldDueCollection';
import axios from 'axios';
import uploadIcon from '../resources/images/upload-icon.png'
export default class Settlement extends Component {
  constructor(props){
    super(props)
  
  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={ 

      loggedIn:true,
      Loading:false,
      SESSION_ID:'',
      SESSION_NAME:'',
      CLASS_ID:'',
      SECTION_ID:'',
     
  }

}
componentDidMount()
{
  var mSession=SessionManager.getCurrentSession();
  this.state.SESSION_ID=mSession[0].session_id;
  this.state.SESSION_NAME=mSession[0].session_name;
}
ChangeSession = () => {
 
  this.HandleShowSessionDialog();
}

HandleShowSessionDialog = () => {
  this.setState({
    isOpenSessionDialog: !this.state.isOpenSessionDialog
  });   
}

HandleCloseSessionDialog = () => {

  this.setState({
    isOpenSessionDialog:false
  });  
   
}
HandleSelectedSession = (session_id,session_name) => {
  this.state.SESSION_ID=session_id;
  this.state.SESSION_NAME=session_name;
  this.setState({
    
    isOpenSessionDialog:false
  });
 
  this.getClass();
}

   render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

       return (
         
      <Router>
      <switch>   
      <Route path='/AdminMain/Settlement'   render={
        ()=>{

          return(
      
          <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Settlement </a></li>
              </ul>
            </div>
          </div> 
          <div className="bg_page_white_rounded" style={{'--mMargin':'50px'}}>
          <div className="row">

              <div className="col-sm-12 col-md-12">
              <Link to='#' onClick={()=>this.ChangeSession()}>
              <p className="bg_card_blue_outline" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center'}}> Session:
                {this.state.SESSION_NAME}
              <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
              </Link>
              </div>  

          </div>
            
          </div>

        </div>
        )
      }
     } />                       

         <Route path='/AdminMain/OldDueCollection'  component={OldDueCollection} />        
       </switch>
  
     </Router>

  )
} 
}
}
