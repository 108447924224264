import React, { Component ,useRef, createRef} from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import StudentList from '../cms/StudentList';
import myToast from '../Utils/MyToast';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from '../dialog/MdlSelectSession';
import COLORS from '../resources/colors/COLORS';
export default class ClassList extends Component {
  constructor(props){
  super(props)
  this.slt_board=createRef();
  this.slt_medium=createRef();
  
 
  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={ 
      isOpenSessionDialog:false,
      loggedIn:true,
      Loading:false, 
      SESSION_ID:'',
      SESSION_NAME:'',
      SESSION_STATUS:'',
      CLASS_ID:'',
      SECTION_ID:'',
      mClassData:[],
      mClassBoard:[],
      mClassMedium:[], 
      mFilteredClassSectionList:[],
      mAllSection:[],
      mStudentCount:[]
  }
    this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
    this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);

}

componentDidMount()
{
 
  var mSession=SessionManager.getCurrentSession();
  this.state.SESSION_ID=mSession[0].session_id;
  this.state.SESSION_NAME=mSession[0].session_name;
  this.state.SESSION_STATUS=mSession[0].status;
  this.getClass();
}
ChangeSession = () => {
 
  this.HandleShowSessionDialog();
}

HandleShowSessionDialog = () => {
  this.setState({
    isOpenSessionDialog: !this.state.isOpenSessionDialog
  });   
}

HandleCloseSessionDialog = () => {

  this.setState({
    isOpenSessionDialog:false
  });  
   
}
HandleSelectedSession = (session_id,session_name,session_status) => {
  this.state.SESSION_ID=session_id;
  this.state.SESSION_NAME=session_name;
  this.state.SESSION_STATUS=session_status;
  this.setState({
    
    isOpenSessionDialog:false
  });
 
  this.getClass();
}
onBoardChange = (event) => {
  //this.setState({ value: event.target.value });
  //let board_id=event.target.selectedOptions[0].getAttribute('board_id');
 
  this.getClassList();


};
onMediumChange=(event)=>{
  this.getClassList();
}
getClassList()
{

  let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
  let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
  //alert(board_id);
  //alert(medium_id);
  //filtering class of selected board and medium
  var mFilteredClassList=[];
  this.state.mFilteredClassSectionList=[];
  for (var i=0; i < this.state.mClassData.length; i++) {
    let item=this.state.mClassData[i];
    if(item.board_id===board_id && item.medium_id===medium_id)
    {
    
      mFilteredClassList.push(item);

    }   
  }
  //console.log("ClassList>>>>>>",mFilteredClassList); 
  //getting section of classes
  for (var i=0; i <mFilteredClassList.length; i++) {
    let item=mFilteredClassList[i];
   
    if(this.state.mAllSection===null)
    {
      //alert(JSON.stringify(this.state.mAllSection));
      var class_id=item.class_id;
      var section_id="No";
      var student=this.getStudent(class_id,section_id);
     
      item["student"]=student;
      item["section_id"]=section_id;
      this.state.mFilteredClassSectionList.push(item);
    }
    else{
     
      var class_id=item.class_id;
      var class_name=item.class_name;
      var tempSection=[];
      tempSection=this.getClassSection(class_id);
      
      if(tempSection.length===0)
      {
        var class_id=item.class_id;
        var section_id="No";
        var student=this.getStudent(class_id,section_id);
        item["student"]=student;
        item["section_id"]=section_id;
        this.state.mFilteredClassSectionList.push(item);
      }
      else {
     
        for (var j=0;j<tempSection.length;j++)
        {
            let item2=tempSection[j];
            var section_id=item2.section_id;
            var student=this.getStudent(class_id,section_id);
            item2["student"]=student;
            item2["class_name"]=class_name;

            item2["board_id"]=board_id;
            item2["board_name"]=item.board_name;
            item2["medium_id"]=medium_id;
            item2["medium_name"]=item.medium_name;
            //console.log("item2>>>>>>",item2);                
            this.state.mFilteredClassSectionList.push(item2);
            
        }
      }

    }
            
  }
  this.setState({       
   
    })
}
getClassSection(class_id)
{
  var mSection=[];
  for (let index = 0; index < this.state.mAllSection.length; index++) {
    if(this.state.mAllSection[index].class_id===class_id)
    {
      let item=this.state.mAllSection[index];
      mSection.push(item);
    }
    
  }
  return mSection;

}
getStudent(class_id,section_id)
{
  
       var student="0";
        for (var i = 0; i <this.state.mStudentCount.length ; i++) {
            if(this.state.mStudentCount[i].class_id===class_id && this.state.mStudentCount[i].section_id===section_id)
            {
                student=this.state.mStudentCount[i].total_student;
            }

        }
        return  student;

}
getClass(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getClassWithStudentCount.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    //console.log("Response2>>>>>"+JSON.stringify(response));
    let api_status=response.api_status;
    //alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({       
         mClassData:response.data.class,
         mClassBoard:response.data.board,
         mClassMedium:response.data.medium,
         mAllSection:response.data.all_section,
         mStudentCount:response.data.student_count,
        })
        this.getClassList();
        //console.log("response>>>>>"+this.state.mClassData);
       // alert(JSON.stringify(this.state.mClassData));
        //alert(JSON.stringify(this.state.mAllSection));
        //alert(JSON.stringify(this.state.mStudentCount));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
       return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{
      return (
        <Router>
        <switch>   
        <Route path='/AdminMain/ClassList/:module_id'  exact render={
          ()=>{
  
            return(
  
              <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Class List</a></li>
              </ul>
            </div>
          </div>
      

          <div className="bg_page_white_rounded box_shadow animated fadeInUp " style={{'--mMargin':'30px'}}>
          
             <div className="row">

                <div className="col-sm-12 col-md-12">
                <Link to='#' onClick={()=>this.ChangeSession()}>
                <p className="bg_card_blue" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center',color:COLORS.white}}> Session:
                  {this.state.SESSION_NAME+"("+ this.state.SESSION_STATUS+")"}
                 <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
                 </Link>
                </div>  

                </div>
                <div className="row m-0" >
                  <div className="col-sm-6 col-md-6" >
                  <div className="">
                 
                  <select className="bg_card_blue  width_match_parent h_35" onChange={this.onBoardChange} ref = {this.slt_board}>
                  {this.state.mClassBoard.map((item, index) => ( 
                  <option board_id={item.board_id}>{item.board_name}</option>
                     
                     ))}
                  </select>
                  </div>
                  </div>
                   <div className="col-sm-6 col-md-6" >
                    <div className="">
                  
                     <select className="bg_card_blue  width_match_parent h_35" onChange={this.onMediumChange} ref = {this.slt_medium}>
                     {this.state.mClassMedium.map((item, index) => ( 
                     <option medium_id={item.medium_id}>{item.medium_name}</option>
                     ))}
                   
                     </select>
                    </div>
                  </div>
  
                 </div> 
                
             

              <div className="row" style={{marginTop:'5px'}}>
                {this.state.mFilteredClassSectionList.map((item, index) => (
                  
                <div className="col-sm-4 col-md-4">
                
                <Link to={item.section==='No'? "/AdminMain/StudentList/"+item.class_id+"/"+'No':"/AdminMain/StudentList/"+item.class_id+"/"+item.section_id}>
                <div className="row bg_card_blue_outline" style={{margin:'5px'}}>
                 <div className="col-sm-5 bg_card_blue gravity_center_vertical" style={{borderRadius:'10px'}}>
                   <h2 style={{color:COLORS.white}}>{item.student}</h2>
                   <h4 style={{color:COLORS.white}}>Students</h4>
                 </div>
                 <div className="col-sm-7">
                  <h5><span> {item.section_id==='No'?'Class:'+item.class_name:item.class_name+':'+item.section_name} 
                  </span>  </h5>
                  <p> Board: <span>{item.board_name}</span> </p>
                  <p> Medium: <span>{item.medium_name}</span> </p>
                  <p>Class Teacher: <span>Null</span> </p>
                 </div>
                </div>
                  
                </Link>
                
                </div>
                
               ))}
             
                 </div>

                 {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           
               </div>
               
                </div>
             
           )
          }
         } />                       
         
           <Route path='/AdminMain/StudentList/:class_id/:section_id'  component={StudentList} />   
              
           </switch>
           
          </Router>
         )
    } 
  }
 
}

