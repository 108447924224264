import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import Button from 'react-bootstrap/Button';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
export default class Assign_homework extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      EXAM_ID: '',
      SESSION_ID: '',
      EXAM_NAME: '',
      START_DATE: '',
      EXAM_STATUS: '',

    }

  }
  componentDidMount() {
    this.state.EXAM_ID = this.props.match.params.exam_id;
    this.state.SESSION_ID = this.props.match.params.session_id;

    this.state.EXAM_NAME = this.props.match.params.exam_name;
    this.state.START_DATE = this.props.match.params.exam_date;
    this.state.EXAM_STATUS = this.props.match.params.exam_status;

    this.setState({

    })

    //alert( this.state.EXAM_ID);

  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/Assign_homework/'} exact render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Assign Home Work</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>

                      <div className='row bg_card_primary'>
                        <div className='col-md-6'>
                          <div className="padding_0 admission_prvform1 mar_top1">
                            <h4 className='white'>Class:<span>Play Group(English)</span></h4>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className="padding_0  admission_prvform1 mar_top1">
                            <h4 className='white'>Date:<span>22/03/2023</span></h4>
                          </div>
                        </div>
                      </div>

                      <div className='row bg_card_orange_outline mar_top1'>
                        <div className='col-md-2 padding_0'>
                          <div class=" text_center1 mar_top">
                            <label for="">Add HomeWork</label>
                          </div>
                        </div>
                        <div className='col-md-10 padding_0'>
                          <div class=" admission_prvform1">
                            <textarea class="form-control form_control2" placeholder="Add Home Work here" id="floatingTextarea"></textarea>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className='row mar_top1'>
                        <div className='col-md-12 padding_0'>
                          <div class="">
                            <h3 className='text_center2 bg_card_primary padding_5'>HomeWork Assigned</h3>
                            <h4 className='bg_card_orange_outline text_center1 padding_5'>No HomeWork Assigned</h4>
                          </div>
                        </div>
                        <div className=''>
                        </div>
                      </div>

                      <table className='table_border1'>
                        <tbody className=''>
                          <tr>
                            <th style={{ 'border': '2px solid black', width: '15%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Announced Date</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '15%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Subject Name</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '40%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Home Work</div>
                            </th>

                            <th style={{ 'border': '2px solid black', width: '20%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Action</div>
                            </th>
                          </tr>


                          <tr>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1'>22/03/2023</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1'>English</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1'> page 1 to page 433333333333333gggvbhnh hngtrfdfdgf dvceyrgtfcvyrthgb hhrklaemfdamflkasjdfijdk,smv kjfsdkpjfzmv,. v/kmlsvnjlksmnv vvmd</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1 mar_top1'>
                                <Button as="input" type="button" value="view" />{' '}
                                <Button as="input" type="submit" value="Edit" />{' '}
                                <Button as="input" type="reset" value="delete" />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1'>22/03/2023</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1'>English</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1'>page 1to 2</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='holiday1 mar_top1'>
                                <Button as="input" type="button" value="view" />{' '}
                                <Button as="input" type="submit" value="Edit" />{' '}
                                <Button as="input" type="reset" value="delete" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='row'>
                      <div className="col-md-12 padding_0 mar_top1"> 
                          <button className="btn btn-success" onClick={this.regSubmit} style={{fontSize: '18px', width: '100%' }}>Submit</button>
                      </div>
                      </div>
                      
                    </div>
                  </div>

                )
              }
            } />



          </switch>
        </Router>
      )
    }
  }
}