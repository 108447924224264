import React, { Component ,createRef} from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';

export default class MdlSelectMultipleClass extends Component {
    constructor(props){
        super(props)       
        this.slt_board=createRef();
        this.slt_medium=createRef();
        
       
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            show:true,
            mClassData:[],
            mClassBoard:[],
            mClassMedium:[], 
            mFilteredClassSectionList:[],
            CLASS_ID:'',
            SECTION_ID:'',
            SelectedClass:'',
            SelectedClassCount:0
        }
      
}
componentDidMount(){
  var mSelectedClass=[];
  localStorage.setItem("SelectedMultipleClass",JSON.stringify(mSelectedClass));
  
  this.getClass();
}

HandleCloseSelectClass= () => {

    this.setState({
      show: !this.state.show
    });
  this.props.action();
    
  }
  
  HandleSelectedClass = () => {
    

  this.props.action2();
  
  }
  getSelectedClass = (e,postion) => {
    
    let isChecked = e.target.checked;
                
    let class_id=this.state.mFilteredClassSectionList[postion].class_id;
    let section_id=this.state.mFilteredClassSectionList[postion].section_id;
    

    var mSelectedClass=JSON.parse(localStorage.getItem("SelectedMultipleClass"));
    if(mSelectedClass==null){
      mSelectedClass=[];
    }
  
    if(isChecked)
    {
        let item=this.state.mFilteredClassSectionList[postion];
        mSelectedClass.push(item);
        localStorage.setItem("SelectedMultipleClass",JSON.stringify(mSelectedClass));
    
    }
    else{
      
      let item_index=this.getItemPostion(class_id,section_id,mSelectedClass);
      mSelectedClass.splice(item_index,1);
      localStorage.setItem("SelectedMultipleClass",JSON.stringify(mSelectedClass));
    
    }
    this.setState({
      SelectedClassCount:mSelectedClass.length,
    })
    
     
  }
  getItemPostion(class_id,section_id,mSelectedClass){
    
    for (let index = 0; index < mSelectedClass.length; index++) {
      const element = mSelectedClass[index];

      if(class_id===element.class_id && section_id===element.section_id)
      {
        return index;
      }
      
    }
    return -1;
  }

  onBoardChange = () => {
    //let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    //alert(board_id);
    this.getClassList();
    
  }
  onMediumChange = () => {
    this.getClassList();
  }
  
  getClassList()
  {
    let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    
    this.state.mFilteredClassSectionList=[];
    var mFilteredClassList=[];
    for (var i=0; i < this.state.mClassData.length; i++) {
      let item=this.state.mClassData[i];
      if(item.board_id===board_id && item.medium_id===medium_id)
      {
    
        mFilteredClassList.push(item);
  
      }   
    }
    for (var i=0; i <mFilteredClassList.length; i++) {
      let item=mFilteredClassList[i];
      let SECTION=item.section;
              if(SECTION==="No")
              {
                item["section_id"]="No";
                this.state.mFilteredClassSectionList.push(item);
              }
              else {
    
                var tempSection=[];
                tempSection=item.section;
                for (var j=0;j<tempSection.length;j++)
                {
                    let item2=tempSection[j];
                    item2["board_id"]=board_id;
                    item2["board_name"]=item.board_name;
                    item2["medium_id"]=medium_id;
                    item2["medium_name"]=item.medium_name;
                    //console.log("item2>>>>>>",item2);                
                    this.state.mFilteredClassSectionList.push(item2);
                    
                }
              }
      
    }
    this.setState({       
     
    })
    
    
  }    
  
  getClass(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  //alert(SESSION_ID);
  
  const FUNCTION_NAME='getClass.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
      //alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mClassData:response.response.class,
          mClassBoard:response.response.board,
          mClassMedium:response.response.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }  
  

    render() {
        
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
        return (               
              <div>
                
    <Modal show={this.state.show} onHide={this.HandleCloseSelectClass} animation={false} style={{opacity:1} }
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
     >
       <Modal.Header closeButton>
         <Modal.Title id="contained-modal-title-vcenter">
           Select Class
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
       {this.state.Loading ? <MyLoader/> : 
          <div className="row class-li">
 
              <form className="row" style={{width:'100%'}}>
                     <div className="form-group col-sm-6">
 
                     <select className="form-control" onChange={this.onBoardChange} ref = {this.slt_board}>
                 {this.state.mClassBoard.map((item, index) => ( 
                    <option board_id={item.board_id}>{item.board_name}</option>
                    
                    ))}
                    </select>
 
                     </div>
                     <div className="form-group col-sm-6">
 
                   <select className="form-control" onChange={this.onMediumChange} ref = {this.slt_medium}>
                    {this.state.mClassMedium.map((item, index) => ( 
                    <option medium_id={item.medium_id}>{item.medium_name}</option>
                    ))}
                    </select>
 
                     </div>
                     
                  </form>
                  <div className="col-sm-12 col-md-12" style={{background:COLORS.grey3}}>
                            <div className="text-center">
                              {/* <!-- Button trigger modal --> */}
                              <h2 className="en-adhar" style={{color:'#666'}}> Selected Class: {this.state.SelectedClassCount}  </h2>
                            </div>
                <div className="row">

                {this.state.mFilteredClassSectionList.map((item, index) => (
               
                    <div className="col-sm-6 col-md-6">

                    <div className="row class-list-box bb-r-c p-1">
                      <div className="col-sm-3 cl-3-part p-0 text-center " style={{ background:COLORS.card_blue}}>
                        <h3>{item.student}</h3>
                        <p>Student</p>
                      </div>
                      <div className="col-sm-6">
                        <p className="mb-0"> <strong>{item.section==='No'?'Class:'+item.class_name:item.class_name+':'+item.section_name}</strong> </p>
                        <p className="mb-0"> Board: <span>{item.board_name}</span> </p>
                        <p className="mb-0"> Medium: <span>{item.medium_name}</span> </p>

                      </div>
                      <div className="col-sm-3 text-center bb-r-c" style={{background:COLORS.card_deep_purple}}>
                        <p> <div className="st-list-roll-n" style={{
                          background: '#fff', width: '40px', height: '40px', padding: '5px', borderRadius: '50%',
                          margin: '5px auto', fontSize: '20px'
                        }}> <input type="checkbox" id="issue_notice_c" class="ch-in" value="" onChange={e=>this.getSelectedClass(e,index)}/></div> </p>
                      </div>
                    </div>
                </div>
                ))}
                </div>


                   </div>

                    
 
                      <div className="col-sm-12 text-center" style={{margin:'15px auto'}} onClick={this.HandleSelectedClass}>
                            <h3 style={{ background: '#129cd9', color:'#fff', padding:'7px'}}> Done </h3>
                      </div>
                       
        
              </div>
       }
                        
       </Modal.Body>
     </Modal>
             </div>
      
      )
    }
    }
}
