import React, { Component, createRef } from 'react'
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Alert, Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import SelectClass from '../ChildComponent/SelectClass';
import ModalDeleteStudentConfirmation from '../dialog/ModalDeleteStudentConfirmation';
export default class DeleteStudent extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();
    this.slt_class = createRef();
    this.slt_section = createRef();


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      show: true,

      CLASS_ID: '',
      SECTION_ID: '',
      SESSION_ID: '',
      SESSION_NAME: '',
      SELECTED_CLASS_POSITION: 0,
      StudentList: [],
      mSelectedStudent: [],
      SelectedClass: '',
      SelectedStudentCount: '',
      showDeleteConfirmation: false,
    }
    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);

  }
  CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name, session_name) => {

    this.state.CLASS_ID = class_id;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    //alert("Back"+class_id+"/"+section_id+"/"+session_id);
    this.getStudentList();

  }
  componentDidMount() {
    var mSession = SessionManager.getCurrentSession();
    this.state.SESSION_ID = mSession[0].session_id;
    this.state.SESSION_NAME = mSession[0].session_name;

    var mSelectedStudent = [];
    localStorage.setItem("SelectedMultipleStudent", JSON.stringify(mSelectedStudent));

  }

  
  //  trigger dialog

  HandleOpenDeleteConfirmation = () => {

    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation
    });


  }

  HandleSelectedStudent = () => {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation
    });
    this.deleteStudent();

    //alert("call back");

  }
  getSelectedStudent = (e, postion) => {

    let isChecked = e.target.checked;
    let student_id = this.state.StudentList[postion].student_enrollment_no;


    //alert("okk"+student_id+"..."+isChecked);
    if (isChecked) {
      
      this.state.mSelectedStudent.push(student_id);

    }
    else {

      let item_index = this.getItemPostion(student_id, this.state.mSelectedStudent);
      this.state.mSelectedStudent.splice(item_index, 1);

    }
    this.setState({
      SelectedStudentCount: this.state.mSelectedStudent.length,
    })


  }

  ifAlredySelectedStudent = (postion) => {

   
    let student_id = this.state.StudentList[postion].student_enrollment_no;
    //alert(student_id);
    for (let index = 0; index < this.state.mSelectedStudent.length; index++) {
      let element = this.state.mSelectedStudent[index];
      if (student_id === element) {
        return true;
      }

    }
    return false;




  }





  getItemPostion(student_id, mSelectedStudent) {

    for (let index = 0; index < mSelectedStudent.length; index++) {
      const element = mSelectedStudent[index];
      if (student_id === element) {
        return index;
      }

    }
    return -1;
  }



  getStudentList() {

    this.setState({
      LoadingStudent: true,
      StudentList: []
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getCurrentStudent.php?'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        class_id: this.state.CLASS_ID,
        section_id: this.state.SECTION_ID,
        session_id: this.state.SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          LoadingStudent: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            StudentList: response.data

          })
          // alert(JSON.stringify(this.state.StudentList));

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }


  deleteStudent() {

    this.setState({
      Loading: true,
      
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'deleteStudent.php?';
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR_CRM + FUNCTION_NAME;
    var str_student_ids=JSON.stringify(this.state.mSelectedStudent);
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        str_student_ids: str_student_ids,
        session_id: this.state.SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        //console.log("Response2>>>>>" + JSON.stringify(response));
        //alert(JSON.stringify(response));
        let api_status = response.api_status;
         alert(api_status);

        if (api_status == "OK") {

          myToast.notify_warning("Deleted Successfully");

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else {
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/DeleteStudent'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Delete Student </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>
                      {this.state.Loading ? <MyLoader /> :
                        <div>
                          <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>

                          <div className='row'>
                            <div className="col-sm-12 col-md-12 bg-primary">
                              <div className="text-center">
                                {/* <!-- Button trigger modal --> */}
                                <h2 className="en-adhar text-white"> Selected Students: {this.state.SelectedStudentCount}  &nbsp;&nbsp;&nbsp;&nbsp; &#8649; </h2>
                              </div>
                            </div>

                            {this.state.LoadingStudent ? <div><MyLoader /></div> :

                              this.state.StudentList.map((item, index) => (

                                <div className="col-sm-6 col-md-6 p-0">
                                  <div className="row bg_card_blue_outline" style={{ margin: '5px 15px 10px 15px', height: '90px' }}>
                                    <div className="col-sm-3  bg_card_blue gravity_center" style={{ padding: '1px', height: '85px' }}>
                                      <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                                        <div className="circular_image">
                                          <img src={item.student_img_path} alt=""></img>
                                          <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6" style={{ padding: '5px 10px', height: '85px' }}>
                                      <h5> {item.stu_name} </h5>
                                      <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                                      <p className="mb-0" style={{ fontSize: '10px' }}><span>{item.current_address}</span> </p>

                                    </div>
                                    <div className="col-sm-3 padding_0 gravity_center" style={{ height: '85px' }}>
                                      <div className="text-center bg_card_blue_outline width_fill_parent" style={{ margin: '2px' }}>
                                        <p className="mb-0"> Roll No:{item.student_roll_no} </p>


                                        <p> <div className="st-list-roll-n" style={{
                                          background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                                          margin: '2px auto', fontSize: '20px'
                                        }}>
                                          <input type="checkbox" id="issue_notice_c" class="ch-in" value="" onChange={e => this.getSelectedStudent(e, index)} checked={this.ifAlredySelectedStudent(index)} /></div>
                                        </p>

                                        <h6><span> {item.class_name} </span>  </h6>
                                      </div>
                                    </div>

                                  </div>

                                </div>

                              ))}

                            <div className="col-sm-12 text-center cursor" style={{ margin: '15px auto' }} onClick={this.HandleOpenDeleteConfirmation}>
                              <h3 style={{ background: '#129cd9', color: '#fff', padding: '7px' }}> Delete Student </h3>
                            </div>

                          </div>
                        </div>
                      }
                    </div>
                  </div>

                )
              }
            } />

          </switch>
          {this.state.showDeleteConfirmation === true ? <ModalDeleteStudentConfirmation actionClose={this.HandleOpenDeleteConfirmation} actionConfirm={this.HandleSelectedStudent} student_count={this.state.mSelectedStudent.length}></ModalDeleteStudentConfirmation> : ''}
        </Router>
      )
    }
  }

}
