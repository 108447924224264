import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import SelectClass from '../ChildComponent/SelectClass';
import Button from 'react-bootstrap/Button';
import TransferCertificateForm from './TransferCertificateForm';
import SessionManager from '../Utils/SessionManager';
import axios from 'axios';
import myToast from '../Utils/MyToast';
export default class TransferCertificate extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: true,
      Loading: false,
      mTransferCertificate: []


    }
    this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
  }


  CallBackClassSelect = (class_id,section_id,session_id,selected_position,class_name,section_name,session_name) => {
  
    this.state.CLASS_ID=class_id;
    this.state.SECTION_ID=section_id;
    this.state.SESSION_ID=session_id;
    this.state.SELECTED_CLASS_POSITION=selected_position;
    //alert("Back"+class_id+"/"+section_id+"/"+session_id);
  
  }


  componentDidMount() {
    // window.scrollTo(0, 0)
    //this.getTransferCertificate();
  }





  getTransferCertificate() {

    this.setState({
      Loading: true
    })

    const SCHOOL_CODE = SessionManager.getSchoolCode();

    var mSession = SessionManager.getCurrentSession();
    var SESSION_ID = mSession[0].session_id;


    const API_NAME = 'getLeavingStudentList.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME;
    //console.log("URL>>>>>"+URL)
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: SESSION_ID,
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        alert(api_status);
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          this.setState({
            mTransferCertificate: response.data,

          })

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //alert(JSON.stringify(this.state.mAdmissionQuery));

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }

  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else if (this.state.Loading) {
      return <MyLoader />
    }
    else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/TransferCertificate'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Transfer Certificate</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>


                      <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>

                      {this.state.Loading?<MyLoader/>:
                      <>
                      <table className='table_border'>
                        <tbody className=''>
                          <tr>
                            <th style={{ 'border': '2px solid black', width: '1%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='table_decoration'>Image</div>

                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='table_decoration'> Student Name</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='table_decoration'> Father Name</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='table_decoration'>class</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='table_decoration'>Certificate Number</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='table_decoration'> Get Certificate Number</div>
                            </th>
                          </tr>
                          <tr>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '50px', width: '50px' }}>
                                <div className="circular_image">
                                  <img src="" alt=""></img>
                                </div>
                              </div>
                            </td>

                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'> Varun Yadav</div>
                            </td>
                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'>Chandar Vijay Yadav</div>
                            </td>
                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'>10</div>
                            </td>
                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'>1123342222222</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='admission_data1'>
                                <ul className="breadcrumbs mar_top">
                                  <li><Link to="/AdminMain/AdmissionQueryDetails"><Button variant="primary">Print</Button>{' '}</Link></li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '50px', width: '50px' }}>
                                <div className="circular_image">
                                  <img src="" alt=""></img>
                                </div>
                              </div>
                            </td>

                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'> Varun Yadav</div>
                            </td>
                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'>Chandravijay Yadav</div>
                            </td>
                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'>10</div>
                            </td>
                            <td style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--white)' }}>
                              <div className='table_decoration1'>1123342222222</div>
                            </td>
                            <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                              <div className='admission_data1'>
                                <ul className="breadcrumbs mar_top">
                                  <li><Link to="/AdminMain/AdmissionQueryDetails"><Button variant="primary">Print</Button>{' '}</Link></li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>


                      <div className='admission_data1'>
                        <ul className="breadcrumbs mar_top">
                          <li className=''><Link to="/AdminMain/TransferCertificateForm"><Button variant="primary">Add New Leaving Student</Button>{' '}</Link></li>
                        </ul>
                      </div>
                      </>
                      }

                    </div>
                  </div>
                )
              }
            } />
            <Route path='/AdminMain/TransferCertificateForm' component={TransferCertificateForm} />
          </switch>
        </Router>
      )
    }
  }
}
