import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactHtmlParser from 'react-html-parser';
const Rs = '&#8377;';

export default class MdlStudentIdCardSettings extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      SAMPLE_ID:'',
      mSettings: [],
      show_ssid:'',
      show_student_pen:'',
      show_admission_no:'',
      show_contact_number:''
     
    }

  }
  componentDidMount() {
    this.state.SAMPLE_ID = this.props.sample_id;
    this.getIdCardSettings();
  }
  
  handleChange = (field) => {
    alert(field);
    this.setState({
      field: 0
    });
    alert(this.state.show_ssid);
    
  }
  HandleClose = () => {
    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }
  HandleSubmit = () => {
    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

    //alert("Add new class");

  }

  getIdCardSettings() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = 'getStudentIdCardSettings.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME;

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        sample_id: this.state.SAMPLE_ID,
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        //alert(api_status);
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          this.setState({
            mSettings: response.data,

          })
          this.getSettings()
          
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //alert(JSON.stringify(this.state.mAdmissionQuery));

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  getSettings()
  {
    this.state.show_ssid=this.state.mSettings[0].show_ssid;
    this.state.show_student_pen=this.state.mSettings[0].show_student_pen;
    this.state.show_admission_no=this.state.mSettings[0].show_srn;
    this.state.show_contact_number=this.state.mSettings[0].show_contact_number;
   // alert(this.state.show_ssid);
   

    this.setState({

    })
  }
 
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
                IdCard Settings And Design Customization
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :

                <>
                  <div className='row'>
                  <div className='col-md-6 padding_5'>
                          <div className='bg_card_primary_outline'>
                            <div className='row'>
                              <div className='col-md-10'><label for="ssid" className='id_card_settings_options'>{"Show SSID"}</label></div>
                              <div className='col-md-2 mar_top2'><input type="checkbox" className='abcd' name="checkbox" id="ssid" value={this.state.show_ssid} checked={this.state.show_ssid==1} onChange={()=>this.handleChange("show_ssid")}></input></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 padding_5'>
                          <div className='bg_card_primary_outline'>
                            <div className='row'>
                              <div className='col-md-10'><label for="pen" className='id_card_settings_options'>{"Show Student PEN"}</label></div>
                              <div className='col-md-2 mar_top2'><input type="checkbox" className='abcd' name="pen" id="pen" value="value" checked={this.state.show_student_pen==1}></input></div>
                            </div>
                          </div>
                        </div>
                  </div>

                </>
              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row width_match_parent">
                <div className='col-md-4 col-sm-4'>
                <Button variant="primary" size="lg" onClick={this.HandleClose}>
                  Close
                </Button>
                </div>
                <div className='col-md-4 col-sm-4'>

                </div>
                <div className='col-md-4 col-sm-4'>
                <Button variant="primary" size="lg" onClick={this.HandleSubmit}>
                  Apply
                </Button>
                </div>
                
                
              </div>

            </Modal.Footer>
          </Modal>
        </div >

      )
    }
  }
}

