import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import StudentList from './StudentList';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
export default class FeeReceiptSettings extends Component {
  constructor(props){
    super(props)
    this.slt_board=createRef();
  this.slt_medium=createRef();
  
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={   
      loggedIn:true,
      Loading:false, 
      CLASS_ID:'',
      SECTION_ID:'',
      mClassData:[],
      mClassBoard:[],
      mClassMedium:[], 
      mFilteredClassSectionList:[] 
    }
  
  }
  onBoardChange = (event) => {
    //this.setState({ value: event.target.value });
    //let board_id=event.target.selectedOptions[0].getAttribute('board_id');
   
    this.getClassList();
  
  
  };
  onMediumChange=(event)=>{
    this.getClassList();
  }
  getClassList()
  {
  
    let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    //filtering class of selected board and medium
    var mFilteredClassList=[];
    this.state.mFilteredClassSectionList=[];
    for (var i=0; i < this.state.mClassData.length; i++) {
      let item=this.state.mClassData[i];
      if(item.board_id===board_id && item.medium_id===medium_id)
      {
      
        mFilteredClassList.push(item);
  
      }   
    }
    //console.log("ClassList>>>>>>",mFilteredClassList); 
    //getting section of classes
    for (var i=0; i <mFilteredClassList.length; i++) {
      let item=mFilteredClassList[i];
      let SECTION=item.section;
              if(SECTION==="No")
              {
              
                this.state.mFilteredClassSectionList.push(item);
              }
              else {
    
                var tempSection=[];
                tempSection=item.section;
                for (var j=0;j<tempSection.length;j++)
                {
                    let item2=tempSection[j];
                    item2["board_id"]=board_id;
                    item2["board_name"]=item.board_name;
                    item2["medium_id"]=medium_id;
                    item2["medium_name"]=item.medium_name;
                    //console.log("item2>>>>>>",item2);                
                    this.state.mFilteredClassSectionList.push(item2);
                    
                }
              }
      
    }
    this.setState({       
     
      })
  }
  
  componentDidMount()
  {
   
    this.getClass();
  }
  getClass(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  //alert(SESSION_ID);
  
  const FUNCTION_NAME='getClassWithStudentCount.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mClassData:response.response.class,
          mClassBoard:response.response.board,
          mClassMedium:response.response.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }

  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }
      else{
        return(
  
   <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Class Settings</a></li>
          </ul>
        </div>
      
      </div>
  
      <div className="animated fadeInUp bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
       
       <div className="row">
            <div className="col-sm-2 col-md-2">
              <div className="text-center">
              <label for="Select board">Select Boards:</label>
              </div>
            </div>
            <div className="col-sm-4 col-md-4">
            <div className="form-group">
            <select className="form-control" onChange={this.onBoardChange} ref = {this.slt_board}>
            {this.state.mClassBoard.map((item, index) => ( 
               <option board_id={item.board_id}>{item.board_name}</option>
               
               ))}
               </select>
            </div>
            </div>
            <div className="col-sm-2 col-md-2">
              <div className="text-center">
              <label for="Select Medium">Select Medium:</label>
              </div>
            </div>
            <div className="col-sm-4 col-md-4">
              <div className="form-group">
               <select className="form-control" onChange={this.onMediumChange} ref = {this.slt_medium}>
               {this.state.mClassMedium.map((item, index) => ( 
               <option medium_id={item.medium_id}>{item.medium_name}</option>
               ))}
               </select>
              </div>
            </div>
           </div>
         
         <div className="row">
            {this.state.mFilteredClassSectionList.map((item, index) => {
               return(      
            <div className="col-sm-6 col-md-6 ">
                   
              <div className="bg_card_blue_outline  box_shadow_primary"
              style={{margin:'5px'}}>
                
                   <div className="bg_card_blue_tltr" style={{margin:'1px',height:'35px'}}>  
                   <div className="row">
                       <div className="col-sm-10 gravity_center padding_0">
                                <p style={{fontSize:'14px',color:COLORS.white}}>{item.section==='No'?'Class:'+item.class_name+" (Student: "+item.student+")":item.class_name+':'+item.section_name+" (Student: "+item.student+")"} </p>
                        </div>
                       
                        <div className="col-sm-2 padding_0 gravity_center">
                         
                            <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'25px', height:'25px',border:'2px',
                            margin:'3px auto',fontSize:'20px',color:COLORS.primary_color_shade5,background:COLORS.white}}>{'>'}</div> </p>
                       
                        </div>
                    </div>         
                  </div>
                  
                  <div className="row" style={{margin:'2px 2px',height:'32px'}}>
                  
                        <div className="col-sm-6 gravity_center padding_0 bg_card_blue">
                                <p style={{fontSize:'14px',color:COLORS.white}}>Add Section</p>
                        </div>
                       
                        <div className="col-sm-6 padding_0 gravity_center bg_card_blue">
                               <p style={{fontSize:'14px',color:COLORS.white}}>Shift Student</p>              
                        </div>
                    </div>
                      

                 
              </div>
          
            </div>
               )
            
               })}
           </div>
         
           </div>
           
            </div>
       
       )
    } 
  }
}
