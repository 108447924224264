import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import StudentList from './StudentList';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Button from 'react-bootstrap/Button';
import TransportAddNewRoute from './TransportAddNewRoute';
export default class TransportRoutes extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      mRoute: [],
      loggedIn: true,
      Loading: false,

    }
  }
  componentDidMount() {
    this.route();

  }
  route() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();



    const FUNCTION_NAME = 'getRouteList.php?'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME

    axios.get(URL, {
      params: { school_code: SCHOOL_CODE, }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        //alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mRoute: response.data

          })
          console.log("response>>>>>" + this.state.mRoute);
          //alert (JSON.stringify( this.state.mRoute));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (

        <Router>
          <switch>
            <Route path={'/AdminMain/TransportRoutes'} exact render={
              () => {
                return (

                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Transport Routes</a></li>
                        </ul>
                      </div>

                    </div>

                    <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>

                      {this.state.mRoute.map((item, index) => {

                        return (

                          <div className='bg_card_primary padding_5'>

                            <div className='row bg_card_peach_outline text_burnt'>
                              <div className='col-md-6 mar_top1'>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <h4 className=''>Route Name</h4>
                                  </div>
                                  <div className='col-md-6 input-group admission_form2 border_form gravity_center'>
                                    <h4>{item.route_name}</h4>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6 mar_top1'>
                                <div className='row'>
                                  <div className='col-md-4'>
                                    <h3>Distance</h3>
                                  </div>
                                  <div className='col-md-8 input-group admission_form2 border_form gravity_center'>
                                    <h4> {item.route_distance} </h4>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='row'>
                                  <div className='col-md-4'>
                                    <h3>from</h3>
                                  </div>
                                  <div className='col-md-8 input-group admission_form2 border_form gravity_center'>
                                    <h4> {item.start_from} </h4>
                                  </div>
                                </div>
                              </div>

                              <div className='col-md-6'>
                                <div className='row'>
                                  <div className='col-md-4 '>
                                    <h3>To</h3>
                                  </div>
                                  <div className='col-md-8 input-group admission_form2 border_form gravity_center'>
                                    <h4>{item.end_to} </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="row bg_card_blue_outline" >

                              <div className="col-md-2  bg_card_peach  gravity_center1" style={{ padding: '0px', height: '100px' }}>
                                <div className="" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                                  <div className="circular_image">
                                    <img src='' alt=""></img>
                                  </div>
                                  <p className="student_id1 gravity_center"> <small>varun kumar yadav </small></p>
                                </div>
                              </div>

                              <div className="col-md-2  bg_card_peach  gravity_center1" style={{ padding: '0px', height: '100px' }}>
                                <div className="" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                                  <div className="circular_image">
                                    <img src='' alt=""></img>
                                  </div>
                                  <p className="student_id1 gravity_center"> <small>varun kumar yadav </small></p>
                                </div>
                              </div>


                            </div>
                          </div>
                        )

                      })}

                      <div className="">
                      <Link to='/AdminMain/TransportAddNewRoute' > <button className="btn btn-success"  style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Add New Route</button></Link>
                      </div>
                    </div>
                  </div>


                )
              }
            } />
            <Route path='/AdminMain/TransportAddNewRoute' component={TransportAddNewRoute} />

          </switch>
        </Router>
      )
    }
  }
}