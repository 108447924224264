import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactHtmlParser from 'react-html-parser';
const Rs = '&#8377;';

export default class MdlAddSubjectMasterList extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      mSessionList: [],
      SESSION_SELECTED_POSITION: 0

    }

  }

  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }
  HandleSubmit = () => {
    
    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();
   
    //alert("Add new class");

 }
 getClassSubject() {
  this.state.mClassSubject = [];

  this.setState({
    Loading: true
  })

  const SCHOOL_CODE = SessionManager.getSchoolCode();
  const FUNCTION_NAME = 'getClassSubject.php'
  URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
  console.log("URL>>>>>" + URL)
  var params = new URLSearchParams();
  params.append('session_id', this.state.SESSION_ID);
  params.append('class_id', this.state.CLASS_ID);
  params.append('school_code', SCHOOL_CODE);
  axios.post(URL, params).then(res => res.data)
    .then((response) => {
      this.setState({
        Loading: false
      })
      // console.log("Response2>>>>>"+response);
      let api_status = response.api_status;
      // alert(api_status);      
      if (api_status == "OK") {
        this.setState({
          mClassSubject: response.data,
        })

      }
      else {
        let api_remark = response.api_remark;
        myToast.notify_warning(api_remark);
      }
      //alert(JSON.stringify(this.state.mClassFee));
    }).catch((error) => {
      this.setState({
        Loading: false
      })
      console.log("error>>>>>" + error);
    });
}
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
               Add New Subject
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :

                <>
                  <Form.Group className="">
                    <Form.Label className='subject_list1'>Subject Name</Form.Label>
                    <Form.Control placeholder="Add New Subject" style={{ margin: '3% 0%', fontSize: '21px', width: '70%' }} />
                  </Form.Group>
                </>
              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <Button variant="primary" size="lg"  onClick={this.HandleSubmit}>
                  Done
                </Button>
                <Button variant="primary" size="lg" onClick={this.HandleClose}>
                  Close
                </Button>
              </div>

            </Modal.Footer>
          </Modal>
        </div>

      )
    }
  }
}

