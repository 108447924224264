import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class ChangePassword extends Component {
  constructor(props){
    super(props)

  
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={
        loggedIn:false,
        Loading:false,
        oldPass:'',
        newpass:'',
        confpass:''

    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
}
regChange(e){

  this.setState({
    [e.target.id]:e.target.value
})
}
regSubmit(){
  // var hh=this.state.newpass;
  // alert(hh);
  if(this.isValidInput())
  {
  
  const StaffId="stf000111";
  const FUNCTION_NAME='changeStaffPassword.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
      staff_id:StaffId,
      new_password: this.state.newpass

    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      //console.log("responsel>>>>>"+response);
      alert("successfully Updated !" );
      window.location.reload();

    console.log("response>>>>>"+JSON.stringify(api_status))
   })
}
}  

isValidInput()
{

  if (this.state.oldPass==="" )
    {
    alert('Please Enter Old Password');
    return false;
    }else if (this.state.oldPass !="145" ){
    alert("Your Old Password did not match !");
    return false;
    }else if(this.state.newpass==="")
  {
    alert("Please Enter new Password");
    return false;
  }
  
  else
   if(this.state.confpass==="")
  {
    
    alert("Please Enter Confirm Password");
    return false;
  }else
  var gg=this.state.newpass;
  var kk=this.state.confpass;
    if(gg!=kk)
  {
    alert("Please Enter Same New password  and Confirm Password");
    return false;
  }
  else{
    return true;
  }

}
        render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Change Password </a></li>
              </ul>
            </div>
          </div> 
          <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Old Password </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="password" id="oldPass" name="oldPass" value={this.state.oldPass} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">New Password </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="password" id="newpass" name="newpass" value={this.state.newpass} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Confirm Password </label>
                                    <div className="col-md-10">
                                      <div className="input-group">
                                        <input type="password" id="confpass" name="confpass" value={this.state.confpass} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    </div>
              <div className="row">
              <div className="col-sm-12 col-md-12">
              <button className="btn btn-info" onClick={this.regSubmit} style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Submit Password </button>
             </div> 
             </div>
            </div>
  
  )
} 
}
}

