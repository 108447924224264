import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';

export default class AdmissionLink extends Component {
    constructor(props) {
        super(props)   
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }     
        this.state={  
        
            loggedIn:true,
            Loading:false,
            
            
        }
      }
    render() {
      
        if(this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
        else if(this.state.Loading)
        {
                return <MyLoader/>
        }
       else{       
         
            return (             
            <Router>
            <switch>
              <Route path={'/AdminMain/AdmissionLink'}  exact render={
                ()=>{
              return(
    
                <div className="content" style={{background:COLORS.white}}>
                        <div className="content-header">
                          <div className="leftside-content-header">
                            <ul className="breadcrumbs">
                              <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Admission Link</a></li>
                            </ul>
                          </div>
                        </div>
                        <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'150px'}}>
                        <h1>Android Application Feature</h1>
                             
                         
                       </div>
                     </div>
               )
              }
            } />
                         
            </switch>       
           </Router>  
            )
        }
        }
}
