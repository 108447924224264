import React, { Component } from 'react'
import {Modal} from 'react-bootstrap'
import MyLoader from './MyLoader'

export default class ProgressDialog extends Component {
    constructor(props){
        super(props)            
        this.state={   
            show:true
        }
      
}
    render() {
        return (
            <div>
    <Modal show={this.state.show}  animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
          <MyLoader/>
          <div style={{ flex: 1, backgroundColor: '#dcdcdc', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ borderRadius: 10, backgroundColor: 'white', padding: 25 }}>
                        <h1 style={{ fontSize: 20, fontWeight: '200' }}>Loading</h1>
                        
                    </div>
                    </div>
            
      </Modal.Body>
    </Modal>
     
            </div>
        )
    }
}
