import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import CommentReply from './CommentReply';
import myToast from '../Utils/MyToast';
export default class Comment extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mComment:[],
        mReply:[]
    }
  
  }
  componentDidMount()
  {
  this.Comment();
  }
  Comment(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Sess="SSN0001";
  const Lim="30";
  const Offset="0";
  const FUNCTION_NAME='getAllComments.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)  
  axios.get(URL,{
    params:{session_id:Sess,limit:Lim, offset:Offset, school_code:SCHOOL_CODE}
  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            mComment:response.comment
          })
          console.log("response>>>>>"+JSON.stringify(this.state.mComment));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
      return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Parents Comment </a></li>
              </ul>
            </div>
          </div> 
          <div className="row">
          <div className="col-sm-12 col-md-12">
          <p className="text-justify">
           These Comments are given from students Parents As Suggestion,query and feedback about school and information.   
         </p>
          </div>
          </div> 
          {this.state.mComment.map((item, index) => ( 
              item.reply!==null?this.state.mReply=item.reply:this.state.mReply=[],
              // this.mReply=item.reply,
              // console.log("reply>>>>",this.mReply),
              <div className="row class-li" style={{margin:'0 100px',margin:'0px 100px',background:'#fff',padding:'20px'}}>
              <div className="col-sm-12 col-md-12" style={{margin:'0px'}}>
              <h5 className="text-justify"> <span style={{color:'#969997'}}> {item.date_added} </span> </h5>
              <div style={{border:'1px solid #ddd',borderRadius:'18px',padding:'10px 40px',background:'#fff'}}>
              <h6><span className=""> {item.comment_title} </span> </h6>
                  <p> {item.comment} </p>
                  <h6 className="text-right"><span style={{color:'#898686'}}>  TO: {item.comment_for}  </span>  </h6>
                <p><span style={{color:'#969997'}}> FROM: {item.sender_name}({item.sender_id})  </span></p>
                <h3 className="text-right">
                  <Link to={{pathname:'/SuperAdminDash/CommentReply', data: {'Cmtcur_id':item.comment_id}}}> 
                    <button className="reply-comment text-info"><img src="https://www.materialui.co/materialIcons/content/reply_all_grey_192x192.png" style={{width:'30%', border:'none', padding:'0px'}} alt="reply"/>  &nbsp; Reply </button>
                    </Link>
                    </h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="row">
                <div className="col-sm-6 col-md-6"></div>
               <div className="col-sm-6 col-md-6" style={{margin:'0px'}}>
              <div style={{border:'1px solid #ddd',borderRadius:'18px',padding:'10px 40px',background:'#fff'}}>
              <h6><span className=""> Recent Reply </span> </h6>
              {
              this.state.mReply.map((item2, index2) => ( 
              <div className="row" style={{borderBottom:'1px dotted #ddd'}}>
              <div className="col-sm-3 col-md-3" style={{margin:'0px',border:'double #fff'}}>
               <div className="text-center"><img src={item2.staff_image_path} alt="student list image"/> </div>
              </div>
                <div className="col-sm-9 col-md-9 text-center" style={{margin:'0px'}}>
               <h5 className="text-success"> From: <span> {item2.sender_name} </span> </h5>
               <h6> {item2.comment} </h6>
                </div>
                </div>
              ))
              }
                </div>
              </div>
                </div>
              </div>
             </div>
             ))}

       <Router>
        <switch>   
        <Route path='/SuperAdminDash/Comment'   render={
          ()=>{

          }
        } />  

          <Route path='/SuperAdminDash/CommentReply'  component={CommentReply} /> 

          </switch>
          
         </Router>
               </div>
  
  )
} 
}
}
