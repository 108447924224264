import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import COLORS from '../resources/colors/COLORS';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class VendorRegistration extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token"); 
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      mgetAdmissionDashboard:''  
  }

}
componentDidMount()
{
this.getAdmissionDashboard();
}
getAdmissionDashboard(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const FUNCTION_NAME='getAdmissionDashboard.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mgetAdmissionDashboard:response.dashboard
        })
        console.log("response>>>>>"+this.state.mgetAdmissionDashboard);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (

      <div className="content">
      <div className="content-header" style={{ background: COLORS.green6 }}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Vendor Registration</a></li>
          </ul>
        </div>
      </div>
      
      <div className="row class-li">
              <div className="col-sm-3 col-md-3 card-class-w class-list-box" style={{margin:'0px',border:'double #fff'}}>
                <div className="row">
               <div className="col-sm-6 col-md-6">
               <div className="myprofile-img-div"><img src="https://www.galaxyskills.org/wp-content/uploads/2019/02/dummy-350x350.png" alt="" title="Vendor" style={{width:'62%',height:'50%'}} /></div>
               
               </div>
               <div className="col-sm-6 col-md-6">
               <h4> <input type="file" style={{width:'100%'}} /></h4>
               <hr/>
               <h4> <button className="btn btn-success" style={{width:'100%'}}> Image Upload </button> </h4>
               </div>
                </div>
                </div>
                <div className="col-sm-9 col-md-9 text-center class-list-box" style={{margin:'0px'}}>
                <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Vendor Name</label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="VendorName" name="vendor name" value="" className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Address</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="address" name="address" value="" className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Phone</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="text" id="phone" name="phone" value="" className="form-control" />
                                       </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Email</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="email" id="phone" name="Email" value="" className="form-control" />
                                       </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Descriptions</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="text" id="descriptions" name="Descriptions" value="" className="form-control" />
                                       </div>
                                    </div>
                </div>
                </div>                  
                </div>
            <section>
                    <div className="row mt-4">
<div className="col-sm-12"> 
<h3> <Link to={{pathname:'/'}} class="btn btn-block text-white" style={{fontSize:'15px',background: COLORS.green7,textDecoration:'none',padding:'10px'}}> <span className="clf"> Submit </span>  </Link>  </h3>
               
</div>
                    </div>
                </section>
           </div>
     
      )
      
  }
 
}
}
