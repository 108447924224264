import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class CommentReply extends Component {
  constructor(props){
  super(props)
  this.cmtCur_id = props.location.data.Cmtcur_id;
 
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={
        loggedIn:false,
        Loading:false,
        Enter_notice:'',
        CommentReply:''


    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
}
regChange(e){
  this.setState({
    [e.target.id]:e.target.value
})
}
regSubmit(){

  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Staff_id="STF000111";
  const Cmt_for="Teacher";
  const Cmt_type=" Local";
  const FUNCTION_NAME='addComments.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR_FOR_PARENTS+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
      school_code:SCHOOL_CODE,
      comment:this.state.CommentReply,
      sender_id:Staff_id,
      comment_for:Cmt_for,
      comment_type:Cmt_type,
      parent_comment_id:this.cmtCur_id
    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
    // console.log("responsel>>>>>"+JSON.stringify(response));

    alert("successfully Updated !" );
    if (typeof window !== 'undefined') {
         window.location.reload(false);
         window.location.href = "../SuperAdminDash/Comment";
    }

    console.log("response>>>>>"+api_status)
   })
  
} 

 render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
            return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Comment Reply </a></li>
              </ul>
            </div>
          </div> 
              <div className="row class-li">
              
              <div className="col-sm-12 col-md-12 card-class-w class-list-box" style={{margin:'0px',border:'double #fff'}}>
              <h5 className="text-center"> <span className="text-success"> Suggestion </span> </h5>
              <hr/>
                  <h3><button className="reply-comment text-info" style={{border:'none'}}><img src="https://www.materialui.co/materialIcons/content/reply_all_grey_192x192.png" style={{width:'30%', border:'none', padding:'0px'}} alt="reply"/>  &nbsp; Reply </button></h3>
                  <div className="input-group">
                  <input type="text" id="CommentReply" name="CommentReply" value={this.state.CommentReply} onChange={this.regChange} className="form-com" style={{padding:'20px'}} />
                  </div>
              </div>
             </div>
             <div className="row">
                                    <div className="">
                                      
                                        <button className="btn btn-success" onClick={this.regSubmit} style={{margin:'2% 0px',fontSize:'24px',width:'100%'}}>Submit</button>
                                       
                                    
                                    </div>
                                  </div>
               </div>
  
  )
} 
}
}
