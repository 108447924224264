import React, { Component, createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
const Rs = '&#8377;';

export default class MdlFeePayDetails extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      mSessionPayDetails: [],
      

    }

  }

  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }



  componentDidMount() {
   
   
  }


  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Pay Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :

                <>

                  <div className="row padding_5">
                    <div className='col-md-12'>
                      <div className='bg_card_fee1'>
                        <div>Amount paid</div>
                        <div>{ReactHtmlParser(Rs)}{this.props.pay_details[0].total_recieved}</div>
                      </div>
                    </div>

                    <div className='col-md-12 mar_top '>
                      <div className='bg_card_fee2'>
                        <div>Current Due</div>
                        <div>{ReactHtmlParser(Rs)} {this.props.pay_details[0].current_due}</div>
                      </div>
                    </div>
                  </div>

                 

                  <div className="row padding_5">
                    <div className='col-md-4'>
                      <div className='bg_card_fee3'>
                        <div>Total Fee</div>
                        <div>{ReactHtmlParser(Rs)} {this.props.pay_details[0].total_fee}</div>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='bg_card_fee4'>
                        <div>Total Due</div>
                        <div>{ReactHtmlParser(Rs)} {this.props.pay_details[0].total_due}</div>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='bg_card_fee5'>
                        <div>Settled</div>
                        <div>{ReactHtmlParser(Rs)} {this.props.pay_details[0].due_settlement}</div>
                      </div>
                    </div>

                  </div>
                </>
              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <Button variant="primary" size="lg" block onClick={this.HandleClose}>
                  OK
                </Button>
              </div>

            </Modal.Footer>
          </Modal>
        </div>

      )
    }
  }
}

