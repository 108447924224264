import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import COLORS from '../resources/colors/COLORS';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class AccessoriesCategory extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token"); 
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      cat:'',
      mAccessoriesCategory:[]  
  }

}
componentDidMount()
{
this.getAccessoriesCategory();
}
getAccessoriesCategory(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getAccessoriesCategory.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mAccessoriesCategory:response.data
        })
        console.log("response>>>>>"+this.state.mAccessoriesCategory);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (

      <div className="content">
      <div className="content-header" style={{ background: COLORS.green6 }}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Accessories Category</a></li>
          </ul>
        </div>
      </div>
             
          <div className="row class-li">  
          {this.state.mAccessoriesCategory.map((item, index) => (        
            <div className="col-sm-3 col-md-3">
            <Link to="/">
            <div className="row class-list-box">
             <div className="col-sm-12 pt-45">
             <h4 className="text-center"> {item.category_name} </h4>
             </div>
            </div>
            </Link>
            </div>
          ))}
            

            </div>
            <section>
                    <div className="row mt-4">
<div className="col-sm-12"> 
<h3 class="btn bg-primary  btn-block text-white" style={{fontSize:'15px',textDecoration:'none',padding:'10px',cursor: 'pointer'}} data-toggle="modal" data-target="#addcat"> <span className="clf"> Add More </span> </h3>
               
</div>
                    </div>
                </section>
                                {/* Add Modal Section */}

                  <div id="addcat" class="modal fade" role="dialog">
                  <div class="modal-dialog">
                    <div class="modal-content" style={{ width: '800px' }}>
                      <div class="modal-header bg-primary">
                        <h4 class="modal-title pull-left">Add Category</h4>
                        <button type="button" class="close full-right" data-dismiss="modal">&times;</button>

                      </div>
                      <div class="modal-body">
                        <div className="row" style={{ padding: '0px 15px' }}>
                          <div className="col-sm-12 col-md-12" >
                            <p>Product Category</p>
                            <div className="">
                              <input type="text" name="cat" value={this.state.cat} placeholder="Enter Category Name" className="select-field" />
                            </div>
                          </div>
                          <div className="col-sm-12 text-center" style={{margin:'15px auto'}}>
                            <h3 style={{ background: '#129cd9', color:'#fff', padding:'7px'}}> Submit </h3>
                          </div>
                        </div>
                     
                      </div>

                    </div>

                  </div>
                </div>
                {/* End Modal Section */}
           </div>
     
      )
      
  }
 
}
}
