import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import proj from '../resources/images/proj.png'
import NavMenuAdmin from './NavMenuAdmin';
import myToast from '../Utils/MyToast';
export default class StaffPrivilege extends Component {
  constructor(props){
    super(props)

    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={

        loggedIn:true,
        Loading:false, 
        mStaffPrivilage:[],
        mStaffNameTitle:[],
        SELECTED_STAFF_POSITION:0,


    }
  
}
componentDidMount()
{
  
  this.getStaffNameTitle();
}
getStaffPrivilege(){
    this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const StaffId=this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_id;
  const FUNCTION_NAME='getStaffPrivilege.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      staff_id:StaffId
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;     
      if(api_status=="OK")
      {
         this.setState({
          mStaffPrivilage:response.data
          })
        //  alert("response hiiii>>>>>"+JSON.stringify(this.state.mStaffPrivilage));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
} 
// add method for staff details
getStaffNameTitle(){
  this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getStaff.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
axios.get(URL,{
  params:{school_code:SCHOOL_CODE
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;     
    if(api_status=="OK")
    {
       this.setState({
        mStaffNameTitle:response.staff
        })
        this.getStaffPrivilege();
      //  alert("response hiiii>>>>>"+JSON.stringify(this.state.mStaffPrivilage));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }
  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
} 

privilageStaff=(event)=>{
var module_id=event.target.getAttribute('module_id');
var status=event.target.getAttribute('status');
var mStatus;
if(status==="Granted")
{
  mStatus="InActive";
}
else{
  mStatus="Active";
}
//alert(module_id);
var STAFF_ID=this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_id;
//alert(STAFF_ID);

  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='updateStaffPrivilege.php';
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
  console.log("URL>>>>>"+URL);
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      staff_id:STAFF_ID,
      module_id:module_id,
      status:mStatus
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
     
      if(api_status=="OK")
      {
        alert("Updated Successfully");
         
       this.getStaffNameTitle();
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
   
  }
  Rightclick=()=>{
   
    if(this.state.mStaffNameTitle.length-1 > this.state.SELECTED_STAFF_POSITION)
    {
      this.state.SELECTED_STAFF_POSITION=this.state.SELECTED_STAFF_POSITION+1;
      //alert(this.state.SELECTED_STAFF_POSITION);
      this.getStaffPrivilege();
     
    }
    else{
      var elem = document.getElementById( 'r-arr' );
      elem.classList.add('right-arrow-light'); // Add class
      elem.classList.remove('right-arrow'); // Remove class
    }
  }
  Leftclick=()=>{
    if(this.state.SELECTED_STAFF_POSITION>0)
    {
      this.state.SELECTED_STAFF_POSITION=this.state.SELECTED_STAFF_POSITION-1;
      //alert(this.state.SELECTED_STAFF_POSITION);
      this.getStaffPrivilege();
   }
    else{
      var elem = document.getElementById( 'L-arr' );
      elem.classList.remove('left-arrow'); // Remove class
      elem.classList.add('left-arrow-light'); // Add class
    }
    }
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{
    return ( 

    
         <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Staff Privilege </a></li>
              </ul>
            </div>
          </div>
          <div className="animated fadeInUp bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
        
              <div className="row">
              <div className="col-sm-3 col-md-3 fee-d-date-s">
                  <h3> <span className="left-arrow" id="L-arr" onClick={this.Leftclick}> &#x27A4; </span>  </h3>
                </div>
                <div className="col-sm-6 col-md-6 fee-d-date-s">
                 <div className="row">
                 <div className="col-sm-4 col-md-4">
                   <img src={this.state.mStaffNameTitle.length!==0?this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_image_path:'Staff Images Path'} className="staff-img-path" alt="teacher-image"/>
                 </div>
                 <div className="col-sm-8 col-md-8">
                  <h3>{this.state.mStaffNameTitle.length!==0?this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_name:'Staff Name'}</h3> 
                  <h5>({this.state.mStaffNameTitle.length!==0?this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_role:'Staff Role'})</h5>
                 </div>
                 </div>
                </div>

                <div className="col-sm-3 col-md-3 fee-d-date-s">
                  <h3> <span className="right-arrow" id="r-arr" onClick={this.Rightclick}> &#x27A4; </span> </h3>
                </div>
                </div>

               
              <div className="row">
                {this.state.mStaffPrivilage.map((item, index) => (
                <div className="col-sm-4 col-md-4" style={{padding:'5px'}}>
                <div className="row class-list-box-privelege" style={{height:'80px'}} >
                <div className="col-sm-4 right-prive-box gravity_center">
                   <div>
                   <p className="text-center mb-0"><button style={{width:'40px',height:'40px',borderRadius:'50%',border: '1px solid rgb(255 255 255)',background:'#ffffff00'}} data-toggle="modal" data-target="#exampleModal">
                    <img src={item.icon_img} style={{width:'100%'}} alt={item.module_name}/>
                    </button></p> 
                  </div>
                 </div>
                 
                 <div className="col-sm-8">
                  <h5> {item.module_name} </h5>
                 <div className="text-center">
                 <span className="s-bt"> {item.status} </span>
                  <label className="switch switch-height-20">
                  <input type="checkbox" checked={item.status==='Granted'?true:false} onClick={this.privilageStaff} module_id={item.module_id} status={item.status} /><span className="slider round"></span>
                  </label>  
                  </div> 
                 </div>
                </div>
                </div>
                ))}

                 </div>
                </div>
               </div>
      
  )
} 
}
}