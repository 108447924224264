import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import EditStaff from './EditStaff';
import AddQualification from './AddQualification';
import EditQualification from './EditQualification';
import myToast from '../Utils/MyToast';
export default class StaffProfile extends Component {
  constructor(props){
    super(props)
    this.STAFF_ID = props.location.data.Staff_id;
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mStaffProfile:[],
        mStaffqly:[]
    }
  
  }
  componentDidMount()
  {
  this.StaffProfile();
  }
  StaffProfile(){
  
  this.setState({
    Loading:true
  })
  const FUNCTION_NAME='getStaffDetails.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)  
  axios.get(URL,{
    params:{staff_id:this.STAFF_ID}
  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            mStaffProfile:response.data,
            mStaffqly:response.data[0].qualification
          })
          console.log("response>>>>>"+JSON.stringify(this.state.mStaffProfile));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
      return (
        <Router>
        <switch>   
        <Route path='/AdminMain/StaffList/StaffProfile'  exact render={
          ()=>{
  
            return(
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">  Staff Profile Details </a></li>
              </ul>
            </div>
          </div>
          {this.state.mStaffProfile.map((item, index) => ( 
              <div className="row class-li">
                <div className="col-sm-6 col-md-6 text-center staff-profile-detail" style={{margin:'0px'}}>
                <div>
                    <p className="text-center posi-rel"> <img src={item.staff_image_path} className="img-phto" alt="staff profilr image" style={{width:'30%', borderRadius:'50%'}} />
                    </p>
                    </div>
                <h3> {item.staff_name} </h3>
                <p> {item.login_type} ({item.staff_role}) </p>
                <hr/>
                <div className="row">
                <div className="col-sm-6 col-md-6 text-justify">
                 <h4 className="btn btn-info w-100"><i className="fa fa-phone"></i> &nbsp; Call : &nbsp; +91- {item.staff_phone} </h4>   
                </div> 
                <div className="col-sm-6 col-md-6 text-justify">
                 <h4 className="btn btn-info w-100"><i className="fa fa-envelope"></i> &nbsp; Message: &nbsp; {item.staff_email}  </h4>   
                </div>  
                </div>
                </div>
                <div className="col-sm-6 col-md-6 text-center" style={{margin:'0px'}}>
                <div className="bg-info">
                <Link to={{pathname:'/AdminMain/StaffList/StaffProfile/EditStaff', data: {Staff_data:this.state.mStaffProfile}}}>
                <h3 className="text-white">
                    Basic Details 
                <button style={{width:'40px',height:'40px',borderRadius:'50%', border:'1px solid #fea223',marginLeft:'200px'}}>
                    <img src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Edit-validated-icon.png" style={{width:'100%'}}/>
                 </button> 
                 </h3>
                </Link>
                </div>
                <div className="row">
                <div className="col-sm-6 col-md-6 text-justify bor-r-leave">
                <h5><strong className="text-info"> Phone : </strong> <span className="f-w-300"> {item.staff_phone} </span> </h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify">
                <h5><strong className="text-info"> Email : </strong> <span className="f-w-300"> {item.staff_email}  </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify bor-r-leave">
                <h5><strong className="text-info"> Adhar No. : </strong> <span className="f-w-300"> {item.aadhar_no}  </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify">
                <h5><strong className="text-info"> Joining Date : </strong> <span className="f-w-300"> {item.joining_date} </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify bor-r-leave">
                <h5><strong className="text-info"> DOB : </strong> <span className="f-w-300"> {item.staff_dob}  </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify">
                <h5><strong className="text-info"> Gender : </strong> <span className="f-w-300"> {item.staff_gender}  </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify bor-r-leave">
                <h5><strong className="text-info"> Login Type : </strong> <span className="f-w-300"> {item.login_type} </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify">
                <h5><strong className="text-info"> Role : </strong> <span className="f-w-300"> {item.staff_role} </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify bor-r-leave">
                <h5><strong className="text-info"> Religion : </strong> <span className="f-w-300"> {item.staff_religion}  </span></h5>
                </div>
                <div className="col-sm-6 col-md-6 text-justify">
                <h5><strong className="text-info"> Nationality : </strong> <span className="f-w-300"> {item.nationality}  </span></h5>
                </div>
                <div className="col-sm-12 col-md-12 text-justify">
                <hr/>
                <h5><strong className="text-info"> Address : </strong> <span className="f-w-300"> {item.staff_address}</span> </h5>
                </div>
                </div>
                </div>
                </div> 
                ))}

                {/* add education */}

                 <div className="row" style={{ background:'#fff', padding:'15px'}}>
                              <div className="pnlSubHedingBorder headMarginTop w-100">
                                <div className="pnlSubHeding heading-with-bg-w">
                                Qualification
                                <Link to={{pathname:'/AdminMain/StaffList/StaffProfile/AddQualification', data: {AddStaffQly:this.STAFF_ID}}}>
                                <button style={{width:'15%',borderRadius:'10px', fontSize:'16px',padding:'4px',background:'#28a745', float:'right',color:'#fff'}}> Add More <img src="https://image.flaticon.com/icons/svg/61/61183.svg" alt="add" style={{width:'10%'}}/> </button>   
                                </Link>
                                </div>
                              </div>
                              {this.state.mStaffqly.map((item2,index2)=>(
                                <div className="form-horizontal form-bordered sect-bg-clean-qla col-sm-12 col-md-12">
                                  
                                  <div className="form-group row">
                                    <div className="col-md-9">
                                    <div className="row">
                                    <label className="col-md-2  col-form-label"> Degree/Diploma: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> {item2.staff_degree} </h6>
                                    </div>
                                    <label className="col-md-2  col-form-label">University/Institute: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> {item2.staff_university}  </h6>
                                    </div>
                                    <label className="col-md-2  col-form-label">Year: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> {item2.staff_passing_year} </h6>
                                    </div>
                                    <label className="col-md-2  col-form-label">Percentage: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> {item2.staff_percentage} </h6>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                    <p>
                                    <Link to={{pathname:'/AdminMain/StaffList/StaffProfile/EditQualification', data: {Staff_qdata:item2}}}> 
                                      <button style={{width:'40px',height:'40px',borderRadius:'50%', border:'1px solid #fea223'}}>
                    <img src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Edit-validated-icon.png" style={{width:'100%'}}/>
                                      </button>
                                     </Link>
                                    </p>  
                 {/* <p><button style={{width:'40px',height:'40px',borderRadius:'50%', border:'1px solid #fea223'}} onClick={this.deleteSure}>
                    <img src="https://pngimage.net/wp-content/uploads/2018/06/icono-eliminar-png-3.png" style={{width:'100%'}}/>
                 </button></p>    */}
                                    </div>
                                  </div>
                                </div>
                                ))}
                              </div>

   
               </div>
               )
              }
             } />    
               <Route path='/AdminMain/StaffList/StaffProfile/EditQualification'  component={EditQualification} />                    
               <Route path='/AdminMain/StaffList/StaffProfile/AddQualification'  component={AddQualification} />  
               <Route path='/AdminMain/StaffList/StaffProfile/EditStaff'  component={EditStaff} />        
               </switch>
               
              </Router>
  
  )
} 
}
}
