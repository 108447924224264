import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import eduok from '../resources/images/EduOk.png'
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Iframe from 'react-iframe'
export default class FeeReceipt extends Component {
 
 printDiv(){
   let pp=document.getElementById('divcontents').innerHTML;
			window.print(pp);
		}
        constructor(props){
            super(props)
           
        
            const token=localStorage.getItem("token");
            let loggedIn=true;
            if(token==null){
              loggedIn=false;
            }
        
            this.state={
        
                loggedIn:true,
                Loading:false, 
                showPreview:true,
                Total:0,
                FILE_URL:''
               

            }
          
        }
        componentDidMount()
        {
          const SCHOOL_CODE=SessionManager.getSchoolCode();
          const Transaction_id=this.props.match.params.transaction_id;
          const Session_id=this.props.match.params.session_id;
          const Enrollment_no=this.props.match.params.student_id;
          var SAMPLE_NAME="FeeReceipt/fee_receipt_sample1.php?";
          this.state.FILE_URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR_PRINTABLE+SAMPLE_NAME +'&student_id='+Enrollment_no
               +'&session_id='+ Session_id+'&transaction_id='+ Transaction_id
               +'&system_code='+ process.env.REACT_APP_SYSTEM_CODE+'&school_code='+ SCHOOL_CODE;

              //alert(this.state.FILE_URL);
               //console.log("FILE_URL>>>>",this.state.FILE_URL);

               this.setState({
                showPreview:true
               })

             

        }
     
       render() {
            if( this.state.loggedIn==false)
            {
             return <Redirect to='/Login'/>;
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
            else{
            return ( 
        
      <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Fee Receipt </a></li>
              </ul>
            </div>
          </div> 

            <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>

            <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Preview</h5>
            </div>

             

            <div className="row"  style={{margin:'0px 10px 0px 10px'}}>
            {this.state.showPreview ? <div><MyLoader/></div> 
                      
            :''}

                <Iframe url={this.state.FILE_URL}
                       
                        width="100%"
                        height="1200px"
                        overflow="visible"
                        id="iFrameStudentId"
                        className="bg_card_primary_outline"
                        display="initial"
                        position="relative"
                    
                        onLoad={() => {
                         // alert("loaded");
              
                         this.setState({
                          showPreview:false,
                          
                        })
                      }} 
                      />
                        
               
              </div> 
            
            </div>  
              
      </div>
  
  )
}    
}
 
}