import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import AddHoliday from './AddHoliday';
import myToast from '../Utils/MyToast';
import Button from 'react-bootstrap/Button';
export default class Holiday extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mHoliday: []
    }

  }
  componentDidMount() {
    this.Holiday();
  }
  Holiday() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    var SESSION_ID = mSession[0].session_id;
    const FUNCTION_NAME = 'getHolidays.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: { school_code: SCHOOL_CODE, session_id: SESSION_ID }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mHoliday: response.holidays
          })
          console.log("response>>>>>" + JSON.stringify(this.state.mHoliday));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {
      return (
        <Router>
          <switch>
            <Route path='/AdminMain/Holiday/:module_id' exact render={
              () => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Holiday </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>
                      <div className="row">
                        <div className='col-md-6'>
                          <h3 className='holiday2'>Holiday List</h3>
                        </div>
                        <div className='col-md-3'>

                        </div>

                        <div className="col-md-3">
                          <Link to={'/AdminMain/AddHoliday/'}>
                            <button className="btn btn-success" style={{ width: '97%', fontSize: '18px' }}> Add Holiday </button>
                          </Link>
                        </div>
                      </div>
                      <hr />
                      <table className='table_border'>
                        <tbody className=''>
                          <tr>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Announced Date</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>from</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>To</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Occasion</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Remark</div>
                            </th>
                            <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade2)' }}>
                              <div className='admission_data'>Status</div>
                            </th>
                          </tr>

                          {this.state.mHoliday && this.state.mHoliday.map((item, index) => (
                            <tr>
                              <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                <div className='holiday'>{item.date_added}</div>
                              </td>
                              <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                <div className='holiday'>{item.start_holiday_date}</div>
                              </td>
                              <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                <div className='holiday'>{item.end_holiday_date}</div>
                              </td>
                              <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                <div className='holiday1'>{item.holiday_Occasion}</div>
                              </td>
                              <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                <div className='holiday1'>{item.holiday_remark}</div>
                              </td>
                              <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                                <div className='holiday'>{item.status}</div>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>

                    </div>
                  </div>

                )
              }
            } />

            <Route path='/AdminMain/AddHoliday' component={AddHoliday} />

          </switch>

        </Router>

      )
    }
  }
}
