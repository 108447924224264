import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import { blue } from '@material-ui/core/colors';
import myToast from '../Utils/MyToast';
import axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import { Pagination } from 'react-bootstrap';
import AddNewSibling from './AddNewSibling';

export default class Siblings extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: true,
      Loading: false,
      OFFSET:'0',
      LIMIT:'10',
      mSiblibsList:[],
      SIBLING_COUNT:'0',
      pagination_size:'0',
      PaginationEllipsis:'OFF',
      visible_pagination_size:12,
      mPageList:[],
      SELECTED_PAGE:1


    }
  }

  componentDidMount()
  {
    
      window.scrollTo(0, 0);
      this.getSiblingsCout();
  }

  getSiblingsCout(){
  
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getAllSiblingCount.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{school_code:SCHOOL_CODE,
      
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
            
              SIBLING_COUNT:response.data
            })
            this.state.pagination_size=Math.ceil(this.state.SIBLING_COUNT/this.state.LIMIT);
            //alert( this.state.pagination_size);
            if(this.state.pagination_size>this.state.visible_pagination_size)
            {
              this.state.PaginationEllipsis="ON";
              this.state.mPageList=[];
              for (let index = 0; index < this.state.visible_pagination_size; index++) {
                
                this.state.mPageList.push(index+1);
              }
            }
            else{
              this.state.mPageList=[];
              for (let index = 0; index < this.state.pagination_size; index++) {
                
                this.state.mPageList.push(index+1);
              }
            }
           

            this.getSiblingsList();
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }


  getSiblingsList(){
  
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getAllSiblings.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{school_code:SCHOOL_CODE,
        limit:this.state.LIMIT,
        offset:this.state.OFFSET
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            mSiblibsList:response.data
            })
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
selectPage(page_no){

     //alert(page_no);
     this.setState({
          SELECTED_PAGE:page_no,
          OFFSET: (page_no-1)*this.state.LIMIT},
       function () {
        this.getSiblingsList();
  });
}
  nextPage(){

   if(this.state.SELECTED_PAGE<this.state.pagination_size)
   {
      var page_no=this.state.SELECTED_PAGE+1
      if(this.state.SELECTED_PAGE===this.state.mPageList[this.state.mPageList.length-1])
      {
        this.state.mPageList.shift();
        this.state.mPageList.push(page_no);
      }
     
      this.setState({
            SELECTED_PAGE:page_no,
            OFFSET: (page_no-1)*this.state.LIMIT},
        function () {
          this.getSiblingsList();
      });

   }
    
 }  
 previousPage(){

  if(this.state.SELECTED_PAGE>1)
  {
     var page_no=this.state.SELECTED_PAGE-1
     if(this.state.SELECTED_PAGE===this.state.mPageList[0])
     {
       this.state.mPageList.slice(-1);
       this.state.mPageList.unshift(page_no);
     }
    
     this.setState({
           SELECTED_PAGE:page_no,
           OFFSET: (page_no-1)*this.state.LIMIT},
       function () {
         this.getSiblingsList();
     });

  }
   
}
firstPage(){

  
  var page_no=1;
  if(this.state.pagination_size>this.state.visible_pagination_size)
  {
     this.state.mPageList=[];
    for (let index = 0; index < this.state.visible_pagination_size; index++) {
      
      this.state.mPageList.push(index+1);
    }
  }
    
     this.setState({
           SELECTED_PAGE:page_no,
           OFFSET: (page_no-1)*this.state.LIMIT},
       function () {
         this.getSiblingsList();
     });

  
   
}
lastPage(){

  
  var page_no=this.state.pagination_size;
  
  if(this.state.pagination_size>this.state.visible_pagination_size)
  {
    this.state.mPageList=[];
    for (let index =(this.state.pagination_size-this.state.visible_pagination_size)+1; index <= this.state.pagination_size; index++) {
   
      this.state.mPageList.push(index);
    }
  }
 
 
  this.setState({
        SELECTED_PAGE:page_no,
        OFFSET: (page_no-1)*this.state.LIMIT},
    function () {
      this.getSiblingsList();
  });



}
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else if (this.state.Loading) {
      return <MyLoader />
    }
    else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/Siblings'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">{"Siblings("+this.state.SIBLING_COUNT+")"}</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>

                        <div className='row '>
                          <div className="col-sm-4 col-md-4"style={{padding:0}}>
                          </div>
                          <div className="col-sm-4 col-md-4 gravity_center"style={{padding:0}}>
                              <h3>{"Siblings("+this.state.SIBLING_COUNT+")"}</h3>
                          </div>
                          <div className="col-sm-4 col-md-4"style={{padding:0}}>
                          </div>
                         
                        </div>
                    

                        {this.state.mSiblibsList && this.state.mSiblibsList.map((item, index) => { 
                          var siblings_details=[];
                          siblings_details=item.siblings_details;
                      
                                           
                          return(
                          <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'15px'}}>

                            <div className="row" style={{margin:'10px'}}>
                                {siblings_details && siblings_details.map((item2, index2) => { 
                         
                                           
                               return(
                                <div className="col-sm-6 col-md-6"style={{padding:0}}>
                                <Link to={{pathname:'/AdminMain/StudentDetails/'+item2.student_enrollment_no}}>
                                <div className="row bg_card_blue_outline" style={{margin:'2px 10px 2px 10px',height:'65px'}}>
        
                                <div className="col-sm-2  bg_card_blue" style={{padding:'0px',height:'60px'}}>
                                <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'50px',width:'50px'}}>
                                  <div className="circular_image">
                                      <img  src={item2.student_img_path}  alt=""></img>
                                      <p className="student_id gravity_center"> <small> {item2.student_enrollment_no} </small></p>
                                  </div> 
                                  </div>                
                                </div>
                                <div className="col-sm-8" style={{padding:'2px',height:'60px'}}>
                                  <h6 className="mb-0 black"> {item2.stu_name} </h6>
                                  <p className="mb-0 black7"> Father: <span className='nowrap'>{item2.father_name}</span> </p>
                                  <div className='gravity_center_row'>
                                      <h6 className="black7"><span> {item2.class[0].section_id==='No'?'Class '+item2.class[0].class_name:'Class '+item2.class[0].class_name+':'+item2.class[0].section_name} </span>  </h6>
                                      <p className="mb-0 black7" style={{fontSize:'10px'}}> SRN: <span>{item2.sr_admission_no}</span> </p>
                                  
                                  </div>
                                 
                                
                                </div>
                                <div className="col-sm-2 padding_0 gravity_center"style={{height:'60px'}} >
                                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                                    <p className="mb-0 black7"> Roll No. </p>
                                    <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'25px', height:'26px',border:'2px',
                                    margin:'3px auto',fontSize:'20px',color:COLORS.white}}>{item2.class[0].student_roll_no}</div> </p>
                                   
                                  </div>
                                </div>
                                
                                
                              
                                </div>
                                </Link>
                              
                                </div> 

                      
                         )
                       })}
                          </div>   
                          </div>                 
                                            
                      
                         )
                       })}

                      
                       <div className='row '>
                          <div className="col-sm-12 col-md-12 gravity_center"style={{padding:0}}>

              

                            <Pagination size='lg'>  
                                  <Pagination.First onClick={()=>this.firstPage()}/>  
                                  <Pagination.Prev onClick={()=>this.previousPage()}/> 
                                 

                                  { this.state.mPageList &&  this.state.mPageList.map((item, index) => { 
                                    
                                    if(this.state.PaginationEllipsis==='ON')
                                    {
                                      if(index===0)
                                      {
                                        return(
                                          <>
                                          <Pagination.Ellipsis />                                          
                                          <Pagination.Item active={this.state.SELECTED_PAGE===item?true:false} onClick={()=>this.selectPage(item)}>{item}</Pagination.Item>  
                                          </>
                                        )
                                      }
                                      if(index<this.state.visible_pagination_size)
                                      {
                                        if(index===this.state.visible_pagination_size-1)
                                        {
                                          return(
                                            <>
                                             <Pagination.Item active={this.state.SELECTED_PAGE===item?true:false} onClick={()=>this.selectPage(item)}>{item}</Pagination.Item>  
      
                                            <Pagination.Ellipsis />                                          
                                            </>
                                          )
                                        }
                                        else{
                                          return(
                                         
                                            <Pagination.Item active={this.state.SELECTED_PAGE===item?true:false} onClick={()=>this.selectPage(item)}>{item}</Pagination.Item>  
                                      
                                            )
                                        }
                                        
                                      }
                                     
                                     
                                        
                                    }
                                    else{
                                      return(
                               
                                        <Pagination.Item active={this.state.SELECTED_PAGE===item?true:false} onClick={()=>this.selectPage(item)}>{item}</Pagination.Item>  
                                      
                                        )
                                    }
                                 
                                  })}
                                  <Pagination.Next  onClick={()=>this.nextPage()}/>  
                                  <Pagination.Last  onClick={()=>this.lastPage()}/>  
                            </Pagination>  
                          </div>
                          
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-12 gravity_center " >
                          <Link to={'/AdminMain/AddNewSibling'} style={{width:'100%'}}>
                              <button className="btn btn-success"  style={{fontSize:'24px', margin:'3px 2px 5px 2px',width:'100%',background:COLORS.primary_color_shade5}}> Add New Sibling </button>
                            </Link>               
                          </div>  
                         </div>


                    </div>
                  </div>
                )
              }
            } />

            <Route path='/AdminMain/AddNewSibling'  component={AddNewSibling} /> 
          </switch>
        </Router>
      )
    }
  }
}
