import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import ProgressDialog from '../Spinner/ProgressDialog';
import myToast from '../Utils/MyToast';
export default class ManageNotice extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        NoticeBoard:[]
    }
  
  }
  componentDidMount()
  {
     this.getStudentNotice();
  }
  getStudentNotice(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  
  const FUNCTION_NAME='getStudentAllNotice.php';
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
          NoticeBoard:response.data
          })
     }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
   // alert(JSON.stringify(this.state.NoticeBoard));
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  saveChanges(){
    alert("Are u sure!");
  }
deleteSure(){
var conf= window.confirm("Are you sure for delete this field..!");
if(conf==true)
{
  alert("Succefully Delete !");
}else{
  alert("Please make sure !");
}
}


updateStudentNotice=(event)=>{
  //var module_id=event.target.getAttribute('module_id');
  var status=event.target.getAttribute('status');
  var notice_id=event.target.getAttribute('notice_id');
  var mStatus;
  if(status==="Active")
  {
    mStatus="InActive";
  }
  else{
    mStatus="Active";
  }
 // alert(mStatus+notice_id);
   
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='updateStudentNotice.php?';
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
    console.log("URL>>>>>"+URL);
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        notice_id:notice_id,
        status:mStatus
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
       // alert(api_status);
       
        if(api_status=="OK")
        {
          alert("Updated Successfully");
           
         this.getStudentNotice();
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
     
    }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <ProgressDialog/>
      }else{
      return (
       
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Super Admin</a></li>
              </ul>
            </div>
            <div className="col-sm-12 col-lg-12 text-center -bg mar-t">
              <h3> Manage Notice </h3>
              </div>
          </div>    
          <div className="animated fadeInUp" style={{margin:' 30px 0px',background:'rgba(255, 255, 255, 255)', padding:'15px', border:' double rgba(18,58,188,0.75)'}}>
          {this.state.NoticeBoard.map((item, index) => (
          <div className="row" style={{borderBottom:'1px solid #74b0e3',marginBottom:'10px'}}>
                <div className="col-sm-8 col-md-8">
                  <div className="text-justify">
                  <p style={{margin:'0px', color: '#0d64b0',textTransform:'uppercase',fontWeight:'700'}}>{item.ordered_by}</p>
                 <p><span>{item.notice_date2}</span></p>
                 <h5 style={{color:'#898886'}}> Status: <small><button style={{borderRadius:'15px', border:'1px solid',color:'#6fce06'}}>{item.status}</button></small></h5>
                 <label className="switch">
                   <input type="checkbox"  checked={item.status==='Active'?true:false} onClick={this.updateStudentNotice} status={item.status} notice_id={item.notice_id}/>
                   <span className="slider round"></span>
                 </label>
                 
                </div>
                </div>
                <div className="col-sm-2 col-md-2 text-right">
                <p><button style={{width:'40px',height:'40px',borderRadius:'50%', border:'1px solid #fea223'}} data-toggle="modal" data-target="#exampleModal">
                    <img src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Edit-validated-icon.png" style={{width:'100%'}}/>
                 </button></p> 
                </div>
                <div className="col-sm-2 col-md-2">
                <p><button style={{width:'40px',height:'40px',borderRadius:'50%', border:'1px solid #fea223'}} onClick={this.deleteSure}>
                    <img src="https://pngimage.net/wp-content/uploads/2018/06/icono-eliminar-png-3.png" style={{width:'100%'}}/>
                 </button></p> 
                </div>
                </div>
                ))} 

                              {/* Add modal for update box */}

                     <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content" style={{width: '810px'}}>
      <div className="modal-header" style={{background:'#516d8b',color:'#fff'}}>
        <h5 className="modal-title" id="exampleModalLabel">Update Notice</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" style={{color:'#fff'}}>&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="row">
                                    
                                    <div className="col-sm-12 col-md-12">
                                    
                                      <div className="input-group">
                                      <h5>Add Notice:</h5>
                                        <input type="text" id="Enter_notice" value={this.state.Enter_notice} onChange={this.regChange} className="add-notice-text-p" />
                                      </div>
                                    </div>
                                    
                                    <div className="col-sm-12 col-md-12">
                                      <div className="input-group">
                                      <h5>Ordered By:</h5>
                                        <input type="text" id="Enter_ordered"  value={this.state.Enter_ordered} onChange={this.regChange} className="notice-order" />
                                      </div>

                                    </div>
                                    </div>
      </div>
      <div class="modal-footer s-pad-30">
        {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
        <button type="button" onClick={this.saveChanges} class="btn btn-primary s-change">Save changes</button>
      </div>
    </div>
  </div>
</div>
                </div>
               </div>
           

      )
    } 
  }
 
}

