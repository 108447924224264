import React, { Component } from 'react'
import axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import MyLoader from '../Spinner/MyLoader';
import myToast from '../Utils/MyToast';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';


export default class ModalAddFee extends Component {
    constructor(props){
        super(props)       
        this.state={   
          isOpen: false, 
          loggedIn:true,
          Loading:false,        
          show:true,
          FEE_NAME:'',
          FEE_TYPE:'CLASSFEE',
          IS_SPECIAL_FEE:'0'
            
        }
        this.onFeeNameChange= this.onFeeNameChange.bind(this);

      
}
onFeeNameChange(e){
  this.setState({
    FEE_NAME: e.target.value
  })
}
componentDidMount(){
  this.state.netPayableAmount=this.props.netPayableAmount;
  
}

HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action();
    
  }
  handleChangeFeeType = event => {
    this.setState({

      IS_SPECIAL_FEE: event.target.value,
    })
  };
  
  submitFeeName = () => {
    if(this.isValidInput())
    {
      
       this.addFeeName();

    }
  }
  isValidInput(){
    if(!this.state.FEE_NAME)
    {
      
      alert("Enter Fee Name!!");
      return false;
    }
    return true;
  }
  addFeeName(){
    this.setState({
      Loading:true
    })
    
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='AddNewApplicableFee.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
        var params = new URLSearchParams();
      params.append('fee_name',this.state.FEE_NAME);
      params.append('fee_type',this.state.FEE_TYPE);
      params.append('is_special_fee',this.state.IS_SPECIAL_FEE);
      params.append('school_code',SCHOOL_CODE);
   axios.post(URL,params).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
        let api_status=response.api_status;
        //alert(api_status);      
        if(api_status=="OK")
        { 
          alert("Fee Added Successfully");  
          this.props.action_submit();
  
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

    
render() {
        return (
            <div>
              <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{background:COLORS.primary_color_shade7,color:COLORS.white}}>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Add New Fee
                    </Modal.Title>
                  

                </Modal.Header>
                <Modal.Body>
            
                {this.state.Loading ? <MyLoader/> :
                <> 
                  <div className="inputBox" style={{padding:'20px 30px 20px 30px'}}>

                        <h4>Fee Name</h4>
                        <input type="text" name="fee_name"  value={this.state.FEE_NAME} onChange={this.onFeeNameChange} style={{width:'100%',height:'60px',fontSize:'22px',padding:'2px 10px 2px 10px'}}></input>

                        
                  </div> 
                  <div className="bg_card_green_outline" style={{margin:'20px 30px 20px 30px',padding:'20px 30px 20px 30px'}}>


                  <h3>Class Fee</h3>
                  <h5>(Apply on every student in class)</h5>
                  <RadioGroup defaultValue="All Student" aria-label="position" name="fee_type" id="fee_type" value={this.state.IS_SPECIAL_FEE} onChange={this.handleChangeFeeType} >
                  <FormControlLabel name='type'
                    value="0"
                    control={<Radio color="primary" />}
                    label="All Student"
                    labelPlacement="end"
                    
                  />
                  <FormControlLabel name='type'
                    value="1"
                    control={<Radio color="primary" />}
                    label="As per Admission type(New admission or readmission)"
                    labelPlacement="end"
                  />
                </RadioGroup>
       

                </div> 
                </>

                }
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" size="lg" block onClick={this.submitFeeName} style={{margin:'5px 30px 5px 30px',background:COLORS.primary_color_shade5}}>
                        Submit
                </Button>
               </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
