import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import COLORS from '../resources/colors/COLORS';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import VendorRegistration from './VendorRegistration';
import myToast from '../Utils/MyToast';
export default class MyVendor extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token"); 
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      mVendor:[]  
  }

}
componentDidMount()
{
    this.getVendorList();
}
getVendorList(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getVendorList.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
  
    let api_status=response.api_status;
    //alert(JSON.stringify(response));
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mVendor:response.data
        })
       
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (
   
  <Router>
      <switch>
        <Route path={'/AdminMain/MyVendor'}  exact render={
          ()=>{
            
      return (

          <div className="content"  style={{ background: COLORS.whitesmoke}}>
          <div className="content-header" style={{ background: COLORS.primary_color_shade5 }}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">My Vendor</a></li>
              </ul>
            </div>
          </div>               
          <div className="animated fadeInUp bg_page_grey_rounded" style={{'--mMargin':'50px',padding:'0px'}}>
          
              <div className="row">          
              
                {this.state.mVendor && this.state.mVendor.map((item, index) => ( 
                <div className="col-sm-6 col-md-6">
                
                 <div className="row bg_card_blue_outline" style={{margin:'5px 5px 10px 5px',height:'120px'}}>
                 <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'0px',height:'115px'}}>
                        <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'90px',width:'90px'}}>
                          <div className="circular_image">
                              <img  src={item.vendor_img_dir}  alt=""></img>
                              
                          </div> 
                          </div>                
                    </div>
                 <div className="col-sm-9 col-md-9">
                  <h5> {item.vendor_name} </h5>
                  <hr/>
                  <p><strong> Phone: </strong> <span>{item.phone}</span> &nbsp; <strong>Email: </strong> <span>{item.email}</span> </p>
                  <p> <strong> Address: </strong> <span>{item.address}</span> </p>
                  <p> <strong> Description: </strong> <span>{item.description}</span> </p>
                 
                 </div>
                </div>
              
                </div>
              ))}
                
                </div>
              
                  <div className="row">
                        <div className="col-sm-12"> 
                     
                        <Link to={{pathname:'/AdminMain/VendorRegistration'}} >
                          <button className="btn btn-success" style={{width:'100%',fontSize:'20px'}}>  Vendor Registration   </button>
                           </Link>           
                        
                        </div>
                    </div>
              

                </div>
               
                
              </div>
        
          )
         }
        } />
          <Route path='/AdminMain/VendorRegistration'  component={VendorRegistration} />    
              
       </switch>       
    </Router>  
   )
  }
 }
}