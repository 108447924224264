import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ReactHtmlParser from 'react-html-parser';
const Rs = '&#8377;';

export default class ModalDeleteStaffConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
    }

  }
  componentDidMount() {
   

  }

  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }

  confirmStudentDelete = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionConfirm();

  }

  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
          <div className='delete_student'>
           Total Delete Staff={this.props.staff_count}
          </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" size="lg"  block onClick={this.confirmStaffDelete}>
            Confirm
            </Button>
            <Button onClick={this.HandleClose}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}
