import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import ExamDateSheet from './ExamDateSheet';
import ExamAdmitCard from './ExamAdmitCard';
import ExamHallTicket from './ExamHallTicket';
import ExamGuideline from './ExamGuideline';
import ExamMarkingSystem from './ExamMarkingSystem';
import ExamAddMarkStudentWise from './ExamAddMarkStudentWise';
export default class ExamDetails extends Component {
    constructor(props){
        super(props)
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false, 
          EXAM_ID:'',
          SESSION_ID:'',
          EXAM_NAME:'',
          START_DATE:'',
          EXAM_STATUS:'',
          CLASS_ID:'',
          SECTION_ID:'',
          mClassList:[], 
          CLASS_SELECTED_POSITION:'0',
          SUJECT_SELECTED_POSITION:'0',
              
      }

    }
    componentDidMount()
    {
        this.state.EXAM_ID=this.props.match.params.exam_id;
        this.state.SESSION_ID=this.props.match.params.session_id;
      
        this.state.EXAM_NAME=this.props.match.params.exam_name;
        this.state.START_DATE=this.props.match.params.exam_date;
        this.state.EXAM_STATUS=this.props.match.params.exam_status;

        this.setState({
          
        })

        this.getExamParticipatingClass();
       
    }
    getExamParticipatingClass(){
      this.setState({
        Loading:true
      })
      
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getClassWithSubjectsPartAndPaper.php?';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
     
     // console.log("URL>>>>>"+URL);

      axios.get(URL,{
          params:{school_code:SCHOOL_CODE,
          exam_id:this.state.EXAM_ID,
          session_id:this.state.SESSION_ID,
          
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
         // console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
         
          if(api_status=="OK")
          {
            this.state.mClassList=response.data;
            this.setClassList(0);
           
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        //console.log("error>>>>>"+error);
        });
    
      }
      SelectSubject=(position)=>{
        //alert(position);
    
          this.getSubject(position);
        
      }
      selectClass=(position)=>{
      //alert(position);
  
        this.setClassList(position);
      
    }
    setClassList(selected_position){  
      this.state.CLASS_SELECTED_POSITION=selected_position;
        if(this.state.mClassList.length!==0)
        {
            this.state.CLASS_ID=this.state.mClassList[selected_position].class_id;
            this.state.SECTION_ID=this.state.mClassList[selected_position].section_id;

            if(this.state.SECTION_ID=="null")
            {
                this.state.SECTION_ID="No";
            }
           
        }
       
    
        this.getSubject(0);
      }
    
      getSubject(selected_position_subject){
        
      
        this.state.SUJECT_SELECTED_POSITION=selected_position_subject;
        this.state.mSubject=[];
        this.state.mSubject=this.state.mClassList[this.state.CLASS_SELECTED_POSITION].subject;
       
        if(this.state.mSubject!==null && this.state.mSubject.length!==0)
        {
          var selected_subject_id=this.state.mSubject[selected_position_subject].subject_id;  
          this.state.selected_subject=this.state.mSubject[selected_position_subject].subject_name;    
          this.getSubjectPaper(selected_subject_id);
        }
        else{
         
          this.setState({
            mSubject:[],
            mSubjectPaper:[]
          })
        }  
       
      }
      getSubjectPaper(selected_subject_id){
      
        this.state.mSubjectPaper=[];
        var mAllSubjectPaper=this.state.mClassList[this.state.CLASS_SELECTED_POSITION].subject_paper;
        //alert(JSON.stringify(mAllSubjectPaper));
        if(mAllSubjectPaper!==null && mAllSubjectPaper.length!==0)
        {
          //alert(JSON.stringify(mAllSubjectPaper));
  
          for (let index = 0; index < mAllSubjectPaper.length; index++) {
            const element = mAllSubjectPaper[index];
            if(element.subject_id===selected_subject_id)
            {
              this.state.mSubjectPaper.push(element);
            }
            
          }
        }
        
        this.setState({
  
        })
        
        
      }
      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

            return (             
                <Router>
                <switch>
                  <Route path={'/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_status'}  exact render={
                    ()=>{
                      
                 return (
                <div className="content">
                    <div className="content-header">
                            <div className="leftside-content-header">
                            <ul className="breadcrumbs">
                                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Exam Details</a></li>
                            </ul>
                            </div>
                    </div>  
                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px',padding:'20px'}}>     
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px'}}>

                    <div className="col-sm-6 col-md-6 gravity_center" style={{padding:'0px 2px 5px 2px'}}>
                       <h3>{"Exam Name:"+this.state.EXAM_NAME+"("+this.state.EXAM_STATUS+")"}</h3>
                    </div>
                    <div className="col-sm-6 col-md-6 gravity_center" style={{padding:'0px 2px 5px 2px'}}>
                    <h3>{"Exam Date:"+this.state.START_DATE}</h3>
                       </div>
                   
                      </div>
                    <div style={{padding:'0px'}}><h5>Participating Class</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px'}}>
                    {this.state.mClassList.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>


                 <div className="bg_card_blue_outline" style={{margin:'10px 0px 5px 0px'}}>
                 <div style={{padding:'5px'}}><h5>Participating Subject</h5></div>
                  <div className="row " style={{padding:'0px 5px 5px 5px'}}>
                    {this.state.mSubject && this.state.mSubject.map((item2, index2) => (
                    <div className="col-sm-2 col-md-2" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.SUJECT_SELECTED_POSITION===index2?"bg_class_select gravity_center":"bg_white_outline_grey gravity_center"} onClick={e=>this.SelectSubject(index2)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:18}}> {item2.subject_name} </p>
                        </div>
                    </div>
                    ))}
                   </div>
                   </div>

                   <div className="bg_card_blue_outline" style={{margin:'10px 0px 5px 0px'}}>
                 <div style={{padding:'5px'}}><h5>Subject Paper</h5></div>
                  <div className="row " style={{padding:'0px 5px 5px 5px'}}>
                    {this.state.mSubjectPaper && this.state.mSubjectPaper.map((item3, index3) => (
                    <div className="col-sm-6 col-md-6" style={{padding:'0px 2px 5px 2px'}}>
                        <div className="bg_corner_round_grey gravity_center_vertical"  style={{cursor:'pointer'}}> 
                           <p style={{fontSize:18}}> {item3.exam_paper_name} </p>
                          
                           <p style={{fontSize:15}}> {item3.is_mark_system==="1"?"Marking Type: Mark System(Max Mark-"+item3.max_mark+")":"Marking Type: Grade System(Max Grade-"+item3.max_grade+")"} </p>
                        </div>
                    </div>
                    ))}
                   </div>
                   </div>
                  
                    <div style={{padding:'0px'}}><h5>Quick Links</h5></div>

                    <div className="row bg_card_blue_outline">
               

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamAdmitCard/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE+"/"+this.state.EXAM_STATUS}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.card_peach,'--myBorderColor':COLORS.whitesmoke,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.card_burnt_orange,fontSize:'15px'}}>Max Mark<br/>Settings</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamAddMarkStudentWise/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.card_peach,'--myBorderColor':COLORS.whitesmoke,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.card_burnt_orange,fontSize:'18px'}}>Add<br/>Mark</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamMarkingSystem/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.card_peach,'--myBorderColor':COLORS.whitesmoke,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.card_burnt_orange,fontSize:'18px'}}>View<br/>Mark</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamDateSheet/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME}}>
                                <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.light_blue4,'--myBorderColor':COLORS.light_blue2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Date<br/>Sheet</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamGuideline/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.light_blue4,'--myBorderColor':COLORS.light_blue2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'16px'}}>Exam<br/>Guideline</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>
                          
                         <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamHallTicket/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.EXAM_STATUS}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.light_blue4,'--myBorderColor':COLORS.light_blue2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Hall<br/>Ticket</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>
                          
                    </div>
                    <div className="row bg_card_blue_outline">
                  

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamAdmitCard/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.EXAM_STATUS}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Admit<br/>Card</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamResult'}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Exam<br/>Result</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamMarkingSystem/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Report<br/>Card</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>
                          
                    </div>
                    </div> 
            
               </div>
  
            )
          }
          } />
            <Route path='/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name'  component={ExamDateSheet} />
            <Route path='/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name'  component={ExamGuideline} />
            <Route path='/AdminMain/ExamAdmitCard/:exam_id/:session_id/:exam_name/:exam_status'  component={ExamAdmitCard} />
            <Route path='/AdminMain/ExamHallTicket/:exam_id/:session_id/:exam_name/:exam_status'  component={ExamHallTicket} />
            <Route path='/AdminMain/ExamMarkingSystem/:exam_id/:session_id'  component={ExamMarkingSystem} />
            <Route path='/AdminMain/ExamAddMarkStudentWise/:exam_id/:session_id/:exam_name/'  component={ExamAddMarkStudentWise} />
           
                 
    </switch>       
   </Router>  
    )
  }
 }
 }