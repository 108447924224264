import React, { Component, createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';


export default class MdlAddSection extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();
    this.slt_class = createRef();
    this.slt_section = createRef();


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      mSessionList: [],
      SESSION_SELECTED_POSITION: 0

    }

  }

  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }


  SelectSession = (index) => {
    this.setState({
      SESSION_SELECTED_POSITION: index,
    })
  }
  HandleSelectedSession = () => {
    //alert("okk"+student_id);
    var session_id = this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].session_id;
    var session_name = this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].session_name;
    var session_status = this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].status;
    this.props.action2(session_id, session_name, session_status);

  }

  componentDidMount() {
    this.getSessionList();
  }


  getSessionList() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getSessionList.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        //alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mSessionList: response.data,

          })

          //alert(JSON.stringify(this.state.mSessionList));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }

  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Select Session
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :
                <div className="row">
                  <div className="col-md-12">
                    <div className='row'>
                      <div className="col-md-4 padding_0 admission_prvform1">
                        <label className="">Add Session</label>
                      </div>
                      <div className="col-md-8 padding_0">
                        <div className="input-group admission_form2">
                          <input type="text" required id="" variant="outlined" placeholder="Add Session" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>

                 


                </div>
              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <Button variant="primary" size="lg" block onClick={this.HandleSelectedSession}>
                  Done
                </Button>
              </div>

            </Modal.Footer>
          </Modal>
        </div>

      )
    }
  }
}

