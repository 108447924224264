import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';

import myToast from '../Utils/MyToast';
import StaffRegistration from '../cms/StaffRegistration';
import StaffProfile from '../cms/StaffProfile';
export default class StaffManagement extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mStaffList: []
    }

  }
  componentDidMount() {
    this.StaffList();
  }
  StaffList() {
    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getStaff.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: { school_code: SCHOOL_CODE }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mStaffList: response.staff

          })
          //  alert(JSON.stringify(this.state.mStaffList));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {
      return (
        <Router>
          <switch>
            <Route path='/AdminMain/StaffManagement' exact render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Staff List</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
                      <h4 className='staffcenter'>STAFF LIST</h4>
                      <div className="row class-li">
                        {this.state.mStaffList && this.state.mStaffList.map((item, index) => (
                          <div className="col-sm-6 col-md-6 padding_5 ">
                            <Link to={{ pathname: '/AdminMain/StaffProfile/'+item.staff_id,}}>
                              <div className="row class-list-box bb-r-c height-130 bg_card_burnt_orange_outline">
                                <div className="col-sm-3 cl-3-part p-0 gravity_center" style={{ background:'#FCEDDA' }}>
                                  <div className="profil-im-img" style={{ background: 'rgb(219 238 246)', width: '110px', height: '110px', margin: '0 auto', padding: '0px' }}>
                                    <div><img src={item.staff_image_path} alt="" style={{ with: '100%', borderRadius: '50%' }} /></div>
                                  </div>
                                </div>
                                <div className="col-sm-9 col-md-9 text_burnt">
                                  <h5 className=''> {item.staff_name} </h5>
                                  <hr />
                                  <p><strong> Joining Date: </strong> <span>{item.joining_date}</span> &nbsp; <strong> Department Name: </strong> <span>{item.department_name}</span> </p>
                                  <p> <strong> Staff Role: </strong> <span>{item.staff_role}</span> </p>

                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>

                      <div className=" row">
                        <div className="col-md-12 padding_0">
                          <center>
                            <Link to={{ pathname: '/AdminMain/StaffRegistration' }}>
                              <button className="btn btn-primary" onClick={this.regSubmit} style={{ margin: '2% 0px', fontSize: '24px', width: '100%' }}> Add Staff </button>
                            </Link>
                          </center>
                        </div>
                      </div>
                    </div>


                  </div>
                )
              }
            } />
            <Route path='/AdminMain/StaffRegistration' component={StaffRegistration} />
            <Route path='/AdminMain/StaffProfile/:Staff_id' component={StaffProfile} />
          </switch>

        </Router>

      )
    }
  }

}
