import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import COLORS from '../resources/colors/COLORS';
import ExamAddSingleStudentMark from './ExamAddSingleStudentMark';
export default class  ExamStudentResult extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        mStudentMark:[],
        EXAM_ID:'',
        EXAM_DATE:'',
        EXAM_NAME:'',
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        STUDENT_ID:'',
        total_obt_mark:0,
        total_mark:0

       
    }
  
  }
  componentDidMount()
  {
      window.scrollTo(0, 0)
      this.state.EXAM_ID=this.props.match.params.exam_id;
      this.state.SESSION_ID=this.props.match.params.session_id;
      this.state.CLASS_ID=this.props.match.params.class_id;
      this.state.SECTION_ID=this.props.match.params.section_id;
      this.state.EXAM_DATE=this.props.match.params.exam_date;
      this.state.EXAM_NAME=this.props.match.params.exam_name;
      this.state.STUDENT_ID=this.props.match.params.student_id;
      
      this.getStudentResult();
  }
  getStudentResult(){
   
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getStudentExamResult.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
    params:{
      student_id:this.state.STUDENT_ID,
      session_id:this.state.SESSION_ID,
      exam_id:this.state.EXAM_ID,
      class_id:this.state.CLASS_ID,
      section_id:this.state.SECTION_ID,
      school_code:SCHOOL_CODE,
      }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));     
      if(api_status=="OK")
      {
      
         this.setState({
          
            mStudentMark:response.data
          })
         //alert(JSON.stringify(this.state.mStudentMark));  
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
 

    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
     
        return (             
          <Router>
          <switch>
            <Route path={'/AdminMain/ExamStudentResult/:exam_id/:session_id/:student_id/:class_id/:section_id/:exam_name/:exam_date'}  exact render={
              ()=>{
                
           return (
            <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Student Marks</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'100px',padding:'0px'}}>
          <div className="bg_primery_t10_b30" style={{height:'150px'}}>
              <h5 style={{padding:'5px',color:COLORS.white}}> {'Exam: '+this.state.EXAM_NAME}</h5>        
              <h6 style={{padding:'5px',color:COLORS.white}}>{'Exam DATE: '+this.state.EXAM_DATE}</h6>        
                  
          </div>
         
          
          <section className="bg_white_t30_b10" style={{marginTop:'-80px',marginLeft:'0px',marginRight:'0px'}}>
             
              {                                
              (() => {
                if(this.state.mStudentMark && this.state.mStudentMark.length!==0)
                {  
                  this.state.total_obt_mark=0;
                  this.state.total_mark=0;
                  var mResult= this.state.mStudentMark[0]['result'];      
                  if(mResult && mResult.length!==0)
                  {
                      var mSubject=[];
                      mSubject=mResult[0]['subjects'];
                    
                  }           
                 return(
                 <div className="row">                   
                   <div className="col-sm-12 col-md-12 p-0">  

                   <div className="row " style={{margin:'5px 40px 10px 40px',height:'120px'}}>
                   <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'0px',height:'115px'}}>
                   <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'110px',width:'110px'}}>
                   <div className="circular_image">
                      <img  src={this.state.mStudentMark[0].student_img_path}  alt=""></img>
                      <p className="student_id gravity_center"> <small> {this.state.mStudentMark[0].student_enrollment_no} </small></p>
                   </div>  
                   </div>               
                 </div>
                 <div className="col-sm-6 bg_card_blue_outline" style={{padding:'5px 10px',height:'115px'}}>
                  <h5> {this.state.mStudentMark[0].stu_name} </h5>
                  <p className="mb-0"> Father: <span>{this.state.mStudentMark[0].father_name}</span> </p>
                  <p className="mb-0"> Board: <span>{this.state.mStudentMark[0].class[0].board_name}</span> </p>
                  <p className="mb-0"> Medium: <span>{this.state.mStudentMark[0].class[0].medium_name}</span> </p>
                 
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center">
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'0px 2px 0px 2px',height:'115px'}}>
                    <p className="mb-0"> Roll No. </p>
                    <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'60px', height:'60px',border:'2px',
                     margin:'2px auto',fontSize:'30px',color:COLORS.white}}>{this.state.mStudentMark[0].class[0].student_roll_no}</div> </p>
                    <h6>Class:<span> {this.state.mStudentMark[0].class[0].class_name} </span>  </h6>
                  </div>
                 </div>
              
                </div>
                  
                    <div className="row" style={{margin:'5px 40px 10px 40px'}}>
                          <div className="col-sm-6 col-md-6 ">
                           
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'100%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Previus Student </button>
                                      
                          </div>
                          <div className="col-sm-6 col-md-6 ">
                           
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Next Student </button>
                                     
                          </div>    
                   </div>
              
                </div> 
                <div  className="row" style={{margin:'5px 40px 10px 40px',width:'100%'}}>
                    <table className="table table-striped" >
                      <thead class="thead-light">
                            <th >Subject Name</th>
                            <th>Subject Paper</th>
                            <th>Obtained Mark</th>
                            <th>Total Mark</th>
                      </thead>
                      <tbody>
                      {mSubject && mSubject.map((item, index) => { 
                        var mSubjectPaperMark=[];
                        mSubjectPaperMark=item.subject_paper;
                        return(
                          <tr>
                            <td>{item.subject_name}</td>
                            <td colspan="3">
                                <table className="table" >
                                <tbody>
                                {mSubjectPaperMark && mSubjectPaperMark.map((item2, index2) => { 
                                    var  TAG_TRUE="1";
                                    var obtained_mark='';
                                    var total_mark='';
                                    var isMarkSystem=item2.is_mark_system;
                                    var isGradeSystem=item2.is_grade_system;                           
                                    var mMark=[];
                                    mMark=item2.mark;
                                  
                                    if(mMark && mMark.length!==0)
                                    {
                                      if (isMarkSystem==TAG_TRUE)
                                      {
                                          obtained_mark=mMark[0]['obtained_mark'];
                                          total_mark=mMark[0]['max_mark'];
                                          this.state.total_obt_mark=this.state.total_obt_mark+Number(obtained_mark);
                                          this.state.total_mark=this.state.total_mark+Number(total_mark);
                                         
                                       }
                                       if (isGradeSystem==TAG_TRUE) {
  
                                          obtained_mark=mMark[0]['grade_obtained'];
                                          total_mark=mMark[0]['max_grade'];
                                        }
                                    }
                                  return(
                                    <tr>
                                    <td>{item2.exam_paper_name}</td>
                                    <td>{obtained_mark}</td>
                                    <td>{total_mark}</td>
                                    </tr>
                                    )
                                })}
                              </tbody>
                              </table>
                            </td>
                           
                          </tr>
                        )
                      })}
                        
                      </tbody>
                      <thead class="thead-light">
                            <th >Total</th>
                            <th></th>
                            <th>{this.state.total_obt_mark}</th>
                            <th>{this.state.total_mark}</th>
                      </thead>
                  </table>

                </div>
                    
                  </div>
                 )

                }
                                             
              })()
            }      
           
           </section>           
                                                                   
         </div>
      </div>
           
           )
          }
          } />
            <Route path='/AdminMain/ExamAddSingleStudentMark'  component={ExamAddSingleStudentMark} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }
