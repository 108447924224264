import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import ExamDateSheet from './ExamDateSheet';
import ExamAdmitCard from './ExamAdmitCard';
import ExamHallTicket from './ExamHallTicket';
import ExamGuideline from './ExamGuideline';
import ExamMarkingSystem from './ExamMarkingSystem';
import StudentMarkDetails from './StudentMarkDetails';
import ExamAddSingleStudentMark from './ExamAddSingleStudentMark';

export default class ClassMarkStatus extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mExamData: [],
      mSubjects: [],
      mClassSubjectPaper: [],
      mClassSubjectPart: [],
      EXAM_STATUS_UPCOMMING: '1',
      EXAM_STATUS_OVER: '3',
      EXAM_STATUS_LIVE: '2',
      EXAM_ID: '',
      SESSION_ID: '',
      EXAM_NAME: '',
      EXAM_DATE: '',
      CLASS_SELECTED_POSITION: 0,
      SELECTED_POSITION_SUBJECT: 0,
      strSUBJECT_PART: ''

    }

  }

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/ClassMarkStatus'} exact render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Class Mark Status</a></li>
                        </ul>
                      </div>

                    </div>

                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>
                      <div className='bg_card_blue1'>
                        <h4 className='subject_2'>Select Class</h4>
                        <div className="row" style={{ padding: '10px 15px 10px 15px' }}>

                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>Play Group</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>Nursery</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>LKG</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>UKG</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>I</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>II</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>III</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>IV</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>V</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>VI</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>VII</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>VIII</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>IX</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>X</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>XI</p>
                            </div>
                          </div>
                          <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                            <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                              <p style={{ fontSize: 14 }}>XII</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h5> Students Count (06) </h5>
                      <div className='row'>

                        <div className="col-sm-6 col-md-6 padding_5">
                          <div className='bg_white_outline_blue1'>
                            <div className="row bg_card_blue_outline" style={{ margin: '5px 15px 10px 15px', height: '90px' }}>
                              <div className="col-sm-3  bg_card_blue gravity_center" style={{ padding: '1px', height: '85px' }}>
                                <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                                  <div className="circular_image">
                                    <img src='#' alt=""></img>
                                    <p className="student_id gravity_center"> <small> {'122'} </small></p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6" style={{ padding: '5px 10px', height: '85px' }}>
                                <h5> {'xyz'} </h5>
                                <p className="mb-0"> Father: <span>{'xyz'}</span> </p>
                                <p className="mb-0" style={{ fontSize: '10px' }}><span>{'xyz'}</span> </p>
                              </div>
                              <div className="col-sm-3 padding_0 gravity_center" style={{ height: '85px' }}>
                                <div className="text-center bg_card_blue_outline width_fill_parent" style={{ margin: '2px' }}>
                                  <p className="mb-0"> Roll No:{'xyz'} </p>
                                  <p> <div className="st-list-roll-n" style={{
                                    background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                                    margin: '2px auto', fontSize: '20px'
                                  }}>
                                    <input type="checkbox" id="issue_notice_c" class="ch-in" value="" /></div>
                                  </p>
                                  <h6><span> {'xyz'} </span>  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="row" style={{ padding: '0px 15px 0px 15px' }}>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>Maths</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>हिंदी</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>Marathi</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>

                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>All Subject</p>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="col-sm-6 col-md-6">
                                <button className="btn btn-success" style={{ cursor: 'pointer', width: '100%', margin: '', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Student Result</button>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <button className="btn btn-success" style={{ cursor: 'pointer', width: '100%', margin: '', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Edit Mark</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 padding_5">
                          <div className='bg_white_outline_blue1'>
                            <div className="row bg_card_blue_outline" style={{ margin: '5px 15px 10px 15px', height: '90px' }}>
                              <div className="col-sm-3  bg_card_blue gravity_center" style={{ padding: '1px', height: '85px' }}>
                                <div className="gravity_center" style={{ padding: '0px', margin: '2px', height: '80px', width: '80px' }}>
                                  <div className="circular_image">
                                    <img src='#' alt=""></img>
                                    <p className="student_id gravity_center"> <small> {'122'} </small></p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6" style={{ padding: '5px 10px', height: '85px' }}>
                                <h5> {'xyz'} </h5>
                                <p className="mb-0"> Father: <span>{'xyz'}</span> </p>
                                <p className="mb-0" style={{ fontSize: '10px' }}><span>{'xyz'}</span> </p>
                              </div>
                              <div className="col-sm-3 padding_0 gravity_center" style={{ height: '85px' }}>
                                <div className="text-center bg_card_blue_outline width_fill_parent" style={{ margin: '2px' }}>
                                  <p className="mb-0"> Roll No:{'xyz'} </p>
                                  <p> <div className="st-list-roll-n" style={{
                                    background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                                    margin: '2px auto', fontSize: '20px'
                                  }}>
                                    <input type="checkbox" id="issue_notice_c" class="ch-in" value="" /></div>
                                  </p>
                                  <h6><span> {'xyz'} </span>  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="row" style={{ padding: '0px 15px 0px 15px' }}>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>Maths</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>हिंदी</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>Marathi</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>English</p>
                                </div>
                              </div>

                              <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }}>
                                <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                                  <p style={{ fontSize: 14 }}>All Subject</p>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="col-sm-6 col-md-6">
                                <Link to={'/AdminMain/StudentMarkDetails'}>
                                  <button className="btn btn-success" style={{ width: '100%', margin: '', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Student Result</button>
                                </Link>
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <Link to={'/AdminMain/ExamAddSingleStudentMark'}>
                                  <button className="btn btn-success" style={{ cursor: 'pointer', width: '100%', margin: '', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Edit Mark</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )
              }
            } />
            <Route path='/AdminMain/StudentMarkDetails' component={StudentMarkDetails} />
            <Route path='/AdminMain/ExamAddSingleStudentMark' component={ExamAddSingleStudentMark} />
          </switch>
        </Router>
      )
    }
  }
}