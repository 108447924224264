import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import ExamAddSingleStudentMark from './ExamAddSingleStudentMark';
import ExamClassResult from './ExamClassResult';
import ClassMarkStatus from './ClassMarkStatus';
export default class ExamPaperSettings extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mExamData: [],
      mSubjects: [],
      mClassSubjectPaper: [],
      mClassSubjectPart: [],
      EXAM_STATUS_UPCOMMING: '1',
      EXAM_STATUS_OVER: '3',
      EXAM_STATUS_LIVE: '2',
      EXAM_ID: '',
      SESSION_ID: '',
      EXAM_NAME: '',
      EXAM_DATE: '',
      CLASS_SELECTED_POSITION: 0,
      SELECTED_POSITION_SUBJECT: 0,
      strSUBJECT_PART: ''
    }
    //  this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Result Status</a></li>
              </ul>
            </div>

          </div>

          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>
            <div className='bg_card_blue1'>
              <h4 className='subject_2'>Select Class</h4>
              <div className="row" style={{ padding: '10px 15px 10px 15px' }}>

                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>Play Group</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>Nursery</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>LKG</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>UKG</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>I</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>II</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>III</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>IV</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>V</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>VI</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>VII</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>VIII</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>IX</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>X</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>XI</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>XII</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className='bg_white_outline_blue1'>
              <h4 className='subject_2'>Select Subject</h4>
              <div className="row" style={{ padding: '0px 15px 0px 15px' }}>

                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>Maths</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>हिंदी</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>Marathi</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>English</p>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }}>
                  <div className={this.state.CLASS_SELECTED_POSITION === 0 ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"} style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>All Subject</p>
                  </div>
                </div>

              </div>
            </div>
            <div>

              <div className='row'>
                <div className='col-md-12 padding_0 mar_top'>
                  <div className='bg_card_primary_outline padding_5'>
                    <h4>Subject Paper</h4>
                    <div className='row bg_card_grey'>
                      <div className='col-md-4 subject_4 mar_top'>
                        Paper Name:English
                      </div>
                      <div className='col-md-4 subject_4 mar_top'>
                        (Max Mark-50)
                      </div>
                      <div className='col-md-4'>
                      <div className="">
                        <button className="btn_font_size btn-success" style={{ height: '40px', width: '100%', margin: '10px 0px 15px 0px', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Delete Paper</button>
                      </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-sm-12 col-md-12 padding_0">
                        <button className="btn_font_size btn-success" style={{ height: '40px', width: '100%', margin: '10px 0px 15px 0px', background: COLORS.primary_color_shade5, border: COLORS.primary_color_shade5 }}>Create New Paper</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div >

      )


    }
  }
}
