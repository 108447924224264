import React, { Component ,createRef} from 'react'

import '../css/Admin_css/admin-customize.css';
import StudentInformation from '../ChildComponent/StudentInformation';
import PaymentHistory from './PaymentHistory';
import PaymentDetails from './PaymentDetails';
import TestModal from '../dialog/TestModal';

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Button, Form} from 'react-bootstrap'
import DueFeeCollection from '../ChildComponent/DueFeeCollection';
import PaymentStatus from './PaymentStatus';

import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import FeeCard from './FeeCard';
import AdvancedPayment from './AdvancedPayment';
import MdlSelectSingleStudent from '../dialog/MdlSelectSingleStudent';
import ModalFeeConfirmation from '../dialog/ModalFeeConfirmation';
import FeeOtherAmount from './FeeOtherAmount';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import MdlSearchStudent from '../dialog/MdlSearchStudent';

export default class FeeCollection extends Component {
  constructor(props) {
    super(props)        
    this.slt_board=createRef();
    this.slt_medium=createRef();
    
   
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpenStudentSelector: false, 
        isOpenStudentSearch: false, 
        loggedIn:true,
        Loading:false, 
        showStudentInfo:false,
        
        showDueFee:false,
        isfeeSubmit:false,
        status:false,
        mClassData:[],
        mClassBoard:[],
        mClassMedium:[], 
        mFilteredClassSectionList:[],  
        mFilteredClassList:[],
        mStudentDetails:[],
        mStudentClass:[],
        mStudentFeeDetails:[],
        
        ENROLLMENT_NO:'',
        ADHAAR_NO:'',
        SESSION_ID:'',
        SESSION_NAME:'',
        TRANSACTION_ID:'',
        current_due:''
        
    }
    
    this.onStudentIdChange= this.onStudentIdChange.bind(this);
    this.onAdhaarChange= this.onAdhaarChange.bind(this);
    this.handlerFeeSubmit = this.handlerFeeSubmit.bind(this);
    this.handlerSettlementRequest = this.handlerSettlementRequest.bind(this);
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleClose = this.HandleClose.bind(this);
    this.HandleCloseStudentSearch = this.HandleCloseStudentSearch.bind(this);
    this.HandleSelectedSessionPayDtls = this.HandleSelectedSessionPayDtls.bind(this);
    
    
  }
  componentDidUpdate(){    
    window.onpopstate = e => {
      // alert("k");
       this.getStudentFeeDetails();
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onStudentIdChange(e){
    this.setState({
      ENROLLMENT_NO: e.target.value
    })
}

onAdhaarChange(e){
    this.setState({
      ADHAAR_NO: e.target.value
    })
}

setCurrentDue = (current_due) => {
  this.setState({
    current_due:current_due,
    showStudentInfo:true
  })

  //alert(current_due);
}
getStudentFeeDetailsBySearch = () => {
   var mSession=SessionManager.getCurrentSession();
  //alert(JSON.stringify(mSession));
  var session_id=mSession[0].session_id;
  var session_name=mSession[0].session_name;

  this.state.SESSION_ID=session_id;
  this.state.SESSION_NAME=session_name;

  //alert(JSON.stringify(this.state.SESSION_ID));
 this.getStudentFeeDetails();
}
    // This method will be sent to the child component
handlerFeeSubmit(status,transaction_id) {
  
  this.state.ENROLLMENT_NO=this.state.mStudentDetails[0].student_enrollment_no;
  this.state.SESSION_ID=this.state.mStudentDetails[0].class[0].session_id;
  this.state.TRANSACTION_ID=transaction_id;
  
       this.setState({
          isfeeSubmit: true,
          status: status
      }); 
     // this.props.history.push('/PaymentStatus/'+this.state.ENROLLMENT_NO+'/'+this.state.SESSION_ID+'/'+this.state.SESSION_NAME+'/'+this.state.TRANSACTION_ID);
  }
  handlerSettlementRequest() {

       this.getStudentFeeDetails();
       
}

  HandleShow = () => {

    this.setState({
      isOpenStudentSelector: !this.state.isOpenStudentSelector
    });   
  }
  
  HandleClose = () => {

    this.setState({
      isOpenStudentSelector:false
    });  
     
  }
  HandleShowStudentSearch = () => {
  
    this.setState({
      isOpenStudentSearch: !this.state.isOpenStudentSearch
    });   
  }
  
  HandleCloseStudentSearch = () => {

    this.setState({
      isOpenStudentSearch:false
    });  
     
  }
  

  HandleSelectedStudent = (student_id,selected_item,session_id,session_name) => {
    this.state.ENROLLMENT_NO=student_id;
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.setState({
      
      isOpenStudentSelector:false,
      isOpenStudentSearch:false
    });
    
    this.getStudentFeeDetails();
    
  }
  
  HandleSelectedSessionPayDtls = (session_id,session_name) => {
  
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.setState({
      
      isOpenStudentSelector:false
    });
    this.getStudentFeeDetails();
    
  }
  

getStudentFeeDetails = () => {
  
this.setState({
  Loading:true,
  mStudentDetails:[],
  mStudentFeeDetails:[],
  showStudentInfo:false,
  showDueFee:false,
  isfeeSubmit:false

})

const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getStudentFeeDetails.php?';
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
console.log("URL>>>>>"+URL);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID,
    enrollment_no:this.state.ENROLLMENT_NO,
    aadhar_no:this.state.ADHAAR_NO
  },
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
   // console.log("Response2>>>>>"+JSON.stringify(response));
    let api_status=response.api_status;
   //alert(JSON.stringify(response));
    
    if(api_status=="OK")
    {
  
       this.setState({  
        showDueFee:true,      
        mStudentDetails:response.data,  
        mStudentFeeDetails:response.data[0].fee_details,
       
          
        })
        //console.log("mStudentDetails>>>>>"+JSON.stringify(this.state.mStudentDetails)); 
        //console.log("mStudentFeeDetails>>>>>"+JSON.stringify(this.state.mStudentFeeDetails));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
  
}

getStudentFeeDetailsByFetch=()=>{
  
  this.setState({
    Loading:true,
    mStudentDetails:[],
    mStudentFeeDetails:[],
    showStudentInfo:false,
    showDueFee:false
  
  })
  
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const student_id=this.state.ENROLLMENT_NO;
const FUNCTION_NAME='getStudentFeeDetails.php?';
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME+"school_code="+SCHOOL_CODE+"&session_id="+SESSION_ID+"&enrollment_no="+student_id+"&aadhar_no="+"null";
console.log("URL>>>>>"+URL);
const options = {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
  }

  /* body: JSON.stringify({
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID,
    enrollment_no:this.state.ENROLLMENT_NO,
    aadhar_no:this.state.ADHAAR_NO
  }) */
};

fetch(URL, options)
  .then(res => res.json())
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
    console.log("Response2>>>>>"+JSON.stringify(response));
   // alert(JSON.stringify(response));
      
      let api_status=response.api_status;
      
      if(api_status=="OK")
      {
    
         this.setState({       
          mStudentDetails:response.student,
          showStudentInfo:true,
          mStudentFeeDetails:response.student[0].fee_details,
          showDueFee:true,
            
          })
       // console.log("mStudentFeeDetails>>>>>"+JSON.stringify(this.state.mStudentFeeDetails));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
}
  render() {

    
    if( this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else if(this.state.Loading)
    {
    return <MyLoader/>
    }
    else if(this.state.isfeeSubmit)
    {
        return <Router>
          
                  <Redirect to={'/AdminMain/PaymentStatus/'+this.state.ENROLLMENT_NO+'/'+this.state.SESSION_ID+'/'+this.state.SESSION_NAME+'/'+this.state.TRANSACTION_ID }/>
                  <Route path='/AdminMain/PaymentStatus/:student_id/:session_id/:session_name/:transaction_id'  component={PaymentStatus} />       
      
              </Router>
       
       // return <PaymentStatus student_id={this.state.ENROLLMENT_NO} session_id={this.state.SESSION_ID} session_name={this.state.SESSION_NAME} transaction_id={this.state.TRANSACTION_ID}></PaymentStatus>
    }
    else{
    return (

      
      <Router>
        <switch>
          <Route path='/AdminMain/FeeCollection'  exact render={
            ()=>{
          return(
            <div className="content">
               <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Fee Collection</a></li>
                </ul>
              </div>  
            </div>
            
                         
            <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{'--mMargin':'80px'}}>
            
            <div className='' style={{margin:'0px 20px'}}>
            <div className="row">
                <div className="col-sm-6 col-lg-6 text-center" >
                    <div onClick={this.HandleShow} style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                         <h4 className="arrow"> Select Students Manually</h4>
                    </div>
                    
              </div>
              <div className="col-sm-6 col-lg-6 text-center" >
                    <div onClick={this.HandleShowStudentSearch} style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                        <h4 className="arrow"> Search By Name</h4>
                    </div>
                    
              </div>
            </div>
            <div className="row">
                 <div className="col-sm-12 col-md-12 gravity_center">
                    <div className="text-center">
                                     {/* <!-- Button trigger modal --> */} 
                        <h4 className="en-adhar" style={{color:'#bebbb7',paddingTop:'10px'}}> Search By Enrollment No </h4>
                    </div>
                  </div>
            </div>
        
          <div className="row">
              <div className="col-sm-6 col-md-6">
               
                <Form.Group className='match_parent gravity_center'>
                   
                   <Form.Control  placeholder="Enrollment No." value={this.state.ENROLLMENT_NO} onChange={this.onStudentIdChange}style={{  padding:'2px',margin:'0px',fontSize: '16px', width: '100%' }} />
                  
                  </Form.Group>
                  
              </div>
              
              <div className="col-sm-6 col-md-6">
                <Form.Group className='match_parent gravity_center'>
                   
                    <Button className="search-text col-xs-3" onClick={this.getStudentFeeDetailsBySearch} style={{border:'none',cursor:'pointer',background:'#007bff', width: '100%',margin:'10px 0px 5px 0px'}}> SEARCH </Button>
              
                  </Form.Group>
              </div> 
          </div>
          </div>
  
            {this.state.showStudentInfo===true? <StudentInformation showStudentInfo={this.state.showStudentInfo} mStudentDetails={this.state.mStudentDetails} current_due={this.state.current_due}></StudentInformation>:''} 
            
            {this.state.showDueFee===true? <DueFeeCollection showDueFee={this.state.showDueFee} FeeDetails={this.state.mStudentFeeDetails} mStudentDetails={this.state.mStudentDetails} action={this.handlerFeeSubmit} action2={this.handlerSettlementRequest} action_session_pay_dtls={this.HandleSelectedSessionPayDtls} action_set_current_due={this.setCurrentDue}></DueFeeCollection>:''} 
            
            {this.state.isOpenStudentSelector===true? <MdlSelectSingleStudent action={this.HandleClose} action2={this.HandleSelectedStudent}></MdlSelectSingleStudent>:''}
            {this.state.isOpenStudentSearch===true? <MdlSearchStudent actionClose={this.HandleCloseStudentSearch} actionDone={this.HandleSelectedStudent}></MdlSearchStudent>:''}
           
  
                   </div>
                 </div>
              )
            }
           } />
    
                
            <Route path='/AdminMain/AdvancedPayment/:student_id/:session_id/:current_due'  component={AdvancedPayment} />       
            <Route path='/AdminMain/PaymentHistory/:student_id/:session_id/:session_name'  component={PaymentHistory} />       
            <Route path='/AdminMain/FeeCard'  component={FeeCard} />       
            <Route path='/AdminMain/FeeOtherAmount'  component={FeeOtherAmount} /> 
           
        </switch>
        
       </Router>

      
      )
    }


      
  }
 
}

