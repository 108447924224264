import React, { Component, createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactHtmlParser from 'react-html-parser';
const Rs = '&#8377;';

export default class MdlEditStudentFee extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      mSessionList: [],
      FEE_VALUE: '',
      FEE_NAME: '',

    }

  }
  onSubjectNameChange(e) {
    this.setState({
      FEE_VALUE: e.target.value
    })
  }
  componentDidMount() {

    this.setState({
      FEE_VALUE: this.props.fee_value,
      FEE_NAME: this.props.fee_name
    })
    // alert(this.state.SUBJECT_ID)
  }
  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action_close();

  }
  HandleSubmit = () => {
    //this.updateSubjectName();
    this.setState({
      show: !this.state.show
    });
    this.props.action_done(this.state.FEE_VALUE);

    //alert("Add new class");

  }


  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton style={{background:COLORS.primary_color_shade7,color:COLORS.white}}>
              <Modal.Title id="contained-modal-title-vcenter">
                Edit Student Fee
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :

                <>
                <div className="row">
                    <div className="col-sm-5 col-md-5" style={{padding:'10px'}}>
                    <div className='bg_card_blue_outline gravity_center_vertical' style={{height:'100px'}}>
                                      
                      <div  className='gravity_center'>
                          <h3>Fee Name</h3>
                      </div>
                      <div  className='gravity_center'>
                          <h4>{this.state.FEE_NAME}</h4>
                      </div>
                      </div>

                                             
                    </div>
                    <div className="col-sm-7 col-md-7" style={{padding:'10px'}}>
                    <div className='bg_card_blue_outline' style={{height:'100px'}}>
                     
                      <div  className='gravity_center'>
                          <h3>Fee value</h3>
                      </div>
                     
                      <div  className='gravity_center'>
                      <Form.Group className='match_parent padding_10'>
                   
                           <Form.Control className="gravity_center" value={this.state.FEE_VALUE} onChange={(e) => this.onSubjectNameChange(e)} placeholder="Fee Value" style={{  padding:'5px',margin:'0px',fontSize: '21px', width: '100%' }} />
                          </Form.Group>
                      </div>
                     
                        
                      </div>
                          
                    </div>
                  </div>
               
                </>
              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <Button variant="primary" size="lg" onClick={this.HandleSubmit}>
                  Done
                </Button>
            
              </div>

            </Modal.Footer>
          </Modal>
        </div>

      )
    }
  }
}


