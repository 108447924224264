import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
import MdlViewStudentAttendance from '../dialog/MdlViewStudentAttendance';
import MdlEditStudentAttendance from '../dialog/MdlEditStudentAttendance';
import MdlEditStudentRemark from '../dialog/MdlEditStudentRemark';
import Iframe from 'react-iframe';
export default class ExamReportCardCrossList extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
      
        mExamMarkingSystem:[],
        mExamParticipatingClass:[],
        mMarkingSystem:[],
        mMarkingSystemValue:[],

        mSamples:[],
                mSettings:[],
                mColorOption:[],
                iFrameHeight:'0px',
                SELECTED_SAMPLE_ID:'',
                SELECTED_SAMPLE_POSITION:0,
                SELECTED_COLOR_OPTION_POSITION:0,
                SELECTION_TYPE:'',
                FILE_URL:'',
                FILE_NAME:'',
                FILE_DIRECTORY:'',
                SELECTION_TYPE:'Class',
      
        EXAM_ID:'',
        STUDENT_ID:'',
        REPORT_CARD_ID:'',
        REPORT_CARD_TYPE:'ANNUAL',
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        CLASS_SELECTED_POSITION:0,
       

    }
   
  
  }
  
  componentDidMount()
  {
  
    this.state.REPORT_CARD_ID=this.props.match.params.report_card_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
      
  
    window.scrollTo(0, 0); 
    this.getMarkingSystemData();  
    
  }

  getMarkingSystemData(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamMarkingSystem.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
      exam_id:"",
      report_card_type:'ANNUAL',
      report_card_id:this.state.REPORT_CARD_ID,
    
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
       //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
            mExamMarkingSystem:response.data,
                   
          })
          if(this.state.mExamMarkingSystem.length!==0)
          {
            this.state.EXAM_ID=this.state.mExamMarkingSystem[0]['concerned_exams'];

          }
         

          this.getParticipatingClass();
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  getParticipatingClass()
  {
      
    this.setState({         
      mExamParticipatingClass:this.state.mExamMarkingSystem[0]['participating_class'],
    })
    this.setClassList(0);
  }
  selectClass=(position)=>{  
    this.setClassList(position);
    
  }
  setClassList(selected_position){      
    if(this.state.mExamParticipatingClass.length!==0)
    {
        this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
        this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;

        if(this.state.SECTION_ID=="null")
        {
            this.state.SECTION_ID="No";
        }
        this.state.CLASS_SELECTED_POSITION=selected_position;

    }
 
    this.state.mMarkingSystem=this.state.mExamParticipatingClass[selected_position].marking_system;
    this.state.mMarkingSystemValue=this.state.mMarkingSystem[0].MarkingSystemValue;
    //alert(this.state.MANUAL_ATTENDANCE);

    this.setState({

    })
    this.getPrintableSamples();
    
   }

  
getPrintableSamples(){
    this.setState({
      Loading:true
    })
    var document_type="CROSS_LIST";
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getPrintableSample.php?';
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
   // alert(URL);
    console.log("URL>>>>>"+URL);

    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        document_type:document_type,
        
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        //console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
        //alert(JSON.stringify(response));
       
        if(api_status=="OK")
        {
        
           this.setState({
              mSamples:response.data,
             
            })
            //alert("mSamples>>>>>"+JSON.stringify(this.state.mSamples));
            this.setSample(this.state.SELECTED_SAMPLE_POSITION);
         
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
  
    }
    setSample(selected_sample_position){
     
      var sample_id=this.state.mSamples[selected_sample_position].sample_id;
      this.state.FILE_NAME=this.state.mSamples[selected_sample_position].file_name;
      this.state.FILE_DIRECTORY=this.state.mSamples[selected_sample_position].file_directory;
      this.state.SELECTED_SAMPLE_ID=sample_id;

    //  alert(JSON.stringify(this.state.SELECTED_SAMPLE_ID));
      this.setFileUrL();
      
    }
  
setFileUrL(){
var class_id="";
var section_id="";
var session_id="";
const SCHOOL_CODE=SessionManager.getSchoolCode();



this.state.FILE_URL=process.env.REACT_APP_SERVER_NAME+this.state.FILE_DIRECTORY+this.state.FILE_NAME+'?&class_id='+this.state.CLASS_ID+'&section_id='+this.state.SECTION_ID+'&session_id='+this.state.SESSION_ID+'&sample_id='+this.state.SELECTED_SAMPLE_ID+'&report_card_id='+this.state.REPORT_CARD_ID+'&report_card_type='+this.state.REPORT_CARD_TYPE+'&selection_type='+this.state.SELECTION_TYPE+'&school_code='+SCHOOL_CODE;
console.log("FILE_URL",this.state.FILE_URL);
//alert(this.state.FILE_URL);
this.setState({
showPreview:true  
});

}   

changeSample(position){

this.setState({
  SELECTED_SAMPLE_POSITION:position
  }, () => {
   
    // alert(this.state.SELECTED_SAMPLE_POSITION);
     this.setSample(this.state.SELECTED_SAMPLE_POSITION);
});


}
changeColorOption(position){


this.setState({
  SELECTED_COLOR_OPTION_POSITION:position
  }, () => {
   
    // alert(this.state.SELECTED_SAMPLE_POSITION);
     this.setColorOptions(this.state.SELECTED_COLOR_OPTION_POSITION);
});


}

    
  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return  <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamReportCardCrossList/:report_card_id/:session_id/'}  exact render={
            ()=>{
              
         return (
       
        <div className="content" style={{background:COLORS.whitesmoke}}>
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#">Exam Result  Register</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
        
          <div style={{padding:'0px 15px 0px 15px'}}><h5>Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
            </div>


            <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Sample</h5>
            </div>
            
            <div className="row bg_card_grey_outline" style={{margin:'0px 10px 0px 10px',height:'140px'}}>
                
            {this.state.mSamples && this.state.mSamples.map((item, index) => (

              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent" >
                    <div className={this.state.SELECTED_SAMPLE_POSITION==index? 'bg_card_green_outline cursor':'bg_card_grey_outline cursor'} style={{padding:'5px',color:'#fff',margin:'5px 10px 0px 10px',height:'110px'}} onClick={e=>this.changeSample(index)}>                 
                      <div className="gravity_center">
                          <img src={item.sample_img} class="img_logo" />
                      </div>
                    
                    </div>
                    <div className="gravity_center">
                      {item.sample_name}
                    </div>                   
                     
              </div>
            ))}

            </div>


       
            <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Preview</h5>
            </div>
        <div className="row"  style={{margin:'0px 10px 0px 10px'}}> 
              {this.state.showPreview ? <div><MyLoader/></div> 
                      
                      :''}                   
                <Iframe url={this.state.FILE_URL}
                      
                        width="100%"
                        height="2500px"
                        overflow="visible"
                        id="iFrame"
                        className="bg_card_primary_outline"
                        display="initial"
                        position="relative"                      
                        frameBorder="0"
                      
                        onLoad={() => {
                        // alert("loaded");
                         //this.autoResize();
                    
                          this.setState({
                            showPreview:false,
                            
                          })
                      }} 
                      />
              
              </div> 
    
               


               
            </div>
           
            
            {this.state.isOpenViewStudentAttendance===true? <MdlViewStudentAttendance selected_student={this.state.SELECTED_STUDENT} action={this.HandleCloseViewStudentAttendance}></MdlViewStudentAttendance>:''}

            {this.state.isOpenEditStudentAttendance===true? <MdlEditStudentAttendance selected_student={this.state.SELECTED_STUDENT} action={this.HandleCloseEditStudentAttendance}></MdlEditStudentAttendance>:''}
          
         
     </div>


           
           )
          }
          } />
             <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }