import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import SendNotification from './SendNotification';
import myToast from '../Utils/MyToast';
export default class Notification extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mNotification: []
    }

  }
  componentDidMount() {
    this.Notification();
  }
  Notification() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    const Lim = "30";
    const Offset = "0";
    const FUNCTION_NAME = 'getAllNotifications.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: { session_id: SESSION_ID, limit: Lim, offset: Offset, school_code: SCHOOL_CODE }
    }).then(res => res.data)
      .then((response) => {
        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mNotification: response.notification
          })
          console.log("response>>>>>" + JSON.stringify(this.state.mNotification));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {
      return (
        <Router>
          <switch>
            <Route path='/AdminMain/Notification/:module_id' exact render={
              () => {

                return (

                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Notification </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <p className="text-justify">
                            These Notification are given from students Parents As Suggestion,query and feedback about school and information.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {this.state.mNotification.map((item, index) => (

                          <div className="col-sm-6 col-md-6" style={{ margin: '0px' }}>

                            <h5 className="text-justify"> <span style={{ color: '#0288D1' }}> {item.date_added} </span> </h5>
                            <div style={{ border: '2px solid gray', borderRadius: '18px', padding: '10px 40px', background: '#fff' }}>
                              <h4><span className=""> {item.notification_title} </span> </h4>
                              <p> {item.notification} </p>
                              <h6 className="text-right"><span style={{ color: '#898686' }}>  Regards:  {item.department}  </span>  </h6>
                              <p><span style={{ color: '#969997' }}> Recipient: {item.recipient_id} </span></p>
                            </div>

                          </div>

                        ))}
                      </div>

                      <div className="row mar_top">

                        <div className="col-md-12">
                          <Link to={{ pathname: '/AdminMain/SendNotification' }}>
                            <button type="button" class="btn btn-primary w-100 text-medium"> Send Message </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            } />

            <Route path='/AdminMain/SendNotification' component={SendNotification} />

          </switch>

        </Router>
      )
    }
  }
}
