import React, { Component ,createRef} from 'react'

import '../css/Admin_css/admin-customize.css';
import StudentInformation from '../ChildComponent/StudentInformation';
import PaymentHistory from './PaymentHistory';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import PaymentStatus from './PaymentStatus';

import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import FeeCard from './FeeCard';
import FeeOtherAmount from './FeeOtherAmount';
import myToast from '../Utils/MyToast';
import DueFeeCollectionAdvance from '../ChildComponent/DueFeeCollectionAdvance';

export default class AdvancedPayment extends Component {
  constructor(props) {
    super(props)        
    this.slt_board=createRef();
    this.slt_medium=createRef();
    
   
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpen: false, 
        loggedIn:true,
        Loading:false, 
        showStudentInfo:false,
        
        showDueFee:false,
        isfeeSubmit:false,
        status:false,
        mClassData:[],
        mClassBoard:[],
        mClassMedium:[], 
        mFilteredClassSectionList:[],  
        mFilteredClassList:[],
        mStudentDetails:[],
        mStudentClass:[],
        mStudentFeeDetails:[],
        
        ENROLLMENT_NO:'',
        ADHAAR_NO:'',
        SESSION_ID:'',
        SESSION_NAME:'',
        TRANSACTION_ID:'',
        current_due:''
        
    }
    
    this.handlerFeeSubmit = this.handlerFeeSubmit.bind(this);
    this.handlerSettlementRequest = this.handlerSettlementRequest.bind(this);
  
    
  }
  componentDidUpdate(){    
    window.onpopstate = e => {
     
       
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.state.SESSION_ID=this.props.match.params.session_id;  
    this.state.SESSION_NAME=this.props.match.params.session_name;  
    this.state.ENROLLMENT_NO=this.props.match.params.student_id;  
    this.state.current_due=this.props.match.params.current_due;  
   //alert( this.state.current_due);
    this.getStudentFeeDetails();
  }

    // This method will be sent to the child component
handlerFeeSubmit(status,transaction_id) {
  
  this.state.ENROLLMENT_NO=this.state.mStudentDetails[0].student_enrollment_no;
  this.state.SESSION_ID=this.state.mStudentDetails[0].class[0].session_id;
  this.state.TRANSACTION_ID=transaction_id;
  
       this.setState({
          isfeeSubmit: true,
          status: status
      }); 
     
  }
  handlerSettlementRequest() {

       this.getStudentFeeDetails();
       
}
  

getStudentFeeDetails = () => {
  
this.setState({
  Loading:true,
  mStudentDetails:[],
  mStudentFeeDetails:[],
  showStudentInfo:false,
  showDueFee:false,
  isfeeSubmit:false,
 

})

const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getStudentFeeCard.php?';
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
console.log("URL>>>>>"+URL);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID,
    enrollment_no:this.state.ENROLLMENT_NO,
    
  },
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(JSON.stringify(response));
    
    if(api_status=="OK")
    {
  
       this.setState({       
        mStudentDetails:response.data.student_details,
        showStudentInfo:true,
        mStudentFeeDetails:response.data.fee_details,
        showDueFee:true,
          
        })
      //console.log("mStudentFeeDetails>>>>>"+JSON.stringify(this.state.mStudentFeeDetails));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
  
}


  render() {

    
    if( this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else if(this.state.Loading)
    {
    return <MyLoader/>
    }
    else if(this.state.isfeeSubmit)
    {
      return <Router>
      <Redirect to={'/AdminMain/PaymentStatus/'+this.state.ENROLLMENT_NO+'/'+this.state.SESSION_ID+'/'+this.state.SESSION_NAME+'/'+this.state.TRANSACTION_ID }/>
      <Route path='/AdminMain/PaymentStatus/:student_id/:session_id/:session_name/:transaction_id'  component={PaymentStatus} />       

  </Router>
    }
    else{
    return (

      
      <Router>
        <switch>
          <Route path='/AdminMain/AdvancedPayment/:student_id/:session_id/:current_due'  exact render={
            ()=>{
          return(
            <div className="content">
               <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Advanced Payment</a></li>
                </ul>
              </div>  
            </div>
            
                         
            <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{'--mMargin':'80px'}}>
            
  
            {this.state.showStudentInfo===true? <StudentInformation showStudentInfo={this.state.showStudentInfo} mStudentDetails={this.state.mStudentDetails}></StudentInformation>:''} 
            
            {this.state.showDueFee===true? <DueFeeCollectionAdvance showDueFee={this.state.showDueFee} FeeDetails={this.state.mStudentFeeDetails} mStudentDetails={this.state.mStudentDetails} current_due={this.state.current_due} action={this.handlerFeeSubmit} action2={this.handlerSettlementRequest} ></DueFeeCollectionAdvance>:''} 
           
                   </div>
                 </div>
              )
            }
           } />
    
            <Route path='/AdminMain/PaymentStatus'  component={PaymentStatus} />       
              
            <Route path='/AdminMain/PaymentHistory/:student_id/:session_id'  component={PaymentHistory} />       
            <Route path='/AdminMain/FeeCard'  component={FeeCard} />       
            <Route path='/AdminMain/FeeOtherAmount'  component={FeeOtherAmount} />       
      
        </switch>
        
       </Router>

      
      )
    }


      
  }
 
}

