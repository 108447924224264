import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import COLORS from '../resources/colors/COLORS';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import AddProduct from './AddProduct';
import myToast from '../Utils/MyToast';
export default class AccessoriesList extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token"); 
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }
  this.state={

    loggedIn:true,
    Loading:false, 
    mAccessoriesCategory:[],
    mAllAccessoriesProduct:[],
    mclassNamed:[]
}

}
componentDidMount()
{
this.getAccessoriesCategory();
this.getAllAccessoriesProduct();
}
getAccessoriesCategory(){

this.setState({
Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getAccessoriesCategory.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
//  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
params:{
  school_code:SCHOOL_CODE
}
}).then(res => res.data)
.then((response) => {

this.setState({ 
  Loading:false
 })
  console.log("Response2>>>>>"+response);
  let api_status=response.api_status;
 // alert(api_status);
  
  if(api_status=="OK")
  {
  
     this.setState({
      
      mAccessoriesCategory:response.data
      })
      console.log("response>>>>>"+this.state.mAccessoriesCategory);
  }
  else{
    let api_remark=response.api_remark;
    myToast.notify_warning(api_remark);
  }

//console.log("response>>>>>"+api_status)
}).catch((error)=>{
this.setState({ 
  Loading:false
 })
console.log("error>>>>>"+error);
});
}

getAllAccessoriesProduct(){

  this.setState({
  Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getAllAccessoriesProduct.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)  
  axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE
  }
  }).then(res => res.data)
  .then((response) => {
  
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mAllAccessoriesProduct:response.data,
        mclassNamed:response.data[0].for_class
        })
        console.log("response>>>>>"+this.state.mAllAccessoriesProduct);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }
  
  //console.log("response>>>>>"+api_status)
  }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
  }

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (

      <div className="content">
      <div className="content-header" style={{ background: COLORS.green6 }}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Accessories List</a></li>
          </ul>
        </div>
      </div>
      <section className="exam-section-1" style={{background: COLORS.green6}}>
              <div className="row class-li">
                {this.state.mAccessoriesCategory.map((item,index)=>(
              <div className="col-sm-1 col-md-1">
               <div className="exam-p-setting-cls"> <p> {item.category_name} </p></div>
                </div>
))}
                </div>
                </section> 
                <section className="mt-4" style={{background:'rgb(203 215 255 / 75%)'}}>
                <div className="row">
                  {this.state.mAllAccessoriesProduct.map((item,index)=>(
                <div className="col-sm-12 col-md-12 mb-2">
                <Link to={{pathname:'/'}}>
                 <div className="row">
                 <div className="col-sm-2 card-class-w text-center">
                     <div style={{background:'#008000'}}>
                     <p className="m-0 text-white">In Stock </p>
                     <h1 className="text-white"> {item.in_stock} </h1>
                     <p className="m-0 text-white" style={{background: COLORS.green7}}>Details</p>
                     </div>
                  </div>
                 <div className="col-sm-10 text-center">
                  <h5> {item.category_name} ({item.product_name}) </h5>
                  <div className="row class-li">
                  
                 {this.state.mclassNamed.map((item2,index) =>(
              <div className="col-sm-1 col-md-1">
               <div className="exam-p-setting-cls"> <p> {item2.class_name} </p></div>
                </div>
                )) }
                </div>
                 </div>
                </div>
                </Link>
                </div>  
                   ))}
                </div>
                </section>   
                   
    
            <section>
                    <div className="row mt-4">
<div className="col-sm-12"> 
<h3> <Link to={{pathname:'/AdminMain/AddProduct'}} class="btn btn-block text-white" style={{fontSize:'15px',background: COLORS.green7,textDecoration:'none',padding:'10px'}}> <span className="clf"> Add More Product </span>  </Link>  </h3>
               
</div>
                    </div>
                </section>
                <Router>
             <switch>
               <Route path={'/AdminMain/AccessoriesList'} render={
                 ()=>{
              
              }
            } />
        
                  <Route path='/AdminMain/AddProduct'  component={AddProduct} />
                         
            </switch>
            
           </Router>
           </div>
     
      )
      
  }
 
}
}
