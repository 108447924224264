import React, { Component } from 'react'
import {Modal, Table} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import Axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import MyLoader from '../Spinner/MyLoader';


export default class MdlEditStudentAttendance extends Component {
    constructor(props){
        super(props)       
        this.state={   
            show:true,
            Loading:false,
            netPayableAmount:0,
            SELECTED_STUDENT:'',
            mAttendance:[],
            total_working_days:'',
            total_present:''
            
        }
      
}
componentDidMount(){

  this.state.SELECTED_STUDENT=this.props.selected_student;
  this.state.mAttendance=this.state.SELECTED_STUDENT.attendance_report;
  //alert(JSON.stringify(this.state.mAttendance));
  this.setState({

  })
  
}
handleChangeWorkingDays(position,event){
  this.state.mAttendance[position]['working_days'] = event.target.value
  this.state.total_working_days=0;
  this.state.total_present=0;
  this.setState({

  })
 
 
}
handleChangeAttendance(position,event){
  this.state.mAttendance[position]['present'] = event.target.value
  this.state.total_working_days=0;
  this.state.total_present=0;
  this.setState({

  })
 
 
 
}

HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action();
    
  }
 
  SubmitAttendance = () => {
    
    this.setState({
      Loading:true,
    
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mStaffData=SessionManager.getStaffDetails();
  var STAFF_ID=mStaffData.staff_id;

  const str_attendance_report=JSON.stringify(this.state.mAttendance);
  
  const FUNCTION_NAME='updateStudentAttendanceReport.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  
  Axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      staff_id:STAFF_ID,
      attendance_report:str_attendance_report,
      
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      //console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
       
        myToast.notify_warning("Updated Successfully");     
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }

    
render() {
        return (
            <div>
              <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       Student Attendance Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                <Table striped bordered hover  style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th>Month</th>
                              <th style={{ textAlign: "center" }}>Working Days</th>
                              <th style={{ textAlign: "center" }}>Attendance</th>
                             
                          
                            </tr>
                          </thead>
                          <tbody>
                         
                             
                              {this.state.mAttendance && this.state.mAttendance.map((item,index)=>{
                                 this.state.total_working_days=Number(this.state.total_working_days)+Number(item.working_days);
                                 this.state.total_present=Number(this.state.total_present)+Number(item.present);
                             return(   
                              <tr>    
                              <td>{item.month2}</td>                  
                              <td style={{ textAlign: "center" }}><input type="text"   value={item.working_days} onChange={(e) => this.handleChangeWorkingDays(index, e)} style={{width:'60%',height:'30px',fontSize:'16px',padding:'2px'}}></input></td>
                              <td style={{ textAlign: "center" }}><input type="text"  value={item.present} onChange={(e) => this.handleChangeAttendance(index, e)} style={{width:'60%',height:'30px',fontSize:'16px',padding:'2px'}}></input></td>
                             
                              </tr>
                                                        
                              )
                              })}
                             
                             
                              
                         
                            <tr>
                            <th>Total</th>
                            <td style={{ textAlign: "center" }}>{this.state.total_working_days}</td>
                            <td style={{ textAlign: "center" }}>{this.state.total_present}</td>
                            </tr>

                          </tbody>
                        </Table>
                </div>
                {this.state.Loading?<div><MyLoader/></div>:''}
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" size="lg" block onClick={this.SubmitAttendance}>
                        Submit
                </Button>
                    
               </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
