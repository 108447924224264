import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import { blue } from '@material-ui/core/colors';
import myToast from '../Utils/MyToast';
import axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import { Pagination } from 'react-bootstrap';
import MdlSelectSingleStudent from '../dialog/MdlSelectSingleStudent';
import MdlSelectMultipleStudent from '../dialog/MdlSelectMultipleStudent';


export default class AddNewSibling extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: true,
      Loading: false,
      SingleStudentSelector:false,
      MultipleStudentSelector:false,
    
      mSiblibsList:[],
      mSelectedStudent:'',
      mSiblingsDetails:[],
      mSelectedMulipleStudent:[],
      ENROLLMENT_NO:'',
      SESSION_ID:'',
      SESSION_NAME:''

  
    }
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleClose = this.HandleClose.bind(this);

    this.HandleSelectedMultipleStudent = this.HandleSelectedMultipleStudent.bind(this);
    this.HandleCloseMultipleStudentSelector = this.HandleCloseMultipleStudentSelector.bind(this);
  }
  componentDidMount()
  {
    
      window.scrollTo(0, 0);
      
  }

  HandleShow = () => {

    this.setState({
      SingleStudentSelector: !this.state.SingleStudentSelector
    });   
  }
  
  HandleClose = () => {

    this.setState({
      SingleStudentSelector:false
    });  
     
  }
  

  HandleSelectedStudent = (student_id,selected_item,session_id,session_name) => {
    this.state.ENROLLMENT_NO=student_id;
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.setState({
      
      SingleStudentSelector:false,
      mSelectedStudent:selected_item,
    
   
    });
    this.state.mSiblingsDetails=[];
    this.getStudentSiblings();
    
  }

  HandleCloseMultipleStudentSelector = () => {

    this.setState({
      MultipleStudentSelector:false
    });  
     
  }

  HandleSelectedMultipleStudent = (mSelectedSibling) => {
   // alert(JSON.stringify(mSelectedStudent));
    //var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
    if(mSelectedSibling==null){
      mSelectedSibling=[];
    }
    this.state.MultipleStudentSelector=false;
    this.state.mSelectedMulipleStudent=mSelectedSibling;
     
    this.addNewSibling();

  }
  SelectStudent=()=>{
   
    this.setState({
    
      MultipleStudentSelector:true,
    
    })
   
  }
  

  

  getStudentSiblings(){
  
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
   
    const FUNCTION_NAME='getStudentSiblings.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        enrollment_no:this.state.ENROLLMENT_NO,
   
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        //alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            mSiblingsDetails:response.data[0].siblings_details
            })
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

    addNewSibling(){
      this.setState({
        Loading:true
      })
      var SIBLINGS;
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      for (let index = 0; index < this.state.mSelectedMulipleStudent.length; index++) {
        const element = this.state.mSelectedMulipleStudent[index];
        if(index===0)
        {
            SIBLINGS=element.student_enrollment_no;
        }
        else{
          SIBLINGS=SIBLINGS+","+element.student_enrollment_no;
        }
        
      }
      
      const FUNCTION_NAME='AddStudentSibling.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
          var params = new URLSearchParams();
        params.append('student_id', this.state.ENROLLMENT_NO);
        params.append('siblings', SIBLINGS);
  
        params.append('school_code',SCHOOL_CODE);
     axios.post(URL,params).then(res => res.data)
    .then((response) => {
      this.setState({ 
         Loading:false
        })
          let api_status=response.api_status;
          //alert(api_status);      
          if(api_status=="OK")
          {   
            myToast.notify_success("Siblings Added Successfully");
            //this.props.history.push("/AdminMain/ClassFee");
            this.getStudentSiblings();
    
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
       
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else if (this.state.Loading) {
      return <MyLoader />
    }
    else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/AddNewSibling'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">{"Add New Siblings"}</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '100px' }}>

                    <div className="row" style={{margin:'5px 20px'}}>
                        <div className="col-sm-12 col-lg-12 text-center" onClick={this.HandleShow} style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                            <h4 className="arrow"> Select Student</h4>
                      </div>
                    </div>


                  {this.state.mSelectedStudent.length===0?<></>:<>
                    <div className='bg_card_blue_outline' style={{margin:'5px 20px'}}>
                   
                    <div className="row" style={{margin:'5px 5px 5px 5px',height:'100px'}}>

                    <div className="col-sm-2  bg_card_blue gravity_center" style={{padding:'0px',height:'95px'}}>
                    <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                      <div className="circular_image">
                          <img  src={this.state.mSelectedStudent.student_img_path}  alt=""></img>
                          <p className="student_id gravity_center"> <small> {this.state.mSelectedStudent.student_enrollment_no} </small></p>
                      </div> 
                      </div>                
                    </div>
                    <div className="col-sm-8" style={{padding:'2px',height:'95px'}}>
                      <h5 className="mb-0 black"> {this.state.mSelectedStudent.stu_name} </h5>
                      <p className="mb-0 black7"style={{fontSize:'15px'}}> Father: <span className='nowrap'>{this.state.mSelectedStudent.father_name}</span> </p>
                      <p className="mb-0"><span>{"Village/Town:"+this.state.mSelectedStudent.village_town}</span> </p>
                       <p className="mb-0" style={{fontSize:'10px'}}> SRN: <span>{this.state.mSelectedStudent.sr_admission_no}</span> </p>
                  
                 
                    
                    </div>
                    <div className="col-sm-2 padding_0 gravity_center">
                      <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px',height:'95px'}}>
                        <p className="mb-0 black7"> Roll No. </p>
                        <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'40px', height:'40px',border:'2px',
                        margin:'3px auto',fontSize:'20px',color:COLORS.white}}>{this.state.mSelectedStudent.student_roll_no}</div> </p>
                        <h6 className="black7"><span> {this.state.mSelectedStudent.section_id==='No'?this.state.mSelectedStudent.class_name:this.state.mSelectedStudent.class_name+":"+this.state.mSelectedStudent.section_name} </span>  </h6>
                      </div>
                    </div>
                    
           
                      </div>

               </div>  


               <div className='row '>
                  <div className="col-sm-4 col-md-4"style={{padding:0}}>
                  </div>
                  <div className="col-sm-4 col-md-4 gravity_center"style={{padding:0}}>
                      <h3>{"Siblings Already"}</h3>
                  </div>
                  <div className="col-sm-4 col-md-4"style={{padding:0}}>
                  </div>
                  
                </div>
                <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'2px 20px 5px 20px'}}>
  
                {this.state.mSiblingsDetails.length===0?<>
                  <div className="row gravity_center" style={{margin:'10px'}}>
                  <div className="col-sm-4 col-md-4"style={{padding:0}}>
                  </div>
                  <div className="col-sm-4 col-md-4 gravity_center"style={{padding:0}}>
                  <h4>No Sibling Found!</h4>
                  </div>
                  <div className="col-sm-4 col-md-4"style={{padding:0}}>
                  </div>
                      
                  </div>
                </>:
                <div className="row" style={{margin:'10px'}}>
                  {this.state.mSiblingsDetails && this.state.mSiblingsDetails.map((item2, index2) => { 
                                                                  
                  return(
                  <div className="col-sm-6 col-md-6"style={{padding:0}}>
                  
                  <div className="row bg_card_blue_outline" style={{margin:'2px 10px 2px 10px',height:'65px'}}>

                  <div className="col-sm-2  bg_card_blue" style={{padding:'0px',height:'60px'}}>
                  <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'50px',width:'50px'}}>
                    <div className="circular_image">
                        <img  src={item2.student_img_path}  alt=""></img>
                        <p className="student_id gravity_center"> <small> {item2.student_enrollment_no} </small></p>
                    </div> 
                    </div>                
                  </div>
                  <div className="col-sm-8" style={{padding:'2px',height:'60px'}}>
                    <h6 className="mb-0 black"> {item2.stu_name} </h6>
                    <p className="mb-0 black7"> Father: <span className='nowrap'>{item2.father_name}</span> </p>
                    <div className='gravity_center_row'>
                        <h6 className="black7"><span> {item2.class[0].section_id==='No'?'Class '+item2.class[0].class_name:'Class '+item2.class[0].class_name+':'+item2.class[0].section_name} </span>  </h6>
                        <p className="mb-0 black7" style={{fontSize:'10px'}}> SRN: <span>{item2.sr_admission_no}</span> </p>
                    
                    </div>
                    
                  
                  </div>
                  <div className="col-sm-2 padding_0 gravity_center"style={{height:'60px'}} >
                    <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                      <p className="mb-0 black7"> Roll No. </p>
                      <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'25px', height:'26px',border:'2px',
                      margin:'3px auto',fontSize:'20px',color:COLORS.white}}>{item2.class[0].student_roll_no}</div> </p>
                      
                    </div>
                  </div>
                               
                  </div>
                             
                  </div> 

        
                        )
                       })}
                          </div>   

                     }
                   </div> 

                    <div className="row" style={{margin:'5px 20px'}}>
                        <div className="col-sm-12 col-lg-12 text-center" onClick={this.SelectStudent} style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                            <h4 className="arrow"> Add New SibLings</h4>
                      </div>
                    </div>  
                  </>
              }

              


                      
             {this.state.SingleStudentSelector===true? <MdlSelectSingleStudent action={this.HandleClose} action2={this.HandleSelectedStudent}></MdlSelectSingleStudent>:''}
             {this.state.MultipleStudentSelector===true? <MdlSelectMultipleStudent actionClose={this.HandleCloseMultipleStudentSelector} actionHandleSelected={this.HandleSelectedMultipleStudent}></MdlSelectMultipleStudent>:''}

           

                    </div>
                  </div>
                )
              }
            } />

          
          </switch>
        </Router>
      )
    }
  }
}
