import React, { Component, useRef, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import eduok from '../resources/images/EduOk.png'
import SessionManager from '../Utils/SessionManager';
import SelectClass from '../ChildComponent/SelectClass';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Assign_homework from './Assign_homework';
export default class HomeWork extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      classBoard: [],
      classMedium: [],
      ClassSection: [],
      mFilteredClassList: [],
      mFilteredClassSectionList: [],
      mClassData: [],
      CLASS_SELECTED_POSITION: 0,
      mOldDueList: [],
      mFilteredOldDueList: [],
    }

  }
  onBoardChange = (event) => {
    //this.setState({ value: event.target.value });
    //let board_id=event.target.selectedOptions[0].getAttribute('board_id');

    this.getClassList();


  };
  onMediumChange = (event) => {
    this.getClassList();
  }
  getClassList() {

    let board_id = this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id = this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    //filtering class of selected board and medium
    var mFilteredClassList = [];
    this.state.mFilteredClassSectionList = [];
    for (var i = 0; i < this.state.mClassData.length; i++) {
      let item = this.state.mClassData[i];
      if (item.board_id === board_id && item.medium_id === medium_id) {

        mFilteredClassList.push(item);

      }
    }
    //console.log("ClassList>>>>>>",mFilteredClassList); 
    //getting section of classes
    for (var i = 0; i < mFilteredClassList.length; i++) {
      let item = mFilteredClassList[i];
      let SECTION = item.section;
      if (SECTION === "No") {

        this.state.mFilteredClassSectionList.push(item);
      }
      else {

        var tempSection = [];
        tempSection = item.section;
        for (var j = 0; j < tempSection.length; j++) {
          let item2 = tempSection[j];
          item2["board_id"] = board_id;
          item2["board_name"] = item.board_name;
          item2["medium_id"] = medium_id;
          item2["medium_name"] = item.medium_name;
          //console.log("item2>>>>>>",item2);                
          this.state.mFilteredClassSectionList.push(item2);

        }
      }

    }
    this.setState({

    })
  }

  componentDidMount() {

    this.getClass();
  }
  getClass() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    //alert(SESSION_ID);

    const FUNCTION_NAME = 'getClass.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mClassData: response.response.class,
            mClassBoard: response.response.board,
            mClassMedium: response.response.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  handleDropdownChange(e) {
    alert("hhh");
    // console.log("ggggggggggggggg>>>>>>>>>>");
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/HomeWork/:module_id'} exact render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header" style={{ background: COLORS.primary_color_shade5 }}>
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" style={{ color: COLORS.white }} /><a href="#"> Home Work </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUp bg_page_white_rounded" style={{ '--mMargin': '50px' }}>
                      <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>
                      <hr />
                      <div className='row mar_top bg_card_deep_purple_outline'>
                        <div className='col-md-6 mar_top1'>
                          <div className='row'>
                            <div className="col-md-4 padding_0  admission_prvform1">
                              <label className=""> Date</label>
                            </div>
                            <div className="col-md-8 padding_0">
                              <div className="input-group date admission_form2" id="stAdmissionDate">
                                <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 home_work'>
                          <h4><span className='fee_border'>Sunday</span> </h4>
                        </div>

                      </div>
                      <hr />
                      <section className="bg-white mt-4">
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <h4 className="p-4"> Home Work Status </h4>
                          </div>
                          <div className="col-sm-3 col-md-3">
                            <div className="row class-list-box" style={{ border: '1px solid #fea223', borderRadius: '10px' }}>
                              <div className="col-sm-12 p-0 text-center">
                                <h5 style={{ color: COLORS.card_orange }}> Computer(Not Assigned) </h5>
                                <p> <span> Not Assigned </span> </p>
                                <Link to={'/AdminMain/Assign_homework'}>
                                  <button className="btn btn-success" style={{ width: '100%', fontSize: '16px', background: COLORS.card_orange, border: COLORS.green5 }}> Assign Homework </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3">
                            <div className="row class-list-box" style={{ border: '1px solid #fea223', borderRadius: '10px' }}>
                              <div className="col-sm-12 p-0 text-center">
                                <h5 style={{ color: COLORS.card_orange }}> Computer(Not Assigned) </h5>
                                <p> <span> Not Assigned </span> </p>
                                <button className="btn btn-success" style={{ width: '100%', fontSize: '16px', background: COLORS.card_orange, border: COLORS.green5 }}> Assign Homework </button>

                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3">
                            <div className="row class-list-box" style={{ border: '1px solid #fea223', borderRadius: '10px' }}>
                              <div className="col-sm-12 p-0 text-center">
                                <h5 style={{ color: COLORS.card_orange }}> Computer(Not Assigned) </h5>
                                <p> <span> Not Assigned </span> </p>
                                <button className="btn btn-success" style={{ width: '100%', fontSize: '16px', background: COLORS.card_orange, border: COLORS.green5 }}> Assign Homework </button>

                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3">
                            <div className="row class-list-box" style={{ border: '1px solid #fea223', borderRadius: '10px' }}>
                              <div className="col-sm-12 p-0 text-center">
                                <h5 style={{ color: COLORS.card_orange }}> Computer(Not Assigned) </h5>
                                <p> <span> Not Assigned </span> </p>
                                <button className="btn btn-success" style={{ width: '100%', fontSize: '16px', background: COLORS.card_orange, border: COLORS.green5 }}> Assign Homework </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                )
              }
            } />
            <Route path='/AdminMain/Assign_homework' component={Assign_homework} />


          </switch>
        </Router>
      )
    }
  }
}