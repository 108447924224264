import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import StudentDetails from './StudentDetails';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';

export default class StudentList extends Component {
  constructor(props){
  super(props)

  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      StudentList:[],
      
  }      
}

componentDidMount()
{   
  this.StudentList();
}
StudentList(){
  
  this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const CLASS_ID=this.props.match.params.class_id;
const SECTION_ID=this.props.match.params.section_id;


const FUNCTION_NAME='getCurrentStudent.php?'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME

axios.get(URL,{
  params:{school_code:SCHOOL_CODE,class_id:CLASS_ID,section_id:SECTION_ID,session_id:SESSION_ID}
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
     
       this.setState({
        
        StudentList:response.data

        })
        console.log("response>>>>>"+this.state.StudentList);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{
    return (
      <Router>
      <switch>   
      <Route path='/AdminMain/StudentList/:class_id/:section_id'   render={
        ()=>{

          return(
              <div className="content">
                  <div className="content-header">
                    <div className="leftside-content-header">
                      <ul className="breadcrumbs">
                        <li><i className="fa fa-home" aria-hidden="true" /><a href="#">{"Students List("+this.state.StudentList.length+")"}</a></li>
                      </ul>
                    </div>
                  </div>
                 
                   <div className="row bg_page_white_rounded box_shadow gravity_center" style={{'--mMargin':'10px'}}>
             
                   {this.state.StudentList && this.state.StudentList.map((item, index) => { 
                                           
                        return(       
                        <div className="col-sm-4 col-md-4"style={{padding:0}}>
                        <Link to={{pathname:'/AdminMain/StudentDetails/'+item.student_enrollment_no}}>
                        <div className="row bg_card_blue_outline" style={{margin:'5px 5px 10px 5px',height:'90px'}}>

                        <div className="col-sm-3  bg_card_blue" style={{padding:'0px',height:'85px'}}>
                        <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'70px',width:'70px'}}>
                          <div className="circular_image">
                              <img  src={item.student_img_path}  alt=""></img>
                              <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                          </div> 
                          </div>                
                        </div>
                        <div className="col-sm-6" style={{padding:'0px 2px 0px 2px',height:'85px'}}>
                             <h6 className="mb-0"> {item.stu_name} </h6>
                             <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                             <p className="mb-0"><span>{"Village/Town:"+item.village_town}</span> </p>
                             <p className="mb-0" style={{fontSize:'10px'}}> SRN: <span>{item.sr_admission_no}</span> </p>
                  
                        
                        </div>
                        <div className="col-sm-3 padding_0 gravity_center"style={{height:'85px'}} >
                          <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                            <p className="mb-0 black7"> Roll No. </p>
                            <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'30px', height:'30px',border:'2px',
                            margin:'3px auto',fontSize:'20px',color:COLORS.white}}>{item.student_roll_no}</div> </p>
                            <h6 className="black7"><span> {item.class_name} </span>  </h6>
                          </div>
                        </div>
                        
                        
                      
                        </div>
                        </Link>
                      
                        </div>                   
                        )
                    })}
                 </div>
               </div>
                   
                   )
                  }
                  
                 } />                       
                 
                   <Route path='/AdminMain/StudentDetails/:student_id'  component={StudentDetails} />        
                   </switch>
                   
                  </Router>
  
  )
} 
}

}
