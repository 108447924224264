import React, { Component,useState } from 'react';
import { Redirect, Route,Link } from 'react-router-dom';
import axios from 'axios'
import MyLoader from './Spinner/MyLoader';
import SessionManager from './Utils/SessionManager'
import Test2 from './Admin/Test2';
import test from './Admin/test';
import eduok from './resources/images/EduOk.png'
import myToast from './Utils/MyToast';

class Login extends Component {
    constructor(props){
        super(props)

      
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }

        this.state={
            LoginId:'',
            Password:'',
            loggedIn:false,
            Loading:false,
            login_type:''
          
        }
        this.onLoginIdChange= this.onLoginIdChange.bind(this);
        this.onPasswordChange= this.onPasswordChange.bind(this);
        this.onLoginSubmit= this.onLoginSubmit.bind(this);
    }

    onLoginIdChange(e){
        this.setState({
            LoginId: e.target.value
        })
    }

    onPasswordChange(e){
        this.setState({
            Password: e.target.value
        })
    }
  onLoginSubmit(){
     if(this.isValidInput())
     {
  
      this.setState({
        Loading:true
      })
      const FUNCTION_NAME='staffLogin.php?'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      var system_code=process.env.REACT_APP_SYSTEM_CODE;
      console.log("URL>>>>>"+URL)
      axios.get(URL, {
        params: {
          user_id:this.state.LoginId,
          password:this.state.Password,
          system_code:system_code,
        }

      }).then(res => res.data)
      .then((response) => {
        this.setState({ 
           Loading:false
          })
          let api_status=response.api_status;
          if(api_status=="OK")
          {
              localStorage.setItem("token","abcdasdf");
              var staff=response.staff;
              SessionManager.saveSchoolCode(staff[0].school_code);
              SessionManager.createStaffLoginSession(staff[0].staff_id,staff[0].staff_name,staff[0].staff_phone,staff[0].staff_email,staff[0].password,staff[0].staff_role,staff[0].login_type,staff[0].staff_image_path);
                            

              this.setState({
                loggedIn:true,
                login_type:staff[0].login_type
              })
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
  
        console.log("response>>>>>"+api_status)
       })
      

     }

  }
  isValidInput()
  {
    if(this.state.LoginId===""){
      alert("Enter Login Id");
      return false;
    }
    else if(this.state.Password==="")
    {
      alert("Enter Password");
      return false;
    }
    else{
      return true;
    }

  }
 
  render() {
  
            if(this.state.loggedIn)
            {
             
              if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_ADMIN)
              {
                 return <Redirect to='/AdminMain' />
              }
              else if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_CLERK)
              {
                return <Redirect to='/AdminMain' />

              }
              else if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_TEACHER)
              {
                alert("Access Not Granted.Please use android application");

              }
              else if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_STUDENT)
              {
                alert("Access Not Granted.Please use android application");
              }
              
               
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
            else{
              return (
                <div >
                   
                
              <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" style={{height:'100hv !important'}}>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="./img/login-bg-1.jpg" className="d-block w-100 h-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="./img/login-bg-2.jpg" className="d-block w-100 h-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="./img/login-bg-3.jpg" className="d-block w-100 h-100" alt="..." />
                  </div>
                </div>
               
              </div>
              <div class="absolute">
                  <div className="row">
                  
                  <div className="col-md-12">
                  <div className="form-horizontal" >
                          <div className="form-group">
                                <div className="col-sm-12">
                                <input type="text" id="loginId" className="loginId form-control" placeholder="Login Id" value={this.state.LoginId} onChange={this.onLoginIdChange} ></input>
                                </div>
                          </div>
                          <div className="form-group">
                                <div className="col-sm-12">          
                                <input type="password" id="Password" name="Password" placeholder=" Password" className="form-control" value={this.state.Password}  onChange={this.onPasswordChange}></input>
                                </div>
                          </div>
        
                         
                          <div className="form-group">        
                                <div className="col-sm-12">
                                <input type="submit" id="createNewUser" className="btn btn-primary form-control" value="Login" onClick={this.onLoginSubmit} ></input>
                               <p><Link to="./" style={{fontSize:'16px',marginLeft:'15px',textDecoration:'none'}}> Forgot Your Password ? </Link></p>
                                </div>
                          </div>
                        
                  </div>
                  </div>
                  </div>
                  </div>                 
                  
                </div>
            );
            }
           
          

 
  }
}

export default Login;
