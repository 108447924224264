import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import OldDueCollection from './OldDueCollection';
import myToast from '../Utils/MyToast';
import SelectClass from '../ChildComponent/SelectClass';
export default class OldDueList extends Component {
    constructor(props){
        super(props)
        this.slt_board=createRef();
        this.slt_medium=createRef();
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={ 
    
          loggedIn:true,
          Loading:false,
          LoadingStudent:false,
          classBoard:[],
          classMedium:[],
          ClassSection:[],
          mFilteredClassList:[],
          mFilteredClassSectionList:[],
          mClassData:[], 
          CLASS_SELECTED_POSITION:0,
          mOldDueList:[], 
          mFilteredOldDueList:[], 
           
      }
      this.CallBackClassSelect = this.CallBackClassSelect.bind(this);
    }
    componentDidMount()
    {
       
    }
    CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name, session_name) => {

      this.state.CLASS_ID = class_id;
      this.state.SECTION_ID = section_id;
      this.state.SESSION_ID = session_id;
      this.state.SELECTED_CLASS_POSITION = selected_position;
      // alert("Back"+class_id+"/"+section_id+"/"+session_id);
      this.getOldDueList();
    }
    getOldDueList(){
      this.setState({
        LoadingStudent:true
      })
      this.state.mOldDueList=[];
      const SCHOOL_CODE=SessionManager.getSchoolCode();
     const FUNCTION_NAME='getClassStudentOldDueList.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL,{
        params:{
          school_code:SCHOOL_CODE,
          class_id:this.state.CLASS_ID,
          section_id:this.state.SECTION_ID,
          session_id:this.state.SESSION_ID
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingStudent:false
         })
          let api_status=response.api_status;       
          if(api_status=="OK")
          {
          
              this.setState({              
                mOldDueList:response.data
              })
              
            this.getFilteredList();
                         
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
            this.getFilteredList();
                        
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
    }
getFilteredList()
{
      this.state.mFilteredOldDueList=[];
      this.state.total_amount=0;
      for (var i=0;i<this.state.mOldDueList.length;i++)
      {      
        if(this.state.mOldDueList[i].old_due!==null)
        {
           this.state.mFilteredOldDueList.push(this.state.mOldDueList[i]);
           
        }
        
      }

      this.setState({

      })
} 
getTotalAmount(){
  var total_due=0;
  var total_paid=0;
  for (let index = 0; index < this.state.mFilteredOldDueList.length; index++) {
      total_due=total_due+this.getTotalDue(index);
      total_paid=total_paid+this.getTotalPaid(index);
  }
  var tag=this.props.match.params.tag;
  if(tag=="1")
  {
    return "Total Due: INR "+total_due;
  }
  else if(tag=="2")
  {
    return "Total Recieved: INR "+total_paid;
  }
  else if(tag=="3")
  {
    return "Current Old Due: INR "+(total_due-total_paid);
  }
}
getTotalDue(index){
  var amt=0;
  var mOldDue=[];
 
  mOldDue=this.state.mFilteredOldDueList[index].old_due;
  for (let i = 0; i <mOldDue.length; i++) {
    
     amt=amt+Number(mOldDue[i].amount);
     
  }
  return amt;

}
getTotalPaid(index){
  var amt=0;
  var mOldDue=[];
 
  mOldDue=this.state.mFilteredOldDueList[index].old_due;
  for (let i = 0; i <mOldDue.length; i++) {
    
     amt=amt+Number(mOldDue[i].amount_paid);
     
  }
  return amt;

}
saveData= (item) =>{
  //alert("saved");
  localStorage.setItem("selected_student",JSON.stringify(item));

}
      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

       return (
         
      <Router>
      <switch>   
      <Route path='/AdminMain/OldDueList/:tag'   render={
        ()=>{

          return(

          <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Old Due List </a></li>
              </ul>
            </div>
          </div>   
          <div className="bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
               
          <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>
             
              

                
                <div className="row">
                <div className="col-sm-12 col-md-12">
                <p className="du-old">{this.getTotalAmount()}</p>                                    
                </div>    
                </div>
                <section className="">
                <div className="row">
                {this.state.LoadingStudent?<div><MyLoader/></div>:
              
              this.state.mFilteredOldDueList.map((item, index) => (  
            
                <div className="col-sm-6 col-md-6" style={{ marginBottom: "5px", marginTop: "5px" }}>
                <Link to={{pathname:'/AdminMain/OldDueCollection'}}>
                 <div className="row" style={{ height: 100 }}>
                 <div className="col-sm-3 bg_card_red gravity_center">
                 <div className="profil-im-img" style={{ background: 'rgb(219 238 246)' }}>
                    <img src={item.student_img_path} alt="img" style={{ width: '100%' }} />

                    </div>
                 </div>
                 
                 <div className="col-sm-9 bg_card_red_outline">
                  <h5> {item.stu_name}<span> {item.class.length!==0?item.class[0].class_name:'Class Name'} </span><span>:{item.class.length!==0?item.class[0].section_name:'Section Name'}</span> </h5>
                  <p> Father: <span>{item.father_name}</span> </p>
                  <p className="weight-d7"> Total Old Due: <span>{"INR "+this.getTotalDue(index)} </span> </p>                 
                  <p className="weight-d7"> Total Paid: <span>{"INR "+this.getTotalPaid(index)} </span> </p>                  
                  <p className="weight-d7"> Current Old Due: <span>{"INR "+(this.getTotalDue(index)-this.getTotalPaid(index))} </span> </p>
                  
                 </div>
                </div>
                </Link>
                </div> 
              ))}
               
                </div>
                </section>           
                <div className="row">
                <div className="col-sm-12 col-md-12">
               <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px',background:COLORS.primary_color_shade5,border:COLORS.primary_color_shade5}}> Add More </button>
                                    
                </div>    
                </div>
               </div>
               </div> 
     
           )
         }
        } />                       
   
            <Route path='/AdminMain/OldDueCollection'  component={OldDueCollection} />        
          </switch>
     
        </Router>

     )
} 
}
}
