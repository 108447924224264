import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import AddNotice from './AddNotice';
import ManageNotice from './ManageNotice';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import icon_new from '../resources/icons/icons_new.png';
export default class NoticeBoard extends Component {
  constructor(props){
  super(props)
  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      NoticeBoard:[],
      StaffNoticeBoard:[]   
  }

}
componentDidMount()
{
this.NoticeBoard();
this.StaffNoticeBoard();
}
NoticeBoard(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;

var mStaffData=SessionManager.getStaffDetails();
var STAFF_ID=mStaffData.staff_id;
var LOGIN_TYPE=mStaffData.login_type;
  
const FUNCTION_NAME='getStudentNoticeBoard.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID,
    staff_id:STAFF_ID,
    login_type:LOGIN_TYPE
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    //console.log("Response2>>>>>"+JSON.stringify(response));
    let api_status=response.api_status;
    //alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        NoticeBoard:response.data
        })
        //console.log("response>>>>>"+JSON.stringify(this.state.NoticeBoard));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
StaffNoticeBoard(){

  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  var mStaffData=SessionManager.getStaffDetails();
var STAFF_ID=mStaffData.staff_id;
var LOGIN_TYPE=mStaffData.login_type;
  
  const FUNCTION_NAME='getStaffNoticeBoard.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:SESSION_ID,
      staff_id:STAFF_ID,
      login_type:LOGIN_TYPE
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      //console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
      //alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          StaffNoticeBoard:response.data
          })
         // console.log("response>>>>>"+JSON.stringify(this.state.StaffNoticeBoard));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{
    
      return (
        <Router>
        <switch>   
        <Route path='/AdminMain/NoticeBoard/:module_id'  exact render={
          ()=>{
  
            return(
             <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Notice Board</a></li>
              </ul>
            </div>
           
          </div>
      
          <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{'--mMargin':'50px'}}>
          <div className="gravity_center">
              <h4 className="bg_rect_primary_outline_yellow gravity_center" style={{height:'40px',width:'260px',color:COLORS.white}}> Student Notice Board </h4>
          </div>
          
          <div className="row">
             <div className="col-sm-12 col-md-12">
                <div  className="bg_rect_grey_outline_primery box_shadow_highlight2" style={{margin:'5px 10px 5px 10px',padding:'10px'}}>                     
                  {this.state.NoticeBoard && this.state.NoticeBoard.map((item, index) => (  
                  <div className="row">
                        <div className="col-sm-11 col-md-11">
                          <div className="text-justify">
                              <p style={{fontSize:'16px'}}> {item.notice}    </p>
                        </div>
                        </div>
                        <div className="col-sm-1 col-md-1">
                        <p><img  className="bg_circle_fill_red_grey_outline" src={icon_new} style={{width:'50px',height:'50px',float:'right'}}/></p>
                          
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div className="text-right">
                          <p style={{padding:'5px',color: '#0d64b0',textTransform:'uppercase',fontWeight:'700',borderBottom: '1px solid #d9d5d5'}}>{item.ordered_by}(<span>{item.notice_date2}</span>)</p>
                          </div>
                        </div> 
                        </div> 
                        ))}
                         
                  </div>
               </div>
            </div>

            <div className="row">           
                <div className="col-sm-12 col-md-12">
                
                  <div className="text-justify">
                  <Link to={{pathname:'/AdminMain/ManageNotice'}}>
                  <button style={{border:'1px dodgerblue',padding:'5px 35px', borderRadius:'14px'}}> Manage Notice </button>
                  </Link>
                  </div>
                </div> 
            </div>


           <div className="row">
             <div className="col-sm-12 col-md-12">
          <div  style={{boxShadow:'2px 6px 11px #0000001f', padding:'5px'}}>

          <h3 style={{textAlign:'center',background:'#679cc9', padding:'5px', color:'#fff',margin:'10px 0px'}}> Staff Notice Board </h3>
         
          {this.state.StaffNoticeBoard && this.state.StaffNoticeBoard.map((item, index) => ( 
          <div className="row">
                <div className="col-sm-10 col-md-10">
                  <div className="text-justify">
                      <p style={{fontSize:'16px'}}> {item.notice}    </p>
                 </div>
                </div>
                <div className="col-sm-2 col-md-2">
                <p><img src="https://www.sirti.it/wp-content/uploads/2017/06/CAMPAGNE-new-icon-4776.png" style={{width:'45%',float:'right'}}/></p>
                  
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="text-right">
                  <p style={{padding:'5px',color: '#0d64b0',textTransform:'uppercase',fontWeight:'700',borderBottom: '1px solid #d9d5d5'}}>{item.ordered_by}(<span>{item.notice_date2}</span>)</p>
                  </div>
                </div> 
                </div> 
                ))}
                <div className="row">           
                <div className="col-sm-12 col-md-12">
                
                  <div className="text-justify">
                  <Link to={{pathname:'/AdminMain/ManageNotice'}}>
                    <button style={{border:'1px dodgerblue',padding:'5px 35px', borderRadius:'14px'}}> Manage Notice </button>
                    </Link>
                  </div>
                </div> 
                </div>
               </div>
              </div>
              </div>





              <div className="col-sm-12 col-md-12">
              <Link to={{pathname:'/AdminMain/AddNotice'}}>
              <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Add Notce </button>
              </Link>
              </div>                
              </div>
              </div>
        
           )
          }
         } />                       
         
           <Route path='/AdminMain/AddNotice'  component={AddNotice} />   
           <Route path='/AdminMain/ManageNotice'  component={ManageNotice} />   
              
           </switch>
           
          </Router>
         )
    } 
  }
 
}

