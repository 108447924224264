import React, { Component, createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import uploadIcon from '../resources/images/upload-icon.png'


export default class MdlDeleteExamConfirmation extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      isOpenSessionDialog: false,
      show: true,
      mExamList: [],
      CLASS_ID: '',
      SECTION_ID: '',
      EXAM_ID: '',
      SESSION_ID: '',
      SESSION_NAME: '',
      SESSION_STATUS: '',
      EXAM_SELECTED_POSITION: 0

    }

  }

  ChangeSession = () => {

    this.HandleShowConfirmDialog();
  }

  HandleShowConfirmDialog = () => {
    this.setState({
      isOpenConfirmDialog: !this.state.isOpenConfirmDialog
    });
  }

  HandleCloseConfirmDialog = () => {

    this.setState({
      isOpenConfirmDialog: false
    });

  }
  HandleSelectedSession = (session_id, session_name, session_status) => {
    this.state.SESSION_ID = session_id;
    this.state.SESSION_NAME = session_name;
    this.state.SESSION_STATUS = session_status;
    this.setState({

      isOpenSessionDialog: false
    });

    this.getExamList();
  }

  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action_close();

  }

  confirmExamDelete = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionConfirm();

  }


  HandleSelectedExam = (exam_id, exam_name) => {
    //alert("okk"+student_id);

    this.props.action_select(exam_id, exam_name);

  }

  componentDidMount() {

    var mSession = SessionManager.getCurrentSession();
    this.state.SESSION_ID = mSession[0].session_id;
    this.state.SESSION_NAME = mSession[0].session_name;
    this.state.SESSION_STATUS = mSession[0].status;


    this.getExamList();
  }

  getExamList() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getExamList.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: this.state.SESSION_ID,

      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          this.setState({
            mExamList: response.data,

          })

          //console.log("response>>>>>"+this.state.mClassData);
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }


  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Exam
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :
                <>


                  <h3 className='deleteExam_degin'>Are You Sure To Delete Exam ?</h3>

                </>
              }

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" size="lg" block onClick={this.confirmExamDelete}>
                Confirm
              </Button>

            </Modal.Footer>
          </Modal>



        </div>

      )
    }
  }
}

