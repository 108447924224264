import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class Attendance extends Component {
 
  render() {

    return (
      <div className="content" >
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Attendance </a></li>
              </ul>
            </div>
          </div> 
          <div className="bg_page_white_rounded box_shadow" style={{'--mMargin':'50px'}}>  

          <div className="row">
                <div className="col-sm-12 col-md-12">
                
                </div>
          </div>         
          <div className="row">
          <div className="col-sm-12 col-lg-12 ">
         <h3 className="attend-status"> Color: Hints <label className="switch bb-switch" data-toggle="collapse" data-target="#demo">
  <input type="checkbox"/>
  <span className="slider round"></span>
</label> </h3>
<div className="panel-group">
  <div className="panel panel-default">
    <div id="demo" className="panel-collapse collapse">
      <div className="panel-body">
          <div className="row">
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Attendance Taken </span></p>
          </div>
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Not Taken </span></p>
          </div>
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Sunday </span></p>
          </div>
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Holiday </span></p>
          </div>
          </div>
      </div>
      
    </div>
  </div>
</div>
              </div>
               </div>
                {/* Before taken attendance show section */}
               <section>
               <div className="row">
               <div className="col-sm-12 col-md-12"> 
                <h3 className="text-center"> Class:<span> VI </span> </h3> 
                <hr/>
                </div> 
                <div className="col-sm-6 col-md-6">
                <h3> Date: <span> 2020-02-12 </span>  </h3>
                <hr/>
                </div>
                <div className="col-sm-6 col-md-6"> 
                <h3 style={{height:'28px'}}>
                <label className="switch bb-switch">
                <input type="checkbox"/> <span className="slider round"></span>
                </label>
                </h3>
                <hr/>
                </div>
          </div> 
          <div className="row class-li">
              <div className="col-sm-6 col-md-6">
                <Link to="#">
                <div className="row class-list-box">
                <div className="col-sm-2">
                  <div className="top-bottom-center">
                  <h3 className="top-bottom-center-sr"> 1 </h3>
                  </div>                 
                 </div>
                 <div className="col-sm-3 card-class-w">
                   <div><img src="https://treeminesglobal.com/wp-content/uploads/2019/01/placeholder-female-square.png" alt="student list image"/></div>
                 </div>
                 <div className="col-sm-5">
                 <div className="top-bottom-center">
                  <h5> Raj Kumar </h5>
                  <p> S/O: <span> Ramnath </span> </p>                 
                 </div>
                 </div>
                 <div className="col-sm-2 text-center">
                 <div className="top-bottom-center">
                 <p className="top-bottom-center-present"> P </p>
                 </div>
                 </div>
                </div>
                </Link>
                </div>
                <div className="col-sm-6 col-md-6">
                <Link to="#">
                <div className="row class-list-box">
                <div className="col-sm-2">
                  <div className="top-bottom-center">
                  <h3 className="top-bottom-center-sr"> 2 </h3>
                  </div>                 
                 </div>
                 <div className="col-sm-3 card-class-w">
                   <div><img src="https://treeminesglobal.com/wp-content/uploads/2019/01/placeholder-female-square.png" alt="student list image"/></div>
                 </div>
                 <div className="col-sm-5">
                 <div className="top-bottom-center">
                  <h5> Kumar k </h5>
                  <p> S/O: <span> Kamal </span> </p>                 
                 </div>
                 </div>
                 <div className="col-sm-2 text-center">
                 <div className="top-bottom-center">
                 <p className="top-bottom-center-present"> P </p>
                 </div>
                 </div>
                </div>
                </Link>
                </div>
                </div>
              
          <div className="row">
          <div className="col-sm-12 col-lg-12 ">
              <h3 className="attend-status"> Class: Today Attendance Not Taken  </h3>
              </div>
               </div>
               <div className="row">
                <div className="col-sm-3"> 
                <div className="atten-2"> Student: &nbsp; <span>7</span> </div>
                 </div>
                <div className="col-sm-3"> 
                <div className="atten-2"> Present: &nbsp; <span>7</span> </div>
                 </div>
                 <div className="col-sm-3"> 
                <div className="atten-2"> Leave: &nbsp; <span>0</span> </div>
                 </div>
                <div className="col-sm-3"> 
                <div className="atten-2"> Absent: &nbsp; <span>0</span> </div>
                 </div>
               </div>
               </section>
               {/* After taken attendance show section */}
               <section  className="after-attendance-sec hideen-taken-attendance">
               <div className="row">
               <div className="col-sm-12 col-md-12"> 
                <h3 className="text-center"> Class:<span> VI </span> </h3> 
                <hr/>
                <h3 className="text-center"> Date: <span> 2020-02-12 </span>  </h3>
                <hr/>
                </div> 
                
                <div className="col-sm-12"> 
                <table width="100%" border="1" className="tbl">
                  <tr>
                    <td> Student</td>
                    <td> 7 </td>
                    <td> <button> View </button></td>
                  </tr>
                  <tr>
                    <td> Present </td>
                    <td> 4-57% </td>
                    <td> <button> View </button></td>
                  </tr>
                  <tr>
                    <td> Absent </td>
                    <td> 3-42% </td>
                    <td> <button> View </button></td>
                  </tr>
                  <tr>
                    <td> Leave </td>
                    <td> 0-0% </td>
                    <td> <button> View </button></td>
                  </tr>
                </table>
              
                 </div>
               </div>
               </section>
                <div className="row">
                <div className="col-sm-12 col-md-12">
               <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Submit Attendance </button>
                                    
                </div>    
                </div>
                </div>
               </div>
  
  )
} 
}

