import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import COLORS from '../resources/colors/COLORS';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class PublicationList extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token"); 
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={ 

      loggedIn:true,
      Loading:false, 
      mgetAdmissionDashboard:''  
  }

}
componentDidMount()
{
this.getAdmissionDashboard();
}
getAdmissionDashboard(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const FUNCTION_NAME='getAdmissionDashboard.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mgetAdmissionDashboard:response.dashboard
        })
        console.log("response>>>>>"+this.state.mgetAdmissionDashboard);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (

      <div className="content">
      <div className="content-header" style={{ background: COLORS.green6 }}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Publication List</a></li>
          </ul>
        </div>
      </div>
      
                   
      <div className="row animated fadeInUp" style={{margin:' 30px 0px'}}>
       
          <div className="row class-li">          
            <div className="col-sm-4 col-md-4">
            <Link to="/">
            <div className="row class-list-box">
             <div className="col-sm-12 pt-45">
             <h4 className="text-center"><strong>Publication:</strong> <span className="text-primary"> Vidya </span> </h4>
             </div>
            </div>
            </Link>
            </div>
            <div className="col-sm-4 col-md-4">
            <Link to="/">
            <div className="row class-list-box">
             <div className="col-sm-12 pt-45">
             <h4 className="text-center"><strong>Publication:</strong> <span className="text-primary"> Surya </span> </h4>
             </div>
            </div>
            </Link>
            </div>
            <div className="col-sm-4 col-md-4">
            <Link to="/">
            <div className="row class-list-box">
             <div className="col-sm-12 pt-45">
             <h4 className="text-center"><strong>Publication:</strong> <span className="text-primary"> Krishana </span> </h4>
             </div>
            </div>
            </Link>
            </div>
            <div className="col-sm-4 col-md-4">
            <Link to="/">
            <div className="row class-list-box">
             <div className="col-sm-12 pt-45">
             <h4 className="text-center"><strong>Publication:</strong> <span className="text-primary"> Rajiv </span> </h4>
             </div>
            </div>
            </Link>
            </div>

            </div>


            </div>
            <section>
                    <div className="row mt-4">
<div className="col-sm-12"> 
<h3> <Link to={{pathname:'/'}} class="btn bg-primary  btn-block text-white" style={{fontSize:'15px',textDecoration:'none',padding:'10px'}}> <span className="clf"> Add New Publication </span>  </Link>  </h3>               
</div>
                    </div>
                </section>
           </div>
     
      )
      
  }
 
}
}
