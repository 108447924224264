import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Iframe from 'react-iframe'
export default class Aknowlagement extends Component {
  printDiv(){
    let pp=document.getElementById('divcontentakno').innerHTML;
       window.print(pp);
     }
     constructor(props){
      super(props)
  
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
  
      this.state={
  
          loggedIn:true,
          Loading:false, 
          mAknowlagement:[],
          mStudentdata:[] ,
          showPrintableDocs:false,
          mSelectedStudent:[],
                mPrintableSamples:[],
                iFrameHeight:'0px',
                SELECTED_SAMPLE_ID:'',
                SELECTED_SAMPLE_POSITION:0,
                SELECTION_TYPE:'student',
                FILE_URL:'',
                FILE_NAME:'',
                FILE_DIRECTORY:'',
          DOCUMENT_TYPE:'adm_ack' 
      }
    
  }
componentDidMount()
{

  this.getPrintableSamples();
}
getPrintableSamples(){
  this.setState({
    Loading:true
  })
 
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getPrintableSample.php?';
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
 // alert(URL);
  console.log("URL>>>>>"+URL);

  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      document_type:this.state.DOCUMENT_TYPE,
      
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
     // console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
      //alert(JSON.stringify(response));
     
      if(api_status=="OK")
      {
      
         this.setState({
            mPrintableSamples:response.data,
           
          })
          //alert("mPrintableSamples>>>>>"+JSON.stringify(this.state.mPrintableSamples));
          this.setSample();
       
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });

  }
  setSample(){
   
    var sample_id=this.state.mPrintableSamples[0].sample_id;
    this.state.FILE_NAME=this.state.mPrintableSamples[0].file_name;
    this.state.FILE_DIRECTORY=this.state.mPrintableSamples[0].file_directory;
    this.state.SELECTED_SAMPLE_POSITION=0;
    this.state.SELECTED_SAMPLE_ID=sample_id;
    this.setFileUrL();
     
  }
setFileUrL(){
var class_id="";
var section_id="";
var session_id="";
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mStudentIds=[];

if(this.state.SELECTION_TYPE==='student')
{
  var student_id=this.props.match.params.document_type;
  session_id=this.props.match.params.session_id;
  mStudentIds[0]=student_id;
//alert(JSON.stringify(mStudentIds));
}
this.state.FILE_URL=process.env.REACT_APP_SERVER_NAME+this.state.FILE_DIRECTORY+this.state.FILE_NAME+'?&student_ids='+JSON.stringify(mStudentIds)+'&class_id='+class_id+'&section_id='+section_id+'&session_id='+session_id+'&sample_id='+this.state.SELECTED_SAMPLE_ID+'&selection_type='+this.state.SELECTION_TYPE+'&system_code='+process.env.REACT_APP_SYSTEM_CODE+'&school_code='+SCHOOL_CODE;
console.log("FILE_URL",this.state.FILE_URL);
// alert(this.state.FILE_URL);
this.setState({
    
});
} 
render() {
  if( this.state.loggedIn==false)
  {
   return <Redirect to='/Login'/>;
  }
  if(this.state.Loading)
  {
     return <MyLoader/>
  }
  else{

  return ( 
       <div className="content content-customize-area-student-details">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Aknowledgement </a></li>
              </ul>
            </div>
          </div>
          <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Preview</h5>
            </div>

           
             
              <div className="row"  style={{margin:'0px 10px 0px 10px'}}>
              {this.state.showPrintableDocs? <div><MyLoader/></div> 
                      
                      :''}                        
                <Iframe url={this.state.FILE_URL}
                        width="100%"
                        height="800px"
                        overflow="visible"
                        id="iFrameStudentId"
                        className="bg_card_primary_outline"
                        display="initial"
                        position="relative"
                        onLoad={() => {
                         
                          this.setState({
                            showPrintableDocs:false,
                            
                          })
                      }} 
                      />
                        
               
              </div> 
              
               </div>             
      )
      
    }    
  }
 
}
