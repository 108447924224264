import React, { Component, useRef, createRef } from 'react'
import '../css/Admin_css/admin-customize.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import axios from 'axios'
import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';
import Form from 'react-bootstrap/Form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import StRegSuccessfull from './StRegSuccessfull';
import myToast from '../Utils/MyToast';
export default class UpdateStudentDetails extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: false,
      Loading: false,
      isOpenSessionDialog:false,
      mClassBoard: [],
      mClassMedium: [],
      mClassData: [],
      AddmisType: '',
      AddmissionNumber: '1',
      FirstName: '',
      LastName: '',
      dob: '',
      admission_date: '',
      gender: '',
      caste: '',
      community: '',
      RELIGION: '',
      AdharNo: '',
      nationality: 'Indian',
      board_id: '',
      medium_id: '',

      village_town: '',
      post_locality: '',
      police_station: '',
      current_address:'',
      permanent_address:'',
      
      FatherName: '',
      fatherOccupation: 'Farmer',
      father_qualification: '',
      father_aadhar: '',
      motherName: "",
      motherOccupation: 'House Wife',
      mother_qualification: "",
      mother_aadhar: "",
      contact1: '',
      contactName_1: '',
      relation_1: '',
      contact2: '',
      contactName_2: '',
      relation_2: '',
      email: '',   
      CLASS_ID: '',
      SECTION_ID: '',
      SESSION_ID:'',
      SESSION_NAME:'',
      SESSION_STATUS:'',
      PreviousSchoolDetails: '',
      StateSpecificIdName: '',
      StateSpecificIdValue: '',
      mSTUDetails: []
    }
    this.regSubmit = this.regSubmit.bind(this);
    this.regChange = this.regChange.bind(this);
   
  }
  componentDidMount() {
    var mSTDetails = JSON.parse(localStorage.getItem("STDetails"));
    //alert("heloo"+JSON.stringify(mSTDetails));
    if (mSTDetails == null) {
      mSTDetails = [];
    }
    this.state.mSTUDetails = mSTDetails;
    //alert(JSON.stringify(this.state.mSTUDetails));
    this.setStuDetails();
  }
  setStuDetails = () => {
    var name = this.state.mSTUDetails[0].stu_name;
    var nm = name.split(" ");
    this.state.FirstName = nm[0];
    this.state.LastName = nm[1];
    this.state.StateSpecificIdName = this.state.mSTUDetails[0].state_specific_id_name;
    this.state.StateSpecificIdValue = this.state.mSTUDetails[0].state_specific_id_value;

    this.state.nationality = this.state.mSTUDetails[0].nationality;
    this.state.AdharNo = this.state.mSTUDetails[0].adharcard_no;
    this.state.stRegion = this.state.mSTUDetails[0].religion;
    this.state.dob = this.state.mSTUDetails[0].birthday;
    this.state.gender = this.state.mSTUDetails[0].gender;
    this.state.community = this.state.mSTUDetails[0].community;
    this.state.caste = this.state.mSTUDetails[0].caste;
    this.state.admission_date = this.state.mSTUDetails[0].class[0].registration_date;

    this.state.current_address = this.state.mSTUDetails[0].current_address;
    this.state.permanent_address = this.state.mSTUDetails[0].permanent_address;
    this.state.village_town = this.state.mSTUDetails[0].village_town;
    this.state.post_locality = this.state.mSTUDetails[0].post_locality;
    this.state.police_station = this.state.mSTUDetails[0].police_station;

    this.state.FatherName = this.state.mSTUDetails[0].father_name;   
    this.state.fatherOccupation = this.state.mSTUDetails[0].father_occupation;

    this.state.motherName = this.state.mSTUDetails[0].mother_name;   
    this.state.motherOccupation = this.state.mSTUDetails[0].mother_occupation;

    var mContacts=[];
    mContacts=this.state.mSTUDetails[0].contact_details;
 
    if(mContacts!=null &&  mContacts.length>0)
    {
    
      this.state.contact1 = mContacts[0].contact_number;
      this.state.contactName_1 = mContacts[0].contact_person_name;
      this.state.relation_1 = mContacts[0].contact_person_relation;
    }
    if(mContacts!=null &&  mContacts.length>1)
    {
    
      this.state.contact2 = mContacts[1].contact_number;
      this.state.contactName_2 = mContacts[1].contact_person_name;
      this.state.relation_2 = mContacts[1].contact_person_relation;
    }
    this.state.email = this.state.mSTUDetails[0].email_id;
   
    //alert(this.state.community);
    this.setState({

    })

  }
  regChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    })

  }

 
  handleChangeGender = event => {
    this.setState({

      gender: event.target.value,
    })
  };
  handleChangeCommunity = event => {
    this.setState({

      community: event.target.value,
    })
  };

  copytopermAddr = e => {
    var val = e.target.checked;
    if (val == true) {
      this.setState({
        permanent_address: this.state.current_address
      });
    } else {
      this.setState({
        permanent_address: this.state.permanent_address

      });
    }
  };


  regSubmit() {


    if (this.isValidInput()) {
      this.setState({
        Loading: true
      })

      const SCHOOL_CODE = SessionManager.getSchoolCode();    
      var mStaffData = SessionManager.getStaffDetails();
      var Staff_id = mStaffData.staff_id;
      var Enroll_no=this.state.mSTUDetails[0].student_enrollment_no;

      const FUNCTION_NAME = 'updateStudentPersonalDetails.php'
      URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
      console.log("URL>>>>>" + URL)
      
      axios.get(URL, {
        params: {
          enrollment_no: Enroll_no,
          staff_id: Staff_id,
          student_name: this.state.FirstName + this.state.LastName,
          dob: this.state.dob,
          religion: this.state.stRegion,
          gender: this.state.gender,
          community: this.state.community,
          aadhar_no: this.state.AdharNo,
          nationality: this.state.nationality,
          curAddress: this.state.current_address,
          perAddress: this.state.permanent_address,
          caste: this.state.caste,
          village_town: this.state.village_town,
          post_locality: this.state.post_locality,
          police_station: this.state.police_station,

          father_name: this.state.FatherName,
          father_occupation: this.state.fatherOccupation,
          mother_name: this.state.motherName,
          mother_occupation: this.state.motherOccupation,

          contact1: this.state.contact1,
          contact2: this.state.contact2,
          contact_person_name1: this.state.contactName_1,
          contact_person_name2: this.state.contactName_2,
          contact_person_relation1: this.state.relation_1,
          contact_person_relation2: this.state.relation_2,
          email: this.state.email,
          school_code: SCHOOL_CODE,
        },
       
      }).then(res => res.data)
        .then((response) => {
          this.setState({
            Loading: false
          })
          let api_status = response.api_status;       
          console.log("response>>>>>" + api_status)
          if (api_status == "OK") {
            alert("Updated successfully");
            window.location.reload();
          
          }
          else {
            alert("Something went wrong !");
          }
        })
    } 
  }

  isValidInput() {

    if (this.state.FirstName === "") {
      alert('Your name have accepted Only Alphabate!');
      return false;
    }
    else if (this.state.LastName === "") {
      alert("Enter Last name have accepted Only Alphabate!");
      return false;
    }
    else if (this.state.dob === "") {
      alert("DOB have accepted Only Numeric!");
      return false;
    }
    else
      if (this.state.gender === "") {
        alert("Must have choose one option!");
        return false;
      }
      else if (this.state.community === "") {
        alert("Enter Last name have accepted Only Alphabate!");
        return false;
      }
      else
        // var phoneno = /^\d{10}$/ .value.match(phoneno);
        var rej = /^\d{14}$/;
    if (rej.test(this.state.AdharNo)) {
      alert("Adharcard have accepted Only 14 digits !");
      return false;
    }
    else if (this.state.LastName === "") {
      alert("Enter Last name have accepted Only Alphabate!");
      return false;
    }
    else {
      return true;
    }

  }
  render() {

    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Update Student Details</a></li>
              </ul>
            </div>
          </div>
          <div className="row animated fadeInUp">
            <div className="col-sm-12 col-lg-12">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="panel">
                    <div className="panel-content text-center" style={{ minHeight: '400px' }}>

                      {/* content body */}
                      <div className="panel-body"> 
                      <div style={{ float: 'left', width: '100%' }}>
                                    <div className="form-horizontal form-bordered ">
                                      <div className="col-md-10 bg_card_blue_outline1 s mar_bottom bg_admission">
                                      
                                        <div className=" row padding_5">
                                          <div className="col-md-4 ">
                                            <div className='padding_5 admission_prvform1 bg_grid_primary1 margin_3'>
                                              <label className="">Admission Number
                                                (Optional)</label>
                                              <div className="input-group admission_form2">
                                                <input type="text" required id="AddmissionNumber" name="Addmission Number" variant="outlined" placeholder="Admission Number(Optional)" value={this.state.AddmissionNumber} onChange={this.regChange} className="form-control word_center" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-4 ">
                                            <div className='padding_5 admission_prvform1 bg_grid_primary1 margin_3'>
                                              <label className="">State Speafic Id Name(Optional)</label>
                                              <div className="input-group admission_form2">
                                                <input type="text" required id="FirstName" variant="outlined" placeholder="State Speafic Id Name" value={this.state.StateSpecificIdName} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-4">
                                            <div className='padding_5 admission_prvform1 bg_grid_primary1 margin_3'>
                                              <label className="">State Speafic Id Number(Optional)</label>
                                              <div className="input-group admission_form2">
                                                <input type="text" required id="FirstName" variant="outlined" placeholder="State Speafic Id Number" value={this.state.StateSpecificIdValue} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>


                                        <div className="row ">
                                          <div className='col-md-4'>
                                            <div className=' admission_prvform2 padding_0'>
                                              <label className="padding_0">Previous School Details:</label>
                                            </div>
                                          </div>
                                          <div className='col-md-8'>
                                            <div className="input-group admission_prvform1 padding_0 ">
                                              <input type="text" required id="previus_school" variant="outlined" placeholder="Previous School Details" value={this.state.PreviousSchoolDetails} onChange={this.regChange} style={{ width: '100%' }} className="mar_right1 form-control" />
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div className="col-md-2 bg-white mar_bottom mar_top1 ">
                                        <div class="circular_image1">
                                          <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img_staff1" alt="student list image" />
                                        </div>

                                        <div className="photo-row-bottom posi-rel">
                                          <span><input type="file" name="upload photo" style={{ width: '150px' }} /></span>
                                        </div>

                                      </div>

                                    </div>
                                  </div>                     
                        <div className="pnlSubHedingBorder">
                          <div className="pnlSubHeding heading-with-bg-w">
                            Basic Information
                          </div>
                        </div>

                        <div className="row mar_top">

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className="">First Name</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input type="text" required id="FirstName" variant="outlined" placeholder="First Name" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className="">Last Name</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input type="text" required id="LastName" variant="outlined" placeholder="Last Name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0  admission_prvform1">
                                <label className="">Date of Birth</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group date" id="stDateofBirth">
                                  <input type="date" id="dob" name="dob" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0  admission_prvform1">
                                <label className=""> Gender </label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className='d-flex admission_form'>
                                  <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.gender} onChange={this.handleChangeGender} row>
                                    <FormControlLabel
                                      value="Male"
                                      control={<Radio color="primary" />}
                                      label="Male"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      control={<Radio color="primary" />}
                                      label="Female"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 padding_0">
                            <div className='row padding_0'>
                              <div className="col-md-4 padding_0  admission_prvform1">
                                <label className="admission_radio1">Community</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.community} onChange={this.handleChangeCommunity} row>
                                  <FormControlLabel
                                    value="SC"
                                    control={<Radio color="primary" />}
                                    label="SC"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="ST"
                                    control={<Radio color="primary" />}
                                    label="ST"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="OBC"
                                    control={<Radio color="primary" />}
                                    label="OBC"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="General"
                                    control={<Radio color="primary" />}
                                    label="General"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="Others"
                                    control={<Radio color="primary" />}
                                    label="Others"
                                    labelPlacement="end"
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className="">Religion</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input type="text" className="form-control" placeholder='Religion' value={this.state.stRegion} onChange={this.regChange} id="stRegion" />
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className="">Caste(Optional)</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input id="caste" type="text" placeholder='Caste' value={this.state.caste} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className="">Adhar Number</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input id="AdharNo" type="text" placeholder='Adhar Number' value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className="">Nationality</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input type="text" id="nationality" placeholder='Nationality' name="nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className=""> Village/Town </label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input id="village_town" placeholder='Village/Town/Building Number' value={this.state.village_town} onChange={this.regChange} type="text" className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                           
                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className=""> Post/Locality </label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input id="post_locality" type="text" placeholder='Post/Locality' value={this.state.post_locality} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className='row'>
                              <div className="col-md-4 padding_0 admission_prvform1">
                                <label className=""> Police Station(Optional) </label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input id="police_station" type="text" placeholder='Police Station' value={this.state.police_station} onChange={this.regChange} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div style={{ float: 'left', width: '100%' }}>
                          <div className="pnlSubHedingBorder headMarginTop">
                            <div className="pnlSubHeding heading-with-bg-w">
                              Current Address
                            </div>
                          </div>

                        
                          <div className='row'>
                             
                             <div className="col-md-12 padding_0">
                               <div className="gravity_center" >
                                 <input  className="edt_address" id="current_address" type="text" placeholder='current address' value={this.state.current_address} onChange={this.regChange} />
                               </div>
                             </div>
                           </div>

                          

                        </div>


                        <div className="pnlSubHedingBorder headMarginTop">
                          <div className="pnlSubHeding heading-with-bg-w">
                            Permanent Address
                          </div>
                        </div>

                        <div className="row mar_top">
                          <div className="col-md-8 padding_0">
                            <div className=" padding_0 admission_prvform1 d-flex">
                              <label className="admission_radio1"> Same As Current Address </label>
                              <div className="switcher admission_radio1">
                                <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" onChange={this.regChange} defaultChecked={this.state.checked} onClick={this.copytopermAddr} />
                                <label htmlFor="switcher_checkbox_1" />
                              </div>
                            </div>
                          </div>
                        </div>

                        
                        <div className='row'>
                             
                             <div className="col-md-12 padding_0">
                               <div className="gravity_center" >
                                 <input  className="edt_address" id="permanent_address" type="text" placeholder='permanent address' value={this.state.permanent_address} onChange={this.regChange} />
                               </div>
                             </div>
                           </div>


                        <div style={{ float: 'left', width: '100%' }}>
                          <div className="pnlSubHedingBorder headMarginTop">
                            <div className="pnlSubHeding heading-with-bg-w">
                              Family Information
                            </div>
                          </div>
                          <div className="row mar_top">

                            <div className="col-md-6">
                              <div className='row'>
                                <div className="col-md-4 padding_0 admission_prvform1">
                                  <label className=""> Father Name </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input id="FatherName" placeholder='Father Name' type="text" value={this.state.FatherName} onChange={this.regChange} className="form-control" />
                                  </div>
                                </div>
                                
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className='row'>
                                <div className="col-md-6 padding_0 admission_prvform1">
                                  <label className="">Father Occupation</label>
                                </div>
                                <div className="col-md-6 padding_0">
                                  <div className="input-group admission_form2">
                                    <input id="fatherOccupation" placeholder='Father Occupation' type="text" value={this.state.fatherOccupation} onChange={this.regChange} className="form-control" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className='row'>
                                <div className="col-md-4 padding_0 admission_prvform1">
                                  <label className=""> Mother Name </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input id="motherName" placeholder='Mother Name' type="text" value={this.state.motherName} onChange={this.regChange} className="form-control" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className='row'>
                                <div className="col-md-6 padding_0 admission_prvform1">
                                  <label className="">Mother Occupation</label>
                                </div>
                                <div className="col-md-6 padding_0">
                                  <div className="input-group admission_form2">
                                    <input id="motherOccupation" placeholder='Mother Occupation' type="text" value={this.state.motherOccupation} onChange={this.regChange} className="form-control" />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>

                        <div style={{ float: 'left', width: '100%' }}>
                          <div className="pnlSubHedingBorder headMarginTop">
                            <div className="pnlSubHeding heading-with-bg-w">
                              Contact Details
                            </div>
                          </div>

                          <div className='row mar_top'>
                            <div className="col-md-2 padding_0 admission_prvform2">
                              <label className=""> Contact Number 1</label>
                            </div>
                            <div className="col-md-2 padding_0">
                              <div className="input-group admission_form2">
                                <input id="contact1" placeholder='Enter Mobile Number' type="text" value={this.state.contact1} onChange={this.regChange} className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-2 padding_0 admission_prvform2">
                              <label className=""> Name</label>
                            </div>
                            <div className="col-md-2 padding_0">
                              <div className="input-group admission_form2">
                                <input id="contactName_1" type="text" placeholder='Name' value={this.state.contactName_1} onChange={this.regChange} className="form-control" />
                              </div>
                            </div>

                            <div className="col-md-2 padding_0 admission_prvform2">
                              <label className="">Relation</label>
                            </div>
                            <div className="col-md-2 padding_0">
                            <div className="input-group admission_form2">
                                        <Form.Select className="form-control"  value={this.state.relation_1} onChange={this.handleChangeRelation_1}>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Uncle">Uncle</option>
                                            <option value="Aunt">Aunt</option>
                                            <option value="Grand Father">Grand Father</option>
                                            <option value="Grand Mother">Grand Mother</option>
                                            <option value="Elder Brother">Elder Brother</option>
                                            <option value="Elder Sister">Elder Sister</option>
                                            <option value="Younger Brother">Younger Brother</option>
                                            <option value="Younger Sister">Younger Sister</option>
                                            <option value="Maternal Uncle">Maternal Uncle</option>
                                            <option value="Maternal Aunt">Maternal Aunt</option>
                                            <option value="Maternal Grand Father">Maternal Grand Father</option>
                                            <option value="Maternal Grand Mother">Maternal Grand Mother</option>


                                          </Form.Select>
                                        </div>
                            </div>

                          </div>

                          <div className='row'>
                            <div className="col-md-2 padding_0 admission_prvform2">
                              <label className=""> Contact Number 2</label>
                            </div>
                            <div className="col-md-2 padding_0">
                              <div className="input-group admission_form2">
                                <input id="contact2" type="text" placeholder='Contact Number ' value={this.state.contact2} onChange={this.regChange} className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-2 padding_0 admission_prvform2">
                              <label className=""> Name</label>
                            </div>
                            <div className="col-md-2 padding_0">
                              <div className="input-group admission_form2">
                                <input id="contactName_2" type="text" placeholder='Name' value={this.state.contactName_2} onChange={this.regChange} className="form-control" />
                              </div>
                            </div>

                            <div className="col-md-2 padding_0 admission_prvform2">
                              <label className="">Relation</label>
                            </div>
                            <div className="col-md-2 padding_0">
                            <div className="input-group admission_form2">
                                        <Form.Select className="form-control"  value={this.state.relation_2} onChange={this.handleChangeRelation_1}>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Uncle">Uncle</option>
                                            <option value="Aunt">Aunt</option>
                                            <option value="Grand Father">Grand Father</option>
                                            <option value="Grand Mother">Grand Mother</option>
                                            <option value="Elder Brother">Elder Brother</option>
                                            <option value="Elder Sister">Elder Sister</option>
                                            <option value="Younger Brother">Younger Brother</option>
                                            <option value="Younger Sister">Younger Sister</option>
                                            <option value="Maternal Uncle">Maternal Uncle</option>
                                            <option value="Maternal Aunt">Maternal Aunt</option>
                                            <option value="Maternal Grand Father">Maternal Grand Father</option>
                                            <option value="Maternal Grand Mother">Maternal Grand Mother</option>


                                          </Form.Select>
                                        </div>
                            </div>
                            <div className="col-md-2 padding_0 admission_prvform1">
                              <label className="email_mar_left">Email Address </label>
                            </div>
                            <div className="col-md-10 padding_0">
                              <div className="input-group admission_form2">
                                <input id="email" type="text" value={this.state.email} onChange={this.regChange} className="form-control" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <center>
                          <button className="btn btn-success" onClick={this.regSubmit} style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Submit</button>

                        </center>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
