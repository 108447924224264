import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import ExamAddSingleStudentMark from './ExamAddSingleStudentMark';
import ExamClassResult from './ExamClassResult';
export default class ExamClassResultStatus extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mExamData:[],
        mSubjects:[],
        mClassSubjectPaper:[],
        mClassSubjectPart:[],
        EXAM_STATUS_UPCOMMING:'1',
        EXAM_STATUS_OVER:'3',
        EXAM_STATUS_LIVE:'2',
        EXAM_ID:'',
        SESSION_ID:'',
        EXAM_NAME:'',
        EXAM_DATE:'',
        CLASS_SELECTED_POSITION:0,
        SELECTED_POSITION_SUBJECT:0,
        strSUBJECT_PART:''

    }
  
  }
  componentDidMount()
  {
      window.scrollTo(0, 0)
      this.state.EXAM_ID=this.props.match.params.exam_id;
      this.state.SESSION_ID=this.props.match.params.session_id;
      this.state.EXAM_NAME=this.props.match.params.exam_name;
      this.state.EXAM_DATE=this.props.match.params.exam_date;

      //alert(this.state.EXAM_NAME);
      //alert(this.state.EXAM_DATE);
      this.getExamClassResultStatus();
  }
  getExamClassResultStatus(){
   
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamClassResultStatus.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,session_id:this.state.SESSION_ID,exam_id:this.state.EXAM_ID}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));     
      if(api_status=="OK")
      {
      
         this.setState({
          
             mExamData:response.data
          })
          this.selectClass(0);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  selectClass=(position)=>{  
    this.state.CLASS_SELECTED_POSITION=position; 
    this.state.SELECTED_POSITION_SUBJECT=0; 

     
    this.getParticipatingSubjects(this.state.SELECTED_POSITION_SUBJECT);  
  }
  selectSubject=(position)=>{  
    this.state.SELECTED_POSITION_SUBJECT=position;     
    this.getParticipatingSubjects(this.state.SELECTED_POSITION_SUBJECT);  
  }

  getParticipatingSubjects(subject_selected_position)
  {
    this.state.mSubjects=this.state.mExamData[this.state.CLASS_SELECTED_POSITION]['subjects'];
    //alert(JSON.stringify(this.state.mSubjects));

    this.setState({

    }) 
    this.getSubjectParts();
    this.getSubjectPaper();
  }
  getSubjectPaper()
  {
    this.state.mClassSubjectPaper=this.state.mSubjects[this.state.SELECTED_POSITION_SUBJECT]['subject_paper'];
    this.setState({

    }) 
  }
  getSubjectParts(){
    var strParts='';
    this.state.mClassSubjectPart=this.state.mSubjects[this.state.SELECTED_POSITION_SUBJECT]['subject_part'];

    if(this.state.mClassSubjectPart && this.state.mClassSubjectPart.length!==0)
    {
      
      for (var i=0;i<this.state.mClassSubjectPart.length;i++)
      {
          if(i==0)
          {
              strParts="  ("+(i+1)+")"+this.state.mClassSubjectPart[i]['subject_part_name'];
          }
          else {
              strParts=strParts+","+"  ("+(i+1)+")"+this.state.mClassSubjectPart[i]['subject_part_name'];
          }

      }
      
    }
    else{
      strParts="Main";
    }
    this.setState({
      strSUBJECT_PART:strParts
    })
  }
 
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
     
        return (             
          <Router>
          <switch>
            <Route path={'/AdminMain/ExamClassResultStatus/:exam_id/:session_id/:exam_name/:exam_date'}  exact render={
              ()=>{
                
           return (
            <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Result Status</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
          <div className="row bg_card_blue" style={{padding:'5px 10px 5px 10px',margin:'2px 5px 2px 5px'}}>
                    {this.state.mExamData && this.state.mExamData.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
          </div>

          <div className="bg_card_blue_outline" style={{margin:'2px 5px 2px 5px'}}>
                  <div style={{paddingLeft:'10px'}}>
                    <h5>Select Subject:</h5>
                  </div>
                    <div className="row" style={{padding:'5px 10px 5px 20px'}}>
                    {this.state.mSubjects && this.state.mSubjects.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.SELECTED_POSITION_SUBJECT===index?"bg_class_select gravity_center":"bg_white_outline_grey gravity_center"} onClick={e=>this.selectSubject(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.subject_name} </p>
                        </div>
                    </div>
                    ))}
                    </div>
                    <div style={{paddingLeft:'10px'}}>
                    <h5>Subject Part:</h5>
                  </div>
                  <div style={{paddingLeft:'10px'}}>
                    <h6>{this.state.strSUBJECT_PART}</h6>
                  </div>
          </div>

          <div style={{paddingLeft:'10px'}}>
                    <h5>Subject Paper</h5>
          </div>
          
          <div className="row">
          {this.state.mClassSubjectPaper && this.state.mClassSubjectPaper.map((item, index) => { 
                                           
            return( 

             <div className="col-sm-6 col-md-6">
              <div style={{margin:'10px'}}>
              
                <div className="bg_card_blue_outline">
                 
                  <div className="row">
                    <div className="col-sm-8 col-md-8 gravity_center">
                      <p style={{fontSize:'18px'}}>Number Of Student:</p>
                    </div>
                    <div className="col-sm-4 col-md-4 gravity_center">
                      <p style={{fontSize:'18px'}}>{item.total_student}</p>
                    </div>
                  

                  </div>
                  <div className="row">
                    <div className="col-sm-8 col-md-8 gravity_center">
                      <p style={{fontSize:'14px'}}>Mark Added Student:</p>
                    </div>
                    <div className="col-sm-4 col-md-4 gravity_center">
                      <p style={{fontSize:'14px'}}>{item.student_mark_added}</p>
                    </div>
                   
                  </div>
                  <div className="row">
                    <div className="col-sm-8 col-md-8 gravity_center">
                      <p style={{fontSize:'15px'}}>Mark Not Added Student:</p>
                    </div>
                    <div className="col-sm-4 col-md-4 gravity_center">
                      <p style={{fontSize:'15px'}}>{item.student_mark_not_added}</p>
                    </div>
                   
                  </div>
                  <hr></hr>
                
                   <div className="row">
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamClassResult/'+this.state.EXAM_ID+'/'+this.state.SESSION_ID+'/'+this.state.CLASS_SELECTED_POSITION+'/'+this.state.SELECTED_POSITION_SUBJECT+'/'+this.state.EXAM_NAME+'/'+this.state.EXAM_DATE}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'100%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>View Details </button>
                            </Link>                
                          </div>
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamAddSingleStudentMark'}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Add Mark </button>
                            </Link>                
                          </div>    
                   </div>
                   
                </div>
              </div>

            </div>
              )
            })}
          </div>                             
                                
         </div>
      </div>
           
           )
          }
          } />
            <Route path='/AdminMain/ExamAddSingleStudentMark'  component={ExamAddSingleStudentMark} />
            <Route path='/AdminMain/ExamClassResult/:exam_id/:session_id/:selected_position_class/:selected_position_subject/:exam_name/:exam_date'  component={ExamClassResult} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }
