import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import sett from '../resources/images/sett.png'
import StudentDetails from './StudentDetails';
import ClassFee from './ClassFee';

import COLORS from '../resources/colors/COLORS';

import icon_setting from '../resources/icons/icons_settings_100_white.png'
import icon_class_setting from '../resources/icons/icons_class_setting_100_white.png'
import icon_student from '../resources/icons/icons_students_100_white.png'
import icon_special from '../resources/icons/icons_special_100.png'
import icon_fee from '../resources/icons/icons_fee_80_white.png'
import FeeNameList from './FeeNameList';
import SpecialCaseStudent from './SpecialCaseStudent';
import myToast from '../Utils/MyToast';
import Siblings from './Siblings';
export default class FeeSettings extends Component {

  render() {
    return (
      <Router>
<switch>
<Route path='/AdminMain/FeeSettings'   render={
        ()=>{
          return(
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Fee Settings </a></li>
              </ul>
            </div>
          </div>
              <div className="row class-li bg_page_white_rounded" style={{'--mMargin':'120px'}}>
              <div className="col-sm-6 col-md-6 " >
                <Link to={'/AdminMain/FeeNameList'}>
                <div className="row box_shadow bg_card_blue_outline" style={{padding:2,height:'80px',margin:10}}>
              
                 <div className="col-sm-3 gravity_center" style={{background:COLORS.card_blue,height:'100%',margin:0,padding:0,borderRadius:10}}>
                     <img src={icon_setting} style={{height:'50px',width:'50px'}}/>
                 </div>
                 <div className="col-sm-9 gravity_center" style={{height:'100%',margin:0,padding:0}}>
                  <h5> Fee Name List </h5>
                 </div>
                 </div>
                 </Link>

                </div>

                <div className="col-sm-6 col-md-6 " >
                <Link to={{pathname:'/AdminMain/ClassFee'}}>
                <div className="row box_shadow bg_card_blue_outline" style={{padding:2,height:'80px',margin:10}}>
              
                 <div className="col-sm-3 gravity_center" style={{background:COLORS.card_blue,height:'100%',margin:0,padding:0,borderRadius:10}}>
                     <img src={icon_class_setting} style={{height:'50px',width:'50px'}}/>
                 </div>
                 <div className="col-sm-9 gravity_center" style={{height:'100%',margin:0,padding:0}}>
                  <h5> Class Fee </h5>
                 </div>
                 </div>
                 </Link>

                </div>
                <div className="col-sm-6 col-md-6 " >
                <Link to={{pathname:'/AdminMain/Siblings'}}>
                <div className="row box_shadow bg_card_blue_outline" style={{padding:2,height:'80px',margin:10}}>
              
                 <div className="col-sm-3 gravity_center" style={{background:COLORS.card_blue,height:'100%',margin:0,padding:0,borderRadius:10}}>
                     <img src={icon_student} style={{height:'50px',width:'50px'}}/>
                 </div>
                 <div className="col-sm-9 gravity_center" style={{height:'100%',margin:0,padding:0}}>
                  <h5>Siblings </h5>
                 </div>
                 </div>
                 </Link>

                </div>
                <div className="col-sm-6 col-md-6 " >
                <Link to={{pathname:'/AdminMain/SpecialCaseStudent'}}>
                <div className="row box_shadow bg_card_blue_outline" style={{padding:2,height:'80px',margin:10}}>
              
                 <div className="col-sm-3 gravity_center" style={{background:COLORS.card_blue,height:'100%',margin:0,padding:0,borderRadius:10}}>
                     <img src={icon_special} style={{height:'50px',width:'50px'}}/>
                 </div>
                 <div className="col-sm-9 gravity_center" style={{height:'100%',margin:0,padding:0}}>
                  <h5>Special Case </h5>
                 </div>
                 </div>
                 </Link>

                </div>
                <div className="col-sm-6 col-md-6 " >
                <Link to={{pathname:'./'}}>
                <div className="row box_shadow bg_card_blue_outline" style={{padding:2,height:'80px',margin:10}}>
              
                 <div className="col-sm-3 gravity_center" style={{background:COLORS.card_blue,height:'100%',margin:0,padding:0,borderRadius:10}}>
                     <img src={icon_fee} style={{height:'50px',width:'50px'}}/>
                 </div>
                 <div className="col-sm-9 gravity_center" style={{height:'100%',margin:0,padding:0}}>
                  <h5>Fine Structure </h5>
                 </div>
                 </div>
                 </Link>

                </div>

             
                
                </div>
               </div>
                )
              }
            } />
        
        <Route path='/AdminMain/ClassFee'  component={ClassFee} />
        <Route path='/AdminMain/FeeNameList'  component={FeeNameList} />
        <Route path='/AdminMain/SpecialCaseStudent'  component={SpecialCaseStudent} />
        <Route path='/AdminMain/Siblings'  component={Siblings} />
                  
                   
             
              </switch>
              
             </Router>
  
)
} 
}
