import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
export default class LeaveManagementAdmin extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mLeaveStudent: []
    }

  }
  componentDidMount() {
    this.LeaveStudent();
  }
  LeaveStudent() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const Session_Id = "SSN0001";
    const FUNCTION_NAME = 'getStudentLeave.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: { school_code: SCHOOL_CODE, session_id: Session_Id }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mLeaveStudent: response.leave
          })
          console.log("response>>>>>" + JSON.stringify(this.state.mLeaveStudent));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {
      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Leave Applications (Student) </a></li>
              </ul>
            </div>
          </div>
          <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
            <div className='bg_card_red_outline'>
              <div className='row'>
                <div className='col-md-10'>
                  <div>
                    <center>
                      <h2>To : The Principal</h2>
                    </center>
                  </div>

                  <div>
                    <h4>Subject........................................................</h4>
                  </div>

                </div>
                <div className='col-md-2'>
                  <div className="text-center mar_top1">
                    <div className="pendding"> <p><i class="fa fa-street-view"></i></p></div>
                    <h5> panding </h5>
                  </div>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-6'>
                  <div className="col-md-4 col-form-label">
                    <label className="">From</label>
                  </div>
                  <div className="col-md-8 padding_0 mar_top1">
                    <div>12/12/2022</div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className="col-md-4 col-form-label">
                    <label className="">To</label>
                  </div>
                  <div className="col-md-8 padding_0 mar_top1">
                    <div>12/12/2022</div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='margin_vp'>
                  <div className='bg_card_orange_outline margin_10 padding_10'>
                    <h5 className=''>Dear Sir,
                      I am writing this application to inform you that I am suffering from severe viral disease and therefore, I want sick leave from work. I got this infection last night and I will not be capable to come to the office for at least 12. As advised by my doctor, I should take rest and recover appropriately before continuing work. The report from the doctor is also attached for your reference.Kindly grant me a leave for 10 days. I will be available on the phone in case of any emergency cases. Please reach me as per your comfort. For important matters, I have notified
                      to take care of them to assure that all deadlines are met.I believe you will understand and grant me leave for the mentioned period. I am waiting for your approval.Thanks for the consideration.
                      Yours Sincerely
                      Yadav </h5>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <h4>Student Name : Varun Yadav</h4>
                </div>
                <div className='col-md-6'>
                <h4>class : 12<sup>th</sup></h4>
                </div>

              </div>

            </div>
          </div>
        </div>

      )
    }
  }
}
