import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";
import { Form } from 'react-bootstrap';

const BplBeneficiary = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [loadClass, setLoadClass] = useState(false);
 
  const [SESSION_ID, setSessionId] = useState("");
  const [CLASS_ID, setClassId] = useState("");
  const [SECTION_ID, setSectionId] = useState("");
  const [SELECTED_CLASS_POSITION, setSelectedClassPosition] = useState(0);
  const [classSectionName, setClassSectionName] = useState("");
  const [Inputs, setInputs] = useState([]);
  const [mStudentList, setMStudentList] = useState([]);
  
  useEffect(() => {
    setLoadClass(true);
  }, []);

  useEffect(() => {
    studentList();
  }, [CLASS_ID,SECTION_ID]);
  

  const CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name) => {
    const classSectionName = section_id === "No" ? class_name : `${class_name}:${section_name}`;
    
    setSectionId(section_id);
    setSessionId(session_id);
    setClassSectionName(classSectionName);
    setSelectedClassPosition(selected_position);
    setClassId(class_id);   
    
  };
  const studentList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getCurrentStudent.php?";
    const URL =
      `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
       // console.log("Response2>>>>>" + JSON.stringify(response));
        const api_status = response.api_status;
       // alert(api_status);

        if (api_status === "OK") {
          setMStudentList(response.data);
          const formattedData = response.data.map(item => ({
            id: item.id,
            student_enrollment_no: item.student_enrollment_no,
            bpl_beneficiary_old: item.bpl_beneficiary,
            bpl_beneficiary_new: item.bpl_beneficiary,
          

          }));
          setInputs(formattedData);
          

        } else {
          setMStudentList([]);
          
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error>>>>>" + error);
      });
  };
  const onChangeBplBeneficiary = (id, value) => {
    //alert(JSON.stringify(id));
    const newInputs = Inputs.map(input => {
      if (input.id === id) {
        return { ...input, ['bpl_beneficiary_new']: value };
      }
      return input;
    });
    setInputs(newInputs);
  };
  const saveBplBeneficiary = (index,bpl_beneficiary_new) => {

  
    const BPLB_OLD=mStudentList[index].bpl_beneficiary_old;
         const STUDENT_ID=mStudentList[index].student_enrollment_no;
         const BPLB_NEW=bpl_beneficiary_new;
         if(BPLB_NEW!=BPLB_OLD)
         {
          updateStudentBPLB(STUDENT_ID,BPLB_OLD,BPLB_NEW);
         }
         
   
  };

  const updateStudentBPLB = (STUDENT_ID,BPLB_OLD,BPLB_NEW) => {
    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStudentBplBeneficiary.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    const params={
      staff_id: STAFF_ID,
      school_code: SCHOOL_CODE,
      session_id: SESSION_ID,
      student_id: STUDENT_ID,
      bpl_beneficiary_new: BPLB_NEW,
      bpl_beneficiary_old: BPLB_OLD,
      
    }
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    axios
      .get(URL, {
        params: params
      })
      .then((res) => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
         alert("BplBeneficiary updated successfully");
         
        } else {
           myToast.notify_warning(response.api_remark);
         
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


const SubmitAll = () => {
    const updatedList = [];
    Inputs.forEach((student, i) => {
      const BPLB_Old = student["bpl_beneficiary_old"];
      const BPLB_New = student["bpl_beneficiary_new"];
      
      if (BPLB_New && BPLB_Old !== BPLB_New) {
        const updatedStudent = {
          student_enrollment_no: student["student_enrollment_no"],
          bpl_beneficiary: BPLB_Old,
          bpl_beneficiary_new: BPLB_New,
        };
        updatedList.push(updatedStudent);
      }
    });
    //alert(JSON.stringify(updatedList));
    var str_student_list=JSON.stringify(updatedList);
    setLoading(true);
    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateClassStudentBplBeneficiary.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    
    var params = new URLSearchParams();
    params.append('staff_id',STAFF_ID);
    params.append('school_code',SCHOOL_CODE);
    params.append('session_id',SESSION_ID);
    params.append('student_list',str_student_list);
    params.append('class_id',CLASS_ID);
    params.append('section_id',SECTION_ID);
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
  
    axios.post(URL,params).then(res => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
             alert("Bpl Beneficiary updated successfully");
         
        } else {
           myToast.notify_warning(response.api_remark);
         
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }
  return (
    <Router>
      <Route
        path={"/AdminMain/BplBeneficiary"}
        exact
        render={() => (
          <div
            className="content"
            style={{ background: COLORS.whitesmoke }}
          >
            <div
              className="content-header"
              style={{ background: COLORS.primary_color_shade6 }}
            >
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Manage BplBeneficiary </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="animated fadeInUp bg_page_grey_rounded box_shadow"
              style={{ "--mMargin": "50px", marginTop: "20px" ,padding:'20px'}}
            >
              {loadClass && (
                <SelectClass
                  action={CallBackClassSelect}
                  selected_position={SELECTED_CLASS_POSITION}
                />
              )}

               {Loading ? (
                <MyLoader />
              ) : Inputs.length > 0 ? (
                mStudentList.map((item, index) => (
                <div className="bg_card_blue_outline btn_set">
                  <div className="row btn_set">
                    <div className="col-md-6 mp1_0 gravity_center">
                      <div className="row" style={{width:'100%'}}>
                        <div className="col-md-3 gravity_center mp1_0">
                          <div className="bg_card_blue" style={{ height: "70px", width: "70px" }}>
                            <div className="circular_image">
                              <img src="" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                        <div>
                              <h6 className="text-center">
                                <span className="text_color">Student Name:</span> {item.stu_name}
                              </h6>
                            </div>
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Father Name:</span> {item.father_name}
                              </h6>
                            </div>
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Class:</span> {item.section_id === 'No' ? item.class_name : `${item.class_name}:${item.section_name}`}
                              </h6>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row mar_top">
                        <div className="col-md-4 mp1_0">
                          <Form.Label className="mar_top2">Bpl BplBeneficiary</Form.Label>
                        </div>
                        <div className="col-md-6 mp1_0">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control
                              as="select"
                              value={
                                Inputs && Inputs.length > 0 && Inputs[index]?.bpl_beneficiary_new
                                  ? Inputs[index].bpl_beneficiary_new 
                                  : ''
                              }
                              
                              onChange={(e) => onChangeBplBeneficiary(Inputs[index].id,e.target.value)}
                            >
                              <option value="NA">NA</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-md-2">
                            <center>
                              <button className="btn btn-success" onClick={() => saveBplBeneficiary(index,Inputs[index].bpl_beneficiary_new)}>{ 'Save'}</button>
                            </center>
                          </div>

                      </div>
                    </div>
                  </div>
                </div>
                ))
              ) : (
                <div>No students found</div>
              )}
              
               <div className="row mar_top">
                <button
                  className="btn btn-success"
                  onClick={(e) => SubmitAll()}

                  style={{ margin: "3% 0%", fontSize: "21px", width: "100%" }}
                >
                  Submit All
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </Router>
  );
};

export default BplBeneficiary;
