import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import COLORS from '../resources/colors/COLORS';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class AddProduct extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token"); 
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      mgetAdmissionDashboard:''  
  }

}
componentDidMount()
{
this.getAdmissionDashboard();
}
getAdmissionDashboard(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const FUNCTION_NAME='getAdmissionDashboard.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mgetAdmissionDashboard:response.dashboard
        })
        console.log("response>>>>>"+this.state.mgetAdmissionDashboard);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (

      <div className="content">
      <div className="content-header" style={{ background: COLORS.green6 }}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Add Product</a></li>
          </ul>
        </div>
      </div>
      <div className="row">
                <div className="col-sm-6 col-md-6">
                <div className="">
                <h4 className="selectMultiplst-h4" style={{background: COLORS.green6,color:'#fff'}}>Accessories Category:</h4>
                <select className="select-field">
                   <option>Bags</option>
                   <option>Dairy</option>
                   <option>Pen</option>
                   <option>Book</option>
                </select>
                </div>
                </div>
                 <div className="col-sm-6 col-md-6">
                  <div className="">
                   <h4 className="selectMultiplst-h4" style={{background: COLORS.green6,color:'#fff'}}>Product Name:</h4>
                                      <div className="input-group">
                                        <input type="text" id="productName" name="product name" value="" placeholder="Book" className="select-field" style={{border:'1px solid #ddd'}} />
                                      </div>
                  </div>
                </div>

               </div>
               <section className="exam-section-1" style={{background: COLORS.green6,color:'#fff'}}>
              <div className="row class-li">
              <div className="col-sm-6 col-md-6">
                  <div className="row">
                  <div className="col-sm-6 col-md-6">
                <h5><input type="checkbox"/> &nbsp;
                        <span>CBSE </span> <span>English</span> 
                        </h5>
                      </div>
                  </div>
              </div>
                  <div className="col-sm-6 col-md-6 text-right">
                  <div style={{position:'absolute',right:'80px'}}> All Class</div> <label className="switch switch-height-20">
                  <input type="checkbox"/><span className="slider round"></span>
                  </label> 
                  </div>
              <div className="col-sm-1 col-md-1">
               <div className="exam-p-setting-cls"> <p> Nursery B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> UKG B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> LKG B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> I B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> II B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> III B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> IV B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> V B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> VI B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> VII B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> VIII B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> IX B1 </p></div>
                </div>
                </div>
                </section> 
      <div className="row class-li m-0">
              
                <div className="col-sm-12 col-md-12 text-center class-list-box" style={{margin:'0px'}}>
                <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Vendor </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                      <select className="form-control">
                   <option>Select Vedor</option>
                   <option>Vidya</option>
                   <option>Rajiv</option>
                   <option>Krishna</option>
                </select>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Max Retail Price</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="mrp" name="mrp" value="" placeholder="Enter MRP" className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Cost Price</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="text" id="costprice" name="costprice" value="" placeholder="Enter Cost Price" className="form-control" />
                                       </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Issueing Cost </label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="text" id="issuecost" name="issue cost" value="" placeholder="Enter Sale Price(Including all charge)" className="form-control" />
                                       </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Quantity</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="text" id="quantity" name="quantity" value="" placeholder="Enter quantity" className="form-control" />
                                       </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Remark (Optional)</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                      <input type="text" id="remark" name="remark" value="" placeholder="Enter remark" className="form-control" />
                                       </div>
                                    </div>
                </div>
                </div>                  
                </div>
            <section>
                    <div className="row mt-4">
<div className="col-sm-12"> 
<h3> <Link to={{pathname:'/'}} class="btn btn-block text-white" style={{fontSize:'15px',background: COLORS.green7,textDecoration:'none',padding:'10px'}}> <span className="clf"> Submit </span>  </Link>  </h3>
               
</div>
                    </div>
                </section>
           </div>
     
      )
      
  }
 
}
}
