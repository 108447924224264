import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import SelectClass from '../ChildComponent/SelectClass';
import COLORS from '../resources/colors/COLORS';
import AddClassFee from './AddClassFee';
import myToast from '../Utils/MyToast';
import MdlAddClassSubjectList from '../dialog/MdlAddClassSubjectList';
export default class ClassSubject extends Component {
  constructor(props) {
    super(props)

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      isOpenAddSubjectDialog: false,
      isOpen: false,
      loggedIn: true,
      Loading: false,
      SESSION_ID: "",
      CLASS_ID: "",
      CLASS_NAME: "",
      SECTION_ID: "",
      mClassSubject: [],
      SELECTED_CLASS_POSITION: ''

    }

    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);

  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  HandleShow1 = () => {

    this.setState({
      isOpenAddSubjectDialog: !this.state.isOpenAddSubjectDialog
    });
  }
  HandleCloseMdlclassSubject = () => {

    this.setState({
      isOpenAddSubjectDialog: !this.state.isOpenAddSubjectDialog
    });

  }
  CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name, session_name) => {

    this.state.CLASS_ID = class_id;
    this.state.CLASS_NAME = class_name;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    //alert("Back"+class_id+"/"+section_id+"/"+session_id);
    this.getClassSubject();
  }

  getClassSubject() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = 'getClassSubject.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME;

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: this.state.SESSION_ID,
        class_id: this.state.CLASS_ID,

      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          this.setState({
            mClassSubject: response.data,

          })

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //alert(JSON.stringify(this.state.mAdmissionQuery));

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }






  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else {
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/ClassSubject'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Class Subject </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>


                      <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>



                      <div className='row'>
                      {this.state.mClassSubject && this.state.mClassSubject.map((item, index) => {
                         return (
                        <div className='col-md-4 '>
                          <div className='row mar_top1  bg_card_primary_outline'>
                            <div className='col-md-10 padding_0'>
                              <div className='class_subject mar_top1'>{item.subject_name}</div>
                            </div>

                          </div>
                        </div>
                         )
                        })}
                        <div className="col-md-12">
                          <center>
                            <button className="btn btn-success" onClick={this.HandleShow1} style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Add Class Subject</button>
                          </center>
                        </div>

                        {this.state.isOpenAddSubjectDialog === true ? <MdlAddClassSubjectList actionClose={this.HandleCloseMdlclassSubject} class_id={this.state.CLASS_ID} class_name={this.state.CLASS_NAME} class_subject={this.state.mClassSubject} ></MdlAddClassSubjectList> : ''}
                      </div>
                    </div>
                  </div>

                )
              }
            } />

          </switch>

        </Router>
      )
    }
  }

}
