import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import COLORS from '../resources/colors/COLORS';
import icon_forward from '../resources/icons/icons_double_right_100.png'
import icon_edit from '../resources/icons/icons_edit_pencile_100_white.png'
import icon_upcoming from '../resources/icons/icons_upcoming_100_white.png'
import icon_live from '../resources/icons/icons_live_exam_100_white.png'
import icon_over from '../resources/icons/icons_over_100_white.png'
import ExamPaperSettings from './ExamPaperSettings';
import ExamClassResultStatus from './ExamClassResultStatus';
import ExamDetails from './ExamDetails';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from '../dialog/MdlSelectSession';
import ExamCreation from './ExamCreation';
export default class ExamList extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
        isOpenSessionDialog:false,
        loggedIn:true,
        Loading:false, 
        Loading:false, 
        SESSION_ID:'',
        SESSION_NAME:'',
        mExamList:[],
        EXAM_STATUS_UPCOMMING:'1',
        EXAM_STATUS_OVER:'3',
        EXAM_STATUS_LIVE:'2'

    }
    this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
    this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);
  
  }
  componentDidMount()
  {
      var mSession=SessionManager.getCurrentSession();
      this.state.SESSION_ID=mSession[0].session_id;
      this.state.SESSION_NAME=mSession[0].session_name;
      window.scrollTo(0, 0);
      this.getExamList();
  }

  ChangeSession = () => {
 
    this.HandleShowSessionDialog();
  }
  
  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog
    });   
  }
  
  HandleCloseSessionDialog = () => {
  
    this.setState({
      isOpenSessionDialog:false
    });  
     
  }
  HandleSelectedSession = (session_id,session_name) => {
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.setState({
      
      isOpenSessionDialog:false
    });
   
    this.getExamList();
  }
  
  getExamList(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamList.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,session_id:this.state.SESSION_ID}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({
          
          mExamList:response.data
          })
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  saveChanges(){
    alert("Are u sure Edit Exam Details!");
  }
  getIcons(item)
  {
      if(item.status==this.state.EXAM_STATUS_UPCOMMING)
      {
          return icon_upcoming;
      }
      if(item.status==this.state.EXAM_STATUS_LIVE)
      {
          return icon_live;
      }
      else{
        return icon_over;
      }
  }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamList'}  exact render={
            ()=>{
              
         return (
       
          <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam List</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
          <div className="row">

            <div className="col-sm-12 col-md-12">
            <Link to='#' onClick={()=>this.ChangeSession()}>
            <p className="bg_card_blue_outline" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center'}}> Session:
              {this.state.SESSION_NAME}
            <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
            </Link>
            </div>  

            </div>
          <div className="row">
          {this.state.mExamList && this.state.mExamList.map((item, index) => { 
                                           
            return( 

             <div className="col-sm-6 col-md-6">
              <div style={{margin:'10px'}}>
              
                <div className="bg_card_blue_outline">
                  <div className="row">
                    <div className="col-sm-2 col-md-2 gravity_center">
                       <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'25px',width:'25px'}}>
                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade3,'--myBorderColor':COLORS.primary_color_shade5,'--myBorder':'1px'}}>
                              <img  src={this.getIcons(item)}  alt="" style={{width:'100%'}}></img>
                             
                          </div> 
                        </div>  
                       
                    </div>
                    <div className="col-sm-5 col-md-5 gravity_center bg_card_grey">
                      <p style={{fontSize:'15px'}}>{item.status_name}</p>
                    </div>
                    <div className="col-sm-5 col-md-5 gravity_center">
                    <div className="row">
                          <div className="col-sm-12 col-md-12 ">
                            <Link to={'/AdminMain/AddClassFee'}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Update Status </button>
                            </Link>                
                          </div>    
                    </div>
                    </div>
                  
                  </div>
                
                  <div className="row">
                    <div className="col-sm-5 col-md-5 gravity_center">
                      <p style={{fontSize:'18px'}}>Exam Name:</p>
                    </div>
                    <div className="col-sm-5 col-md-5 gravity_center">
                      <p style={{fontSize:'18px'}}>{item.exam_name}</p>
                    </div>
                    <div className="col-sm-2 col-md-2 gravity_center">
                       <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'25px',width:'25px'}}>
                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade3,'--myBorderColor':COLORS.primary_color_shade5,'--myBorder':'1px'}}>
                              <img  src={icon_edit}  alt="" style={{width:'100%'}}></img>
                             
                          </div> 
                        </div>  
                       
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-sm-5 col-md-5 gravity_center">
                      <p style={{fontSize:'14px'}}>Exam Date:</p>
                    </div>
                    <div className="col-sm-5 col-md-5 gravity_center">
                      <p style={{fontSize:'14px'}}>{item.start_date}</p>
                    </div>
                    <div className="col-sm-2 col-md-2 gravity_center">
                       <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'25px',width:'25px'}}>
                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade3,'--myBorderColor':COLORS.primary_color_shade5,'--myBorder':'1px'}}>
                              <img  src={icon_edit}  alt="" style={{width:'100%'}}></img>
                             
                          </div> 
                        </div>  
                       
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-sm-5 col-md-5 gravity_center">
                      <p style={{fontSize:'15px'}}>Participating Class:</p>
                    </div>
                    <div className="col-sm-5 col-md-5 gravity_center">
                      <p style={{fontSize:'15px'}}>{item.participating_class}</p>
                    </div>
                    <div className="col-sm-2 col-md-2 gravity_center">
                       <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'25px',width:'25px'}}>
                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade3,'--myBorderColor':COLORS.primary_color_shade5,'--myBorder':'1px'}}>
                              <img  src={icon_forward}  alt="" style={{width:'100%'}}></img>
                             
                          </div> 
                        </div>  
                       
                    </div>

                  </div>
                  <hr></hr>
                
                   <div className={item.status===this.state.EXAM_STATUS_OVER?'row viewShow':'row viewHide'}>
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamClassResultStatus/'+item.exam_id+"/"+item.session_id+"/"+item.exam_name+"/"+item.start_date}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Result </button>
                            </Link>                
                          </div> 
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamDetails/'+item.exam_id+"/"+item.session_id+"/"+item.exam_name+"/"+item.status_name}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>View Details </button>
                            </Link>                
                          </div>    
                   </div>
                   <div className={item.status===this.state.EXAM_STATUS_LIVE?'row viewShow':'row viewHide'}>
                          <div className="col-sm-12 col-md-12 ">
                            <Link to={'/AdminMain/ExamDetails/'+item.exam_id+"/"+item.session_id+"/"+item.exam_name+"/"+item.status_name}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>View Details </button>
                            </Link>                
                          </div>    
                   </div>
                   <div className={item.status===this.state.EXAM_STATUS_UPCOMMING?'row viewShow':'row viewHide'}>
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamPaperSettings'}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Exam Paper Settings </button>
                            </Link>                
                          </div> 
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamDetails/'+item.exam_id+"/"+item.session_id+"/"+item.exam_name+"/"+item.status_name}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>View Details </button>
                            </Link>                
                          </div>     
                   </div>
                 

                </div>
              </div>

            </div>
              )
            })}
          </div>    
          <div className="row">
            <div className="col-md-12">
            <center>
                <Link to={'/AdminMain/ExamCreation/'}>
                    <button className="btn btn-success" style={{ width: '100%', fontSize: '18px' }}> Create Exam </button>
                </Link>
            </center>
              </div>
          </div>
          {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           

                                 


     </div>


  </div>
           
           )
          }
          } />
            <Route path='/AdminMain/ExamPaperSettings'  component={ExamPaperSettings} />
            <Route path='/AdminMain/ExamClassResultStatus/:exam_id/:session_id/:exam_name/:exam_date'  component={ExamClassResultStatus} />
            <Route path='/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_status'  component={ExamDetails} />  
            <Route path='/AdminMain/ExamCreation' component={ExamCreation} />
                 
    </switch>       
   </Router>  
    )
  }
 }
 }