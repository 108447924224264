import React, { Component,createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import SelectClass from '../ChildComponent/SelectClass';
import myToast from '../Utils/MyToast';


export default class MdlBatchShift extends Component {
    constructor(props){
        super(props)       
        this.slt_board=createRef();
        this.slt_medium=createRef();
        this.slt_class=createRef();
        this.slt_section=createRef();
        
       
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            loggedIn:true,
            Loading:false, 
            show:true, 
            LoadClass:false,  
            CLASS_ID:'',
            SECTION_ID:'',
            mCurentSession:[]
           
        }
        this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
      
}

HandleClose = () => {

  this.setState({
    show: !this.state.show
  });
this.props.action();
  
}

CallBackClassSelect = (class_id,section_id,session_id,selected_position,board_id,medium_id) => {
  
  this.state.CLASS_ID=class_id;
  this.state.SECTION_ID=section_id;
  this.state.SESSION_ID=session_id;
  this.state.SELECTED_CLASS_POSITION=selected_position;
  this.props.action2(class_id,section_id,session_id,selected_position,board_id,medium_id);
  
}


componentDidMount(){
  this.getCurrentOrUpcomingSession();
}

getCurrentOrUpcomingSession(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getCurrentOrUpcomingSession.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{school_code:SCHOOL_CODE,
    
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({       
        mCurentSession:response.data,
        LoadClass:true
        
        })
        this.getClassList();
        //console.log("response>>>>>"+this.state.mClassData);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}  

    render() {
      
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
            return (

              <div>
                
      <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{background:COLORS.primary_color_shade7}}>
        <Modal.Title id="contained-modal-title-vcenter" >
          {this.state.LoadClass==true? 'Select Class To Shift('+this.state.mCurentSession[0].session_name+')':'Select Class To Shift'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.state.Loading ? <MyLoader/> : 
         <div>

                {this.state.LoadClass==true? <SelectClass action={this.CallBackClassSelect} selected_position='-1' session_id={this.state.mCurentSession[0].session_id}></SelectClass>:''}
                              
          </div>
      }
                       
      </Modal.Body>
    </Modal>
            </div>
      
            )
        }
    }
}

