import React, { Component } from 'react'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import IconBack from '../resources/icons/icons_back_36.png'
import IconForward from '../resources/icons/icons_forward_36.png'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



export default class ModalFeeConfirmation extends Component {
    constructor(props){
        super(props)       
        this.state={   
            show:true,
            netPayableAmount:0,
            TRANSACTION_DATE:'',
            TRANSACTION_DATE2:'',
            day_count:0,
            PaymentRemark:'',
            PAYMENT_MODE:'CASH'
            
        }
        this.regChange = this.regChange.bind(this);
}
regChange(e) {

  this.setState({
    [e.target.id]: e.target.value
  })
}
handleChangePaymentMode = event => {
  this.setState({

    PAYMENT_MODE: event.target.value,
  })
 
};
componentDidMount(){
  this.state.netPayableAmount=this.props.netPayableAmount;
 this.getCurrentDate();
 
}
getCurrentDate()
{
  var today=new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();


  var date_dd_mm_yyyy = dd + '-' + mm + '-' + yyyy;
  var date_yyyy_mm_dd = yyyy + '-' + mm + '-' + dd;
 
  this.setState({
    TRANSACTION_DATE:date_dd_mm_yyyy,
    TRANSACTION_DATE2:date_yyyy_mm_dd,
 })

 
}
nextDate(){
  this.state.day_count++;
  var date=new Date();
  date.setDate(date.getDate() + this.state.day_count);

  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();

  var date_dd_mm_yyyy = dd + '-' + mm + '-' + yyyy;
  var date_yyyy_mm_dd = yyyy + '-' + mm + '-' + dd;
 
  this.setState({
    TRANSACTION_DATE:date_dd_mm_yyyy,
    TRANSACTION_DATE2:date_yyyy_mm_dd,
})

}
previousDate(){
  this.state.day_count--;
  var date=new Date();
  date.setDate(date.getDate() + this.state.day_count);

  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();

  var date_dd_mm_yyyy = dd + '-' + mm + '-' + yyyy;
  var date_yyyy_mm_dd = yyyy + '-' + mm + '-' + dd;
 
  this.setState({
    TRANSACTION_DATE:date_dd_mm_yyyy,
    TRANSACTION_DATE2:date_yyyy_mm_dd,
})
//alert(this.state.TRANSACTION_DATE);
}

HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action();
    
  }
  
submitFeeDetails = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action2(this.state.TRANSACTION_DATE2,this.state.PAYMENT_MODE,this.state.PaymentRemark);
    
  }
    
render() {
        return (
            <div>
              <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
               
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{background:COLORS.primary_color_shade7,color:COLORS.white}}>
                    <Modal.Title id="contained-modal-title-vcenter " >
                      Fee Collection Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
            
                <div className="">
                   <div className="gravity_center">
                        <h4>Transaction Amount</h4>
                  </div>
                  <div className="bg_card_grey gravity_center width_match_parent" style={{height:50,color:COLORS.black,fontSize:20}}>
                        <p>Rs:{this.props.netPayableAmount}</p>
                  </div>
                </div>

                <div className="bg_corner_round_green margin_5">
                   <div className="gravity_center">
                        <h5>Collection Date</h5>
                  </div>
                  <div className="row width_match_parent padding_5">
                       <div className="col-sm-2 col-md-2">
                             <div className='bg_circle_primery1 padding_0 gravity_center cursor' onClick={()=>this.previousDate()} style={{ width: '40px',height: '40px' }}>
                               <img src={IconBack} ></img> 
                             </div>
                       </div>
                       <div className="col-sm-8 col-md-8 gravity_center">
                            <h5>{this.state.TRANSACTION_DATE}</h5>
                       </div>
                       <div className="col-sm-2 col-md-2">
                        <div className='bg_circle_primery1 padding_0 gravity_center cursor' onClick={()=>this.nextDate()} style={{ width: '40px',height: '40px' }}>
                               <img src={IconForward} ></img>
                             </div>
                       </div>
                  </div>

                 
                </div>


                <div className="bg_corner_round_green margin_5">
                   <div className="gravity_center">
                        <h5>Payment Mode</h5>
                  </div>
                  <div className="row width_match_parent padding_5">
                       
                       <div className="col-sm-12 col-md-12 gravity_center">
                       <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" 
                       value={this.state.PAYMENT_MODE} onChange={this.handleChangePaymentMode} row={true}
                       defaultValue="CASH">
                                            <FormControlLabel
                                              value="CASH"
                                              control={<Radio color="primary" />}
                                              label="Pay in Cash"
                                              labelPlacement="end"
                                              defaultChecked ="true"
                                             
                                            />
                                            <FormControlLabel
                                              value="CHEQUE"
                                              control={<Radio color="primary" />}
                                              label="Pay by Cheque"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="ONLINE"
                                              control={<Radio color="primary" />}
                                              label="Pay Online"
                                              labelPlacement="end"
                                            />
                                           
                                          </RadioGroup>
                       </div>
                     
                     
                      
                  </div>
               

                 
                </div>
                <div className="bg_corner_round_green margin_5">
                   <div className="gravity_center">
                        <h5>Payment remark</h5>
                  </div>
                <div className="row width_match_parent padding_5">
                       
                       <div className="col-sm-12 col-md-12 gravity_center">
                       <div className="input-group admission_form2">
                           <input type="text" required id="PaymentRemark" variant="outlined" placeholder="Payment Remark" value={this.state.PaymentRemark} onChange={this.regChange} className="form-control" />
                        </div>
                       </div>
                     
                     
                       </div> 
                  </div>
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" size="lg" className='width_match_parent' block onClick={this.submitFeeDetails}>
                        Submit
                   </Button>
                    
                  </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
