import React, { Component, useRef, createRef } from 'react'
import '../css/Admin_css/admin-customize.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import axios from 'axios'
import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';
import Form from 'react-bootstrap/Form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import StRegSuccessfull from './StRegSuccessfull';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from '../dialog/MdlSelectSession';


export default class StudentRegistration extends Component {
  constructor(props) {
    super(props)
    this.slt_board=createRef();
    this.slt_medium=createRef();
  
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn: false,
      Loading: false,
      loadClass:true,
      isOpenSessionDialog:false,
   
      AddmisType: '',
      AddmissionNumber: '1',
      FirstName: '',
      LastName: '',
      dob: '',
      date: '',
      gender: '',
      caste: '',
      community: '',
      RELIGION: '',
      AdharNo: '',
      nationality: 'Indian',
      board_id: '',
      medium_id: '',

      curntVillage: '',
      crntPost: '',
      crntDistrict: '',
      crntState: '',
      crntPincode: '',
      perVillage: '',
      perPost: '',
      perDistrict: '',
      perState: '',
      perPincode: '',
      FatherName: '',
      fatherOccupation: 'Farmer',
      father_qualification: '',
      father_aadhar: '',
      motherName: "",
      motherOccupation: 'House Wife',
      mother_qualification: "",
      mother_aadhar: "",
      contact1: '',
      contactName_1: '',
      relation_1: '',
      contact2: '',
      contactName_2: '',
      relation_2: '',
      email: '',
  
      CLASS_ID: '',
      SECTION_ID: '',
      SESSION_ID:'',
      SESSION_NAME:'',
      SESSION_STATUS:'',
      PreviousSchoolDetails: '',
      StateSpecificIdName: '',
      StateSpecificIdValue: '',
      crntPoliceStation: '',
      perPoliceStation: '',


      classBoard:[],
      classMedium:[],
      ClassSection:[],
      mFilteredClassList:[],
      mFilteredClassSectionList:[],
      mClassData:[], 
      CLASS_SELECTED_POSITION:0,

    }
    this.regSubmit = this.regSubmit.bind(this);
    this.regChange = this.regChange.bind(this);
   
  }
  
  componentDidMount() {
    
    var mSession=SessionManager.getCurrentSession();
    this.state.SESSION_ID=mSession[0].session_id;
    this.state.SESSION_NAME=mSession[0].session_name;
    this.state.SESSION_STATUS=mSession[0].status;
 
    this.getClass();
    this.getAdmissionNo();

 
 }

  ChangeSession = () => {
 
    this.HandleShowSessionDialog();
  }
  
  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog
    });   
  }
  
  HandleCloseSessionDialog = () => {
  
    this.setState({
      isOpenSessionDialog:false
    });  
     
  }
  HandleSelectedSession = (session_id,session_name,session_status) => {
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.state.SESSION_STATUS=session_status;
    this.setState({
      
      isOpenSessionDialog:false,
      
    });
    this.getClass();
    
  }
  selectClass=(position)=>{  
    this.state.CLASS_SELECTED_POSITION=position;  
    this.state.CLASS_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION]['class_id'];
    this.state.SECTION_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION]['section_id'];
   
    this.setState({

    })  
    
  }

  getClass(){
  
  this.setState({
    Loading:true
  })

  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getClass.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
     // console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
      //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({
          
          mClassData:response.data.class,
          classBoard:response.data.board,
          classMedium:response.data.medium,
          ClassSection:response.data.class[0].section
          })
          this.getClassList();
         // console.log("response>>>>>"+this.state.mClassData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
 
  onBoardChange = () => {
    this.state.CLASS_SELECTED_POSITION=0;
    this.getClassList();
    
  }
  onMediumChange = () => {
    this.state.CLASS_SELECTED_POSITION=0;
    this.getClassList();
    
  }
  getClassList()
  {
    let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
   // alert(board_id);
    //alert(medium_id);
    
    this.state.mFilteredClassSectionList=[];
    var mFilteredClassList=[];
    for (var i=0; i < this.state.mClassData.length; i++) {
      let item=this.state.mClassData[i];
      if(item.board_id===board_id && item.medium_id===medium_id)
      {
    
        mFilteredClassList.push(item);
  
      }   
    }
    for (var i=0; i <mFilteredClassList.length; i++) {
      let item=mFilteredClassList[i];
      let SECTION=item.section;
              if(SECTION==="No")
              {
                item["section_id"]="No";
                this.state.mFilteredClassSectionList.push(item);
              }
              else {
    
                var tempSection=[];
                tempSection=item.section;
                for (var j=0;j<tempSection.length;j++)
                {
                    let item2=tempSection[j];
                    item2["board_id"]=board_id;
                    item2["board_name"]=item.board_name;
                    item2["medium_id"]=medium_id;
                    item2["medium_name"]=item.medium_name;               
                    this.state.mFilteredClassSectionList.push(item2);
                    
                }
              }
             
    } 
    this.setState({

    })
    if(this.state.mFilteredClassSectionList.length!==0)
    {
      this.state.CLASS_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION]['class_id'];
      this.state.SECTION_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION]['section_id'];
   
      
    }
    //alert(JSON.stringify(this.state.mFilteredClassSectionList));
    
  }

  handleChangeAdmissionType = event => {
    console.log("adm>>>>>>>>>>>>", event.target.value);
    this.setState({

      AddmisType: event.target.value,
    })

    /* alert(this.state.AddmisType);
   */
  };
  handleChangeGender = event => {
    this.setState({

      gender: event.target.value,
    })
  };
  handleChangeCommunity = event => {
    this.setState({

      community: event.target.value,
    })
  };
  handleChangeReligion = (e) => {
    this.state.RELIGION=(e.target.value);
   // alert(this.state.RELIGION)
  };

  handleChangeCurState = (e) => {
    this.setState({
      crntState:(e.target.value)
    })
  
   // alert(this.state.RELIGION)
  };
  handleChangePerState = (e) => {

    this.setState({
      perState:(e.target.value)
    })
    
   // alert(this.state.RELIGION)
  };
  handleChangeRelation_1 = (e) => {

    this.setState({
      relation_1:(e.target.value)
    })
    
  };
  
  handleChangeRelation_2 = (e) => {

    this.setState({
      relation_2:(e.target.value)
    })
    
  };
  

  getAdmissionNo() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getLastAdmissionNo.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,

      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })

        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          if (response.data.length !== 0) {

            var sr_admission_no = response.data[0].sr_admission_no;

            var temp = sr_admission_no.match(/[a-z]+|[^a-z]+/gi);

            if (temp.length === 1) {
              this.state.AddmissionNumber = parseInt(temp[0]) + 1;
            }
            else {
              if (isFinite(temp[0])) {
                this.state.AddmissionNumber = (parseInt(temp[0]) + 1) + temp[1];
              }
              else {
                this.state.AddmissionNumber = temp[0] + (parseInt(temp[1]) + 1);
              }

            }
          }
          else {
            this.state.AddmissionNumber = 1;
          }
          //alert(this.state.AddmissionNumber);
          this.setState({

          })

        }
        else {
          let api_remark = response.api_remark;
          //myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }

  regChange(e) {

    this.setState({
      [e.target.id]: e.target.value
    })
  }
  copytopermAddr = e => {
    let val = e.target.checked;
    if (val) {
      this.setState({
        perVillage: this.state.curntVillage,
        perPost: this.state.crntPost,
        perDistrict: this.state.crntDistrict,
        perState: this.state.crntState,
        perPoliceStation: this.state.crntPoliceStation,
        perPincode: this.state.crntPincode
      });
    } else {
      this.setState({
        perVillage: this.state.perVillage,
        perPost: this.state.perPost,
        perDistrict: this.state.perDistrict,
        perState: this.state.perState,
        perPoliceStation: this.state.perPoliceStation,
        perPincode: this.state.perPincode
      });
    }
  };
  regSubmit() {
    //alert(this.state.AddmisType);
    if (this.isValidInput()) {


      this.state.board_id = this.slt_board.current.selectedOptions[0].getAttribute('board_id');
      this.state.medium_id = this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');

      const SCHOOL_CODE = SessionManager.getSchoolCode();
     
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;

      var option = document.getElementsByName('admission_type');
      if ((option[0].checked)) {

        this.state.AddmisType="New";
      }
      else{
        this.state.AddmisType="Re";
      }
     
      const ACADEMIC_MODE = "ACDM01";
      const FUNCTION_NAME = 'registerStudent.php?'
      URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME;
      console.log("URL>>>>>" + URL);

      var params = new URLSearchParams();
      params.append('school_code', SCHOOL_CODE);
      params.append('session_id', this.state.SESSION_ID);
      params.append('student_name', this.state.FirstName + " " + this.state.LastName);
      params.append('dob', this.state.dob);
      params.append('admDate', this.state.date);
      params.append('admission_type', this.state.AddmisType);
      params.append('admission_no', this.state.AddmissionNumber);
      params.append('state_specific_id_name', this.state.StateSpecificIdName);
      params.append('state_specific_id_value', this.state.StateSpecificIdValue);
      params.append('previous_school_details', this.state.PreviousSchoolDetails);
      params.append('academic_mode', ACADEMIC_MODE);
      params.append('religion', this.state.RELIGION);
      params.append('gender', this.state.gender);
      params.append('community', this.state.community);
      params.append('caste', this.state.caste);
      params.append('aadhar_no', this.state.AdharNo);
      params.append('nationality', this.state.nationality);
      params.append('board_id', this.state.board_id);
      params.append('medium_id', this.state.medium_id);
      params.append('class_id', this.state.CLASS_ID);
      params.append('section_id', this.state.SECTION_ID);
      params.append('village_town', this.state.curntVillage);
      params.append('post_locality', this.state.crntPost);
      params.append('police_station', this.state.crntPoliceStation);
      params.append('curAddress', "Village : " + this.state.curntVillage + " Post : " + this.state.crntPost + " District : " + this.state.crntDistrict + " State : " + this.state.crntState + " Pincode : " + this.state.crntPincode);
      params.append('perAddress', "Village : " + this.state.perVillage + " Post : " + this.state.perPost + " District : " + this.state.perDistrict + " State : " + this.state.perState + " Pincode : " + this.state.perPincode);

      params.append('father_name', this.state.FatherName);
      params.append('father_occupation', this.state.fatherOccupation);
      params.append('father_qualification', this.state.father_qualification);
      params.append('father_aadhar', this.state.father_aadhar);

      params.append('mother_name', this.state.motherName);
      params.append('mother_occupation', this.state.motherOccupation);
      params.append('mother_qualification', this.state.mother_qualification);
      params.append('mother_aadhar', this.state.mother_aadhar);

      params.append('contact1', this.state.contact1);
      params.append('contact_person_name1', this.state.contactName_1);
      params.append('contact_person1', this.state.relation_1);

      params.append('contact2', this.state.contact2);
      params.append('contact_person_name2', this.state.contactName_2);
      params.append('contact_person2', this.state.relation_2);
      params.append('email', this.state.email);
      params.append('caste', this.state.caste);
      params.append('staff_id', STAFF_ID);



      axios.post(URL, params).then(res => res.data)
        .then((response) => {

          this.setState({
            Loading: false
          })
          let api_status = response.api_status;
          //console.log("responsel>>>>>"+response);
          if (api_status == "OK") {
            var enrollment_no = response.data;
            alert("successfully Registered !");
            window.location.reload();
            window.location = "/AdminMain/StudentRegSuccess/" + enrollment_no + "/" + this.state.SESSION_ID;

          }
          else {
            alert("Problem in Registration !");
          }

        })
   }
  }

  isValidInput() {
    
    //alert(this.state.RELIGION );
    var option = document.getElementsByName('admission_type');

    if (!(option[0].checked || option[1].checked)) {
      alert("Please Select  Addmission Type !");
      return false;
    }

    else if (this.state.FirstName === "") {
        alert('First Name is Required!');
        return false;
      }

      else if (this.state.dob === "") {
        alert("DOB  is Required!");
        return false;
      }
      else
        var option = document.getElementsByName('radio_css_inline');

    if (this.state.gender === "") {
      alert("Please Select Your Gender");
      return false;
    }
    else
      var option = document.getElementsByName('radio_css_inline1');

    if (this.state.community === "") {
      alert("Please Select Your Community");
      return false;
    }

    else if (this.state.nationality === "") {
      alert("Nationality is Required!");
      return false;
    }

    else if (this.state.RELIGION ==="Select Religion" || this.state.RELIGION ==="") {
       alert("Religion  is Required!!");
      return false;
    }
    else if (this.state.board === "") {
      alert("Select Board!");
      return false;
    }
    else if (this.state.medium === "") {
      alert("Select Medium !");
      return false;
    }
    else if (this.state.class === "") {
      alert("Enter your Class Name");
      return false;
    }
    else if (this.state.curntVillage === "") {
      alert("Current village  is Required!");
      return false;
    }
    else if (this.state.crntPost === "") {
      alert("Current Post  is Required!");
      return false;
    }
    else if (this.state.crntDistrict === "") {
      alert("Current District  is Required!");
      return false;
    }
    else if (this.state.crntState === "") {
      alert("Current state  is Required!");
      return false;
    }
    else if (this.state.crntPincode === "") {
      alert("Current Pin code  is Required!");
      return false;
    }
    else if (this.state.perVillage === "") {
      alert("Permanent village is Required!");
      return false;
    }
    else if (this.state.perPost === "") {
      alert("Permanent Post  is Required!");
      return false;
    }
    else if (this.state.perDistrict === "") {
      alert("Permanent District  is Required!");
      return false;
    }
    else if (this.state.perState === "") {
      alert("Permanent state  is Required!");
      return false;
    }
    else if (this.state.perPincode === "") {
      alert("Permanent Pin code  is Required!");
      return false;
    }
    else if (this.state.FatherName === "") {
      alert("Father Name  is Required!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  is Required!");
      return false;
    }
    else if (this.state.motherName === "") {
      alert("Mother Name  is Required!");
      return false;
    }
    else if (this.state.motherOccupation === "") {
      alert("Mother Occupation  is Required!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation is Required!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  is Required!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  is Required!");
      return false;
    }
    else if (this.state.fatherOccupation === "") {
      alert("Father Occupation  is Required!");
      return false;
    }
    else {
      return true;
    }

  }
 
  render() {

    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/StudentRegistration'} exact render={
              () => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Student Registration</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="row animated fadeInUp">
                      <div className="col-sm-12 col-lg-12">
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="panel">
                              <div className="panel-content text-center" style={{ minHeight: '400px' }}>

                                {/* content body */}
                                <div className="panel-body">
                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="form-horizontal form-bordered ">
                                      <div className="col-md-10 bg_card_blue_outline1 s mar_bottom bg_admission">
                                       <div className='row'>
                                          <div className="col-sm-12 col-md-12" style={{marginTop:'8px'}}>
                                          <Link to='#' onClick={()=>this.ChangeSession()}>
                                          <p className="bg_card_blue" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center',color:COLORS.white}}> Session:
                                            {this.state.SESSION_NAME+"("+ this.state.SESSION_STATUS+")"}
                                          <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
                                          </Link>
                                          </div>  
                                       </div>
                                       
                                        <div className='row'>
                                          <div className="col-md-4 padding_0  admission_prvform3 mar_top1">
                                            <label className=""> ADMISSION TYPE:</label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <div className='d-flex admission_form'>
                                              <div className='padding_5 margin_3'>
                                                <input type='radio' required id='' name='admission_type' value='' /> New Admission
                                              </div>
                                              <div className='padding_5 margin_3'>
                                                <input type='radio' required id='' name='admission_type' value='' /> Re Admission(Promoted)
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div className=" row padding_5">
                                          <div className="col-md-4 ">
                                            <div className='padding_5 admission_prvform1 bg_grid_primary1 margin_3'>
                                              <label className="">Admission Number
                                                (Optional)</label>
                                              <div className="input-group admission_form2">
                                                <input type="text" required id="AddmissionNumber" name="Addmission Number" variant="outlined" placeholder="Admission Number(Optional)" value={this.state.AddmissionNumber} onChange={this.regChange} className="form-control word_center" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-4 ">
                                            <div className='padding_5 admission_prvform1 bg_grid_primary1 margin_3'>
                                              <label className="">State Specific Id Name(Optional)</label>
                                              <div className="input-group admission_form2">
                                                <input type="text" required id="StateSpecificIdName" variant="outlined" placeholder="State Speafic Id Name" value={this.state.StateSpecificIdName} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-4">
                                            <div className='padding_5 admission_prvform1 bg_grid_primary1 margin_3'>
                                              <label className="">State Specific Id Number(Optional)</label>
                                              <div className="input-group admission_form2">
                                                <input type="text" required id="StateSpecificIdValue" variant="outlined" placeholder="State Speafic Id Number" value={this.state.StateSpecificIdValue} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>


                                        <div className="row ">
                                          <div className='col-md-4'>
                                            <div className=' admission_prvform2 padding_0'>
                                              <label className="padding_0">Previous School Details:</label>
                                            </div>
                                          </div>
                                          <div className='col-md-8'>
                                            <div className="input-group admission_prvform1 padding_0 ">
                                              <input type="text" required id="PreviousSchoolDetails" variant="outlined" placeholder="Previous School Details" value={this.state.PreviousSchoolDetails} onChange={this.regChange} style={{ width: '100%' }} className="mar_right1 form-control" />
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div className="col-md-2 bg-white mar_bottom mar_top1 ">
                                        <div class="circular_image1">
                                          <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img_staff1" alt="student list image" />
                                        </div>

                                        <div className="photo-row-bottom posi-rel">
                                          <span><input type="file" name="upload photo" style={{ width: '150px' }} /></span>
                                        </div>

                                      </div>

                                    </div>
                                  </div>


                                  <div className="pnlSubHedingBorder">
                                    <div className="pnlSubHeding heading-with-bg-w">
                                      Basic Information
                                    </div>
                                  </div>

                                  <div className="row mar_top">

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">First Name</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" required id="FirstName" variant="outlined" placeholder="First Name" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Last Name</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" required id="LastName" variant="outlined" placeholder="Last Name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className="">Admission Date</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group date admission_form2" id="stAdmissionDate">
                                            <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className="">Date of Birth</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group date" id="stDateofBirth">
                                            <input type="date" id="dob" name="dob" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className=""> Gender </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className='d-flex admission_form'>
                                            <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.gender} onChange={this.handleChangeGender} row>
                                              <FormControlLabel
                                                value="Male"
                                                control={<Radio color="primary" />}
                                                label="Male"
                                                labelPlacement="end"
                                              />
                                              <FormControlLabel
                                                value="Female"
                                                control={<Radio color="primary" />}
                                                label="Female"
                                                labelPlacement="end"
                                              />
                                            </RadioGroup>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 padding_0">
                                      <div className='row padding_0'>
                                        <div className="col-md-4 padding_0  admission_prvform1">
                                          <label className="admission_radio1">Community</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.community} onChange={this.handleChangeCommunity} row>
                                            <FormControlLabel
                                              value="SC"
                                              control={<Radio color="primary" />}
                                              label="SC"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="ST"
                                              control={<Radio color="primary" />}
                                              label="ST"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="OBC"
                                              control={<Radio color="primary" />}
                                              label="OBC"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="General"
                                              control={<Radio color="primary" />}
                                              label="General"
                                              labelPlacement="end"
                                            />
                                            <FormControlLabel
                                              value="Others"
                                              control={<Radio color="primary" />}
                                              label="Others"
                                              labelPlacement="end"
                                            />
                                          </RadioGroup>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Religion</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="admission_form2">
                                          
                                            <Form.Select className="form-control"  onChange={this.handleChangeReligion} id="student_religion">
                                                  <option value="Select Religion">Select Religion</option>
                                                  <option value="Hindu">Hindu</option>
                                                  <option value="Muslim">Muslim</option>
                                                  <option value="Christian">Christian</option>
                                                  <option value="Sikh">Sikh</option>
                                                  <option value="Buddhist">Buddhist</option>
                                                  <option value="Jain">Jain</option>
                                                  <option value="Other">Other</option>
                                           </Form.Select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Caste(Optional)</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="caste" type="text" placeholder='Caste' value={this.state.caste} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Adhar Number</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="AdharNo" type="text" placeholder='Adhar Number' value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Nationality</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input type="text" id="nationality" placeholder='Nationality' name="nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>




                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Class Information
                                      </div>
                                    </div>
                                    <div className="bg_card_blue" style={{padding:'5px 5px 10px 5px'}}> 

        
                <div className="row m-0">
                  <div className="col-sm-6 col-md-6" >
                  <div className="">
                  <h5>Select Boards:</h5>
                  <select className="select-field" onChange={this.onBoardChange} ref = {this.slt_board}>
                  {this.state.classBoard.map((item, index) => ( 
                  <option board_id={item.board_id}>{item.board_name}</option>
                     
                     ))}
                  </select>
                  </div>
                  </div>
                   <div className="col-sm-6 col-md-6" >
                    <div className="">
                     <h5>Select Medium</h5>
                     <select className="select-field" onChange={this.onMediumChange} ref = {this.slt_medium}>
                     {this.state.classMedium.map((item, index) => ( 
                     <option medium_id={item.medium_id}>{item.medium_name}</option>
                     ))}
                   
                     </select>
                    </div>
                  </div>
  
                 </div> 
              
                    <div className="row" style={{padding:'0px 15px 0px 15px'}}>
                    {this.state.mFilteredClassSectionList.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>
                
            </div>
                        

                                  </div>

                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Current Address
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Village/Town </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="curntVillage" placeholder='Village/Town/Building Number' value={this.state.curntVillage} onChange={this.regChange} type="text" className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Post/Locality </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntPost" type="text" placeholder='Post/Locality' value={this.state.crntPost} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Police Station </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntPoliceStation" type="text" placeholder='Police Station' value={this.state.crntPoliceStation} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> District </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntDistrict" type="text" placeholder='District' value={this.state.crntDistrict} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> State </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="admission_form2">
                                           
                                          <Form.Select className="form-control"  value={this.state.crntState} onChange={this.handleChangeCurState} id="">
                                          
                                                  <option value="Select State">Select State</option>
                                                    <option value="Andra Pradesh">Andra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Orissa">Orissa</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telagana">Telagana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttaranchal">Uttaranchal</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                    <option value="Pondicherry">Pondicherry</option>

                                           </Form.Select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className=""> Pin Code </label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2">
                                            <input id="crntPincode" placeholder='Pin Code' type="text" value={this.state.crntPincode} onChange={this.regChange} className="form-control" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                  <div className="pnlSubHedingBorder headMarginTop">
                                    <div className="pnlSubHeding heading-with-bg-w">
                                      Permanent Address
                                    </div>
                                  </div>

                                  <div className="row mar_top">
                                    <div className="col-md-8 padding_0">
                                      <div className=" padding_0 admission_prvform1 d-flex">
                                        <label className="admission_radio1"> Same As Current Address </label>
                                        <div className="switcher admission_radio1">
                                          <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" onChange={this.regChange} defaultChecked={this.state.checked} onClick={this.copytopermAddr} />
                                          <label htmlFor="switcher_checkbox_1" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Village/Town </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perVillage" placeholder='Village/Town/Building Number' type="text" value={this.state.perVillage} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Post/Locality </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perPost" placeholder='Post/Locality' type="text" value={this.state.perPost} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Police Station </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perPoliceStation" type="text" placeholder='Police Station' value={this.state.perPoliceStation} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> District </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perDistrict" placeholder='District' type="text" value={this.state.perDistrict} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> State </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="admission_form2">
                                        <Form.Select className="form-control"  value={this.state.perState} onChange={this.handleChangePerState} id="">
                                          
                                              <option>Select State</option>
                                           
                                                       <option value="Andra Pradesh">Andra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Orissa">Orissa</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telagana">Telagana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttaranchal">Uttaranchal</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                    <option value="Pondicherry">Pondicherry</option>

                                   </Form.Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className='row'>
                                      <div className="col-md-4 padding_0 admission_prvform1">
                                        <label className=""> Pin Code </label>
                                      </div>
                                      <div className="col-md-8 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="perPincode" placeholder='Pin Code' type="text" value={this.state.perPincode} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Family Information
                                      </div>
                                    </div>
                                    <div className="row mar_top">

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className=""> Father Name </label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="FatherName" placeholder='Father Name' type="text" value={this.state.FatherName} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-6 padding_0 admission_prvform1">
                                            <label className="">Father Occupation</label>
                                          </div>
                                          <div className="col-md-6 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="fatherOccupation" placeholder='Father Occupation' type="text" value={this.state.fatherOccupation} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-4 padding_0 admission_prvform1">
                                            <label className=""> Mother Name </label>
                                          </div>
                                          <div className="col-md-8 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="motherName" placeholder='Mother Name' type="text" value={this.state.motherName} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className='row'>
                                          <div className="col-md-6 padding_0 admission_prvform1">
                                            <label className="">Mother Occupation</label>
                                          </div>
                                          <div className="col-md-6 padding_0">
                                            <div className="input-group admission_form2">
                                              <input id="motherOccupation" placeholder='Mother Occupation' type="text" value={this.state.motherOccupation} onChange={this.regChange} className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>

                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Contact Details
                                      </div>
                                    </div>

                                    <div className='row mar_top'>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Contact Number 1</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contact1" placeholder='Enter Mobile Number' type="text" value={this.state.contact1} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Name</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contactName_1" type="text" placeholder='Name' value={this.state.contactName_1} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className="">Relation</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                        <Form.Select className="form-control"  value={this.state.relation_1} onChange={this.handleChangeRelation_1}>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Uncle">Uncle</option>
                                            <option value="Aunt">Aunt</option>
                                            <option value="Grand Father">Grand Father</option>
                                            <option value="Grand Mother">Grand Mother</option>
                                            <option value="Elder Brother">Elder Brother</option>
                                            <option value="Elder Sister">Elder Sister</option>
                                            <option value="Younger Brother">Younger Brother</option>
                                            <option value="Younger Sister">Younger Sister</option>
                                            <option value="Maternal Uncle">Maternal Uncle</option>
                                            <option value="Maternal Aunt">Maternal Aunt</option>
                                            <option value="Maternal Grand Father">Maternal Grand Father</option>
                                            <option value="Maternal Grand Mother">Maternal Grand Mother</option>


                                          </Form.Select>
                                        </div>
                                      </div>

                                    </div>

                                    <div className='row'>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Contact Number 2</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contact2" type="text" placeholder='Contact Number ' value={this.state.contact2} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>
                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className=""> Name</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="contactName_2" type="text" placeholder='Name' value={this.state.contactName_2} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                      <div className="col-md-2 padding_0 admission_prvform2">
                                        <label className="">Relation</label>
                                      </div>
                                      <div className="col-md-2 padding_0">
                                        <div className="input-group admission_form2">
                                        <Form.Select className="form-control"  value={this.state.relation_2} onChange={this.handleChangeRelation_2}>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Uncle">Uncle</option>
                                            <option value="Aunt">Aunt</option>
                                            <option value="Grand Father">Grand Father</option>
                                            <option value="Grand Mother">Grand Mother</option>
                                            <option value="Elder Brother">Elder Brother</option>
                                            <option value="Elder Sister">Elder Sister</option>
                                            <option value="Younger Brother">Younger Brother</option>
                                            <option value="Younger Sister">Younger Sister</option>
                                            <option value="Maternal Uncle">Maternal Uncle</option>
                                            <option value="Maternal Aunt">Maternal Aunt</option>
                                            <option value="Maternal Grand Father">Maternal Grand Father</option>
                                            <option value="Maternal Grand Mother">Maternal Grand Mother</option>


                                          </Form.Select>
                                        </div>
                                      </div>
                                      <div className="col-md-2 padding_0 admission_prvform1">
                                        <label className="email_mar_left">Email Address </label>
                                      </div>
                                      <div className="col-md-10 padding_0">
                                        <div className="input-group admission_form2">
                                          <input id="email" type="text" value={this.state.email} onChange={this.regChange} className="form-control" />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <center>
                                    <button className="btn btn-success" onClick={this.regSubmit} style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Submit</button>

                                  </center>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                 {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           
                    </div>
                  </div>

                )
              }
            } />

            <Route path='/AdminMain/StudentRegSuccess/:Enrollment_no/:session_id' component={StRegSuccessfull} />

          </switch>

        </Router >
      )
    }
  }
}
