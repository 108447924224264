import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactHtmlParser from 'react-html-parser';
const Rs = '&#8377;';

export default class MdlAddClassSubjectList extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      CLASS_ID: '',
      mSessionList: [],
      SESSION_SELECTED_POSITION: 0,
      mSubjectList: [],
      mSubjectMasterList: [],
      mFreshSubjectList: []
    }

  }
  componentDidMount() {
    this.state.CLASS_ID = this.props.class_id;
    this.state.CLASS_NAME = this.props.class_name
    this.state.mSubjectList = this.props.class_subject

    this.getSubjectMasterList();
    //alert(this.state.mSubjectList)
  }

  AddclasSubject = (item,index) => {
   // alert(index)

  }


  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }
  HandleSubmit = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

    //alert("Add new class");

  }

  getSubjectMasterList() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = 'getSubjectMasterList.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME;

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        //alert(api_status);
        //alert(JSON.stringify(response));

        if (api_status == "OK") {

          this.setState({
            mSubjectMasterList: response.data,

          })
          this.getNotAddedSubject();
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //alert(JSON.stringify(this.state.mAdmissionQuery));

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  getNotAddedSubject() {

    for (let i = 0; i < this.state.mSubjectMasterList.length; i++) {

      var item = this.state.mSubjectMasterList[i];
      var Subject_id = item.subject_id;

      //alert(Subject_id)

      var flag = this.getAlreadyAddedSubject(Subject_id);
  //alert(flag)
      if (!flag) {
        this.state.mFreshSubjectList.push(item);

        //alert(this.state.mFreshSubjectList)
      }
    }
    this.setState({

    })

  }

  getAlreadyAddedSubject(Subject_id) {

    for (let i = 0; i < this.state.mSubjectList.length; i++) {

      if (this.state.mSubjectList[i].subject_id == Subject_id) {

        return true;

      }
    }
    return false;
  }

  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
                Add Subject To Class:{this.state.CLASS_NAME}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :

                <>
                  <div className='row'>
                    {this.state.mFreshSubjectList && this.state.mFreshSubjectList.map((item, index) => {
                      return (
                        <div className='col-md-6 padding_5'>
                          <div className='bg_card_primary_outline' onClick={this.AddclasSubject(item,index)}>
                            <div className='row'>
                              <div className='col-md-10'><label for="checkbox_id" className='class_subject'>{item.subject_name}</label></div>
                              <div className='col-md-2 mar_top2'><input type="checkbox" className='abcd ' name="checkbox" id="checkbox_id" value="value"></input></div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                </>
              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <Button variant="primary" size="lg" onClick={this.HandleSubmit}>
                  Submit Subject
                </Button>
                <Button variant="primary" size="lg" onClick={this.HandleClose}>
                  Close
                </Button>
              </div>

            </Modal.Footer>
          </Modal>
        </div >

      )
    }
  }
}

