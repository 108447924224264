import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import MdlSelectExam from '../dialog/MdlSelectExam';
import MdlDeleteExamConfirmation from '../dialog/MdlDeleteExamConfirmation';

export default class DeleteExam extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mExamRecord: [],
      mSelectedStaffList: [],
      ShowExamSelector: false,
      showDeleteConfirmation: false,
      EXAM_ID: '',
      SESSION_ID: '',
      EXAM_NAME:''
    }

  }
  componentDidMount() {

  }


  SelectExam = () => {

    this.setState({
      ShowExamSelector: !this.state.ShowExamSelector
    });


  }
  DeleteExamConfirm = () => {

    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation
    });


  }


  HandleSelectedExam = (exam_id, exam_name, session_id) => {
    this.setState({
      ShowExamSelector: !this.state.ShowExamSelector,
      EXAM_ID: exam_id,
      SESSION_ID: session_id,
      EXAM_NAME:exam_name
    });

    //alert("call back"+exam_id);
    this.getExamDependentRecord();

  }


  getExamDependentRecord() {
    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getExamDependentRecord.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        exam_id: this.state.EXAM_ID,
        session_id: this.state.SESSION_ID,
        school_code: SCHOOL_CODE
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mExamRecord: response.data

          })
          //alert(JSON.stringify(this.state.mStaffList));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {
      return (
        <Router>
          <switch>
            <Route path='/AdminMain/DeleteExam' exact render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Exam List</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
                      <div className=" row">
                        <div className="col-sm-12 text-center cursor" style={{ margin: '15px auto' }} onClick={this.SelectExam}>
                          <h3 style={{ background: '#129cd9', color: '#fff', padding: '7px' }}> Select Exam</h3>
                        </div>
                      </div>
                      <h4 className='staffcenter'>Exam Name-{this.state.EXAM_NAME}</h4>
                      <p className='staffcenter1'>These records will be deleted </p>
                      <hr />
                      <div className="row class-li">
                        {this.state.mExamRecord && this.state.mExamRecord.map((item, index) => (
                          <div className="col-sm-6 col-md-6 ">
                            <div className="row class-list-box bb-r-c bg_card_burnt_orange_outline exam_list_margin">
                              <div className="col-sm-8 col-md-8 text_burnt">
                                <h3>{item.table_caption}</h3>
                              </div>
                              <div className="col-sm-4 col-md-4 text_burnt">
                                <h3>{item.records}</h3>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className=" row">
                        <div className="col-sm-12 text-center cursor" style={{ margin: '15px auto' }} onClick={this.DeleteExamConfirm}>
                          <h3 style={{ background: '#129cd9', color: '#fff', padding: '7px' }}> Delete Exam(All Data) </h3>
                        </div>
                      </div>
                    </div>


                  </div>
                )
              }
            } />
          </switch>
          {this.state.ShowExamSelector === true ? <MdlSelectExam actionClose={this.SelectExam} action_select={this.HandleSelectedExam}  ></MdlSelectExam> : ''}
          {this.state.showDeleteConfirmation === true ? <MdlDeleteExamConfirmation actionClose={this.DeleteExamConfirm} actionConfirm={this.HandleConfirmExam}  ></MdlDeleteExamConfirmation> : ''}

          {/* <MdlDeleteExamConfirmation></MdlDeleteExamConfirmation> */}
        </Router>

      )
    }
  }

}
