import React, { Component } from 'react'
import {Modal, Table} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';


export default class MdlViewStudentAttendance extends Component {
    constructor(props){
        super(props)       
        this.state={   
            show:true,
            SELECTED_STUDENT:'',
            mAttendance:[],
            total_working_days:'',
            total_present:''
            
        }
      
}
componentDidMount(){
  
  this.state.SELECTED_STUDENT=this.props.selected_student;
  this.state.mAttendance=this.state.SELECTED_STUDENT.attendance_report;
  alert(JSON.stringify(this.props.selected_student));
  this.setState({

  })
  
}

HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action();
    
  }
  

    
render() {
        return (
            <div>
              <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       Student Attendance Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                <Table striped bordered hover variant="dark" style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th>Month</th>
                              {this.state.mAttendance && this.state.mAttendance.map((item,index)=>{
                             return(   
                                                      
                              <th>{item.month2.substring(0,3)}</th>
                                                        
                              )
                              })}
                              <th>Total</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          <tr>
                              <td>Working Days</td>
                              {this.state.mAttendance && this.state.mAttendance.map((item,index)=>{
                                 this.state.total_working_days=Number(this.state.total_working_days)+Number(item.working_days);
                             return(   
                                                      
                              <td>{item.working_days}</td>
                                                        
                              )
                              })}
                               <td>{this.state.total_working_days}</td>
                             
                              
                            </tr>
                            <tr>
                              <td>Attendance</td>
                              {this.state.mAttendance && this.state.mAttendance.map((item,index)=>{
                                 this.state.total_present=Number(this.state.total_present)+Number(item.present);
                             return(   
                                                      
                              <td>{item.present}</td>
                                                        
                              )
                              })}
                             <td>{this.state.total_present}</td>
                              
                            </tr>

                          </tbody>
                        </Table>
                </div>
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" size="lg" block onClick={this.HandleClose}>
                        OK
                </Button>
                    
               </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
