import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import COLORS from '../resources/colors/COLORS';
import STRINGS from '../resources/strings/STRINGS';
import uploadIcon from '../resources/images/upload-icon.png';
import MdlSelectSession from '../dialog/MdlSelectSession';
import Parser from 'html-react-parser';
import PaymentDetails from './PaymentDetails';
export default class TransactionPanel extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
        isOpenSessionDialog:false,
        loggedIn:true,
        Loading:false, 
        Loading:false, 
        SESSION_ID:'',
        SESSION_NAME:'',
        SESSION_STATUS:'',
        mTransactionList:[],
       

    }
    this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
    this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);
  
  }
  componentDidMount()
  {
      var mSession=SessionManager.getCurrentSession();
      this.state.SESSION_ID=mSession[0].session_id;
      this.state.SESSION_NAME=mSession[0].session_name;
      this.state.SESSION_STATUS=mSession[0].status;
      window.scrollTo(0, 0);
      this.getAllTransaction();
  }

  ChangeSession = () => {
 
    this.HandleShowSessionDialog();
  }
  
  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog
    });   
  }
  
  HandleCloseSessionDialog = () => {
  
    this.setState({
      isOpenSessionDialog:false
    });  
     
  }
  HandleSelectedSession = (session_id,session_name,session_status) => {
    this.state.SESSION_ID=session_id;
    this.state.SESSION_NAME=session_name;
    this.state.SESSION_STATUS=session_status;
    this.setState({
      
      isOpenSessionDialog:false
    });
   
    this.getAllTransaction();
  }
  
  getAllTransaction(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getAllTransaction.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,session_id:this.state.SESSION_ID}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({
          
          mTransactionList:response.data
          })
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  saveChanges(){
    alert("Are u sure Edit Exam Details!");
  }

    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/TransactionPanel/:module_id'}  exact render={
            ()=>{
              
         return (
       
          <div className="content " >
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">{"Transaction Panel("+this.state.mTransactionList.length+")"}</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{'--mMargin':'20px'}}>
      
          <div className="row">

            <div className="col-sm-12 col-md-12">
            <Link to='#' onClick={()=>this.ChangeSession()}>
            <p className="bg_card_blue" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center',color:COLORS.white,fontWeight:'500'}}> Session:
              {this.state.SESSION_NAME+"("+this.state.SESSION_STATUS+")"}
            <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
            </Link>
            </div>  

            </div>
          <div className="row">
          {this.state.mTransactionList && this.state.mTransactionList.map((item, index) => { 

            if(item.transaction_for=='FEE')
            {
              return( 

                <div className="col-sm-4 col-md-4">
                 <div style={{margin:'10px 0px 10px 0px'}}>
                 <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+item.student_id+"/"+item.transaction_for} style={{textDecoration:'none'}}>
                   
                   <div className="bg_card_green_outline gravity_center_vertical">
                  
                    <div className="text-center" style={{marginTop:'3px'}}>
                         <div className="bg_circle_green_check" style={{height:'40px',width:'40px',fontSize:'24px'}}> &#10003; </div>   
                    </div>
                    <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.card_green}}>{Parser(STRINGS.rupees_sign)+item.paid_amount}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Receipt No: "+item.reciept_no}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction ID: "+item.transaction_id}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction Date: "+item.transaction_date}</p>
                    <p style={{fontSize:'16px',fontWeight:'700',color:COLORS.card_green}}>{"Transaction For: "+item.transaction_for}</p>
   
                   </div>
                   </Link>
                 </div>
   
               </div>
                 )
            }
            else if(item.transaction_for=='OLD_DUE')
            {
              return( 

                <div className="col-sm-4 col-md-4">
                 <div style={{margin:'10px 0px 10px 0px'}}>
                 <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+item.student_id+"/"+item.transaction_for} style={{textDecoration:'none'}}>
                   
                   <div className="bg_card_red_outline gravity_center_vertical">
                  
                    <div className="text-center" style={{marginTop:'3px'}}>
                         <div className="bg_circle_red_check" style={{height:'40px',width:'40px',fontSize:'24px'}}> &#10003; </div>   
                    </div>
                    <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.card_red}}>{Parser(STRINGS.rupees_sign)+item.paid_amount}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Receipt No: "+item.reciept_no}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction ID: "+item.transaction_id}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction Date: "+item.transaction_date}</p>
                    <p style={{fontSize:'16px',fontWeight:'700',color:COLORS.card_red}}>{"Transaction For: "+item.transaction_for}</p>
   
                   </div>
                   </Link>
                 </div>
   
               </div>
                 )
            }
            else{
              return( 

                <div className="col-sm-4 col-md-4">
                 <div style={{margin:'10px 0px 10px 0px'}}>
                 <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+item.student_id+"/"+item.transaction_for} style={{textDecoration:'none'}}>
                   
                   <div className="bg_card_primary_outline gravity_center_vertical">
                  
                    <div className="text-center" style={{marginTop:'3px'}}>
                         <div className="bg_circle_primery_check" style={{height:'40px',width:'40px',fontSize:'24px'}}> &#10003; </div>   
                    </div>
                    <p style={{fontSize:'18px',fontWeight:'700',color:COLORS.primary_color_shade5}}>{Parser(STRINGS.rupees_sign)+item.paid_amount}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Receipt No: "+item.reciept_no}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction ID: "+item.transaction_id}</p>
                    <p style={{fontSize:'16px',fontWeight:'500',color:COLORS.black7}}>{"Transaction Date: "+item.transaction_date}</p>
                    <p style={{fontSize:'16px',fontWeight:'700',color:COLORS.primary_color_shade5}}>{"Transaction For: "+item.transaction_for}</p>
   
                   </div>
                   </Link>
                 </div>
   
               </div>
                 )
            }
                                           
            
            })}
          </div>    
         
          {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           

                                 


     </div>


  </div>
           
           )
          }
          } />

      <Route path='/AdminMain/PaymentDetails/:transaction_id/:session_id/:student_id/:transaction_for'  component={PaymentDetails} />       
        
                 
    </switch>       
   </Router>  
    )
  }
 }
 }