import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import StudentList from './StudentList';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Button from 'react-bootstrap/Button';

export default class TransportAddNewRoute extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      mRoute: [],
      loggedIn: true,
      Loading: false,

    }
  }
  componentDidMount() {


  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (

        <Router>
          <switch>
            <Route path={'/AdminMain/TransportAddNewRoute'} exact render={
              () => {
                return (

                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Add New Routes</a></li>
                        </ul>
                      </div>

                    </div>

                    <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>


                      <div className='bg_card_primary padding_5'>

                        <div className='row bg_card_peach_outline text_burnt'>
                          <div className='col-md-6 mar_top1'>
                            <div className='row'>
                              <div className='col-md-4'>
                                <h4 className=''>Route Name</h4>
                              </div>
                              <div className='col-md-8 input-group admission_form2  gravity_center'>
                              <input id="" type="text" placeholder='Route Name'className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 mar_top1'>
                            <div className='row'>
                              <div className='col-md-4'>
                                <h3>Distance</h3>
                              </div>
                              <div className='col-md-8 input-group admission_form2  gravity_center'>
                              <input id="" type="text" placeholder='Route Name'className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='row'>
                              <div className='col-md-4'>
                                <h3>from</h3>
                              </div>
                              <div className='col-md-8 input-group admission_form2  gravity_center'>
                              <input id="" type="text" placeholder='Route Name'className="form-control"   />
                              </div>
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='row'>
                              <div className='col-md-4 '>
                                <h3>To</h3>
                              </div>
                              <div className='col-md-8 input-group admission_form2  gravity_center'>
                              <input id="" type="text" placeholder='Route Name'className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mar_top1">
                       <button className="btn btn-success"  style={{fontSize: '18px', width: '100%' }}>Add Route</button>
                      </div>

                    </div>
                  </div>
                )
              }
            } />
          </switch>
        </Router>
      )
    }
  }
}