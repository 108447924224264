import React, { Component } from 'react'
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import FeeCard from './FeeCard';
import FeeReceipt from './FeeReceipt';
import PaymentHistory from './PaymentHistory';
export default class PaymentStatus extends Component {


  constructor(props) {
    super(props)        

    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpen: false, 
        loggedIn:true,
        Loading:false, 
        status:false,
        TRANSACTION_ID:'',
        STUDENT_ID:'',
        SESSION_ID:'',
        SESSION_NAME:''
        
    }
    

    
  }

  componentDidMount() {

    this.setState({
      TRANSACTION_ID:this.props.match.params.transaction_id,
      STUDENT_ID:this.props.match.params.student_id,
      SESSION_ID:this.props.match.params.session_id,
      SESSION_NAME:this.props.match.params.session_name 
  },() => {
    //alert("okkk");
    
  });
 
   
  }


    render() {
      
        return (
          <Router>
          <switch>
            <Route path={'/AdminMain/PaymentStatus/:student_id/:session_id/:session_name/:transaction_id'}  exact render={
              ()=>{
                
           return (
            <div className="content">
            <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Payment Status </a></li>
              </ul>
            </div>
          </div> 
          <div className="animated fadeInUp bg_page_white_rounded" style={{'--mMargin':'50px'}}>

<div className='row'>

<div className="col-sm-12 col-md-12 gravity_center">
  <div className="img-checked"><img src="https://images.vexels.com/media/users/3/157931/isolated/preview/604a0cadf94914c7ee6c6e552e9b4487-curved-check-mark-circle-icon-by-vexels.png" alt="check image" /></div>

  </div>

</div>

<div className="row gravity_center">
       <h3 className="text-center mb-100 text-success"> Collected Successfully </h3>
  </div>


  <div className="row gravity_center">
      <div className="col-sm-3">
    <div className="bg_card_primary gravity_center" style={{width:'100%',height:'40px',fontSize:'16px'}}>
      <Link to={'/AdminMain/FeeReceipt/'+this.state.TRANSACTION_ID+"/"+this.state.STUDENT_ID+"/"+this.state.SESSION_ID} className="text-white">Get Fee Receipt</Link>
      </div>
    </div>
    <div className="col-sm-12 col-md-12">
      <hr/>

   </div>
  </div>


 <div className="row">
 <div className="col-sm-3">
  <div className="bg_card_primary gravity_center" style={{width:'100%',height:'40px',fontSize:'16px'}}>
  <Link to={'/AdminMain/FeeCard'} className="text-white">Fee Card</Link>
  </div>
 </div>
 <div className="col-sm-3">
  <div className="bg_card_primary gravity_center" style={{width:'100%',height:'40px',fontSize:'16px'}}>
  <Link to={'/AdminMain/PaymentHistory/'+this.state.STUDENT_ID+"/"+this.state.SESSION_ID+"/"+this.state.SESSION_NAME} className="text-white">Payment History</Link>
  </div>
 </div>
 <div className="col-sm-3">
  <div className="bg_card_primary gravity_center" style={{width:'100%',height:'40px',fontSize:'16px'}}>
  <Link to={'/AdminMain/FeeDashBoard'} className="text-white">Collect Another</Link>
  </div>
 </div>
 <div className="col-sm-3">
  <div className="bg_card_primary gravity_center" style={{width:'100%',height:'40px',fontSize:'16px'}}>
  <Link to={'/AdminMain'} className="text-white">Back To Home </Link>
  </div>
</div>

<div className="col-sm-12 col-md-12">
<hr/>

</div>
 </div>

  </div>
         </div>

)
}
} />
  <Route path='/AdminMain/FeeReceipt/:transaction_id/:student_id/:session_id'  component={FeeReceipt} />       
          <Route path='/AdminMain/PaymentHistory/:student_id/:session_id/:session_name'  component={PaymentHistory} />       
          <Route path='/AdminMain/FeeCard'  component={FeeCard} />       
       
</switch>       
</Router>  
)
}
}
