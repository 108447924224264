import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import TransportRoutes from './TransportRoutes';
import COLORS from '../resources/colors/COLORS';
import icon_class from '../resources/icons/icons_class_setting_100_white.png'
import icon_subject from '../resources/icons/icons_subject_100_white.png'
import icons_settings from '../resources/icons/icons_settings_100_white.png'
import icons_department from '../resources/icons/icons_department_100.png'
import icon_fee from '../resources/icons/icons_fee_80_white.png'
import TransportVehicles from './TransportVehicles';
import TransportStudent from './TransportStudent';
export default class Transport extends Component {
  render() {
    return (
      <Router>
        <switch>
          <Route path='/AdminMain/Transport' render={
            () => {
              return (
                <div className="content">
                  <div className="content-header">
                    <div className="leftside-content-header">
                      <ul className="breadcrumbs">
                        <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Transport </a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row  bg_page_white_rounded" style={{ '--mMargin': '50px' }}>
                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/TransportRoutes'}}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_class} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5> Routes </h5>
                          </div>
                        </div>
                      </Link>

                    </div>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/TransportVehicles' }}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_fee} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5> Vehicles </h5>
                          </div>
                        </div>
                      </Link>

                    </div>

                    <div className="col-sm-4 col-md-4 " >
                      <Link to={{ pathname: '/AdminMain/TransportStudent' }}>
                        <div className="row box_shadow bg_card_blue_outline" style={{ padding: 2, height: '80px', margin: '10px 0px 10px 0px' }}>

                          <div className="col-sm-4 gravity_center" style={{ background: COLORS.card_blue, height: '100%', margin: 0, padding: 0, borderRadius: 10 }}>
                            <img src={icon_fee} style={{ height: '50px', width: '50px' }} />
                          </div>
                          <div className="col-sm-8 gravity_center" style={{ height: '100%', margin: 0, padding: 0 }}>
                            <h5> Student </h5>
                          </div>
                        </div>
                      </Link>

                    </div>


                  </div>
                </div>
              )
            }
          } />

          <Route path='/AdminMain/TransportRoutes' component={TransportRoutes} />
          <Route path='/AdminMain/TransportVehicles' component={TransportVehicles} />
          <Route path='/AdminMain/TransportStudent' component={TransportStudent} />
        </switch>

      </Router>

    )
  }
}
