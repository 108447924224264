import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class EditQualification extends Component {
    constructor(props){
        super(props)
        // this.STAFF_Qid = props.location.data.AddStaffQly;
        this.STAFF_Qdata = props.location.data.Staff_qdata;  
        alert(this.STAFF_Qdata);
        console.log(this.STAFF_Qdata);
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
    
        this.state={
            loggedIn:false,
            Loading:false,
            Degree:this.STAFF_Qdata.staff_degree,
            University:this.STAFF_Qdata.staff_university,
            year:this.STAFF_Qdata.staff_passing_year,
            percentage:this.STAFF_Qdata.staff_percentage,
            }
        this.regSubmit= this.regSubmit.bind(this);
        this.regChange= this.regChange.bind(this);
    }
    regChange(e){
    
      this.setState({
        [e.target.id]:e.target.value
    })
    }
    
    regSubmit(){
      if(this.isValidInput())
      {

    const staffId=this.STAFF_Qdata.id;
     const FUNCTION_NAME='updateStaffQualification.php?'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL, {
        params: {
          id:staffId,
          qualification:this.state.Degree,
          university:this.state.University,
          passing_year:this.state.year,
          percentage:this.state.percentage
    
        }
    
      }).then(res => res.data)
      .then((response) => {
        this.setState({ 
           Loading:false
          })
          let api_status=response.api_status;
          //console.log("responsel>>>>>"+response);
    
          alert("successfully Updated !" );
          if (typeof window !== 'undefined') {
               window.location.reload(false);
              // window.location.href = "../SuperAdminDash/Comment";
          }
        console.log("response>>>>>"+api_status)
       })
    } 
    }
    isValidInput()
    {
      if (this.state.Degree==="")
        {
        alert('Please Enter your Qualification !');
        return false;
        }
        else if(this.state.University==="")
        {
        alert('Please Enter your University !');
          return false;
        }
        else if(this.state.year==="")
        {
          alert("Enter your Passing Year !");
          return false;
        }
        else if(this.state.percentage==="")
        {
          alert("Please Enter you Percentage!");
          return false;
        }
      else{
        return true;
      }
    
    }
    
    render() {
    
     if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{
    return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">  Add Qualification </a></li>
              </ul>
            </div>
          </div>
          <div className="row">
          <label className="col-md-2  col-form-label"> Degree/Diploma: </label>
                                            <div className="col-md-4 ">
                                              <div className="input-group">
                                                <input id="Degree" type="text" value={this.state.Degree} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                            <label className="col-md-2  col-form-label">University/Institute: </label>
                                            <div className="col-md-4 ">
                                              <div className="input-group">
                                                <input id="University" type="text" value={this.state.University} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                            <label className="col-md-2  col-form-label"> Passing Year: </label>
                                            <div className="col-md-4 ">
                                              <div className="input-group">
                                                <input id="year" type="text" value={this.state.year} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                            <label className="col-md-2  col-form-label">Percentage: </label>
                                            <div className="col-md-4 ">
                                              <div className="input-group">
                                                <input id="percentage" type="text" value={this.state.percentage} onChange={this.regChange} className="form-control" />
                                              </div>
                                            </div>
                                            </div>
                                            <div className=" row">
                                      <div className="col-md-12">
                                      <center>
                                        <button className="btn btn-success" onClick={this.regSubmit} style={{margin:'2% 0px',fontSize:'24px',width:'100%'}}> Update Qualification </button>
                                       
                                      </center>
                                    </div>
                                  </div>

                                           </div>
           
  
  )
} 
}
}
