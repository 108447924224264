import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import check from '../resources/images/check.png';
import print from '../resources/images/print.svg';
import StudentRegistration from './StudentRegistration';
import Acknowledgement from './Acknowledgement';
import myToast from '../Utils/MyToast';
export default class StRegSuccessfull extends Component {
  render() {

    return (
        <Router>
        <switch>   
        <Route path='/AdminMain/StudentRegSuccess/:Enrollment_no/:session_id'   render={
          ()=>{
  
            return(
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Student Registration Successfull </a></li>
              </ul>
            </div>
          </div> 
              <div className="row class-li">
              <div className="col-sm-9 success-m">
                <h2 className="text-center text-white bg-success sp-10"> Student Registered Successfully </h2>
               <div className="row sp-10 s-mt-100">
               <div className="col-sm-4 col-md-4">
                   <div className="myprofile-img-div im"><img src={check} alt="check image" /></div>
               <p className="text-center text-info"> Submission Successfull </p>
                </div>
                <div className="col-sm-3 col-md-3"></div>
               <div className="col-sm-5 col-md-5" style={{textAlign:'center',background:'#f6f6f6',padding:'10px',boxShadow:'2px 4px 8px #dbdbdb'}}>
            <p className="text-right text-info"> Enrollment No: &nbsp; {this.props.match.params.Enrollment_no}</p> 
               <hr/> 
               <h4 className="text-success"><Link to={{pathname:'/AdminMain/Acknowledgement/'+this.props.match.params.Enrollment_no+"/"+this.props.match.params.session_id}} style={{border:'double',padding:'3px',color:'#88b93c'}}><img src={print} alt="print image" style={{width:'7%'}}/> Print Acknowledgement Letter </Link> </h4>
               <hr/>
               <p><Link to={{pathname:'/AdminMain/StudentRegistration/'}}><button style={{padding:'10px 45px',borderRadius:'25px',marginTop:'85px'}}> Another Registration </button></Link></p>

             </div>
             <div className="col-sm-12 col-md-12">
             <hr/>
             <p className="text-center"><small> &copy; 2020 Eduok | Design & Developed By: Eduok </small></p>
             </div>
               </div>
              </div>
                </div>
               </div>
   )
}
} />                       
 <Route path='/AdminMain/Acknowledgement/:student_id/:Session_id'  component={Acknowledgement} /> 
 <Route path='/AdminMain/StudentRegistration'  component={StudentRegistration} />        
 </switch>
 
</Router>
  )
} 
}
