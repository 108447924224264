import React, { Component, createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';


export default class MdlAddNewClass extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();
    this.slt_class = createRef();
    this.slt_section = createRef();


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      show: true,
      mSessionList:[],
      mStaffList:[],
      SESSION_SELECTED_POSITION: 0

    }

  }

  HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.actionClose();

  }


  SelectSession = (index) => {
    this.setState({
      SESSION_SELECTED_POSITION: index,
    })
  }
  HandleSelectedSession = () => {
    //alert("okk"+student_id);
    var session_id = this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].session_id;
    var session_name = this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].session_name;
    var session_status = this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].status;
    this.props.action2(session_id, session_name, session_status);

  }

  componentDidMount() {

    this.getClass();
    this.getStaff();

  }


  getClassList() {

    let board_id = this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id = this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    //filtering class of selected board and medium
    var mFilteredClassList = [];
    this.state.mFilteredClassSectionList = [];
    for (var i = 0; i < this.state.mClassData.length; i++) {
      let item = this.state.mClassData[i];
      if (item.board_id === board_id && item.medium_id === medium_id) {

        mFilteredClassList.push(item);

      }
    }
    //console.log("ClassList>>>>>>",mFilteredClassList); 
    //getting section of classes
    for (var i = 0; i < mFilteredClassList.length; i++) {
      let item = mFilteredClassList[i];
      let SECTION = item.section;
      if (SECTION === "No") {

        this.state.mFilteredClassSectionList.push(item);
      }
      else {

        var tempSection = [];
        tempSection = item.section;
        for (var j = 0; j < tempSection.length; j++) {
          let item2 = tempSection[j];
          item2["board_id"] = board_id;
          item2["board_name"] = item.board_name;
          item2["medium_id"] = medium_id;
          item2["medium_name"] = item.medium_name;
          //console.log("item2>>>>>>",item2);                
          this.state.mFilteredClassSectionList.push(item2);

        }
      }

    }
    this.setState({

    })
  }


  getClass() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    //alert(SESSION_ID);

    const API_NAME = 'getClassWithStudentCount.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME
    //console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        //console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mClassData: response.data.class,
            mClassBoard: response.data.board,
            mClassMedium: response.data.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }

  getStaff() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    //alert(SESSION_ID);

    const API_NAME = 'getStaff.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME
    //console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
       
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        //console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        //  alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mStaffList: response.staff,
         
          })
          //  alert(JSON.stringify(this.state.mStaffList));
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }

  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }

    else {
      return (

        <div>

          <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add New Class
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.Loading ? <MyLoader /> :
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-sm-4 col-md-4 padding_0 ">
                       
                      <label for="Select Boards">Select Boards:</label>
                       
                      </div>
                      <div className="col-sm-8 col-md-8 padding_0">
                        <div className="form-group">
                          <select className="form-control" onChange={this.onBoardChange} ref={this.slt_board}>
                            {this.state.mClassBoard && this.state.mClassBoard.map((item, index) => (
                              <option board_id={item.board_id}>{item.board_name}</option>

                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='row'>
                      <div className="col-sm-4 col-md-4 padding_0 ">

                        <label for="Select Medium">Select Medium:</label>

                      </div>
                      <div className="col-sm-8 col-md-8 padding_0">
                        <div className="form-group">
                          <select className="form-control" onChange={this.onMediumChange} ref={this.slt_medium}>
                            {this.state.mClassMedium && this.state.mClassMedium.map((item, index) => (
                              <option medium_id={item.medium_id}>{item.medium_name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='row'>
                      <div className="col-md-4 padding_0 ">
                        <label className="">Class Name</label>
                      </div>
                      <div className="col-md-8 padding_0">
                        <div className="input-group admission_form2">
                          <input type="text" required id="" variant="outlined" placeholder="Add new Class" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='row'>
                      <div className="col-md-4 padding_0 ">
                        <label className="">Add Class Teacher</label>
                      </div>
                      <div className="col-md-8 padding_0">
                        <div className="input-group admission_form2">
                        <select className="form-control">
                            {this.state.mStaffList && this.state.mStaffList.map((item, index) => (
                              <option staff_id={item.staff_id}>{item.staff_name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              }

            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <Button variant="primary" size="lg" block onClick={this.HandleSelectedSession}>
                  Done
                </Button>
              </div>

            </Modal.Footer>
          </Modal>
        </div >

      )
    }
  }
}

