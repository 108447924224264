import React, { Component,createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';


export default class MdlSearchStudent extends Component {
    constructor(props){
        super(props)       
     
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            isOpenSessionDialog:false,
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            show:true,
          
            CLASS_ID:'',
            SECTION_ID:'',
            SESSION_ID:'',
            SESSION_NAME:'',
            StudentList:[],
            SelectedClass:'',
            mSearchOption:[],
            KEYWORD:''
        }
       
        
        this.regChange = this.regChange.bind(this);
   
}


componentDidMount(){

  this.getReachOption();
  
}
regChange(e) {

  this.setState({
    [e.target.id]: e.target.value
  })
}

HandleClose = () => {

  this.setState({
    show: !this.state.show
  });
this.props.actionClose();
  
}
HandleSelectedStudent = (student_id,selected_item) => {
 // alert("okk"+student_id);

  this.props.actionDone(student_id,selected_item,selected_item.class[0].session_id,selected_item.class[0].session_name);
  
}


getReachOption(){
  
  this.setState({
  Loading:true,
  mSearchOption:[]
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getStudentSearchOptions.php?'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
   
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
     
       this.setState({
        
        mSearchOption:response.data

        })
       // alert(JSON.stringify(this.state.StudentList));
           
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
onCheckedSearchOption(e,position)
{
  let isChecked = e.target.checked;
  //alert(isChecked);

  if (isChecked) {
    let newArray = [...this.state.mSearchOption];
    newArray[position]['isChecked'] = 'true';
    this.setState({ mSearchOption: newArray });

  
  }
  else {

    let newArray = [...this.state.mSearchOption];
    newArray[position]['isChecked'] = 'false';
    this.setState({ mSearchOption: newArray });
    if(!this.isAnyOptionSelected())
    {
      myToast.notify_warning("Select atleast one option");
      let newArray = [...this.state.mSearchOption];
      newArray[position]['isChecked'] = 'true';
      this.setState({ mSearchOption: newArray });
    }

  }
  this.setState({

  })
  //alert(JSON.stringify(this.state.mSearchOption));
}
  isAnyOptionSelected() {
 
  for (var i=0;i<this.state.mSearchOption.length;i++)
  {
      if(this.state.mSearchOption[i]["isChecked"]==='true')
      {
          return true;
      }
  }
  return false;
}
SearchStudent()
{
  this.getStudentList();
}
getStudentList(){
  
  this.setState({
  LoadingStudent:true,
  StudentList:[]
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mOption=[];
for (let index = 0; index < this.state.mSearchOption.length; index++) {
  const element = this.state.mSearchOption[index];
  if(element.isChecked==='true')
  {
      mOption.push(element.search_option);
  }
  
}
var OPTION=JSON.stringify(mOption);


const FUNCTION_NAME='searchStudentList.php?'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME

axios.get(URL,{
  params:{  
    keyword:this.state.KEYWORD,
    option:OPTION,
    school_code:SCHOOL_CODE,
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    LoadingStudent:false
   })
    ///console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(api_status);
    
    if(api_status=="OK")
    {
     
       this.setState({
        
        StudentList:response.data

        })
       //alert(JSON.stringify(this.state.StudentList));
           
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
    render() {
      
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
            return (

              <div>
                
      <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton style={{background:COLORS.primary_color_shade7,color:COLORS.white}}>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Student
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:'5px'}}>
      {this.state.Loading ? <MyLoader/> : 
         <div>
           
            <div className='row bg_card_blue' >
            {this.state.mSearchOption && this.state.mSearchOption.map((item, index) => (
                <div className="col-sm-3 col-md-3" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={item.isChecked==='true'?"row bg_class_select gravity_center":"row bg_white_outline_grey gravity_center"}  style={{cursor:'pointer'}}> 
                           <div className="col-sm-2 col-md-2 gravity_center height_match_parent">
                               <input type="checkbox" id="issue_notice_c" class="check_box1" value="" onChange={e=>this.onCheckedSearchOption(e,index)} checked={item.isChecked==='true'?true:false}/>
                           </div>
                           <div className="col-sm-10 col-md-10 gravity_center">
                               <p style={{fontSize:18}}> {item.search_caption} </p>
                           </div>
                        </div>
                    </div>
              ))}

           </div>


           <div className='row bg_card_blue_outline' style={{padding:'5px'}}>
        
                <div className="col-sm-10 col-md-10 gravity_center">
                       
                        <div className="input-group admission_form2 gravity_center margin_0">
                            <input type="text" required id="KEYWORD" variant="outlined" placeholder="Enter First Name,Address,AdmissionNo etc" value={this.state.KEYWORD} onChange={this.regChange} className="form-control" />
                     
                        </div>
                   </div>
                   <div className="col-sm-2 col-md-2" style={{padding:'3px'}}>
                   <div  className='gravity_center match_parent' >
                        <Button className='margin_0' onClick={()=>this.SearchStudent()}>Search Student</Button>
                   </div>
                                         
                   </div>
            

           </div>

          
           <div className='row'>
           
              {this.state.LoadingStudent?<div><MyLoader/></div>:
              
              this.state.StudentList.map((item, index) => ( 
                                           
                       
                <div className="col-sm-6 col-md-6 p-0">
                <Link to='#' onClick={()=>this.HandleSelectedStudent(item.student_enrollment_no,item)}>
                <div className="row bg_card_blue_outline" style={{margin:'5px 5px 10px 5px',height:'90px'}}>
                 <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'0px',height:'85px'}}>
                   <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                   <div className="circular_image">
                      <img  src={item.student_img_path}  alt=""></img>
                      <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                   </div>  
                   </div>               
                 </div>
                 <div className="col-sm-6" style={{padding:'0px 2px 0px 2px',height:'85px'}}>
                  <h6 className="mb-0"> {item.stu_name} </h6>
                  <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                  <p className="mb-0"><span>{"Village/Town:"+item.village_town}</span> </p>
                  <p className="mb-0" style={{fontSize:'10px'}}> SRN: <span>{item.sr_admission_no}</span> </p>
                  
                 
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center">
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'1px',height:'83px'}}>
                    <p className="mb-0"> Roll No. </p>
                    <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'35px', height:'35px',border:'2px',
                     margin:'2px auto',fontSize:'20px',color:COLORS.white}}>{item.student_roll_no}</div> </p>
                    <h6 className="mb-0"><span> {item.class[0].section_id==='No'?item.class[0].class_name:item.class[0].class_name+':'+item.class[0].section_name} </span>  </h6>
                    
                  </div>
                 </div>
              
                </div>
                </Link>
                </div>                   
                
                ))}

                   </div>
                   </div>
               }
                       
      </Modal.Body>
    </Modal>
            </div>
      
            )
        }
    }
}

