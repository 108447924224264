import React, { Component } from 'react'
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import Form from 'react-bootstrap/Form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import SelectClass from '../ChildComponent/SelectClass';


export default class TransferCertificateForm extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {

      loggedIn: true,
      Loading: false,


    }
  }
  render() {

    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    else if (this.state.Loading) {
      return <MyLoader />
    }
    else {

      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/TransferCertificateForm'} exact render={
              () => {
                return (

                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">TransferCertificateForm</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{ '--mMargin': '50px' }}>

                    {/* <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass> */}

                      <div className="row mar_top">

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Certificate Number</Form.Label>
                            <div className="input-group admission_form2">
                              <input type="text" required id="Certificate Number" name="" variant="outlined" placeholder="Certificate Number" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Admission Date</Form.Label>
                            <div className="input-group date" id="stAdmissionDate">
                              <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Passed Date</Form.Label>
                            <div className="input-group date" id="stAdmissionDate">
                              <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Leaving Date</Form.Label>
                            <div className="input-group date" id="stAdmissionDate">
                              <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Reason For Leaving</Form.Label>
                            <div className="input-group admission_form2">
                              <input type="text" name="" variant="outlined" placeholder="Parents Need" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Conduct</Form.Label>
                            <div className="input-group admission_form2">
                              <input type="text" name="" variant="outlined" placeholder="Good" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Progress</Form.Label>
                            <div className="input-group admission_form2">
                              <input type="text" name="" variant="outlined" placeholder="Progress" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Language</Form.Label>
                            <div className="input-group admission_form2">
                              <input type="text" name="" variant="outlined" placeholder="Language" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group >
                            <Form.Label className='admission_form'>Remark</Form.Label>
                            <div className="input-group admission_form2">
                              <input type="text" name="" variant="outlined" placeholder="Remark" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                            </div>
                          </Form.Group>
                        </div>

                      </div>



                      <div className='admission_form2'>

                        <h2> Student Details(As per admission time)</h2>

                      </div>




                      <div className='bg_card_blue_outline mar_transfer'>

                        <div className="row mar_top">

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Student Name</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Student name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>father Name</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="father Name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Board</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Board" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Medium</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Medium" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>



                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Class</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Class" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>



                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Roll Number</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Roll Number" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>



                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Adhar Number</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Adhar Number" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Gender</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Gender" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>DOB</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="date" name="" variant="outlined" placeholder="Date Of Birth" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Religion</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Religion " value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Nationality</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Nationality" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4">

                                <Form.Label className='admission_prvform'>Community</Form.Label>

                              </div>

                              <div className="col-md-8">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Community" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3 margin_2">

                                <Form.Label className='admission_prvform'>Current Address</Form.Label>

                              </div>

                              <div className="col-md-9 margin_2">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Current Address" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3 margin_2">

                                <Form.Label className='admission_prvform'>Permanent Address</Form.Label>

                              </div>

                              <div className="col-md-9 margin_2">
                                <div className="input-group admission_form2">
                                  <input type="text" name="" variant="outlined" placeholder="Current Address" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                </div>

                              </div>

                            </div>
                          </div>




                        </div>
                      </div>

                      <div className='admission_data1'>
                        <ul className="breadcrumbs mar_top">
                          <li className=''><Link to="/AdminMain/TransferCertificateForm"><Button variant="primary">Submit</Button>{' '}</Link></li>
                        </ul>
                      </div>

                    </div>


                  </div>
                )
              }
            } />

          </switch>
        </Router>
      )
    }
  }
}
