import React, { Component } from 'react'
import { css } from '@emotion/core';
import HashLoader from 'react-spinners/HashLoader';
 
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

export default class MyLoader extends Component {
  render() {
    return (
      <div>
        <div className='sweet-loading'>
        <HashLoader
          css={override}
          sizeUnit={"px"}
          size={100}
          color={'#123abc'}
        />
        
      </div> 
      </div>
    )
  }
}
