import React, { useState} from 'react'

import '../css/Admin_css/admin-customize.css';



import MyLoader from '../Spinner/MyLoader';


import { BrowserRouter as Router, Redirect, Route,Link } from 'react-router-dom';
import AdvancedPayment from './AdvancedPayment';
import COLORS from '../resources/colors/COLORS';
import ExamAddMarkSubjectWise from './ExamAddMarkSubjectWise';

 const ExamAddMarkStudentWise =(props)=>  {
  const[Loading,setLoading]=useState(false);

  const token = localStorage.getItem("token");
  let loggedIn = true;
  if (token == null) {
    loggedIn = false;
  }
  const EXAM_ID=props.match.params.exam_id;
  const SESSION_ID=props.match.params.session_id;
  const EXAM_NAME=props.match.params.exam_name;

  window.scrollTo(0, 0);


 
    if(loggedIn==false)
    {
     
       return <Redirect to='/Login'/>;
    }
    else if(Loading)
    {
      
       return <MyLoader/>
    }
   
    else{
    
    return (

      
      <Router>
        <switch>
          <Route exact path='/AdminMain/ExamAddMarkStudentWise/:exam_id/:session_id/:exam_name/'>  
          
         <div className="content">
               <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Fee Collection</a></li>
                </ul>
              </div>  
            </div>
            
                         
            <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{'--mMargin':'80px'}}>
            
            <div className='' style={{margin:'0px 20px'}}>
            <div className="row">
                <div className="col-sm-6 col-lg-6 text-center" >
                    <div  style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                         <h4 className="arrow"> Add Mark Student Wise</h4>
                    </div>
                    
              </div>
              <div className="col-sm-6 col-lg-6 text-center" >
              <Link to={{pathname:'/AdminMain/ExamAddMarkSubjectWise/'+EXAM_ID+"/"+SESSION_ID+"/"+EXAM_NAME}}>
                          
                    <div style={{padding:'4px',color:'#fff',borderRadius:'11px',background:COLORS.primary_color_shade5}}>
                        <h4 className="arrow"> Add Mark Subject Wise</h4>
                    </div>
                 </Link>   
              </div>
            </div>
         
          
          </div>
     
                  </div>
                 </div>
           

                 </Route>
    
                
                 <Route path='/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/'  component={ExamAddMarkSubjectWise} />
           
        </switch>
        
       </Router>

      
      )
    }

}
export default ExamAddMarkStudentWise;

