import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import StudentList from '../cms/StudentList';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Button from 'react-bootstrap/Button';
import MdlAddSection from '../dialog/MdlAddSection';
import MdlAddNewClass from '../dialog/MdlAddNewClass';
export default class ClassSettings extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      isOpenAddSection:false,
      isOpenAddNewClass:false,
      loggedIn: true,
      Loading: false,
      CLASS_ID: '',
      SECTION_ID: '',
      mClassData: [],
      mClassBoard: [],
      mClassMedium: [],
      mFilteredClassSectionList: []
    }
    this.HandleCloseAddSection= this.HandleCloseAddSection.bind(this);
     this.HandleCloseAddNewClass= this.HandleCloseAddNewClass.bind(this);

  }


  componentDidMount() {

    this.getClass();
  }


  AddSection = () => {
 
   //alert("Add Section");
   this.HandleCloseAddSection();

  }
  AddNewClass = () => {
 
    // alert("Add new class");
    this.HandleCloseAddNewClass();
 
   }
  HandleCloseAddSection(){
   
    this.setState({
      isOpenAddSection:!this.state.isOpenAddSection
    })
  }
   HandleCloseAddNewClass(){
   
    this.setState({
      isOpenAddNewClass:!this.state.isOpenAddNewClass
    })
  }
  onBoardChange = (event) => {
    //this.setState({ value: event.target.value });
    //let board_id=event.target.selectedOptions[0].getAttribute('board_id');

    this.getClassList();


  };
  onMediumChange = (event) => {
    this.getClassList();
  }
  getClassList() {

    let board_id = this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id = this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    //filtering class of selected board and medium
    var mFilteredClassList = [];
    this.state.mFilteredClassSectionList = [];
    for (var i = 0; i < this.state.mClassData.length; i++) {
      let item = this.state.mClassData[i];
      if (item.board_id === board_id && item.medium_id === medium_id) {

        mFilteredClassList.push(item);

      }
    }
    //console.log("ClassList>>>>>>",mFilteredClassList); 
    //getting section of classes
    for (var i = 0; i < mFilteredClassList.length; i++) {
      let item = mFilteredClassList[i];
      let SECTION = item.section;
      if (SECTION === "No") {

        this.state.mFilteredClassSectionList.push(item);
      }
      else {

        var tempSection = [];
        tempSection = item.section;
        for (var j = 0; j < tempSection.length; j++) {
          let item2 = tempSection[j];
          item2["board_id"] = board_id;
          item2["board_name"] = item.board_name;
          item2["medium_id"] = medium_id;
          item2["medium_name"] = item.medium_name;
          //console.log("item2>>>>>>",item2);                
          this.state.mFilteredClassSectionList.push(item2);

        }
      }

    }
    this.setState({

    })
  }

  
  getClass() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    //alert(SESSION_ID);

    const API_NAME = 'getClassWithStudentCount.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + API_NAME
    //console.log("URL>>>>>" + URL)
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        session_id: SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        //console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({
            mClassData: response.data.class,
            mClassBoard: response.data.board,
            mClassMedium: response.data.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }


  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (

        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Class Settings</a></li>
              </ul>
            </div>

          </div>

          <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>


            <div className="row mar_top">
              <div className="col-sm-2 col-md-2">
                <div className="padding_0 admission_prvform1">
                  Select Boards:
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group">
                  <select className="form-control" onChange={this.onBoardChange} ref={this.slt_board}>
                    {this.state.mClassBoard.map((item, index) => (
                      <option board_id={item.board_id}>{item.board_name}</option>

                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-2 col-md-2">
                <div className="padding_0 admission_prvform1">
                  <label for="Select Medium">Select Medium:</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group">
                  <select className="form-control" onChange={this.onMediumChange} ref={this.slt_medium}>
                    {this.state.mClassMedium.map((item, index) => (
                      <option medium_id={item.medium_id}>{item.medium_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-md-12 padding_0'>


                <table className='table_border'>
                  <tbody className=''>
                    <tr>
                      <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade6)' }}>
                        <div className='admission_data2'>Class Name</div>
                      </th>
                      <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade6)' }}>
                        <div className='admission_data2'>Student Count</div>
                      </th>
                      <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade6)' }}>
                        <div className='admission_data2'> Class Teacher</div>
                      </th>
                      <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade6)' }}>
                        <div className='admission_data2'> Section</div>
                      </th>
                      <th style={{ 'border': '2px solid black', width: '10%', 'backgroundColor': 'var(--primary_color_shade6)' }}>
                        <div className='admission_data2'>Action</div>
                      </th>
                    </tr>
                    {this.state.mFilteredClassSectionList && this.state.mFilteredClassSectionList.map((item, index) => (
                    <tr>
                      <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                        <div className='admission_data3'>{item.class_name}</div>
                      </td>
                      <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                        <div className='admission_data3'>{item.student}</div>
                      </td>
                      <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                        <div className='admission_data3'> {item.class_teacher_name}</div>
                      </td>
                      <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                        <div className='admission_data3'>{item.section}</div>
                      </td>
                      <td style={{ 'border': '2px solid black', 'backgroundColor': 'var(--white)' }}>
                        <div className='admission_data1'>
                          <ul className="breadcrumbs btn_set">
                            <li><Link to="#" onClick={()=>this.AddSection()}><Button variant="primary">Add Section</Button>{' '}</Link></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>


              <div className="col-md-12">
                <center>
                  <button className="btn btn-success" onClick={()=>this.AddNewClass()} style={{ margin: '3% 0%', fontSize: '21px', width: '98%' }}>Add New Class</button>

                </center>
              </div>
            </div>

            {this.state.isOpenAddSection===true? <MdlAddSection actionClose={this.HandleCloseAddSection} action2={this.HandleSelectedSession}></MdlAddSection>:''}

            {this.state.isOpenAddNewClass===true? <MdlAddNewClass actionClose={this.HandleCloseAddNewClass} action2={this.HandleSelectedSession}></MdlAddNewClass>:''}

          </div>
        </div>

      )
    }
  }
}
