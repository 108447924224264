import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import ChangePassword from './ChangePassword';
import myToast from '../Utils/MyToast';
export default class MyProfile extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mMyProfile:[]
    }
  
  }
  componentDidMount()
  {
  this.Holiday();
  }
  Holiday(){
  
  this.setState({
    Loading:true
  })
  // const SCHOOL_CODE=SessionManager.getSchoolCode();
  // const Session_Id="SSN0001";
  const staffId="STF000111";
  const FUNCTION_NAME='getStaffProfile.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  var params = new URLSearchParams();
     params.append('staff_id', staffId);
  
     axios.post(URL,params).then(res => res.data)
     .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            mMyProfile:response.data
          })
          console.log("response>>>>>"+JSON.stringify(this.state.MyProfile));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
      return (
        <Router>
        <switch>   
        <Route path='/AdminMain/MyProfile/:module_id'  exact render={
          ()=>{
            return(
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> My Profile </a></li>
              </ul>
            </div>
          </div> 
          {this.state.mMyProfile.map((item, index) => ( 
              <div className="row class-li">
              <div className="col-sm-6 col-md-6 card-class-w class-list-box" style={{margin:'0px',border:'double #fff'}}>
                   <div className="myprofile-img-div myprop"><img src={item.staff_image_path} alt="student list image"/></div>
        
                </div>
                <div className="col-sm-6 col-md-6 text-center class-list-box" style={{margin:'0px'}}>
               <h3> {item.staff_name} </h3>
               <h5> ({item.Department_name})</h5>
               <hr/>
               <p><strong> Address: </strong> {item.staff_address}</p>
               <p><strong>Adhar No.:</strong> {item.aadhar_no} &nbsp; <span><strong> Phone No.:</strong> {item.staff_phone}</span> </p>
               <p><strong> Joining Date: </strong> {item.joining_date} <span><strong> Staff Role: </strong> { item.staff_role}</span></p>
          <p><strong>Staff Degree: </strong>{item.staff_degree} <span><strong> Staff Percentage: </strong> { item.staff_percentage}</span></p>
               <hr/>
               <Link to={{pathname:'/AdminMain/ChangePassword'}}> 
               <button className="btn btn-info" style={{width:'50%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Change Password </button>
               </Link>
                </div>
                </div>
          ))}
        
        </div>
            )
          }
        } />  

          <Route path='/AdminMain/ChangePassword'  component={ChangePassword} /> 

          </switch>
          
         </Router>
              
  
  )
} 
}
}
