import React, { Component } from 'react'

var SessionManager = (function() {
    var full_name = "";
  
    var getName = ()=> {
      return full_name;    // Or pull this from cookie/localStorage
    };
  
    var setName = (name,age) =>{
      full_name = name; 
      alert(full_name+age);    
      // Also set this in cookie/localStorage
    };

    var createStaffLoginSession=(staff_id,staff_name,staff_phone,staff_email,password,staff_role,login_type,staff_image_path)=>{

      var staff={
        isStaffLoggedIn:true,
        staff_id:staff_id,
        staff_name:staff_name,
        staff_phone:staff_phone,
        staff_email:staff_email,
        password:password,
        staff_role:staff_role,
        login_type:login_type,
        staff_image_path:staff_image_path
      }
      localStorage.setItem("staff_details",JSON.stringify(staff));
    }
    var getStaffDetails=()=>{
      var staffData=JSON.parse(localStorage.getItem("staff_details"));
      return staffData;
    }

    var saveSchoolCode=(SchoolCode)=>{
        localStorage.setItem("SchoolCode",SchoolCode);
    }
    var getSchoolCode=()=>{
      return(localStorage.getItem("SchoolCode"));
    }

  var saveCurrentSession=(mCurrentSession)=>{
      localStorage.setItem("CurrentSession",JSON.stringify(mCurrentSession));
  }
  var getCurrentSession=()=>{
    var mCurrentSession=JSON.parse(localStorage.getItem("CurrentSession"));
    return mCurrentSession;
  }
  
    return {
      getName: getName,
      setName: setName,
      createStaffLoginSession:createStaffLoginSession,
      getStaffDetails:getStaffDetails,
      saveSchoolCode:saveSchoolCode,
      getSchoolCode:getSchoolCode,
      saveCurrentSession:saveCurrentSession,
      getCurrentSession:getCurrentSession

    }
  
  })();
  
  export default SessionManager;
