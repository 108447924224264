import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";
import { Alert } from "react-bootstrap";

const StudentPen = () => {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") !== null);
  const [Loading, setLoading] = useState(false);
  const [LoadClass, setLoadClass] = useState(false);
  const [SESSION_ID, setSESSION_ID] = useState("");
  const [CLASS_ID, setCLASS_ID] = useState("");
  const [SECTION_ID, setSECTION_ID] = useState("");
  const [SELECTED_CLASS_POSITION, setSELECTED_CLASS_POSITION] = useState(0);
  const [CLASS_SECTION_NAME, setCLASS_SECTION_NAME] = useState("");
  const [mStudentList, setMStudentList] = useState([]);
  const [Inputs, setInputs] = useState([]);

  useEffect(() => {
    setLoadClass(true);
  }, []);

  useEffect(() => {
    StudentList();
  }, [CLASS_ID,SECTION_ID]);
  

  const toggleDisableAadhar = (index,state) => {
    setInputs((prev) =>
      prev.map((input, i) =>  i === index ? { ...input, disable_student_pen: !input.disable_student_pen }: input)
    );
  
    if(!state)
      {
         const STUDENT_PEN_OLD=Inputs[index].student_pen;
         const STUDENT_ID=Inputs[index].student_enrollment_no;
         const STUDENT_PEN_NEW=Inputs[index].student_pen_new;
         updateStudentPen(STUDENT_ID,STUDENT_PEN_OLD,STUDENT_PEN_NEW);
      }
  };
  const handleInputChange = (id,my_key, event) => {
    //alert(JSON.stringify(id));
    const newInputs = Inputs.map(input => {
      if (input.id === id) {
        return { ...input, [my_key]: event.target.value };
      }
      return input;
    });
    setInputs(newInputs);
  };
  
  const CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name) => {
    const classSectionName = section_id === "No" ? class_name : `${class_name}:${section_name}`;
    
    setSECTION_ID(section_id);
    setSESSION_ID(session_id);
    setCLASS_SECTION_NAME(classSectionName);
    setSELECTED_CLASS_POSITION(selected_position);
    setCLASS_ID(class_id);  
    
  };

  const StudentList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getCurrentStudent.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          setMStudentList(response.data);
          const formattedData = response.data.map(item => ({
            id: item.id,
            student_enrollment_no: item.student_enrollment_no,
            student_pen: item.student_pen,
            student_pen_new: item.student_pen,
            disable_student_pen: true,  
          }));
          setInputs(formattedData);
         
        } else {
         // myToast.notify_warning(response.api_remark);
          setMStudentList([]);
          setInputs([]);
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const updateStudentPen = (STUDENT_ID,STUDENT_PEN_OLD,STUDENT_PEN_NEW) => {
    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStudentPen.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    const params={
      staff_id: STAFF_ID,
      school_code: SCHOOL_CODE,
      session_id: SESSION_ID,
      student_id: STUDENT_ID,
      student_pen_new: STUDENT_PEN_NEW,
      student_pen_old: STUDENT_PEN_OLD,
    
    }
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
  
    axios
      .get(URL, {
        params: params
      })
      .then((res) => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
         alert("Updated successfully");
         
        } else {
           myToast.notify_warning(response.api_remark);
         
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const SubmitAll = () => {
    const updatedList = [];
    Inputs.forEach((student, i) => {
      const STUDENT_PEN_Old = student["student_pen"];
      const STUDENT_PENNew = student["student_pen_new"];
      
      if (STUDENT_PENNew && STUDENT_PEN_Old !== STUDENT_PENNew) {
        const updatedStudent = {
          student_enrollment_no: student["student_enrollment_no"],
          student_pen: STUDENT_PEN_Old,
          student_pen_new: STUDENT_PENNew,
        };
        updatedList.push(updatedStudent);
      }
    });
    //alert(JSON.stringify(updatedList));
    var str_student_list=JSON.stringify(updatedList);

    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateClassStudentPen.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    
    var params = new URLSearchParams();
    params.append('staff_id',STAFF_ID);
    params.append('school_code',SCHOOL_CODE);
    params.append('session_id',SESSION_ID);
    params.append('student_list',str_student_list);
    params.append('class_id',CLASS_ID);
    params.append('section_id',SECTION_ID);
    const queryString = new URLSearchParams(params).toString();
    //console.log("Full URL: ", `${URL}${queryString}`);
  
    axios.post(URL,params).then(res => res.data)
      .then((response) => {
       // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
             alert("Updated successfully");
         
        } else {
           myToast.notify_warning(response.api_remark);
         
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path="/AdminMain/StudentPen"
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.whitesmoke }}>
            <div className="content-header" style={{ background: COLORS.primary_color_shade6 }}>
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Manage Student PEN(Parmanent Education Number) </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="animated fadeInUp bg_page_grey_rounded box_shadow" style={{ "--mMargin": "50px", marginTop: "20px", padding: '20px' }}>
              {LoadClass && (
                <SelectClass
                  value={LoadClass}
                  action={CallBackClassSelect}
                  selected_position={SELECTED_CLASS_POSITION}
                />
              )}

              {Loading ? (
                <MyLoader />
              ) : Inputs.length > 0 ? (
                mStudentList.map((item, index) => (
                  <div key={index} className="bg_card_blue_outline btn_set">
                    <div className="row btn_set">
                      <div className="col-md-6 mp1_0 gravity_center">
                        <div className="row" style={{width:'100%'}}>
                          <div className="col-md-3 gravity_center mp1_0">
                            <div className="bg_card_blue" style={{ height: "70px", width: "70px" }}>
                              <div className="circular_image">
                                <img src={item.student_img_path} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Student Name:</span> {item.stu_name}
                              </h6>
                            </div>
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Father Name:</span> {item.father_name}
                              </h6>
                            </div>
                            <div>
                              <h6 className="text-center">
                                <span className="text_color">Class:</span> {item.section_id === 'No' ? item.class_name : `${item.class_name}:${item.section_name}`}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="row mar_top">
                        <div className="col-md-4 mp1_0">
                          <label className="text_color mar_top2">
                            Student PEN
                          </label>
                        </div>
                        <div className="col-md-6 padding_0">
                          <div className="input-group">
                            <input
                              id=""
                              placeholder="Edit Student PEN"
                              type="text"
                              value={Inputs[index].student_pen_new}
                              disabled={Inputs[index].disable_student_pen}
                              onChange={(e) => handleInputChange(item.id,'student_pen_new', e)}
                          
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <center>
                          <button className="btn btn-success" onClick={() => toggleDisableAadhar(index,Inputs[index].disable_student_pen)}>{Inputs[index].disable_student_pen ? 'Edit' : 'Save'} </button>
                          </center>
                        </div>
                      </div>
                    </div>


                    </div>
                  </div>
                ))
              ) : (
                <div>No students found</div>
              )}

              

<div className="row mar_top">
                <button
                  className="btn btn-success"
                  onClick={(e) => SubmitAll()}

                  style={{ margin: "3% 0%", fontSize: "21px", width: "100%" }}
                >
                  Submit All
                </button>
              </div>
              
            </div>
          </div>
        )}
      />
      
    </Router>
  );
};

export default StudentPen;
