import React, { Component,createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';


export default class MdlSelectExamClassAndMultipleStudent extends Component {
    constructor(props){
        super(props)       
       
       
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            show:true,
            mExamParticipatingClass:[],
            CLASS_ID:'',
            SECTION_ID:'',
            EXAM_ID:'',
            SESSION_ID:'',
            StudentList:[],
            SelectedClass:''
        }
      
}

componentDidMount(){
  var mSelectedStudent=[];
  localStorage.setItem("SelectedMultipleStudent",JSON.stringify(mSelectedStudent));
  this.state.EXAM_ID=this.props.exam_id;
  this.state.SESSION_ID=this.props.session_id;
  this.getClass();
} 

HandleClose = () => {

  this.setState({
    show: !this.state.show
  });
this.props.action();
  
}
HandleSelectedStudent = () => {
  this.props.action2();
  
  }
getSelectedStudent = (e,postion) => {
    
  let isChecked = e.target.checked;
  let student_id=this.state.StudentList[postion].student_enrollment_no;

  var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
  if(mSelectedStudent==null){
    mSelectedStudent=[];
  }

  //alert("okk"+student_id+"..."+isChecked);
  if(isChecked)
  {
      let item=this.state.StudentList[postion];
      mSelectedStudent.push(item);
      localStorage.setItem("SelectedMultipleStudent",JSON.stringify(mSelectedStudent));
  
  }
  else{
    
    let item_index=this.getItemPostion(student_id,mSelectedStudent);
    mSelectedStudent.splice(item_index,1);
    localStorage.setItem("SelectedMultipleStudent",JSON.stringify(mSelectedStudent));
  
  }
  this.setState({
    SelectedStudentCount:mSelectedStudent.length,
  })
  
   
}
getItemPostion(student_id,mSelectedStudent){
    
  for (let index = 0; index < mSelectedStudent.length; index++) {
    const element = mSelectedStudent[index];
    if(student_id===element.student_enrollment_no)
    {
      return index;
    }
    
  }
  return -1;
}
   

getClass(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();


const FUNCTION_NAME='getExamParticipatingClass.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID,
    exam_id:this.state.EXAM_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
   // console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(JSON.stringify(response));
    
    if(api_status=="OK")
    {
    
       this.setState({       
        mExamParticipatingClass:response.data,
       
        })
        this.setClassList(0);
        //console.log("response>>>>>"+this.state.mClassData);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
} 

selectClass=(position)=>{  
  this.setClassList(position);
  
}
setClassList(selected_position){      
  if(this.state.mExamParticipatingClass.length!==0)
  {
      this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
      this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;

      if(this.state.SECTION_ID=="null")
      {
          this.state.SECTION_ID="No";
      }
      this.state.CLASS_SELECTED_POSITION=selected_position;

  }
 
  this.getStudentList();
 
}
getStudentList(){
  
  this.setState({
  LoadingStudent:true,
  StudentList:[]
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getCurrentStudent.php?'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    class_id:this.state.CLASS_ID,
    section_id:this.state.SECTION_ID,
    session_id:this.state.SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    LoadingStudent:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
     
       this.setState({
        
        StudentList:response.data

        })
       // alert(JSON.stringify(this.state.StudentList));
           
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
    render() {
      
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
            return (

              <div>
                
      <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Student
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.state.Loading ? <MyLoader/> : 
         <div className="row class-li">

                 <form style={{width:'100%'}}>
                   <div style={{padding:'0px 5px 0px 5px'}}><h5>Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 5px 0px 5px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-2 col-md-2" style={{padding:'0px 10px 5px 10px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>
                    </form>
              {this.state.LoadingStudent?<div><MyLoader/></div>:
               
               this.state.StudentList.map((item, index) => ( 
                                            
                <div className="col-sm-6 col-md-6 p-0">
                <div className="row bg_card_blue_outline" style={{margin:'5px 15px 10px 15px',height:'90px'}}>
                 <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'1px',height:'85px'}}>
                   <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                   <div className="circular_image">
                      <img  src={item.student_img_path}  alt=""></img>
                      <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                   </div>  
                   </div>               
                 </div>
                 <div className="col-sm-6" style={{padding:'5px 10px',height:'85px'}}>
                  <h5> {item.stu_name} </h5>
                  <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                  
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center" style={{height:'85px'}}>
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                    <p className="mb-0"> Roll No:{item.student_roll_no} </p>
                    

                    <p> <div className="st-list-roll-n" style={{
                              background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                              margin: '2px auto', fontSize: '20px'
                             }}> 
                             <input type="checkbox" id="issue_notice_c" class="ch-in cursor" value="" onChange={e=>this.getSelectedStudent(e,index)}/></div>
                    </p>

                    <h6>Class:<span> {item.class_name} </span>  </h6>
                  </div>
                 </div>
              
                </div>
              
                </div>   
                                   
                 ))}

                  <div className="col-sm-12 text-center cursor" style={{margin:'15px auto'}} onClick={this.HandleSelectedStudent}>
                            <h3 style={{ background: COLORS.primary_color_shade5, color:'#fff', padding:'5px'}}> Done </h3>
                 </div>
                       

       
                   </div>
      }
                       
      </Modal.Body>
    </Modal>
            </div>
      
            )
        }
    }
}

