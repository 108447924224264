import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class OldDueCollection extends Component {
    constructor(props){
        super(props)
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false, 
          selected_student:[],
          mDueList:[],
          total_amount:0
      }
    }
    componentDidMount()
    {
      
      this.state.selected_student[0]=JSON.parse(localStorage.getItem("selected_student"));
      //alert(this.state.selected_student[0].class[0].board_name);
     
      if(this.state.selected_student.length!==0)
      {
        this.getdueList();
      }
      
    }
    getdueList(){
        var mDueData=[];
        mDueData=this.state.selected_student[0].old_due;
        for (let index = 0; index <mDueData.length; index++) {
          var amt=mDueData[index].amount-mDueData[index].amount_paid;
            if(amt!=0)
            {
                this.state.mDueList.push(mDueData[index]);
            }
        }
        this.setState({

        })
        
    }  
    

      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

        return (
         <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Old Due Collection  </a></li>
              </ul>
            </div>
          </div>   
          <section className="">
                <div className="row m-0">
                 
                <div className="col-sm-12 col-md-12 mt-4 mb-4"> 
                <Link to={{pathname:'/'}}>
                 <div className="row addOldDue-Row-1">
                 <div className="col-sm-1 p-0">
                 <div className="profil-im" style={{background:'rgba(18,58,188,0.75)'}}><img src={this.state.selected_student.length!=0? this.state.selected_student[0].student_img_path:''} alt="img" style={{with:'100%',background:'antiquewhite',
    borderRadius:'50%'}}/> <span className="s000no">{this.state.selected_student.length!=0? this.state.selected_student[0].student_enrollment_no:''}</span>
    
    </div>
                 </div>
                 <div className="col-sm-10 pt-2 text-center">
                  <h6 className="mb-0"> {this.state.selected_student.length!=0? this.state.selected_student[0].stu_name:''}</h6>
                  <p> Father: <span>{this.state.selected_student.length!=0? this.state.selected_student[0].father_name:''}</span> &nbsp;&nbsp;  Board: <span>{this.state.selected_student.length!=0?this.state.selected_student[0].class[0].board_name:''}</span> &nbsp;&nbsp; Medium:<small className="darkkhaki"> {this.state.selected_student.length!=0?this.state.selected_student[0].class[0].medium_name:''}</small> </p>
                  
                 </div>
                 <div className="col-sm-1 text-center p-1">
                 <div style={{border:'1px solid rgba(18,58,188,0.75)', borderRadius:'10px',padding:'10px 0px',height:'93px'}}>
                 <p className="mb-0"> Roll No. </p>
                 <p className="mb-0"> <div className="st-list-roll-n hjkc">{this.state.selected_student.length!=0?this.state.selected_student[0].class[0].student_roll_no:''}</div> </p>
                 <h6 className="mb-0">{this.state.selected_student.length!=0?this.state.selected_student[0].class[0].class_name:''} <span>  </span>  </h6>
                 </div>
                 </div>
                </div>
                </Link>
                </div>     
                </div>
                <div className="row m-0">
            <div className="col-sm-12 col-lg-12 text-center ed-1" data-toggle="modal" data-target="#exampleModal">
              <h3 className="arrow"> Due Amount Rs:2500 <span className="pull-right"> <button style={{width:'40px',height:'40px',borderRadius:'15%', border:'1px solid #fff',background:'rgba(18,58,188,0.75)'}}>
                    <i className="fa fa-edit"></i>
                 </button></span></h3>
              </div>
            </div>
                </section> 
                <section background="#fff">
                <div className="row m-0 p-2">
                  <div className="col-md-4 mb-2">
                    <div className="row m-0" style={{border:'2px solid rgba(18,58,188,0.75)',borderRadius:'10px'}}>
                                   
                                    <div className="col-md-2 p-0 ">
                                      <div className="due-main-ox">
                                        <input type="checkbox" id="DueAmount" name="Due Amount" value={this.state.FirstName} onChange={this.regChange} placeholder="Due Amount:" className="checkbox-due-collecton" />
                                      </div>
                                    </div>
                                    <div className="col-md-8  col-form-label text-center">
                                     <p className="m-0"><strong> Rs: <span> 1500 </span></strong></p> 
                                     <p className="m-0">2019-2020</p>
                                     <h6 className="m-0 text-success">Fee</h6>  
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                    <div className="row m-0"  style={{border:'2px solid rgba(18,58,188,0.75)',borderRadius:'10px'}}>
                                   
                                    <div className="col-md-2 p-0 ">
                                      <div className="due-main-ox">
                                        <input type="checkbox" id="DueAmount" name="Due Amount" value={this.state.FirstName} onChange={this.regChange} placeholder="Due Amount:" className="checkbox-due-collecton" />
                                      </div>
                                    </div>
                                    <div className="col-md-8  col-form-label text-center">
                                     <p className="m-0"><strong> Rs: <span> 500 </span></strong></p> 
                                     <p className="m-0">2019</p>
                                     <h6 className="m-0 text-success">Fee</h6>  
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                    <div className="row m-0" style={{border:'2px solid rgba(18,58,188,0.75)',borderRadius:'10px'}}>
                                   
                                    <div className="col-md-2 p-0 ">
                                      <div className="due-main-ox">
                                        <input type="checkbox" id="DueAmount" name="Due Amount" value={this.state.FirstName} onChange={this.regChange} placeholder="Due Amount:" className="checkbox-due-collecton" />
                                      </div>
                                    </div>
                                    <div className="col-md-8  col-form-label text-center">
                                     <p className="m-0"><strong> Rs: <span> 500 </span></strong></p> 
                                     <p className="m-0">2019</p>
                                     <h6 className="m-0 text-success">Fee</h6>  
                                    </div>
                                    </div>
                                    </div>
                                   

               </div>
               <div className="row" style={{borderTop:'1px solid #123abcbf',padding:'5px 0px',marginTop:'100px'}}>
                <div className="col-sm-6 col-md-6 text-center" style={{padding:'5px'}}>
                    <h3> Collect By Other Amount </h3>
                </div>  
                <div className="col-sm-6 col-md-6 viv">
                   <p> Other Amount </p> 
                </div>   
                </div> 
                {/* <!-- Modal --> */}
{/* <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content" style={{width: '810px'}}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Select Students</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h1> Hi .................</h1>
        </div> 
        </div>
        </div>
        </div> */}

                <div className="row">
                <div className="col-sm-12 col-md-12 btn btn-primary"style={{border:COLORS.primary_color_shade5}}>
                <button className="btn btn-primary" style={{width:'100%',fontSize:'24px', margin:'0px',border:'1px solid #fff', borderRadius:'5px'}}> Collect Now(Rs:2500) </button>                   
                </div>    
                </div>  
                </section>
                </div>
        )
     } 
}
}
