import React, { Component, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import '../css/Admin_css/vp_style.css';
import SelectClass from '../ChildComponent/SelectClass';
import myToast from '../Utils/MyToast';
export default class CurrentDue extends Component {
  constructor(props) {
    super(props)
    this.slt_board = createRef();
    this.slt_medium = createRef();

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      mClassData: [],
      mClassBoard: [],
      mClassMedium: [],
      mFilteredClassList: [],
      mFilteredClassSectionList: [],
      mStudentPayList: [],
      mDueDetails: [],
      CLASS_SELECTED_POSITION: 0,
      total_amount: 0
    }
    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);
  }

  componentDidMount() {

  }
  CallBackClassSelect = (class_id, section_id, session_id, selected_position, class_name, section_name, session_name) => {

    this.state.CLASS_ID = class_id;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    // alert("Back"+class_id+"/"+section_id+"/"+session_id);
    this.getClassStudentPayList();
  }
  getClassStudentPayList() {
    this.setState({
      LoadingStudent: true
    })
    this.state.mStudentPayList = [];
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getClassStudentPaylist.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        class_id: this.state.CLASS_ID,
        section_id: this.state.SECTION_ID,
        session_id: this.state.SESSION_ID
      }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          LoadingStudent: false
        })
        let api_status = response.api_status;
        // alert(api_status);
        if (api_status == "OK") {

          this.setState({
            mStudentPayList: response.data,
          })
          this.getDueList();

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
          this.getDueList();

        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });

  }
  getDueList() {
    this.state.mDueDetails = [];
    this.state.total_amount = 0;
    for (var i = 0; i < this.state.mStudentPayList.length; i++) {

      var mPayDetails = [];
      mPayDetails = this.state.mStudentPayList[i].pay_details;

      if (mPayDetails[0].current_due !== "0") {
        this.state.mDueDetails.push(this.state.mStudentPayList[i]);
        this.state.total_amount = this.state.total_amount + Number(mPayDetails[0].current_due);

      }
    }
    this.setState({

    })
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <div className="content">
          <div className="content-header" style={{ background: COLORS.primary_color_shade5 }}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{ color: COLORS.white }} /><a href="#"> Current Due </a></li>
              </ul>
            </div>
          </div>
          <div className="bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
            <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>

            <section className="bg_grey" style={{borderRadius:'5px'}}>
              <div className="row" style={{ marginRight: "8px", marginLeft: "8px", marginTop: "5px" }}>
                {this.state.LoadingStudent ? <div><MyLoader /></div> :

                  this.state.mDueDetails && this.state.mDueDetails.map((item, index) => (

                    <div className="col-sm-6 col-md-6" style={{ marginBottom: "5px", marginTop: "5px" }}>

                      <div className="row" style={{ height: 100 }}>

                        <div className="col-sm-3 bg_card_red gravity_center">
                          <div className="profil-im-img" style={{ background: 'rgb(219 238 246)' }}>
                            <img src={item.student_img_path} alt="img" style={{ width: '100%' }} />

                          </div>

                        </div>

                        <div className="col-sm-9 bg_card_red_outline">
                          <h5> {item.stu_name} </h5>
                          <p className="mb-0"> Father: <span>{item.father_name}</span> </p>

                          <p className="mb-0" style={{ color: COLORS.card_red, fontSize: "20px" }}><strong> Due: Rs <span>{item.pay_details[0].current_due}</span> </strong></p>

                        </div>

                      </div>

                    </div>

                  ))}

              </div>
            </section>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <button className="btn btn-success" style={{ width: '100%', fontSize: '24px', margin: '30px 0px 15px 0px', background: COLORS.card_red, border: COLORS.primary_color_shade5 }}> Total Amount: INR {this.state.total_amount} </button>

              </div>
            </div>


          </div>
        </div>

      )
    }
  }

}
