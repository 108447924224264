import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import COLORS from '../resources/colors/COLORS';
import icon_forward from '../resources/icons/icons_double_right_100.png'
import icon_edit from '../resources/icons/icons_edit_pencile_100_white.png'
import icon_upcoming from '../resources/icons/icons_upcoming_100_white.png'
import icon_live from '../resources/icons/icons_live_exam_100_white.png'
import icon_over from '../resources/icons/icons_over_100_white.png'
import ExamAddSingleStudentMark from './ExamAddSingleStudentMark';
import ExamStudentResult from './ExamStudentResult';
export default class ExamClassResult extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        mClassList:[],
        mSubjects:[],
        mClassSubjectPart:[],
        mStudentList:[],
        EXAM_ID:'',
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        EXAM_NAME:'',
        EXAM_DATE:'',
        CLASS_SELECTED_POSITION:0,
        SELECTED_POSITION_SUBJECT:0,
        strSUBJECT_PART:''

    }
  
  }
  componentDidMount()
  {
      window.scrollTo(0, 0)
      this.state.EXAM_ID=this.props.match.params.exam_id;
      this.state.SESSION_ID=this.props.match.params.session_id;
      this.state.CLASS_SELECTED_POSITION=this.props.match.params.selected_position_class;
      this.state.SELECTED_POSITION_SUBJECT=this.props.match.params.selected_position_subject;
      this.state.EXAM_NAME=this.props.match.params.exam_name;
      this.state.EXAM_DATE=this.props.match.params.exam_date;


      //alert(this.state.EXAM_ID);
      //alert(this.state.SESSION_ID);
      this.getExamClassResultStatus();
  }
  getExamClassResultStatus(){
   
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getClassWithSubjectsPartAndPaper.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,session_id:this.state.SESSION_ID,exam_id:this.state.EXAM_ID}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));     
      if(api_status=="OK")
      {
      
         this.setState({
          
          mClassList:response.response.class
          })
          this.selectClass(0);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  selectClass=(position)=>{ 
    this.state.mStudentList=[]; 
    this.state.mSubjects=[];
    this.state.mClassSubjectPart=[];
    this.state.CLASS_SELECTED_POSITION=position; 
    this.state.SELECTED_POSITION_SUBJECT=0; 
    this.state.CLASS_ID=this.state.mClassList[position]['class_id'];
    this.state.SECTION_ID=this.state.mClassList[position]['section_id'];
    
    if(this.state.SECTION_ID==null)
    {
      this.state.SECTION_ID="No";
    }

    this.getParticipatingSubjects();  
  }
  selectSubject=(position)=>{ 
    this.state.SELECTED_POSITION_SUBJECT=position;     
    this.getParticipatingSubjects();  
  }

  getParticipatingSubjects()
  {
    this.state.mSubjects=[];
    this.state.mSubjects=this.state.mClassList[this.state.CLASS_SELECTED_POSITION]['subject'];
   
     var item={
     "subject_id": "SBT000",
     "subject_name": "All Subject",
   
     };
      var newArray =  [...this.state.mSubjects];    
      newArray.push(item);   
      this.setState({mSubjects:newArray}, () => 
      //console.log("sub2>>>>"+JSON.stringify(this.state.mSubjects))
      this.getSubjectParts()
    )
    if(this.state.mStudentList.length==0)
    {
      this.getClassStudentMarks();
    }
    
    
  }
 
  getSubjectParts(){
    var strParts='';
    if(this.state.SELECTED_POSITION_SUBJECT!==this.state.mSubjects.length-1)
    {
      this.state.mClassSubjectPart=this.state.mSubjects[this.state.SELECTED_POSITION_SUBJECT]['subject_part'];
      if(this.state.mClassSubjectPart && this.state.mClassSubjectPart.length!==0)
      {
        
        for (var i=0;i<this.state.mClassSubjectPart.length;i++)
        {
            if(i==0)
            {
                strParts="  ("+(i+1)+")"+this.state.mClassSubjectPart[i]['subject_part_name'];
            }
            else {
                strParts=strParts+","+"  ("+(i+1)+")"+this.state.mClassSubjectPart[i]['subject_part_name'];
            }
  
        }
        
      }
      else{
        strParts="Main";
      }
    }
    else{
      strParts="";
    }
    
    
    this.setState({
      strSUBJECT_PART:strParts
    })
  }

  getClassStudentMarks(){
   
    this.setState({
      LoadingStudent:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getClassStudentMarks.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        session_id:this.state.SESSION_ID,
        exam_id:this.state.EXAM_ID,
        class_id:this.state.CLASS_ID,
        section_id:this.state.SECTION_ID
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        LoadingStudent:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));     
        if(api_status=="OK")
        {
        
           this.setState({
            
            mStudentList:response.data
            })

        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        LoadingStudent:false
       })
      console.log("error>>>>>"+error);
      });
    }
 getExamPaperDetails(mPaperDetails) {
    var TAG_TRUE="1";
    var strDetails ="";
    for (var i=0;i<mPaperDetails.length;i++)
    {
        var mMark=[];
        mMark=mPaperDetails[i]['mark'];
       
        if(i==0)
        {
            if (mPaperDetails[i]['is_mark_system']===TAG_TRUE)
            {
                strDetails=strDetails+(i+1)+"-"+mPaperDetails[i]['exam_paper_name']+"(Max Mark-"+mPaperDetails[i]['max_mark']+")\n";
                if(mMark && mMark.length!==0)
                {
                    strDetails=strDetails+"Obtained Mark-"+ mMark[0]['obtained_mark'];
                }
                else {
                    strDetails=strDetails+"Obtained Mark-";
                }
            }
            if (mPaperDetails[i]['is_grade_system']===TAG_TRUE)
            {
                strDetails=strDetails+(i+1)+"-"+mPaperDetails[i]['exam_paper_name']+"(Max Grade-"+mPaperDetails[i]['max_grade']+")\n";
                if(mMark && mMark.length!==0)
                {
                    strDetails=strDetails+"Obtained Grade-"+ mMark[0]['grade_obtained'];
                }
                else {
                    strDetails=strDetails+"Obtained Grade-";
                }
            }

        }
        else {
            if (mPaperDetails[i]['is_mark_system']===TAG_TRUE)
            {
                strDetails=strDetails+"\n\n,"+(i+1)+"-"+mPaperDetails[i]['exam_paper_name']+"(Max Mark-"+mPaperDetails[i]['max_mark']+")\n";
                if(mMark && mMark.length!==0)
                {
                    strDetails=strDetails+"Obtained Mark-"+ mMark[0]['obtained_mark']+"\n\n";
                }
                else {
                    strDetails=strDetails+"Obtained Mark-"+"\n\n";
                }
            }
            if (mPaperDetails[i]['is_grade_system']===TAG_TRUE)
            {
                strDetails=strDetails+"\n\n,"+(i+1)+"-"+mPaperDetails[i]['exam_paper_name']+"(Max Grade-"+mPaperDetails[i]['max_grade']+")\n";
                if(mMark && mMark.length!==0)
                {
                    strDetails=strDetails+"Obtained Grade-"+ mMark[0]['grade_obtained']+"\n\n";
                }
                else {
                    strDetails=strDetails+"Obtained Grade-"+"\n\n";
                }
            }
        }

    }
    //Log.d("strDetails>>>>>",strDetails);
    return strDetails;
}


    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
     
        return (             
          <Router>
          <switch>
            <Route path={'/AdminMain/ExamClassResult/:exam_id/:session_id/:selected_position_class/:selected_position_subject/:exam_name/:exam_date'}  exact render={
              ()=>{
                
           return (
            <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Marks Status</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
          <div className="row bg_card_blue" style={{padding:'5px 10px 5px 10px',margin:'2px 5px 2px 5px'}}>
                    {this.state.mClassList && this.state.mClassList.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
          </div>

          <div className="bg_card_blue_outline" style={{margin:'2px 5px 2px 5px'}}>
                  <div style={{paddingLeft:'10px'}}>
                    <h5>Select Subject:</h5>
                  </div>
                    <div className="row" style={{padding:'5px 10px 5px 20px'}}>
                    {this.state.mSubjects && this.state.mSubjects.map((item2, index2) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.SELECTED_POSITION_SUBJECT===index2?"bg_class_select gravity_center":"bg_white_outline_grey gravity_center"} onClick={e=>this.selectSubject(index2)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item2.subject_name} </p>
                        </div>
                    </div>
                    ))}
                    </div>
                    <div style={{paddingLeft:'10px'}}>
                    <h5>Subject Part:</h5>
                  </div>
                  <div style={{paddingLeft:'10px'}}>
                    <h6>{this.state.strSUBJECT_PART}</h6>
                  </div>
          </div>

          <div style={{paddingLeft:'10px'}}>
                    <h5>{"Student"+"("+this.state.mStudentList.length+")"}</h5>
          </div>
          
          <div className="row">
          {this.state.LoadingStudent?<div><MyLoader/></div>:
            this.state.mStudentList && this.state.mStudentList.map((item, index) => { 
              var student_id=item.student_enrollment_no;
              
              var mMarkDetails=[];
              var mSubject=[];
              mMarkDetails=item.mark_details;  
              mSubject=mMarkDetails[0]['subjects']; 
              var mSelectedSubject=[];
              if(this.state.SELECTED_POSITION_SUBJECT==mSubject.length)
              {
                  mSelectedSubject=mSubject;
              }
              else {
                  mSelectedSubject.push(mSubject[this.state.SELECTED_POSITION_SUBJECT]);
              }

            return( 
             <div className="col-sm-6 col-md-6">
              <div style={{margin:'5px'}}>
              
                <div className="bg_card_blue_outline">
                 
                <div className="row" style={{margin:'5px 5px 10px 5px',height:'70px'}}>

                    <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'0px',height:'65px'}}>
                    <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'50px',width:'50px'}}>
                      <div className="circular_image">
                          <img  src={item.student_img_path}  alt=""></img>
                          <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                      </div> 
                      </div>                
                    </div>
                    <div className="col-sm-9" style={{padding:'5px 10px',height:'70px'}}>
                      <h5 className="mb-0 black"> {item.stu_name} </h5>
                      <p className="mb-0 black7"> Father: <span>{item.father_name}</span> </p>
                     
                    </div>
                    </div>
                    <hr></hr>
                  <div className="row">
                  {mSelectedSubject && mSelectedSubject.map((item2, index2) => { 
                    var FLAG=true;
                     var mPaperDetails=[];
                     mPaperDetails=mSelectedSubject[index2]['subject_paper'];
                     if(mPaperDetails==null)
                     {
                      mPaperDetails=[];
                     }
                     if(mPaperDetails && mPaperDetails.length!==0)
                     {
                                
                             for (var i=0;i<mPaperDetails.length;i++) {
                                 var strMark = mPaperDetails[i]['mark'];
                                 if(strMark==null)
                                 {
                                     FLAG=false;
                                 }
             
                             }
                         
                     }
                     else {
                         FLAG=false;
                     }                                         
                    return( 
                    <div className="col-sm-4 col-md-4">
                      <div className={FLAG? "row  bg_card_green_outline":"row  bg_card_grey_outline"} style={{margin:'5px 2px 5px 2px',minHeight:'180px'}}>
                          
                         <div className={FLAG? "gravity_center bg_card_green_tltr ":"gravity_center bg_card_grey_tltr"} style={{fontSize:'16px',width:'100%',height:'40px'}}>
                             {item2.subject_name}
                          </div>
                        
                         <div className='gravity_center' style={{fontSize:'14px'}}>{this.getExamPaperDetails(mPaperDetails)}</div>
                      </div>
                    </div>
                    )
                    })}
                   
                  </div>
                
                  <hr></hr>
                
                   <div className="row">
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamStudentResult/'+this.state.EXAM_ID+'/'+this.state.SESSION_ID+'/'+student_id+'/'+this.state.CLASS_ID+'/'+this.state.SECTION_ID+'/'+this.state.EXAM_NAME+'/'+this.state.EXAM_DATE}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'100%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Student Result </button>
                            </Link>                
                          </div>
                          <div className="col-sm-6 col-md-6 ">
                            <Link to={'/AdminMain/ExamAddSingleStudentMark'}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Edit Mark </button>
                            </Link>                
                          </div>    
                   </div>
                   
                </div>
              </div>

            </div>
              )
            })}

          </div>                             
                                
         </div>
      </div>
           
           )
          }
          } />
            <Route path='/AdminMain/ExamAddSingleStudentMark'  component={ExamAddSingleStudentMark} />
            <Route path='/AdminMain/ExamStudentResult/:exam_id/:session_id/:student_id/:class_id/:section_id/:exam_name/:exam_date'  component={ExamStudentResult} />
            
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }
