import React, { Component,createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import uploadIcon from '../resources/images/upload-icon.png'
import MdlSelectSession from './MdlSelectSession';


export default class MdlSelectExam extends Component {
    constructor(props){
        super(props)       
       
       
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            isOpenSessionDialog:false,
            show:true,
            mExamList:[],
            CLASS_ID:'',
            SECTION_ID:'',
            EXAM_ID:'',
            EXAM_NAME:'',
      
            SESSION_ID:'',
            SESSION_NAME:'',
            SESSION_STATUS:'',
            EXAM_SELECTED_POSITION:0
           
        }
      
}

ChangeSession = () => {
 
  this.HandleShowSessionDialog();
}

HandleShowSessionDialog = () => {
  this.setState({
    isOpenSessionDialog: !this.state.isOpenSessionDialog
  });   
}

HandleCloseSessionDialog = () => {

  this.setState({
    isOpenSessionDialog:false
  });  
   
}
HandleSelectedSession = (session_id,session_name,session_status) => {
  this.state.SESSION_ID=session_id;
  this.state.SESSION_NAME=session_name;
  this.state.SESSION_STATUS=session_status;
  this.setState({
    
    isOpenSessionDialog:false
  });
 
  this.getExamList();
}

HandleClose = () => {

  this.setState({
    show: !this.state.show
  });
this.props.action_close();
  
}
SelectExam = (exam_id,exam_name,position) => {
  //alert("okk"+student_id);
  
  this.setState({
    EXAM_ID:exam_id,
    EXAM_NAME:exam_name,
    EXAM_SELECTED_POSITION:position
  })

  
  
}
HandleSelectedExam = () => {
  //alert("okk"+student_id);
  this.setState({
    show: !this.state.show
  });
  this.props.action_select(this.state.EXAM_ID,this.state.EXAM_NAME);
  
}

componentDidMount(){

  var mSession=SessionManager.getCurrentSession();
  this.state.SESSION_ID=mSession[0].session_id;
  this.state.SESSION_NAME=mSession[0].session_name;
  this.state.SESSION_STATUS=mSession[0].status;

 
  this.getExamList();
}    

getExamList(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getExamList.php?'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
const params={
  school_code:SCHOOL_CODE,
  session_id:this.state.SESSION_ID,

}
const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
  
axios.get(URL,{
  params:params
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
   // console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(JSON.stringify(response));
    
    if(api_status=="OK")
    {
    
       this.setState({       
            mExamList:response.data,      
        })
        if(this.state.mExamList.length!==0)
        {
          this.state.EXAM_ID=this.state.mExamList[this.state.EXAM_SELECTED_POSITION].exam_id;
          this.state.EXAM_NAME=this.state.mExamList[this.state.EXAM_SELECTED_POSITION].exam_name;
        }
        
        //console.log("response>>>>>"+this.state.mClassData);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
} 


    render() {
      
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
            return (

              <div>
                
      <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Exam
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.state.Loading ? <MyLoader/> : 
<>

            <div className="row">

            <div className="col-sm-12 col-md-12">
            <Link to='#' onClick={()=>this.ChangeSession()}>
            <p className="bg_card_blue" style={{padding:'1px 5px 1px 5px',fontSize:'18px', textAlign:'center',color:COLORS.white}}> Session:
              {this.state.SESSION_NAME+"("+ this.state.SESSION_STATUS+")"}
            <span style={{float:'right'}}> <img src={uploadIcon} alt="upload image" className="bg_card_blue" style={{height:'25px',width:'25px'}}/> </span> </p>                                    
            </Link>
            </div>  

            </div>

              <div className="row" style={{padding:'0px 15px 0px 15px'}}>
              {this.state.mExamList.map((item, index) => (
              <div className="col-sm-2 col-md-2" style={{padding:'0px 2px 5px 2px'}}>
                  <div className={this.state.EXAM_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect_grey gravity_center"} onClick={e=>this.SelectExam(item.exam_id,item.exam_name,index)} style={{cursor:'pointer'}}> 
                    <p style={{fontSize:14}}> {item.exam_name} </p>
                  </div>
              </div>
              ))}
                </div>

  </>
      }
                       
      </Modal.Body>
      <Modal.Footer>
            <Button variant="primary" size="lg"  block onClick={this.HandleSelectedExam}>
                Done
            </Button>
          
          </Modal.Footer>
    </Modal>

    {this.state.isOpenSessionDialog===true? <MdlSelectSession action={this.HandleCloseSessionDialog} action2={this.HandleSelectedSession}></MdlSelectSession>:''}
           
            </div>
      
            )
        }
    }
}

