import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import SelectClass from '../ChildComponent/SelectClass';
import COLORS from '../resources/colors/COLORS';
import { withRouter } from "react-router-dom";
import ClassFee from './ClassFee';
import myToast from '../Utils/MyToast';
class AddClassFee extends Component {
    constructor(props) {
        super(props)   
        
        this.slt_feename=createRef();
        this.slt_receipt_mode=createRef();
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={  
            isOpen: false, 
            loggedIn:true,
            Loading:false,
            showInputReAdm:false,
            SESSION_ID:"",
            CLASS_ID:"",
            SECTION_ID:"",
            mFeeName:[],
            mFeeReceiptMode:[],
            mSpecialFee:[],
            mClassFee:[],
            mFeeFiltered:[],
            mCurrentSession:[],
            mSessionMonth:[],
            mApplicableMonth:[],
            REGISTRATION_FEE_ID:'',
            TRANSPORTATION_FEE_ID:'',
            SELECTED_CLASS_POSITION:'',
            FEE_VALUE:'',
            FEE_VALUE_READMISSION:'',
            payment_count:''
            
        }
        
        this.onFeeValueChange= this.onFeeValueChange.bind(this);
        this.onFeeValueReAdmChange= this.onFeeValueReAdmChange.bind(this);
        this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
        
      }
      onFeeValueChange(e){
        this.setState({
            FEE_VALUE: e.target.value
        })
    }
    onFeeValueReAdmChange(e){
        this.setState({
            FEE_VALUE_READMISSION: e.target.value
        })
    }
      componentDidMount() {
        window.scrollTo(0, 0)
        var mSession=SessionManager.getCurrentSession();
        this.state.SESSION_ID=mSession[0].session_id;
        
      }
      onReceiptModeChange = () => {
        let position=this.slt_receipt_mode.current.selectedOptions[0].getAttribute('position');
        //let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
        
                if(position!==null)
                {
                    var session_start_date;
                    if(this.state.mCurrentSession.length!==0)
                    {
                        session_start_date=this.state.mCurrentSession[0]['start_date'];
                    }
                    else {                     
                      this.state.mCurrentSession=SessionManager.getCurrentSession();
                      session_start_date=this.state.mCurrentSession[0]['start_date'];
                    }
                   
                    var period;
                    var selectMode =position;
                    if (selectMode==0){
                        this.state.payment_count=12;
                        period=1;
                    }
                    else if(selectMode==1)
                    {
                      this.state.payment_count=4;
                        period=3;
                    }
                    else if(selectMode==2)
                    {
                      this.state.payment_count=2;
                        period=6;
                    }
                    else if(selectMode==3)
                    {
                      this.state.payment_count=1;
                        period=1;
                    }
                    else if(selectMode==4)
                    {
                      this.state.payment_count=3;
                        period=4;
                    }
                    else if(selectMode==5)
                    {
                      this.state.payment_count=6;
                        period=2;
                    }

                }
                this.getAllSessionMonth(session_start_date);       
      }
      getAllSessionMonth(session_start_date){
        this.state.mSessionMonth=[];
        var temp=[];
        temp=session_start_date.split("-");
        var dateObj = new Date(Number(temp[0]), Number(temp[1])-1, Number(temp[2])+1);   
        
        this.state.mSessionMonth=[];
        for (var i=1;i<=12;i++)
        {
            if(i==1)
            {
                //dateObj.setMonth(dateObj.getMonth() + 0);

                var MONTH =dateObj.getUTCMonth()+ 1; //months from 1-12
                var YEAR=dateObj.getUTCFullYear();
                if(MONTH<10)
                {
                  var month=YEAR+"-"+("0"+MONTH);
                  this.state.mSessionMonth.push(month);
                
                }                   
                else
                   this.state.mSessionMonth.push(YEAR+"-"+MONTH);
                  
            }
            else {
              dateObj.setMonth(dateObj.getMonth() +1);
              var MONTH =dateObj.getUTCMonth() + 1; //months from 1-12
              var YEAR=dateObj.getUTCFullYear();
              if(MONTH<10)
              {
                var month=YEAR+"-"+("0"+MONTH);
                this.state.mSessionMonth.push(month);
              
              }
              else
                 this.state.mSessionMonth.push(YEAR+"-"+MONTH);

            }
            
        }
        
      
        this.setState({

        })

      }
      onFeeNameChange = () => {
        
        let position=this.slt_feename.current.selectedOptions[0].getAttribute('position');
        let fee_id=this.slt_feename.current.selectedOptions[0].getAttribute('fee_id');
        let is_special_fee=this.slt_feename.current.selectedOptions[0].getAttribute('is_special_fee');
        if(is_special_fee==='1')
        {
            this.setState({
              showInputReAdm:true
            })
        }
        else{
          this.setState({
            showInputReAdm:false
          })
        }
      
        
      }
      CallBackClassSelect = (class_id,section_id,session_id,selected_position,class_name,section_name,session_name) => {
      
        this.state.CLASS_ID=class_id;
        this.state.SECTION_ID=section_id;
        this.state.SESSION_ID=session_id;
        this.state.SELECTED_CLASS_POSITION=selected_position;
       // alert("Back"+class_id+"/"+section_id+"/"+session_id);
       // alert( this.state.CLASS_ID);


        this.getApplicableFeeAndMode();
        
      }
      
      getApplicableFeeAndMode(){
    
        this.setState({
          Loading:true
        })
        
        const SCHOOL_CODE=SessionManager.getSchoolCode();
        const FUNCTION_NAME='getApplicableFeeAndMode.php'
        URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
        //console.log("URL>>>>>"+URL)
        axios.get(URL,{
          params:{
                  school_code:SCHOOL_CODE,
                }
        }).then(res => res.data)
        .then((response) => {
         
          this.setState({ 
            Loading:false
           })
           // console.log("Response2>>>>>"+response);
            let api_status=response.api_status;
            //alert(api_status);
            
            if(api_status=="OK")
            {
            
               this.setState({               
                mFeeName:response.data.applicable_fee,
                mFeeReceiptMode:response.data.mode,
               
              })
              this. getClassFee();
            }
            else{
              let api_remark=response.api_remark;
              myToast.notify_warning(api_remark);
            }
           // alert(JSON.stringify(this.state.mFeeName));
         }).catch((error)=>{
          this.setState({ 
            Loading:false
           })
          console.log("error>>>>>"+error);
          });
        }

        getClassFee(){
    
          this.setState({
            Loading:true,
            mClassFee:[]
          })
          
          const SCHOOL_CODE=SessionManager.getSchoolCode();
          const FUNCTION_NAME='getClassApplicableFee.php'
          URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
          //console.log("URL>>>>>"+URL)
          axios.get(URL,{
            params:{
                     session_id:this.state.SESSION_ID,
                     class_id:this.state.CLASS_ID,
                    school_code:SCHOOL_CODE,
                  }
          }).then(res => res.data)
          .then((response) => {
           
            this.setState({ 
              Loading:false
             })
             // console.log("Response2>>>>>"+response);
              let api_status=response.api_status;
              //alert(api_status);
              
              if(api_status=="OK")
              {       
                 this.setState({            
                  mClassFee:response.data,
                  })
                  this.removeAlreadyAddedFee();
              }
              else{
                this.removeAlreadyAddedFee();
               
              }
             // alert(JSON.stringify(this.state.mClassFee));
           }).catch((error)=>{
            this.setState({ 
              Loading:false
             })
            console.log("error>>>>>"+error);
            });
          }
 
    removeAlreadyAddedFee()
    {
        this.state.mFeeFiltered=[];
        if(this.state.mFeeName.length!==0)
        {
            for(var i=0;i<this.state.mFeeName.length;i++)
            {
                if (!this.isAlreadyAdded(this.state.mFeeName[i]['fee_id']))
                {
                    this.state.mFeeFiltered.push(this.state.mFeeName[i]);
                }

            }
        }
        this.setState({

        })

    }
    isAlreadyAdded(fee_id) {
        for (var i=0;i<this.state.mClassFee.length;i++)
        {
            if(this.state.mClassFee[i]['fee_id']===fee_id)
            {
                return true;
            }
        }
        return false;
    }
    getMonth(index)
    {
        var Arr=[];
        Arr=index.split('-');
        var month=Arr[1];
        if (month==1){
          return("Jan");
      }else if (month==2){
        return("Feb");
      }else if (month==3){
          return("Mar");
      }else if (month==4){
          return("Apr");
      }else if (month==5){
          return("May");
      }else if (month==6){
          return("Jun");
      }else if (month==7){
          return("Jul");
      }else if (month==8){
          return("Aug");
      }else if (month==9){
          return("Sep");
      }else if (month==10){
          return("Oct");
      }else if (month==11){
          return("Nov");
      }else if (month==12){
          return("Dec");
      }
        
    }
    
  isMonthApplicable(strMonth) {
      for (var i=0;i<this.state.mApplicableMonth.length;i++)
      {
          if(strMonth===this.state.mApplicableMonth[i])
          {
              return true;
          }
      }
      return false;
  }
  updateApplicableMonth= (strMonth) => {
   
      if(this.isMonthApplicable(strMonth)===true)
      {
          var pos=this.getMonthPosition(strMonth);
         
          this.state.mApplicableMonth.splice(pos, 1);
          let newArray = [...this.state.mApplicableMonth];
          this.setState({mApplicableMonth: newArray});
      }
      else{
        this.state.mApplicableMonth.push(strMonth);
        this.setState({

        })
      }
  }
   
  getMonthPosition(strMonth) {
    for (var i=0;i<this.state.mApplicableMonth.length;i++)
    {
        if(strMonth===this.state.mApplicableMonth[i])
        {
            return i;
        }
    }
    return -1;
}
submitClassFee(){

  if(this.isValidInput())
  {
    this.addClassFee();
  }
  
  
}
isValidInput(){
  if(this.slt_feename.current.selectedOptions[0].getAttribute('position')===null)
  {
    alert("Select Fee !!!");
    return false;
  }
  else if(this.slt_receipt_mode.current.selectedOptions[0].getAttribute('position')===null)
  {
    alert("Select Fee Receipt Mode!!!");
    return false;
  }
  else if(!this.state.FEE_VALUE)
  {
      
    alert("Enter Fee Value!!!");
    return false;
  }
  else if(this.state.showInputReAdm)
  {
    if(!this.state.FEE_VALUE_READMISSION)
    {
      
      alert("Enter Fee Value!!!");
      return false;
    }
  }
  if(this.state.payment_count<12)
    {
        if (this.state.payment_count!= this.state.mApplicableMonth.length)
        {
            var msg="For Reciept Mode:"+this.slt_receipt_mode.current.selectedOptions[0].getAttribute('value')+"\n"+"Select:"+this.state.payment_count+" Collection Month";
            alert(msg);
            return false;
        }

    }
    else {
        if (this.state.mApplicableMonth.length<10)
        {
            var msg="For Reciept Mode:"+this.slt_receipt_mode.current.selectedOptions[0].getAttribute('value')+"\n"+"Select Atleast 10 Collection Month";
            alert(msg);
            return false;
        }
     }

  return true
}
addClassFee(){
  this.setState({
    Loading:true
  })
  
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  
  var FEE_ID=this.slt_feename.current.selectedOptions[0].getAttribute('fee_id');
  var MODE_ID=this.slt_receipt_mode.current.selectedOptions[0].getAttribute('mode_id');
  var applicable_month=this.state.mApplicableMonth.toString();

  var is_special_fee=this.slt_feename.current.selectedOptions[0].getAttribute('is_special_fee')
  
  const FUNCTION_NAME='AddClassFee.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
      var params = new URLSearchParams();
    params.append('session_id', this.state.SESSION_ID);
    params.append('class_id', this.state.CLASS_ID);
    params.append('fee_id',FEE_ID);
    params.append('fee_value',this.state.FEE_VALUE);
    params.append('fee_value_for_existing_student',this.state.FEE_VALUE_READMISSION);
    params.append('applicable_month',applicable_month);
    params.append('mode_id',MODE_ID);
    params.append('is_special_fee',is_special_fee);
    params.append('school_code',SCHOOL_CODE);
 axios.post(URL,params).then(res => res.data)
.then((response) => {
  this.setState({ 
     Loading:false
    })
      let api_status=response.api_status;
      //alert(api_status);      
      if(api_status=="OK")
      {   
        myToast.notify_success("Class Fee Added Successfully");
        this.props.history.push("/AdminMain/ClassFee");

      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
   

  render() {
    if(this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else{       
     
        return (
          
        <Router>
        <switch>
          <Route path={'/AdminMain/AddClassFee/:selected_class_pos'}  exact render={
            ()=>{
          return(

            <div className="content" style={{background:COLORS.white}}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Add Class Fee </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'100px'}}>

                       
                        <SelectClass action={this.CallBackClassSelect} selected_position={this.props.match.params.selected_class_pos} session_id={this.state.SESSION_ID}></SelectClass>
                         
                        
                          <section>
                          <hr/>
                          {this.state.Loading?<MyLoader/>:
                          <div className="row gravity_center">
                           
                            <div className="div_addfeebox  box_shadow_primary " style={{margin:'5px 2px 10px 3px'}}>

                              <section  style={{padding: '0px 30px 0px 30px' }}>
                                <div className="row gravity_center">
                                  <h2 className="gravity_center">Add Class Fee</h2>  
                              </div>
                              <div className="">
                                <h5>Select Fee Name:</h5>
                                <select className="select-field" onChange={this.onFeeNameChange} ref = {this.slt_feename}>
                                   <option>Select Fee</option>

                                    {this.state.mFeeFiltered.map((item, index) => ( 
                                       <option fee_id={item.fee_id} position={index} is_special_fee={item.is_special_fee}>{item.fee_name}</option>
                                    
                                    ))}
                                </select>
                           </div>
                               <div className="">
                                <h5>Select Receipt Mode:</h5>
                                <select className="select-field" onChange={this.onReceiptModeChange} ref = {this.slt_receipt_mode}>
                                    <option >Select Mode</option>
                                    {this.state.mFeeReceiptMode.map((item, index) => ( 
                                   <option mode_id={item.mode_id} position={index} value={item.mode}>{item.mode}</option>
                                    
                                    ))}
                                </select>
                           </div>
                             
                              <div className="inputBox">

                                  <h5>{this.state.showInputReAdm? 'Fee Value(New Admission)':'Fee Value'}</h5>
                                  <input type="text" name="fee_value"  value={this.state.FEE_VALUE} onChange={this.onFeeValueChange}></input>
                                  
                                  {this.state.showInputReAdm &&<h5>Fee Value(Re Admission)</h5>}
                                  {this.state.showInputReAdm && <input type="text" name="feevalue" value={this.state.FEE_VALUE_READMISSION} onChange={this.onFeeValueReAdmChange}></input>}
                                  
                             </div> 
                             </section>
                             <section>
                             <div className="gravity_center">
                                    <h4 className="gravity_center"> Applicable Month </h4>
                                    
                              </div>  
                                 
                                  <hr/>
                                  <div className="row gravity_center " style={{padding:'0px 15px 5px 15px',margin:0}}>
                                    {this.state.mSessionMonth && this.state.mSessionMonth.map((item,index)=>{
                                      return(                                            
                                          <div className="col-sm-2 col-md-2">
                                          <Link>
                                          <div className={this.isMonthApplicable(item)===true?'bg_circle_outline_primery gravity_center':'bg_circle_outline_grey gravity_center'} onClick={()=>this.updateApplicableMonth(item)}style={{'--mWidth':'70px','--mHeight':'70px','--mBorder':'2px',marginBottom:'8px'}}>
                                            <p style={{fontSize:'20px',color:COLORS.black8}}>{this.getMonth(item)} </p>
                                            <p className={this.isMonthApplicable(item)===true?"bg_round_green fee_collection_month":"bg_round_grey fee_collection_month"} style={{fontSize:'15px',color:COLORS.white}}>{item}</p>
                                          </div>
                                          </Link>
                                          </div>
                                          
                                      )
                                    })}
                                </div>

                                </section>
                            </div>
                          </div> 
                                                  
                           }
                         </section>

                         
                          <div className="row">
                          <div className="col-sm-12 col-md-12 gravity_center " >
                           
                              <button className="btn btn-success" onClick={()=>this.submitClassFee()} style={{fontSize:'20px', margin:'3px 2px 5px 2px',width:'52vw',height:'40px',background:COLORS.primary_color_shade5}}> Submit Class Fee </button>
                                           
                          </div>    
                          </div>
                        </div>
                        </div>
           )
          }
          } />
    
        <Route path='/AdminMain/ClassFee'  component={ClassFee} />                    
        </switch>       
       </Router>  
        )
    }
  }
}
export default withRouter(AddClassFee);

