import React, { Component } from 'react'
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';

export default class Test2 extends Component {
    render() {
        return (
       
          <Router>
              <div>
                <h1>Hello mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm</h1>
            </div>
            <switch>
                <Route path='/Test2' component={Test2} />
            </switch>
          </Router>
            

        )
         
      
    }
}
