import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
export default class SchoolWorkingDays extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
     
        mWorkingDays:[],
     
    
        REPORT_CARD_ID:'',   
        SESSION_ID:'',
     
        
      
    }
   
  
  }
  
  componentDidMount()
  {
  
    this.state.REPORT_CARD_ID=this.props.match.params.report_card_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
  
    window.scrollTo(0, 0); 
    this.getSchoolWorkingDays();  
  }

  getSchoolWorkingDays(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getSchoolWorkingDays.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
    
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
       //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
            mWorkingDays:response.data,
                   
          })
      
        
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }

  Recalculate=()=>{
         
    this.refreshSchoolWorkingDays();
   
  }
  refreshSchoolWorkingDays(){
  
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='RefreshSchoolWorkingDays.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        session_id:this.state.SESSION_ID,
      
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
         //alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({         
              mWorkingDays:response.data,
                     
            })
        
          
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
  
  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/SchoolWorkingDays/:report_card_id/:session_id'}  exact render={
            ()=>{
              
         return (
       
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">School Working Days</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
       

          <div className="row gravity_center" style={{margin:'2px 15px 2px 15px'}}>
               
               <div className="col-sm-6 col-md-6" style={{height:'40px'}}>
                 <p  style={{padding:'2px',fontSize:'18px'}}><strong> School Working Days:</strong> </p> 
               </div>
               <div className="col-sm-3 col-md-3 " style={{height:'40px'}}>
                  <div className='gravity_center'>              
                   
                    </div>

               </div>
               <div className="col-sm-3 col-md-3  " style={{height:'40px'}}>
                 <div className='gravity_center'>              
                      <button className="bg_card_primary btn btn-success" onClick={()=>this.Recalculate()}>ReCalculate Working Days</button>
                
                    </div>

               </div>
                
         </div>
            
                 <div className="row">
                            
                  <div className="col-sm-12 col-md-12" >
                  <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'5px 2px 10px 3px'}}>
                   
                    
                    <hr/>
                    <div className="row" style={{padding:'0px 15px 5px 15px'}}>
                      
                    <div className="col-sm-12 col-md-12" >                                          
                          <Table striped bordered hover variant="" style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Sr.</th>
                              <th style={{ textAlign: "center" }}>Year-Month</th>
                              <th style={{ textAlign: "center" }}>Month Name</th>
                              <th style={{ textAlign: "center" }}>Days In Month</th>
                              <th style={{ textAlign: "center" }}>Week Off(Sunday)</th>
                              <th style={{ textAlign: "center" }}>Holidays</th>
                              <th style={{ textAlign: "center" }}>Working Days</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mWorkingDays && this.state.mWorkingDays.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td style={{ textAlign: "center" }}>{index2+1}</td>
                              <td style={{ textAlign: "center" }}>{item2.month}</td>
                              <td style={{ textAlign: "center" }}>{item2.month2}</td>
                              <td style={{ textAlign: "center" }}>{item2.days_in_month}</td>
                              <td style={{ textAlign: "center" }}>{item2.week_off}</td>
                              <td style={{ textAlign: "center" }}>{item2.holidays}</td>
                              <td style={{ textAlign: "center" }}>{item2.working_days}</td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                        
                      
                  </div>
                                 
                  </div>
                  <hr/>             
                  
                  </div>
                </div> 
                                                                           
                </div>  
              
           
                          
               

               
            </div>
           
         
         
     </div>


           
           )
          }
          } />
             <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }