import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import StudentRegistration from "./StudentRegistration";
import "../css/Admin_css/admin-customize.css";
import "../css/Admin_css/varun_style.css";
import COLORS from "../resources/colors/COLORS";
import plus from "../resources/icons/icons_plus_100_white.png";
import batchshift from "../resources/icons/icons_batch_shifting_100.png";
import query from "../resources/icons/icons_query_100_white.png";
import fee from "../resources/icons/icons_fee_80_white.png";
import admission_link from "../resources/icons/icons_admission_80_white.png";
import BatchShifting from "./BatchShifting";
import myToast from "../Utils/MyToast";
import AdmissionQuery from "./AdmissionQuery";
import AdmissionLink from "./AdmissionLink";
import RegistrationFee from "./RegistrationFee";
import Siblings from "./Siblings";
import ClassChange from "./ClassChange";
import TransferCertificate from "./TransferCertificate";
import TransferCertificateForm from "./TransferCertificateForm";
import SrNumber from "./SrNumber";
import RollNumberManagement from "./RollNumberManagement";
import StateSpecificId from "./StateSpecificId";
import Dob from "./Dob";
import BloodGroup from "./BloodGroup";
import Aadhaar from "./Aadhaar";
import Gender from "./Gender";
import MotherTongue from "./MotherTongue";
import HeightAndWeight from "./HeightAndWeight";
import RteStudent from "./RteStudent";
import BplBeneficiary from "./BplBeneficiary";
import PhysicalDisability from "./PhysicalDisability";
import StudentPen from "./StudentPen";
export default class AdmissionDashboard extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mAdmissionDashboard: [],
    };
  }
  componentDidMount() {
    this.getAdmissionDashboard();
  }
  getAdmissionDashboard() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    const FUNCTION_NAME = "getAdmissionDashboard.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          this.setState({
            mAdmissionDashboard: response.data,
          });
          console.log("response>>>>>" + this.state.mAdmissionDashboard);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path="/AdminMain/AdmissionDashboard/:module_id"
              exact
              render={() => {
                return (
                  <div className="content" style={{ background: COLORS.white }}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#">AdmissionDashboard</a>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="col-sm-12 col-lg-12 text-center -bg mar-t"
                        style={{ background: COLORS.primary_color_shade6 }}
                      >
                        <h3>
                          Total Students:
                          {this.state.mAdmissionDashboard.length == 0
                            ? "0"
                            : this.state.mAdmissionDashboard[0]
                              .total_student}
                        </h3>
                      </div>
                    </div>
                    <div
                      className="animated fadeInUp bg_page_grey_rounded box_shadow"
                      style={{ "--mMargin": "50px", marginTop: "20px" }}
                    >
                      <h1>Manage Student Information</h1>
                      <div className="bg_card_orange_outline">
                        <div
                          className="row bg_corner_round_grey"
                          style={{
                            margin: "5px",
                          }}
                        >
                          <div className="col-sm-2">
                            <Link to="/AdminMain/RollNumberManagement">
                              <div
                                className="bg_circle_card_peach gravity_center gravity_center"
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  margin: "5px",
                                }}
                              >
                                <h5 className="text-center text_color cursor">
                                  Roll Number
                                </h5>
                              </div>
                            </Link>
                          </div>

                          <div className="col-sm-2">
                          <Link to="/AdminMain/StateSpecificId">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                State Specific id
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/Dob">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Date of Birth
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/BloodGroup">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Blood Group
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/Aadhaar">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Aadhar Number
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/Gender">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Gender
                              </h5>
                            </div>
                            </Link>
                          </div>
                        </div>
                        <div
                          className="row bg_corner_round_grey"
                          style={{
                            margin: "5px",
                          }}
                        >
                          <div className="col-sm-2">
                          <Link to="/AdminMain/MotherTongue">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Mother Tongue
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/HeightAndWeight">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Height And Weight
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/RteStudent">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                RTE Student{" "}
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/BplBeneficiary">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                BPL Beneficiary
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/PhysicalDisability">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color cursor">
                                Physical Disability
                              </h5>
                            </div>
                            </Link>
                          </div>
                          <div className="col-sm-2">
                          <Link to="/AdminMain/StudentPen">
                            <div
                              className="bg_circle_card_peach gravity_center"
                              style={{
                                width: "90px",
                                height: "90px",
                                margin: "5px",
                              }}
                            >
                              <h5 className="text-center text_color text_color cursor">
                                Student PEN
                              </h5>
                            </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="row class-li bg_card_orange_outline mar_top1">
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/StudentRegistration">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">
                                  Student Registration
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/Siblings">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">Siblings</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/PromoteStudent">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div padding_2">
                                <h5 className="pt-45 text-center">
                                  Promote Student
                                  <br />
                                  (Into Next Session)
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/StudentRegistration">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div padding_2">
                                <h5 className="text-center text-center">
                                  Promote Transport Student
                                  <br />
                                  (Into Next Session)
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/ClassChange">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">
                                  Class Change
                                  <br />
                                  (Current Session)
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/AdmissionQuery">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">
                                  Addmission Query
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/RegistrationFee">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">
                                  Registration Fee
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/AdmissionLink">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">
                                  Online Addmission Link
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/SrNumber">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">
                                  Manage Roll/Addmission Number Sr.
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/StudentRegistration">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div padding_2">
                                <h5 className="pt-45 text-center">
                                  State Specific id Management
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>

                        <div
                          className="col-sm-4 col-md-4 cursor"
                          style={{ padding: "5px" }}
                        >
                          <Link to="/AdminMain/TransferCertificate">
                            <div className="row class-list-box1">
                              <div className="col-sm-4 admission_deshboard_div">
                                <div>
                                  <img
                                    src={plus}
                                    className="admission-dashboard-img"
                                    alt="student list image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 admission_deshboard_Second_div">
                                <h5 className="pt-45 text-center">Manage TC</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />

            <Route
              path="/AdminMain/StudentRegistration"
              component={StudentRegistration}
            />
            <Route path="/AdminMain/BatchShifting" component={BatchShifting} />
            <Route
              path="/AdminMain/AdmissionQuery"
              component={AdmissionQuery}
            />
            <Route path="/AdminMain/AdmissionLink" component={AdmissionLink} />
            <Route
              path="/AdminMain/RegistrationFee"
              component={RegistrationFee}
            />
            <Route path="/AdminMain/Siblings" component={Siblings} />
            <Route path="/AdminMain/ClassChange" component={ClassChange} />
            <Route path="/AdminMain/RollNumberManagement" component={RollNumberManagement} />
            <Route path="/AdminMain/StateSpecificId" component={StateSpecificId} />
            <Route path="/AdminMain/Dob" component={Dob} />
            <Route path="/AdminMain/BloodGroup" component={BloodGroup} />
            <Route path="/AdminMain/Aadhaar" component={Aadhaar} />
            <Route path="/AdminMain/Gender" component={Gender} />
            <Route path="/AdminMain/MotherTongue" component={MotherTongue} />
            <Route path="/AdminMain/HeightAndWeight" component={HeightAndWeight} />
            <Route path="/AdminMain/RteStudent" component={RteStudent} />
            <Route path="/AdminMain/BplBeneficiary" component={BplBeneficiary} />
            <Route path="/AdminMain/PhysicalDisability" component={PhysicalDisability} />
            <Route path="/AdminMain/StudentPen" component={StudentPen} />
            <Route
              path="/AdminMain/TransferCertificate"
              component={TransferCertificate}
            />

            <Route path="/AdminMain/SrNumber" component={SrNumber} />
          </switch>
        </Router>
      );
    }
  }
}
