import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
import MdlViewStudentAttendance from '../dialog/MdlViewStudentAttendance';
import MdlEditStudentAttendance from '../dialog/MdlEditStudentAttendance';
export default class ExamMarkingSystem extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        isOpenViewStudentAttendance:false,
        isOpenEditStudentAttendance:false,
        mExamMarkingSystem:[],
        mExamParticipatingClass:[],
        mMarkingSystem:[],
        mMarkingSystemValue:[],
        mStudentList:[],
        EXAM_ID:'',
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        CLASS_SELECTED_POSITION:0,
        REPORT_CARD_ID:'',
        REPORT_CARD_TYPE:'MT'
       

    }
    this.HandleCloseViewStudentAttendance = this.HandleCloseViewStudentAttendance.bind(this);
    this.HandleCloseEditStudentAttendance = this.HandleCloseEditStudentAttendance.bind(this);
    
  
  }

  ViewStudentAttendance=(item2)=>{
    //alert(JSON.stringify(item2));
         
    this.setState({
    
      isOpenViewStudentAttendance:true,
      SELECTED_STUDENT:item2
    
    })
   
  }
  HandleCloseViewStudentAttendance = () => {

    this.setState({
      isOpenViewStudentAttendance:false,

    });  
     
  }
  EditStudentAttendance=(item2)=>{
    //alert(JSON.stringify(item2));
         
    this.setState({
    
      isOpenEditStudentAttendance:true,
      SELECTED_STUDENT:item2
    
    })
   
  }
  HandleCloseEditStudentAttendance = () => {

    this.setState({
      isOpenEditStudentAttendance:false,

    });  
     
  }
  componentDidMount()
  {
  
    this.state.EXAM_ID=this.props.match.params.exam_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
    
    window.scrollTo(0, 0); 
    this.getMarkingSystemData();  
  }

  getMarkingSystemData(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamMarkingSystem.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
      exam_id:this.state.EXAM_ID,
      report_card_type:'MT',
      report_card_id:'',
    
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
            mExamMarkingSystem:response.data
           
          })
          this.getParticipatingClass();
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  getParticipatingClass()
  {
      
    this.setState({         
      mExamParticipatingClass:this.state.mExamMarkingSystem[0]['participating_class'],
    })
    this.setClassList(0);
  }
  selectClass=(position)=>{  
    this.setClassList(position);
    
  }
  setClassList(selected_position){      
    if(this.state.mExamParticipatingClass.length!==0)
    {
        this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
        this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;

        if(this.state.SECTION_ID=="null")
        {
            this.state.SECTION_ID="No";
        }
        this.state.CLASS_SELECTED_POSITION=selected_position;

    }
 
    this.state.mMarkingSystem=this.state.mExamParticipatingClass[selected_position].marking_system;
    this.state.mMarkingSystemValue=this.state.mMarkingSystem[0].MarkingSystemValue;
    this.getClassStudentAttendanceReport();
   
  }

  getClassStudentAttendanceReport(){
  
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    this.state.REPORT_CARD_ID=this.state.mExamMarkingSystem[0].report_card_id;
    const FUNCTION_NAME='getClassStudentAttendanceReportMannual.php';
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
    console.log("URL>>>>>"+URL);
    var params = new URLSearchParams();
    params.append('school_code', SCHOOL_CODE);
    params.append('session_id', this.state.SESSION_ID);
    params.append('class_id', this.state.CLASS_ID);
    params.append('section_id', this.state.SECTION_ID);
    params.append('report_card_type',this.state.REPORT_CARD_TYPE);
    params.append('report_card_id',  this.state.REPORT_CARD_ID);
    axios.get(URL,{
      params
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        let api_status=response.api_status;
        // alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({         
              mStudentList:response.data,
                     
            })
          
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
    getTotalAttendane(item)
    {
      var AttendaceReport=[];
      var total_present=0;
      AttendaceReport=item.attendance_report;

      //alert(AttendaceReport)
      for (let index = 0; index < AttendaceReport.length; index++) {
        const element = AttendaceReport[index];
        total_present=Number(total_present)+Number(element.present);
        
      }
      return total_present;
    }
    getTotalWorkingDays(item)
    {
      var AttendaceReport=[];
      var total_working_days=0;
      AttendaceReport=item.attendance_report;

      //alert(AttendaceReport)
      for (let index = 0; index < AttendaceReport.length; index++) {
        const element = AttendaceReport[index];
        total_working_days=Number(total_working_days)+Number(element.working_days);
        
      }
      return total_working_days;
    }
 
    render() {
      if( this.state.loggedIn==false)
      {
         return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamMarkingSystem/:exam_id/:session_id'}  exact render={
            ()=>{
              
         return (
       
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Marking System</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
        
          <div className="row gravity_center" style={{margin:'0px 15px 0px 15px'}}>
                <div className="col-sm-4 col-md-4 bg_grid_white_2">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Academic Session:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['session_name']:''}  </p> 
                </div>
                <div className="col-sm-4 col-md-4 bg_grid_white_2">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['exam_name']:''} </p> 
                </div>
                <div className="col-sm-4 col-md-4 bg_grid_white_2">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam Date:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['start_date']:''}  </p> 
                </div>
                               
          </div>
          <div className="row gravity_center" style={{margin:'2px 15px 2px 15px'}}>
               
               <div className="bg_grid_white_2">
                 <p  style={{padding:'2px',fontSize:'18px'}}><strong> ReportCard Caption:</strong> {this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['report_card_name']:''} </p> 
               </div>
                
         </div>
          <div style={{padding:'0px 15px 0px 15px'}}><h5>Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>
       

        
              <div style={{padding:'0px 15px 0px 15px'}}><h5>Marking System:</h5></div>
                 <div className="row">
                            
                  <div className="col-sm-12 col-md-12" >
                  <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'5px 2px 10px 3px'}}>
                    <div className="row">
                     <div className="col-sm-8 col-md-8" >
                                                
                    <div className="gravity_center">
                        <p><strong> Marking System: </strong> </p> 
                    </div> 
                      </div>
                    </div>
                  
                    
                    <hr/>
                    <div className="row" style={{padding:'0px 15px 5px 15px'}}>
                      
                    <div className="col-sm-12 col-md-12" >                                          
                    <Table striped bordered hover variant="dark" style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Sr.</th>
                              <th style={{ textAlign: "center" }}>From</th>
                              <th style={{ textAlign: "center" }}>To</th>
                              <th style={{ textAlign: "center" }}>Marking</th>
                              <th style={{ textAlign: "center" }}>Performance Descriptor</th>
                              <th style={{ textAlign: "center" }}>Result</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mMarkingSystemValue && this.state.mMarkingSystemValue.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td style={{ textAlign: "center" }}>{index2+1}</td>
                              <td style={{ textAlign: "center" }}>{item2.from_value}</td>
                              <td style={{ textAlign: "center" }}>{item2.to_value}</td>
                              <td style={{ textAlign: "center" }}>{item2.marking}</td>
                              <td style={{ textAlign: "center" }}>{item2.performance_descriptors}</td>
                              <td style={{ textAlign: "center" }}>{item2.result}</td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                        
                      
                  </div>
                               
                  </div>
                  <hr/>             
                  
                  </div>
                </div> 
                                                                           
                </div>   
                <div style={{padding:'0px 15px 0px 15px'}}><h5>Student List:</h5></div>

                <div className="row bg_card_blue_outline  box_shadow_primary"  style={{margin:'0px 15px 0px 15px'}}>
                {this.state.LoadingStudent?<div><MyLoader/></div>:
              
               
                                                                 
                  <div className="col-sm-12 col-md-12 p-0">
                  
                  <Table striped bordered hover  style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Sr.</th>
                              <th style={{ textAlign: "center" }}>Student Name</th>
                              <th style={{ textAlign: "center" }}>Father name</th>
                              <th style={{ textAlign: "center" }}>Roll No</th>
                              <th style={{ textAlign: "center" }}>
                                  <p>Attendance</p>
                                  <p>(Attendance/Working Days)</p>
                              </th>
                              <th style={{ textAlign: "center" }}>Attendance(%)</th>
                              <th style={{ textAlign: "center" }}>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mStudentList && this.state.mStudentList.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td style={{ textAlign: "center" }}>{index2+1}</td>
                              <td style={{ textAlign: "center" }}>{item2.stu_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.father_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.student_roll_no}</td>
                              <td style={{ textAlign: "center" }}>{this.getTotalAttendane(item2)+"/"+this.getTotalWorkingDays(item2)}</td>
                              <td style={{ textAlign: "center" }}>{((this.getTotalAttendane(item2)/this.getTotalWorkingDays(item2))*100).toFixed(2)}</td>
                              <td className='gravity_center_row'> 
                                   <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.ViewStudentAttendance(item2)}>View</button>
                                   <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.EditStudentAttendance(item2)}>Edit</button>
                                   <div className='gravity_center'>              
                                     <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.REPORT_CARD_ID+"/"+this.state.REPORT_CARD_TYPE+"/"+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+item2.student_enrollment_no}>
                                           <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}}>Report Card</button>
                                       </Link>
                                  </div>
                              </td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                  </div>  
                           
                
                }

            </div>
            </div>
           
            
           
            {this.state.isOpenViewStudentAttendance===true? <MdlViewStudentAttendance selected_student={this.state.SELECTED_STUDENT} action={this.HandleCloseViewStudentAttendance}></MdlViewStudentAttendance>:''}

            {this.state.isOpenEditStudentAttendance===true? <MdlEditStudentAttendance selected_student={this.state.SELECTED_STUDENT} action={this.HandleCloseEditStudentAttendance}></MdlEditStudentAttendance>:''}

              
         
     </div>


           
           )
          }
          } />
              <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:report_card_type/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
                 
    </switch>       
   </Router>  
    )
  }
 }
 }