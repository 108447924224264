import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Iframe from 'react-iframe'
import MdlSelectMultipleStudent from '../dialog/MdlSelectMultipleStudent';
export default class ExamHallTicket extends Component {
 
     
  constructor(props){
            super(props)
        
            const token=localStorage.getItem("token");
            let loggedIn=true;
            if(token==null){
              loggedIn=false;
            }
        
            this.state={
        
                loggedIn:true,
                Loading:false, 
                isOpenSelectStudent:false,
                showStudentIdCard:false,
                Total:0,
                mSelectedStudent:[],
                mAdmitCardSamples:[],
                iFrameHeight:'0px',
                SELECTED_SAMPLE_ID:'',
                SELECTED_SAMPLE_POSITION:0,
                SELECTION_TYPE:'',
                FILE_URL:'',
                FILE_NAME:'',
                FILE_DIRECTORY:'',
                EXAM_ID:'',
                SESSION_ID:'',
                EXAM_NAME:'',
                EXAM_STATUS:'',

            }
            this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
            this.HandleCloseSelectStudent = this.HandleCloseSelectStudent.bind(this);
          
        }

        componentDidMount()
        {
          this.state.EXAM_ID=this.props.match.params.exam_id;
          this.state.SESSION_ID=this.props.match.params.session_id;
      
          this.state.EXAM_NAME=this.props.match.params.exam_name;
          this.state.EXAM_STATUS=this.props.match.params.exam_status;
          //this.getAdmitCardSamples();
        }
        HandleCloseSelectStudent = () => {

          this.setState({
            isOpenSelectStudent:false
          });  
           
        }
      
        HandleSelectedStudent = (mSelectedStudent) => {
         // var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
          if(mSelectedStudent==null){
            mSelectedStudent=[];
          }
          this.state.isOpenSelectStudent=false;
          this.state.showStudentIdCard=true;
          this.state.mSelectedStudent=mSelectedStudent;
          this.state.SELECTION_TYPE="student";
      
            
          this.setFileUrL();

        }
        SelectStudent=()=>{
         
          this.setState({
          
            isOpenSelectStudent:true,
          
          })
         
        }
       
        getAdmitCardSamples(){
          this.setState({
            Loading:true
          })
          
          const SCHOOL_CODE=SessionManager.getSchoolCode();
          const FUNCTION_NAME='getStudentIdCardSample.php?';
          URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
         // alert(URL);
          console.log("URL>>>>>"+URL);

          axios.get(URL,{
            params:{school_code:SCHOOL_CODE,
              
            }
          }).then(res => res.data)
          .then((response) => {
           
            this.setState({ 
              Loading:false
             })
              console.log("Response2>>>>>"+response);
              let api_status=response.api_status;
              //alert(JSON.stringify(response));
             
              if(api_status=="OK")
              {
              
                 this.setState({
                    mIdCardSamples:response.data,
                   
                  })
                  //alert("mIdCardSamples>>>>>"+JSON.stringify(this.state.mIdCardSamples));
                  this.setSample();
               
              }
              else{
                let api_remark=response.api_remark;
                myToast.notify_warning(api_remark);
              }
           }).catch((error)=>{
            this.setState({ 
              Loading:false
             })
            console.log("error>>>>>"+error);
            });
        
          }
          setSample(){
           
            var sample_id=this.state.mAdmitCardSamples[0].sample_id;
            this.state.FILE_NAME=this.state.mAdmitCardSamples[0].file_name;
            this.state.FILE_DIRECTORY=this.state.mAdmitCardSamples[0].file_directory;
            this.state.SELECTED_SAMPLE_POSITION=0;
            this.state.SELECTED_SAMPLE_ID=sample_id;
            this.setFileUrL();
             
          }
  setFileUrL(){
    var class_id="";
    var section_id="";
    var mStudentIds=[];
    this.state.FILE_NAME="HallTicket/hall_ticket_sample1.php";
    const SCHOOL_CODE=SessionManager.getSchoolCode();


    if(this.state.SELECTION_TYPE==='student')
    {

        for (let index = 0; index < this.state.mSelectedStudent.length; index++) {
          const element = this.state.mSelectedStudent[index];
          var student_id=element.student_enrollment_no;
          mStudentIds[index]=student_id;
          
        }
        //alert(JSON.stringify(mStudentIds));
    }
    this.state.FILE_URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR_PRINTABLE+this.state.FILE_NAME+'?&student_ids='+JSON.stringify(mStudentIds)+'&class_id='+class_id+'&section_id='+section_id+'&session_id='+this.state.SESSION_ID+'&exam_id='+this.state.EXAM_ID+'&exam_name='+this.state.EXAM_NAME+'&sample_id='+this.state.SELECTED_SAMPLE_ID+'&selection_type='+this.state.SELECTION_TYPE+'&school_code='+SCHOOL_CODE;
    console.log("FILE_URL",this.state.FILE_URL);
     //alert(this.state.FILE_URL);
    this.setState({
            
    });
  }        
          autoResize=()=>{
              alert("loaded");
              var iFrame = document.getElementById('iFrame1');
             
             
          }
        
          render() {
            if( this.state.loggedIn==false)
            {
             return <Redirect to='/Login'/>;
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
            else{
            return ( 
              
        
          <div className="content">
              <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#">Hall Ticket </a></li>
                  </ul>
                </div>
              </div>  
              
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
          <div className="row margin_10" style={{height:'120px'}}>
                <div className="col-sm-6 col-lg-6 padding_0" data-toggle="modal" data-target="#exampleModal" onClick={this.HandleShow}>
                    <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" onClick={this.SelectStudent}>                        
                          <h5 className="arrow"> Select Students Manually</h5>
                      </div>

                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5">
                          <h5 className="arrow"> Select Class</h5>
                      </div>
                    </div>
              </div>
              <div className="col-sm-6 col-lg-6 padding_0 height_match_parent" data-toggle="modal" data-target="#exampleModal" onClick={this.HandleShow}>
                    <div className="bg_card_primary_outline  height_match_parent" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      <div className="row">                        
                          <div className="col-sm-4 gravity_center padding_0">
                          <div className="rect_image_view" id="myStudentPicContainer">
                              <img src="<?php echo  $StudentData[$i]['student_img_path'] ;?>" class="img_logo" />
                          </div>
                          </div>
                          <div className="col-sm-8 gravity_center_vertical padding_0">
                              <div className="gravity_center_row ">
                                  <p className="margin_0" style={{color:'#000'}}> <b>Sample Name : </b></p>
                                  <p className="margin_0" style={{color:'#000'}}>{ this.state.mAdmitCardSamples.length!=0? this.state.mAdmitCardSamples[this.state.SELECTED_SAMPLE_POSITION].sample_name:'Default'}</p>
                            </div>
                            <div className="gravity_center_row ">
                                  <p className="margin_0" style={{color:'#000'}}> <b>Sample Description : </b></p>
                                  <p className="margin_0" style={{color:'#000'}}>{ this.state.mAdmitCardSamples.length!=0? this.state.mAdmitCardSamples[this.state.SELECTED_SAMPLE_POSITION].sample_remark:'Default'}</p>
                            </div>
                            <div className="gravity_center width_match_parent margin_5 padding_5" style={{width:'120px'}}>
                                  <button className="bg_card_primary btn btn-success">Change Sample</button>
                              </div>
                          </div>
                         

                      </div>
                     
                    </div>
              </div>

            </div>
            <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Preview</h5>
            </div>

            {this.state.showStudentIdCard?
             
              <div className="row"  style={{margin:'0px 10px 0px 10px'}}>
                                           
                <Iframe url={this.state.FILE_URL}
                        width="100%"
                        height="800px"
                        overflow="visible"
                        id="iFrameStudentId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        onLoad={() => {
                         
                           //this.autoResize();
                      }} 
                      />
                        
               
              </div> 
              :''}
          </div>
          {this.state.isOpenSelectStudent===true? <MdlSelectMultipleStudent actionClose={this.HandleCloseSelectStudent} actionHandleSelected={this.HandleSelectedStudent}></MdlSelectMultipleStudent>:''}

               
         </div>
  
  )
}    
}
 
}