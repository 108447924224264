import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import UpdateStudentDetails from './UpdateStudentDetails';
import Acknowledgement from './Acknowledgement';
import myToast from '../Utils/MyToast';
import Form from 'react-bootstrap/Form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import StRegSuccessfull from './StRegSuccessfull';
import EditStudentDetails from './EditStudentDetails';
import icon_edit from '../resources/icons/icons_pencil_100.png'
import COLORS from '../resources/colors/COLORS';
export default class StudentDetails extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      mStudentDetails: [],
      mclass: [],
      mContactDetails: [],
      mFamilyDetails: [],
    }

  }
  componentDidMount() {
    this.StudentDetails();
  }
  ClstuDetails = () => {
    var arr = [];
    arr = this.state.mStudentDetails;
    localStorage.setItem("STDetails", JSON.stringify(arr));
    window.location.reload();
    window.location = "/AdminMain/UpdateStudentDetails";
  }


  StudentDetails() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    const STUDENT_ID = this.props.match.params.student_id;

    const Adhar_ID = "";
    const FUNCTION_NAME = 'searchStudentDetails.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: { school_code: SCHOOL_CODE, enrollment_no: STUDENT_ID, session_id: SESSION_ID, aadhar_no: Adhar_ID }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mStudentDetails: response.data,
            mclass: response.data[0].class,
            mContactDetails: response.data[0].contact_details,
            mFamilyDetails: response.data[0].family_details
          })

          // console.log("response>>>>>" + this.state.mStudentDetails);
        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <Router>
          <switch>
            <Route path='/AdminMain/StudentDetails/' render={
              () => {

                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Student Details</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="row animated fadeInUp">
                      <div className="col-sm-12 col-lg-12">
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="panel">
                              <div className="panel-content text-center" style={{ minHeight: '400px' }}>

                                {/* content body */}
                                <div className="panel-body">
                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="form-horizontal form-bordered margin_left">
                                      <div className=" bg_card_blue_outline1 s mar_bottom bg_admission form-group ">
                                        <div className='row'>
                                          <div className="col-sm-3  mar_bottom mar_top " style={{ height: '100px', width: '100px' }}>
                                            <div class="circular_image2">
                                              <img src={this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].student_img_path : ''} className="img_staff1" alt="student list image" />
                                            </div>
                                          </div>

                                          <div className="col-md-6 mar_top">
                                            <div>
                                              <h3 className="input-group font_size1 gravity_center padding_0 mar_top">{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].stu_name : ''} </h3>
                                            </div>
                                            <div>
                                             
                                            </div>
                                          </div>
                                          <div className="col-sm-3  mar_bottom mar_top " style={{ height: '100px', width: '100px' }}>
                                            <div className="col-sm-12 col-lg-12 text-center">
                                              <div className="bg-info-1" >
                                                <div onClick={this.ClstuDetails} style={{cursor:'pointer'}}>
                                                  <a><img src={icon_edit} style={{ height: '80px', width: '80px' }} /></a>
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <h3> Edit Information</h3>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                  <div className="pnlSubHedingBorder">
                                    <div className="pnlSubHeding heading-with-bg-w">
                                      Basic Information
                                    </div>
                                  </div>

                                  <div className="row mar_top">

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Student Name</label>
                                        </div>
                                        <div className="col-md-8 padding_0">

                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].stu_name : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">AdharNo.</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].adharcard_no : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Gender</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].gender : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">DOB</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].birthday : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Religion</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].religion : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Nationality</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].nationality : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className='row'>
                                        <div className="col-md-4 padding_0 admission_prvform1">
                                          <label className="">Community</label>
                                        </div>
                                        <div className="col-md-8 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].community : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Current Address
                                      </div>
                                    </div>


                                    <div className="col-md-12">
                                      <div className='row'>
                                        <div className="col-md-3 padding_0 admission_prvform1 mar_top">
                                          <label className="">Current Address:</label>
                                        </div>
                                        <div className="col-md-9 padding_0">

                                          <div className="input-group admission_form2 border_form1 gravity_center">
                                            <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].current_address : ''} </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                  <div className="pnlSubHedingBorder headMarginTop">
                                    <div className="pnlSubHeding heading-with-bg-w">
                                      Permanent Address
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className='row'>
                                      <div className="col-md-3 padding_0 admission_prvform1 mar_top">
                                        <label className=""> Permanent Address:</label>
                                      </div>
                                      <div className="col-md-9 padding_0">

                                        <div className="input-group admission_form2 border_form1 gravity_center">
                                          <h3>{this.state.mStudentDetails.length !== 0 ? this.state.mStudentDetails[0].permanent_address : ''} </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>




                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Family Information
                                      </div>
                                    </div>
                                    <div className="row mar_top">
                                      <div className='col-md-6 border_form2'>
                                        <div className="row">
                                          <div className='col-md-3 padding_0 mar_top mar_bottom' style={{ height: '100px', width: '100px' }}>
                                            <div class="circular_image2">
                                              <img src={this.state.mFamilyDetails.length !== 0 ? this.state.mFamilyDetails[0].father_img_path : ''} className="img_staff1" alt="Fother image" />
                                            </div>
                                          </div>
                                          <div className='col-md-9 '>
                                            <div className='row'>
                                              <div className="col-md-6 padding_0 admission_prvform1 mar_top">
                                                <label className="">Father Name:</label>
                                              </div>
                                              <div className="col-md-6 padding_0">

                                                <div className="input-group admission_form2">
                                                  <h3 className="input-group admission_form2 mar_top">{this.state.mFamilyDetails.length !== 0 ? this.state.mFamilyDetails[0].father_name : ''} </h3>
                                                </div>
                                              </div>

                                              <div className="col-md-6 padding_0 admission_prvform1 mar_top">
                                                <label className="">Occupation:</label>
                                              </div>
                                              <div className="col-md-6 padding_0">

                                                <div className="input-group admission_form2">
                                                  <h3 className="input-group admission_form2 mar_top">{this.state.mFamilyDetails.length !== 0 ? this.state.mFamilyDetails[0].father_occupation : ''} </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                      <div className='col-md-6 border_form2'>
                                        <div className="row">
                                          <div className='col-md-3 padding_0 mar_top mar_bottom' style={{ height: '100px', width: '100px' }}>
                                            <div class="circular_image2">
                                              <img src={this.state.mFamilyDetails.length !== 0 ? this.state.mFamilyDetails[0].mother_img_path : ''} className="img_staff1" alt="Mother image" />
                                            </div>
                                          </div>
                                          <div className='col-md-9 '>
                                            <div className='row'>
                                              <div className="col-md-6 padding_0 admission_prvform1 mar_top">
                                                <label className="">Mother Name:</label>
                                              </div>
                                              <div className="col-md-6 padding_0">

                                                <div className="input-group admission_form2">
                                                  <h3 className="input-group admission_form2 mar_top">{this.state.mFamilyDetails.length !== 0 ? this.state.mFamilyDetails[0].mother_name : ''} </h3>
                                                </div>
                                              </div>

                                              <div className="col-md-6 padding_0 admission_prvform1 mar_top">
                                                <label className="">Occupation:</label>
                                              </div>
                                              <div className="col-md-6 padding_0">

                                                <div className="input-group admission_form2">
                                                  <h3 className="input-group admission_form2 mar_top">{this.state.mFamilyDetails.length !== 0 ? this.state.mFamilyDetails[0].mother_occupation : ''} </h3>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                    </div>

                                  </div>

                                  <div style={{ float: 'left', width: '100%' }}>
                                    <div className="pnlSubHedingBorder headMarginTop">
                                      <div className="pnlSubHeding heading-with-bg-w">
                                        Contact Details
                                      </div>
                                    </div>
                                    {this.state.mContactDetails && this.state.mContactDetails.map((item, index) => (
                                      <div className='row mar_top'>
                                        <div className="col-md-2 padding_0 admission_prvform2">
                                          <label className=""> Contact Number 1</label>
                                        </div>
                                        <div className="col-md-2 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mContactDetails.length !== 0 ? this.state.mContactDetails[index].contact_number : ''} </h3>
                                          </div>
                                        </div>
                                        <div className="col-md-2 padding_0 admission_prvform2">
                                          <label className=""> Name</label>
                                        </div>
                                        <div className="col-md-2 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mContactDetails.length !== 0 ? this.state.mContactDetails[index].contact_person_name : ''} </h3>
                                          </div>
                                        </div>

                                        <div className="col-md-2 padding_0 admission_prvform2">
                                          <label className="">Relation</label>
                                        </div>
                                        <div className="col-md-2 padding_0">
                                          <div className="input-group admission_form2 border_form gravity_center">
                                            <h3>{this.state.mContactDetails.length !== 0 ? this.state.mContactDetails[index].contact_person_relation : ''} </h3>
                                          </div>
                                        </div>

                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            } />
            <Route path='/AdminMain/EditStudentDetails/:student_id' component={EditStudentDetails} />
            <Route path='/AdminMain/Acknowledgement/:Enrollment_no/:Session_id' component={Acknowledgement} />
            <Route path='/AdminMain/UpdateStudentDetails' component={UpdateStudentDetails} />
          </switch>

        </Router>

      )
    }
  }

}
