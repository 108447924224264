import React, { Component } from 'react';
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import axios from 'axios';
import { Button } from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import SessionManager from '../Utils/SessionManager';
import MyLoader from '../Spinner/MyLoader';
import MdlSelectMultipleStudent from '../dialog/MdlSelectMultipleStudent';
import MdlSelectMultipleClass from '../dialog/MdlSelectMultipleClass';
import myToast from '../Utils/MyToast';

export default class SendNotification extends Component {
  constructor(props) {
    super(props)


    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: false,
      Loading: false,
      isOpenSelectStudent:false,
      isOpenSelectClass:false,
      showSelectedStudent:false,
      showSelectedClass:false,
      mSelectedStudent:[],
      mSelectedClass:[],
      RecipientList:[],
      

      isCheckedChkGlobal:false,
      isCheckedChkStudent:false,
      isCheckedChkClass:false,
      isCheckedChkStaff:false,

      showingCardGlobal:false,
      showingCardStudent:false,
      showingCardClass:false,
      showingCardStaff:false,

      FLAG_ALL_STUDENT:true,
      FLAG_SELECT_STUDENT:false,
      FLAG_ALL_CLASS:true,
      FLAG_SELECT_CLASS:false,
      FLAG_SELECT_STAFF:false,
      FLAG_ALL_STAFF:false,
      input_ordered_by:'Principal',
      input_notice:'',
      input_message_title:''


    }
  
    this.regChange = this.regChange.bind(this);
    this.SubmitNotification = this.SubmitNotification.bind(this);
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleSelectedClass = this.HandleSelectedClass.bind(this);
    this.HandleCloseSelectStudent = this.HandleCloseSelectStudent.bind(this);
    this.HandleCloseSelectClass = this.HandleCloseSelectClass.bind(this);
    
  }
  regChange(e) {

    this.setState({
      [e.target.id]: e.target.value
    })
  }
  
  HandleCloseSelectStudent = () => {

    this.setState({
      isOpenSelectStudent:false
    });  
     
  }
  
  HandleCloseSelectClass = () => {

    this.setState({
      isOpenSelectClass:false
    });  
     
  }
  

  HandleSelectedClass = () => {
    var mSelectedClass=JSON.parse(localStorage.getItem("SelectedMultipleClass"));
    if(mSelectedClass==null){
      mSelectedClass=[];
    }

    this.setState({
      isOpenSelectClass:false,
      showSelectedClass:true,
      mSelectedClass:mSelectedClass
    });  
  }

  HandleSelectedStudent = (mSelectedStudent) => {
   // var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
    if(mSelectedStudent==null){
      mSelectedStudent=[];
    }

    this.setState({
      isOpenSelectStudent:false,
      showSelectedStudent:true,
      mSelectedStudent:mSelectedStudent
    });  
  }

  onChangeChkGlobal=(e)=>{
    let isChecked = e.target.checked;
    if(isChecked)
      {                  
                  this.setState({
                    isCheckedChkGlobal:true,
                    isCheckedChkStudent:false,
                    isCheckedChkClass:false,
                    isCheckedChkStaff:false,
                    showingCardGlobal:true,
                    showingCardStudent:false,
                    showingCardClass:false,
                    showingCardStaff:false,
          
                  })

     }
      else {         
        this.setState({
          isCheckedChkGlobal:false,
          showingCardGlobal:false,
                       
        })
                
     }
   
  }
  onChangeChkStudent=(e)=>{
    let isChecked = e.target.checked;
    if(isChecked)
      {                  
                  this.setState({
                    isCheckedChkGlobal:false,
                    isCheckedChkStudent:true,
                    showingCardStudent:true,
                    FLAG_ALL_STUDENT:true,
          
                  })

     }
      else {         
        this.setState({
          isCheckedChkStudent:false,
          showingCardStudent:false,
                       
        })
                
     }
   
  }
  onChangeChkClass=(e)=>{
    let isChecked = e.target.checked;
    if(isChecked)
      {                  
                  this.setState({
                    isCheckedChkGlobal:false,
                    isCheckedChkClass:true,
                    showingCardClass:true,
                    FLAG_ALL_CLASS:true
          
                  })

     }
      else {         
        this.setState({
          isCheckedChkClass:false,
          showingCardClass:false,
                       
        })
                
     }
   
  }
  onChangeChkStaff=(e)=>{
    let isChecked = e.target.checked;
    if(isChecked)
      {                  
                  this.setState({
                    isCheckedChkGlobal:false,
                    isCheckedChkStaff:true,
                    showingCardStaff:true,
          
                  })

     }
      else {         
        this.setState({
          isCheckedChkStaff:false,
          showingCardStaff:false,
                       
        })
                
     }
   
  }
  ToAllStudent=()=>{
    this.setState({
      FLAG_ALL_STUDENT:true,
      FLAG_SELECT_STUDENT:false,
      FLAG_ALL_CLASS:false,
      FLAG_SELECT_CLASS:false,
      showingCardClass:false,
      isCheckedChkClass:false,
    })
    
   
  }
  SelectStudent=()=>{
    this.setState({
      FLAG_ALL_STUDENT:false,
      FLAG_SELECT_STUDENT:true,
      FLAG_ALL_CLASS:false,
      isOpenSelectStudent:true
      

    })
   
  }
  ToAllClass=()=>{
    this.setState({
      FLAG_ALL_CLASS:true,
      FLAG_SELECT_CLASS:false,
      FLAG_ALL_STUDENT:false,
      FLAG_SELECT_STUDENT:false,
      showingCardStudent:false,
      isCheckedChkStudent:false,

    })
  }
  SelectClass=()=>{
    this.setState({
      FLAG_ALL_CLASS:false,
      FLAG_SELECT_CLASS:true,
      FLAG_ALL_STUDENT:false,
                
      isOpenSelectClass:true
      

    })
   
  }

  SubmitNotification() {
   
     if(this.isValidInput())
     {
      this.setState({
        Loading: true
      })
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData=SessionManager.getStaffDetails();
      var STAFF_ID=mStaffData.staff_id;
      this.prepareRecipient();
      
      let Recipient = JSON.stringify(this.state.RecipientList);
      const FUNCTION_NAME ='sendNotificationToStudents.php';
      URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME;
      console.log("URL>>>>>" + URL);
      axios.get(URL,{
        params:{
          title:this.state.input_message_title,
          message:this.state.input_notice,
          recipient:Recipient,          
          staff_id:STAFF_ID,
          department:this.state.input_ordered_by,
          school_code:SCHOOL_CODE,
          
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
         //alert(JSON.stringify(response));
          let api_status=response.api_status;
          //alert(api_status);
          
          if(api_status=="OK")
          {
           
             alert("Submitted Successfully");  
             
             this.props.history.push('/AdminMain/Notification');


             this.setState({

             })
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      
      }
  }
  prepareRecipient(){
    this.state.RecipientList=[];
            if(this.state.isCheckedChkGlobal)
            {
                this.state.RecipientList.push("GLOBAL");
            }
            else {

                if (this.state.isCheckedChkStudent)
                {
                    if(this.state.FLAG_ALL_STUDENT)
                    {
                      this.state.RecipientList.push("ALL_STUDENT");
                    }
                    else if(this.state.FLAG_SELECT_STUDENT)
                    {
                        for (var i=0;i<this.state.mSelectedStudent.length;i++)
                        {
                           var ENROLLMENT_NO=this.state.mSelectedStudent[i].student_enrollment_no;
                           this.state.RecipientList.push(ENROLLMENT_NO);
                        }

                    }
                }
                if(this.state.isCheckedChkClass)
                {
                    if(this.state.FLAG_ALL_CLASS)
                    {
                      this.state.RecipientList.push("ALL_CLASS");
                    }
                    else if(this.state.FLAG_SELECT_CLASS)
                    {
                        for (var i=0;i<this.state.mSelectedClass.length;i++)
                        {
                            let class_id=this.state.mSelectedClass[i].class_id;
                            let section_id=this.state.mSelectedClass[i].section_id;
                            if(section_id!=null)
                            {
                              this.state.RecipientList.push(class_id+"#"+section_id);
                            }
                            else {
                              this.state.RecipientList.push(class_id+"#"+"No");
                            }

                        }
                    }
                }
                if(this.state.isCheckedChkStaff)
                {
                    if(this.state.FLAG_ALL_STAFF)
                    {
                      this.state.RecipientList.push("ALL_STAFF");
                    }
                    else if(this.state.FLAG_SELECT_STAFF)
                    {

                    }
                }
            }
  }

  
isValidInput()
{
      
        if(this.state.input_message_title==="")
        {
          alert('Enter Message  Title');
          return false;
        }
       else if(this.state.input_notice==="")
       {
         alert('Enter Message');
         return false;
       }
       else if(this.state.input_ordered_by==="")
       {
        alert('Enter Ordered By');
        return false;

       }
       else if(!this.state.isCheckedChkGlobal && !this.state.isCheckedChkStudent && !this.state.isCheckedChkClass && !this.state.isCheckedChkStudent)
       {
        alert('Please Select Recipient');
        return false;
      
        }
       else{
          return true;
       }

  }

  render() {

    if (this.state.Loading) {
      return <MyLoader />
    }
    else {
      return (
        <Router>
        <switch>   
        <Route path='/AdminMain/SendNotification'  exact render={
          ()=>{
  
            return(
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Add Notification </a></li>
              </ul>
            </div>
          </div>
          
          <div className="row animated fadeInUp">
            {/*             Add field area                  */}

            <div className="col-sm-1 col-md-1">

            </div>
            <div className="col-sm-10 col-md-10">
              <div className="row">


              <div className="col-sm-12 col-md-12">
                  <div className="input-group">
                    <h5>Message Title :</h5>
                    <input type="text" id="input_message_title" value={this.state.input_message_title} onChange={this.regChange} className="notice-order" />
                  </div>

                </div>
                <div className="col-sm-12 col-md-12">

                  <div className="input-group">
                    <h5>Add Message:</h5>
                    <input type="text" id="input_notice" value={this.state.input_notice} onChange={this.regChange} className="add-notice-text-p" />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12">
                  <div className="input-group">
                    <h5>Ordered By:</h5>
                    <input type="text" id="input_ordered_by" value={this.state.input_ordered_by} onChange={this.regChange} className="notice-order" />
                  </div>

                </div>

              </div>
              <div className="row">
                <div className="col-md-12 class-list-box-privelege">
                  <div className="radio radio-css radio-inline ment">
                    <input type="checkbox" id="issue_notice_gs" value={this.state.isCheckedChkGlobal} checked={this.state.isCheckedChkGlobal} className="ch-in" onChange={e=>this.onChangeChkGlobal(e)} />
                    <label className="ch-text-s">Issue Notice To Global School</label>
                  </div>
                  <Card style={{ display: (this.state.showingCardGlobal ? 'block' : 'none') }}>
                          <Card.Body>
                            <h5>All Student ,All Staff</h5>                                            
                          </Card.Body>
                  </Card>
                </div>
              </div>
              {/* ////////////////////////// Issue Notice To Student section ////////////////////// */}
              <div className="row">
                <div className="col-md-12 class-list-box-privelege">
                  <div className="radio radio-css radio-inline ment">
                    <input type="checkbox" id="issue_notice_s" value={this.state.isCheckedChkStudent} checked={this.state.isCheckedChkStudent} className="ch-in" onChange={e=>this.onChangeChkStudent(e)} />
                    <label className="ch-text-s"> Issue Notice To Student</label>
                  </div>
                  <Card style={{ display: (this.state.showingCardStudent ? 'block' : 'none') }}>
                          <Card.Body>
                            
                  <div className="row">
                    <div className="col-md-12">
                      <div className="radio radio-css">
                        <button className={this.state.FLAG_ALL_STUDENT? "b-color":"b-color-w"} onClick={this.ToAllStudent}>To all Student</button>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ display: (this.state.FLAG_ALL_STUDENT ? 'block' : 'none') }}>
                      <p className="text-justify" style={{ margin: '20px 0px', border: '2px solid #fff', padding: '18px', fontSize: '20px' }}>All Student Selected </p>
                    </div>


                    <div className="col-md-12">
                      <div className="radio radio-css">
                        <button className={this.state.FLAG_SELECT_STUDENT? "b-color":"b-color-w"} onClick={this.SelectStudent}>Select Student</button>
                      </div>
                      <div className="animated fadeInUp" style={{ margin: ' 30px 0px' }}>
                        

              {this.state.showSelectedStudent?
              
              this.state.mSelectedStudent.map((item, index) => ( 
                                                                
                <div className="col-sm-6 col-md-6 p-0">
                
                 <div className="row class-list-box bb-r-c height-130">
                
                 <div className="col-sm-3 cl-3-part p-0" style={{background:'#129cd9'}}>
                   <div className="profil-im-img" style={{background:'rgb(219 238 246)'}}>
                   <img src="https://www.galaxyskills.org/wp-content/uploads/2019/02/dummy-350x350.png" alt="student due" style={{with:'100%'}}/>
                   <p className="stf-bg"> <small> {item.student_enrollment_no} </small></p>
                   </div>
                  
                 </div>
                 <div className="col-sm-5">
                  <h5> {item.stu_name} </h5>
                  <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                  <p className="mb-0"> Board: <span>{item.class[0].board_name}</span> </p>
                  <p className="mb-0"> Medium: <span>{item.class[0].medium_name}</span> </p>
                 
                 </div>
                 <div className="col-sm-4 text-center bb-r-c">
                 
                  <p className="mb-0"> Roll No. </p>

                  <p> <div className="st-list-roll-n" style={{background:'#fff', width:'40px', height:'40px',padding:'5px',borderRadius:'50%',
                   margin:'5px auto',fontSize:'20px'}}>{item.class[0].student_roll_no}</div> </p>
                  <h6>Class:<span> {item.class[0].class_name} </span>  </h6>
                 </div>
                </div>
               
                </div>                   
                )):''}
                    
                  </div>
                </div>
                    

                  </div>
                                            
                          </Card.Body>
                  </Card>
               
                </div>
              </div>
              
            {this.state.isOpenSelectStudent===true? <MdlSelectMultipleStudent actionClose={this.HandleCloseSelectStudent} actionHandleSelected={this.HandleSelectedStudent}></MdlSelectMultipleStudent>:''}



              {/* ///////////// Issue Notice To Class section ////////////////////*/}
              <div className="row">
                <div className="col-md-12" style={{ background: '#fff', margin: '5px' }}>
                  <div className="radio radio-css radio-inline ment">
                    <input type="checkbox" id="issue_notice_c" value={this.state.isCheckedChkClass} checked={this.state.isCheckedChkClass} className="ch-in" onChange={e=>this.onChangeChkClass(e)} />
                    <label className="ch-text-s"> Issue Notice To Class</label>
                  </div>
                  <Card style={{ display: (this.state.showingCardClass ? 'block' : 'none') }}>
                          <Card.Body>
                          <div className="row">
                    <div className="col-md-12">
                      <div className="radio radio-css">
                        <button className={this.state.FLAG_ALL_CLASS? "b-color":"b-color-w"} onClick={this.ToAllClass}>To all Class</button>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ display: (this.state.FLAG_ALL_CLASS ? 'block' : 'none') }}>
                      <p className="text-justify" style={{ margin: '20px 0px', border: '2px solid #fff', padding: '18px', fontSize: '20px' }}>All Class Selected </p>
                    </div>
                    <div className="col-md-12">
                      <div className="radio radio-css">
                        <button className={this.state.FLAG_SELECT_CLASS? "b-color":"b-color-w"} onClick={this.SelectClass}>Select Class</button>
                      </div>
                    </div>
                    
                    {this.state.showSelectedClass?
              
                    this.state.mSelectedClass.map((item, index) => ( 
                    <div className="col-sm-4 col-md-4">

                      <div className="row class-list-box bb-r-c p-1">
                        <div className="col-sm-3 cl-3-part p-0 text-center text-white" style={{ background: '#129cd9' }}>
                          <h3>{item.student}</h3>
                          <p>Student</p>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0"> <strong>{item.section==='No'?'Class:'+item.class_name:item.class_name+':'+item.section_name}</strong> </p>
                          <p className="mb-0"> Board: <span>{item.board_name}</span> </p>
                          <p className="mb-0"> Medium: <span>{item.medium_name}</span> </p>

                        </div>
                        <div className="col-sm-3 text-center bb-r-c">
                          <p> <div className="st-list-roll-n" style={{
                            background: '#fff', width: '40px', height: '40px', padding: '5px', borderRadius: '50%',
                            margin: '5px auto', fontSize: '20px'
                          }}> <input type="checkbox" id="issue_notice_c" class="ch-in" value="" checked /></div> </p>
                        </div>
                      </div>

                  </div>

                     )):''}
                  </div>
                                                           
                          </Card.Body>
                  </Card>

                
                </div>
                
                 {this.state.isOpenSelectClass===true? <MdlSelectMultipleClass action={this.HandleCloseSelectClass} action2={this.HandleSelectedClass}></MdlSelectMultipleClass>:''}

              </div>


              {/* //////////////////////////////// Issue Notice To Staff Section ////////////////////////////  */}

              <div className="row">
                <div className="col-md-12 class-list-box-privelege">
                  <div className="radio radio-css radio-inline ment">
                    <input type="checkbox" id="issue_notice_st" value={this.state.isCheckedChkStaff} checked={this.state.isCheckedChkStaff} className="ch-in" onChange={e=>this.onChangeChkStaff(e)} />
                    <label className="ch-text-s"> Issue Notice To Staff</label>
                  </div>
                  <Card style={{ display: (this.state.showingCardStaff ? 'block' : 'none') }}>
                          <Card.Body>
                            <h5>All Staff</h5>                                            
                          </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-sm-1 col-md-1">

            </div>
                             
            <div style={{ float: 'left', width: '100%', marginTop: '45px' }}>
              <hr />
              <div className="form-horizontal form-bordered">
                <div className="form-group row">

                  <div className="col-md-12">
                    <center>
                      <button className="btn btn-success" onClick={this.SubmitNotification} style={{ width: '25%', fontSize: '24px' }}> Send Notification </button>

                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
   } />                       
   
     <Route path='/AdminMain/Notification'  component={Notification} />   
        
     </switch>
     
    </Router>
   )
   
    }
  }
}
