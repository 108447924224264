import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class HelpDesk extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mHelpdesk:[]
    }
  
  }
  componentDidMount()
  {
  this.getContactUsList();
  }
  getContactUsList(){
  
  this.setState({
    Loading:true
  })
  // const SCHOOL_CODE=SessionManager.getSchoolCode();
  // const Sess="SSN0001";
  // const Lim="30";
  // const Offset="0";
  const FUNCTION_NAME='getContactUsList.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)  
  axios.get(URL,{
    params:{

    }
  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            mHelpdesk:response.data
          })
          console.log("response>>>>>"+JSON.stringify(this.state.mHelpdesk));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
      return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Help Desk </a></li>
              </ul>
            </div>
          </div>
              <div className="row class-li">
              {this.state.mHelpdesk.map((item, index) => ( 
                <div className="col-sm-12 col-md-12">
                <div className="row class-list-box">
                 <div className="col-sm-3 text-center">
                   <div><img src="https://icons.iconarchive.com/icons/graphicloads/business/512/contact-icon.png" alt="staff list image" style={{width:'30%!important', height:'70px'}}/></div>
                 </div>
                 <div className="col-sm-5 col-md-5 text-center border-l-r">
                  <h3 className="text-success"> {item.contact_name} ({item.designation})</h3>
                  <hr/>
                  <p><strong className="text-info"> {item.languages} </strong>  </p>
                 </div>
                 <div className="col-sm-4 col-md-4 text-center">
                <h4>{item.on_call==1?<><span><img src="https://cdn.pixabay.com/photo/2018/04/28/17/38/contact-icon-3357824_960_720.png" alt="staff list image" style={{width:'10%'}}/></span> <span> {item.contact_number}</span></> :''} </h4>
                <h4>
                
                {item.on_whatsapp==1?<> <span><img src="https://p7.hiclipart.com/preview/463/954/394/whatsapp-iphone-messaging-apps-facebook-messenger-viber-thumbnail.jpg" alt="staff list image" style={{width:'10%'}}/></span><span> {item.contact_number}</span></> :''} </h4>
                 </div>
                </div>
                </div>
              ))}
                </div>
               </div>
  
  )
} 
}
}
